import React, { useCallback, useContext, useMemo } from 'react';
import { DiagramModelContext } from '../../../editor/DiagramModelContext';
import { EngineContext } from '../../../editor/EngineContext';
import { useDirectory } from '../../../hooks/useDirectory';
import { TreeNode, TreeNodeChildFactory } from '../../../widgets/tree/TreeNode';
import { Styled as S } from '../../../widgets/library/Library.styled';
import { Tree } from '../../../widgets/tree/Tree';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdModel } from '../../../editor/scd/ScdModel';
import { ScdPacsTreeControllerType } from './ScdPacsTreeControllerType';
import { ScdPacsTreeControllerFactory } from './ScdPacsTreeController';
import { ScdPacsTreeLogicDeviceFactory } from './ScdPacsTreeLogicDevice';
import {
  ExtendableTreeNode,
  HasExtendableParent,
  TreeDraggableType,
  TreeNodeModel,
} from '../../../widgets/tree/state/TreeState';
import { TreeDraggable } from '../../../widgets/tree/TreeDraggable';
import { TreeDroppable } from '../../../widgets/tree/TreeDroppable';
import { ListDropHighlighter } from '../../../widgets/tree/ListDropHighlighter';
import { SelectableTreeNodeWidget } from '../../../widgets/tree/SelectableTreeNodeWidget';

export const ScdStructureTreeDndFormatDragId: Partial<Record<TreeDraggableType, string>> = {
  Controller: 'ScdStructureTreeDndFormatController',
  LogicDevice: 'ScdStructureTreeDndFormatLogicDevice',
};
export const ScdStructureTreeDndFormatDropId: Partial<Record<TreeDraggableType, string[]>> = {
  Controller: [
    ScdStructureTreeDndFormatDragId[TreeDraggableType.Controller]!,
    ScdStructureTreeDndFormatDragId[TreeDraggableType.LogicDevice]!,
  ],
  LogicDevice: [ScdStructureTreeDndFormatDragId[TreeDraggableType.LogicDevice]!],
};

interface SsdPacsTreeProps {
  locked?: boolean;
}

export const ScdPacsTree: React.FC<SsdPacsTreeProps> = ({ locked = false }) => {
  const model = useContext(DiagramModelContext) as ScdModel;
  const engine = useContext(EngineContext) as ScdEngine;
  const { controllerDirectory } = useDirectory();

  const iedNode = useMemo(() => {
    return ScdPacsTreeControllerType(
      model,
      'IED',
      controllerDirectory,
      ScdPacsTreeControllerFactory(engine, model, ScdPacsTreeLogicDeviceFactory(engine))
    );
  }, [model, controllerDirectory, engine]);

  const muNode = useMemo(() => {
    return ScdPacsTreeControllerType(
      model,
      'MU',
      controllerDirectory,
      ScdPacsTreeControllerFactory(engine, model, ScdPacsTreeLogicDeviceFactory(engine))
    );
  }, [model, controllerDirectory, engine]);

  return (
    <S.Library>
      <S.Header>{'PACS structure'}</S.Header>
      <Tree>
        <ScdPacsTreeDroppable model={iedNode} type={TreeDraggableType.Controller}>
          <TreeNode
            model={iedNode}
            childFactory={locked ? lockedControllerFactory : controllerFactory}
            hasParent={false}
            indentLevel={0}
            last
            initialOpen
          />
        </ScdPacsTreeDroppable>
        <ScdPacsTreeDroppable model={muNode} type={TreeDraggableType.Controller}>
          <TreeNode
            model={muNode}
            childFactory={locked ? lockedControllerFactory : controllerFactory}
            hasParent={false}
            indentLevel={0}
            last
            initialOpen
          />
        </ScdPacsTreeDroppable>
      </Tree>
    </S.Library>
  );
};

const controllerFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <ScdPacsTreeDraggable type={TreeDraggableType.Controller} model={model}>
      <ScdPacsTreeDroppable model={model} type={TreeDraggableType.LogicDevice}>
        <TreeNode model={model} childFactory={logicDeviceFactory} indentLevel={indentLevel + 1} last={last} />
      </ScdPacsTreeDroppable>
    </ScdPacsTreeDraggable>
  </SelectableTreeNodeWidget>
);

const lockedControllerFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} childFactory={lockedLogicDeviceFactory} indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);

const logicDeviceFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <ScdPacsTreeDraggable type={TreeDraggableType.LogicDevice} model={model}>
      <ScdPacsTreeDroppable model={model} type={TreeDraggableType.LogicDevice}>
        <TreeNode model={model} indentLevel={indentLevel + 1} last={last} />
      </ScdPacsTreeDroppable>
    </ScdPacsTreeDraggable>
  </SelectableTreeNodeWidget>
);

const lockedLogicDeviceFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);

interface ScdStructureTreeDraggableProps {
  type: TreeDraggableType;
  model: TreeNodeModel;
}

const ScdPacsTreeDraggable: React.FC<ScdStructureTreeDraggableProps> = ({ type, model, children }) => {
  const payload = useMemo(() => ({ type, modelId: model.getKey() }), [model, type]);
  const handleGetDragElement = useCallback(() => undefined, []);
  return (
    <TreeDraggable
      getDragElement={handleGetDragElement}
      formatId={ScdStructureTreeDndFormatDragId[type]!}
      payload={payload}
    >
      {children}
    </TreeDraggable>
  );
};

const ScdPacsTreeDroppable: React.FC<{ model: ExtendableTreeNode & HasExtendableParent; type: TreeDraggableType }> = ({
  model,
  type,
  children,
}) => {
  return (
    <TreeDroppable formatId={ScdStructureTreeDndFormatDropId[type]!} model={model} dropChild={ListDropHighlighter}>
      {children}
    </TreeDroppable>
  );
};
