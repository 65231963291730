import styled from 'styled-components';
import { Styled as StyledInputField } from '../../../../widgets/InputField.styled';
import { Styled as StyledSettings } from '../../../Settings.styled';
import { Styled as StyledModal } from '../../../../Modal.styled';
import { BtnIcon as ButtonIcon } from '../../../../widgets/Btn.styled';
import { Btn as Button } from '../../../../widgets/Btn.styled';
import { Icon } from '../../../../widgets/Icon.styled';
import { Styled as StyledFeedback } from '../../../../widgets/Feedback.styled';

const EditInfo = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fonts.onlyPrimary};
  color: black;
  font-weight: 700;
  font-size: 14px;
  line-height: 110%;

  & div {
    margin-right: 52px;
    & span {
      font-weight: 400;
      margin-left: 19px;
    }
  }
`;

const Shelf = styled.div<{ type: 'top' | 'bottom'; scroll: 'top' | 'bottom' | 'middle' }>`
  height: 25px;
  width: auto;
  z-index: 1000;
  margin-top: ${(props) => (props.type === 'top' ? '-25px' : '0')};
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => {
    if (props.type === props.scroll) {
      return 'none';
    }
    return `0px ${props.type === 'top' ? '5px' : '-5px'} 11px -9px rgba(106, 106, 106, 0.55)`;
  }};
  flex-shrink: 0;
`;

const Settings = styled(StyledSettings.Settings)`
  padding: 63px 73px 62px 73px;
`;

const Btn = styled(Button)`
  text-transform: none !important;
`;

const HeaderInput = styled(StyledModal.HeaderInput)<{ notTransparent: boolean }>`
  ${StyledInputField.InputField} {
    padding-left: 0;
    font-weight: 700;
    font-size: 24px;
    opacity: ${(props) => (props.notTransparent ? '1 !important' : 'inherit')};
  }

  ${ButtonIcon} {
    margin-left: 21px;
    padding-right: 20px;
    padding-left: 20px;
    ${Icon} {
      margin: 7px 9.8px 7px 0px;
      height: 20px;
      width: 20px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  ${StyledFeedback.Feedback} {
    max-width: 800px;
  }
`;

export const Styled = {
  EditInfo,
  Shelf,
  Settings,
  Title: StyledSettings.Title,
  Buttons: StyledSettings.Buttons,
  HeaderInput,
  Btn,
  BtnIcon: ButtonIcon,
  Footer,
};
