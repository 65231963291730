import * as React from 'react';
import { LanNodeModel } from '../../../lan/node/LanNodeModel';
import { Size, sizeFontMapping } from '../../../lan/label/LanLabel';
import { isNetworkNodeModel } from '../../../NgGraceModel';
import { LanNodeLabelSize } from '../../../lan/node/network/NetworkNodeWidget';

interface ExportLanNodeLabelProps {
  node: LanNodeModel;
  size: Size;
}

const BodyLineHeight = 1.5;

export const ExportLanNodeLabel: React.FC<ExportLanNodeLabelProps> = ({ node, size }) => {
  const labelSize = sizeFontMapping[size];
  const hasImage = isNetworkNodeModel(node) && !!node.getImage();

  return (
    <>
      <text
        x={node.getCenter().x - node.getPosition().x}
        y={hasImage ? (LanNodeLabelSize * BodyLineHeight) / 2 : node.getCenter().y - node.getPosition().y}
        fontFamily="GOSTRUS"
        fontSize={`${labelSize}px`}
        textAnchor={'middle'}
        dominantBaseline={'central'}
      >
        {node.getName()}
      </text>
    </>
  );
};
