import { RotatableNodeModel } from '../../generics/RotatableNodeModel';
import { Coordinate, DefaultCoordinate } from '../../geometry/Coordinate';
import { isBusNodeModel } from '../../NgGraceModel';
import { LinkModel } from '@projectstorm/react-diagrams';
import { PartiallyOrderedSets } from './PartiallyOrderedSets';
import { ComparisonResult } from '../../ssd/layer/node/SsdNodeLayerModel';
import { SsdSmartLinkModel } from '../../ssd/link/SsdSmartLinkModel';

const EquivalenceThreshold = 60;

export class NodeWithPlaceholdersOrderedSets<T extends RotatableNodeModel> {
  private nodes: T[];
  private coordinate: Coordinate;

  constructor(nodes: T[], coordinate: Coordinate) {
    this.nodes = nodes;
    this.coordinate = coordinate;
  }

  getSets() {
    const placedNodes: { [key: string]: { node: T; set: T[] } } = {};
    const comparator = CenterOrientedNodeComparator(this.coordinate, EquivalenceThreshold);
    const sets = new PartiallyOrderedSets<T>(comparator);

    const placeNodesRecursively = (nodeToPlace: T) => {
      if (placedNodes[nodeToPlace.getID()]) {
        return;
      }

      if (isBusNodeModel(nodeToPlace)) {
        const set = sets.add(nodeToPlace);
        placedNodes[nodeToPlace.getID()] = { node: nodeToPlace, set };
        return;
      }

      const connectedNodesWithLinks = getConnectedNodes(nodeToPlace);
      const placedCollinearConnected = connectedNodesWithLinks.find((connectedNodeWithLink) => {
        const placed = placedNodes[connectedNodeWithLink.node.getID()];
        return (
          placed &&
          checkLinkIsCollinearTo(connectedNodeWithLink.link, this.coordinate) &&
          !isBusNodeModel(connectedNodeWithLink.node)
        );
      });

      if (placedCollinearConnected) {
        const set = placedNodes[placedCollinearConnected.node.getID()].set;
        set.push(nodeToPlace);
        placedNodes[nodeToPlace.getID()] = { node: nodeToPlace, set };
      } else {
        const set = sets.add(nodeToPlace);
        placedNodes[nodeToPlace.getID()] = { node: nodeToPlace, set };
      }

      connectedNodesWithLinks
        .filter(
          (node) =>
            !placedNodes[node.node.getID()] &&
            !isBusNodeModel(node.node) &&
            checkLinkIsCollinearTo(node.link, this.coordinate)
        )
        .forEach((node) => placeNodesRecursively(node.node as T));
    };

    this.nodes.sort(CenterOrientedNodeComparator(this.coordinate)).forEach(placeNodesRecursively);

    return sets;
  }
}

const getConnectedNodes = (node: RotatableNodeModel) => {
  return Object.values(node.getPorts())
    .flatMap((port) => Object.values(port.getLinks()))
    .map((link) => ({
      link: link,
      node: [link.getSourcePort().getParent(), link.getTargetPort().getParent()].find(
        (possibleNode) => possibleNode.getID() !== node.getID()
      )! as RotatableNodeModel,
    }));
};

const checkLinkIsCollinearTo = (link: LinkModel, coordinate: Coordinate) => {
  return (
    getLinkDominatingCoordinate(link) !== coordinate.getName() &&
    getLinkCoordinateDisposition(link, coordinate) < EquivalenceThreshold
  );
};

const getLinkDominatingCoordinate = (link: LinkModel) => {
  return getLinkCoordinateDisposition(link, new DefaultCoordinate('x')) >
    getLinkCoordinateDisposition(link, new DefaultCoordinate('y'))
    ? 'x'
    : 'y';
};

const getLinkCoordinateDisposition = (link: LinkModel, coordinate: Coordinate) => {
  const pointsPositions = (link as SsdSmartLinkModel)
    .getPoints()
    .map((point) => point.getPosition()[coordinate.getName()]);
  const minPosition = Math.min(...pointsPositions);
  const maxPosition = Math.max(...pointsPositions);
  return maxPosition - minPosition;
};

export const CenterOrientedNodeComparator = (coordinate: Coordinate, equivalenceThreshold: number = 0) => (
  a: RotatableNodeModel,
  b: RotatableNodeModel
): ComparisonResult => {
  const coord = coordinate.getName();
  const valueFactory = (node: RotatableNodeModel) => node.getCenter()[coord];
  const aValue = valueFactory(a);
  const bValue = valueFactory(b);
  return Math.abs(aValue - bValue) < equivalenceThreshold ? 0 : aValue < bValue ? -1 : 1;
};
