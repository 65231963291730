import React, { useCallback, useState } from 'react';

import { Styled as S } from './OpexAccordion.styled';
import { Toolkit } from './accordion/Toolkit';
import { Accordion } from './accordion/Accordion';
import { treeChildAccordionFactory } from './CapexCard';
import { TcoSettingsDto } from '../../../api/nggrace-back';

type OpexFormProps = {
  currency: string;
  form: TcoSettingsDto;
  disabled?: boolean;
  onChange?(action: (form: TcoSettingsDto) => TcoSettingsDto): void;
};

export const OpexAccordion: React.FC<OpexFormProps> = ({ currency, form }) => {
  const [protectionExpanded, setProtectionExpanded] = useState(false);

  const handleToggleAccordion = useCallback(
    () => setProtectionExpanded((protectionExpanded) => !protectionExpanded),
    []
  );
  //TODO: useMemo
  const protectionList = {
    id: Toolkit.UID(),
    title: 'Protection',
    subtitle: '8 00 000 $',
    children: [
      { id: Toolkit.UID(), title: 'Cubes maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'IEDs  maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'DI/DO maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'AI/AO maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'PACS maintenance', subtitle: '8 00 000 $' },
    ],
  };

  const scadaList = {
    id: Toolkit.UID(),
    title: 'Scada',
    subtitle: '8 00 000 $',
    children: [
      { id: Toolkit.UID(), title: 'Cubes maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'Software maintenance and verification', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'Staff training', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'WorkStation maintenance', subtitle: '8 00 000 $' },
      { id: Toolkit.UID(), title: 'Non-routine maintenance activities', subtitle: '8 00 000 $' },
    ],
  };

  return (
    <S.OpexForm>
      <Accordion
        key={protectionList.id}
        model={protectionList}
        indentLevel={0}
        hasParent={false}
        last
        expanded={protectionExpanded}
        childFactory={treeChildAccordionFactory}
        onClick={handleToggleAccordion}
      />
      <Accordion
        key={scadaList.id}
        model={scadaList}
        indentLevel={0}
        hasParent={false}
        last
        expanded={!protectionExpanded}
        childFactory={treeChildAccordionFactory}
        onClick={handleToggleAccordion}
      />
    </S.OpexForm>
  );
};
