import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import * as React from 'react';
import { RackLayerModel, RackLayerModelType } from './RackLayerModel';
import { LanEngine } from '../../LanEngine';
import { GenerateModelEvent } from '../../../scd/controller/ControllerFactory';
import { RackLayerWidget } from './RackLayerWidget';

export class RackLayerFactory extends AbstractReactFactory<RackLayerModel, LanEngine> {
  constructor() {
    super(RackLayerModelType);
  }

  generateModel(event: GenerateModelEvent<RackLayerModel>): RackLayerModel {
    return new RackLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<RackLayerModel>): JSX.Element {
    return <RackLayerWidget engine={this.engine} layer={event.model} />;
  }
}
