import { Styled as SecondStageCardContentStyled } from '../../second-stage-content/SecondStageCardContent.styled';
import { DatasetsStyled } from '../datasets/Datasets.styled';
import { GooseSBStyled } from '../goose-cb/GooseSB.styled';

export const SvSBStyled = {
  Container: DatasetsStyled.Container,
  Column: GooseSBStyled.Column,
  HeaderRow: SecondStageCardContentStyled.HeaderRow,
  BtnIcon: SecondStageCardContentStyled.BtnIcon,
  SvCBListGrid: GooseSBStyled.GooseCBListGrid,
  Cell: DatasetsStyled.Cell,
  ClickedRow: GooseSBStyled.ClickedRow,
};
