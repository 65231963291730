import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Direction,
  VendorViewDto,
  UserBaseDto,
  LogicalNodeTypeSortProperty,
  LogicalNodeTypeSortDto,
  HardwareBaseDto,
  LogicalNodeTypeViewDto,
  LogicalNodeTypeUpdateDto,
} from '../../../../../api/nggrace-back';
import { List } from '../../../../widgets/List.styled';
import { ListWithoutMargin } from '../../ListWithoutMargin.styled';
import { Api } from '../../../../../api/Api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Styled as S } from './LogicalNodeList.styled';
import { SortCellWithoutMargin as SortCell } from '../../SortCellWithoutMargin';
import { EmptyList } from '../../../../widgets/EmptyList';
import { InputSelect } from '../../../../widgets/InputSelect';
import { formatMomentShort } from '../../../../../utils/date-utils';
import { hasCompaniesAccess, hasLibraryEditAccess } from '../../../../../utils/role-utils';
import { Icon } from '../../../../widgets/Icon';
import { useUser } from '../../../login/UserContext';
import { theme } from '../../../../../theme';
import ReactModal from 'react-modal';
import { LogicalNodeCard } from './LogicalNodeCard';

export type CardType = 'view' | 'edit';

interface LogicalNodeRowProps {
  logicalNode: LogicalNodeTypeViewDto;
  userHasLibraryEditAccess: boolean;
  cardType: CardType;
  handleCardTypeChange: (type: CardType) => void;
}

interface LogicalNodeListState {
  logicalNodes: LogicalNodeTypeViewDto[];
  hasMore: boolean;
  page: number;
  size: number;
  vendorId?: number;
  hardwareId?: number;
  editorId?: number;
  sortProperty: LogicalNodeTypeSortProperty;
  sortDirection: Direction;
  lnTypes: string[];
  hardwareList: HardwareBaseDto[];
  vendors: VendorViewDto[];
  editors: UserBaseDto[];
}

export const LogicalNodeList: React.FC = () => {
  const [state, setState] = useState<LogicalNodeListState>({
    logicalNodes: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'TYPE',
    sortDirection: 'ASC',
    lnTypes: [],
    hardwareList: [],
    vendors: [],
    editors: [],
  });

  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);
  const userAccessCompanies = useMemo(() => hasCompaniesAccess(user), [user]);
  const [isListEmpty, setListEmpty] = useState(true);
  const [cardType, setCardType] = useState<CardType>('view');

  useEffect(() => {
    Api.getAllVendors().then((response) => setState((state) => ({ ...state, vendors: response.data })));
    Api.getLogicalNodeTypeFilterOptions().then((response) =>
      setState((state) => ({
        ...state,
        lnTypes: response.data.lnTypes,
        hardwareList: response.data.hardwareList,
        editors: response.data.editors,
        vendors: response.data.vendors,
      }))
    );
  }, []);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: LogicalNodeTypeSortDto = {
        vendorId: state.vendorId,
        editorId: state.editorId,
        hardwareId: state.hardwareId,
        property: state.sortProperty,
        direction: state.sortDirection,
      };
      Api.getLogicalNodeTypes(sort, { page, size }).then((response) => {
        setState((state) => ({
          ...state,
          logicalNodes: reload ? response.data.content || [] : state.logicalNodes.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
        setListEmpty(response.data.totalElements === 0);
      });
    },
    [state.editorId, state.hardwareId, state.sortDirection, state.sortProperty, state.vendorId]
  );

  const reload = useCallback(() => {
    setState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, state.size, true);
  }, [fetch, state.size]);

  useEffect(reload, [reload]);

  const direction = (property: LogicalNodeTypeSortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback((property: LogicalNodeTypeSortProperty, direction: Direction) => {
    setState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const handleNext = useCallback(() => {
    fetch(state.page + 1, state.size);
    setState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, state.page, state.size]);

  const handleVendorChange = useCallback((vendorId: number) => {
    setState((state) => ({ ...state, vendorId }));
  }, []);

  const handleHardwareChange = useCallback((hardwareId: number) => {
    setState((state) => ({ ...state, hardwareId }));
  }, []);

  const handleEditorChange = useCallback((editorId: number) => {
    setState((state) => ({ ...state, editorId }));
  }, []);

  return (
    <>
      {!isListEmpty && (
        <S.LibraryList>
          <InfiniteScroll
            dataLength={state.logicalNodes.length}
            next={handleNext}
            hasMore={state.hasMore}
            scrollableTarget="scrollableTarget"
            loader={null}
          >
            <S.LogicalNodeGrid id="scrollableTarget" showCompany={userAccessCompanies}>
              <List.HeaderRow>
                <SortCell<LogicalNodeTypeSortProperty> type={'TYPE'} direction={direction('TYPE')} onSort={handleSort}>
                  LN Type
                </SortCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Vendor'}
                    hideLabel
                    hideBorder
                    selected={state.vendorId}
                    options={state.vendors}
                    onChange={handleVendorChange}
                  />
                </ListWithoutMargin.FilterCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'IEDs'}
                    hideLabel
                    hideBorder
                    selected={state.hardwareId}
                    options={state.hardwareList}
                    onChange={handleHardwareChange}
                  />
                </ListWithoutMargin.FilterCell>
                <SortCell<LogicalNodeTypeSortProperty>
                  type={'CLAZZ'}
                  direction={direction('CLAZZ')}
                  onSort={handleSort}
                >
                  LN class
                </SortCell>
                <ListWithoutMargin.HeaderCell>Description</ListWithoutMargin.HeaderCell>
                <SortCell<LogicalNodeTypeSortProperty>
                  type={'PERFORMANCE_POINTS'}
                  direction={direction('PERFORMANCE_POINTS')}
                  onSort={handleSort}
                >
                  Performance points
                </SortCell>
                <SortCell<LogicalNodeTypeSortProperty>
                  type={'EDITED_AT'}
                  direction={direction('EDITED_AT')}
                  onSort={handleSort}
                >
                  Edited
                </SortCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Editor'}
                    hideLabel
                    hideBorder
                    selected={state.editorId}
                    options={state.editors}
                    onChange={handleEditorChange}
                  />
                </ListWithoutMargin.FilterCell>
                {userHasLibraryEditAccess && <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>}
              </List.HeaderRow>
              {state.logicalNodes.map((LogicalNode) => (
                <LogicalNodeRow
                  key={LogicalNode.id}
                  cardType={cardType}
                  logicalNode={LogicalNode}
                  userHasLibraryEditAccess={userHasLibraryEditAccess}
                  handleCardTypeChange={setCardType}
                />
              ))}
            </S.LogicalNodeGrid>
          </InfiniteScroll>
        </S.LibraryList>
      )}
      {isListEmpty && <EmptyList height={'calc(100vh - 341px)'} />}
    </>
  );
};

const formatLnType = (lnType: string) => {
  const maxLength = 16;
  return lnType.length > maxLength ? `${lnType?.substring(0, maxLength)}...` : lnType;
};

const LogicalNodeRow: React.FC<LogicalNodeRowProps> = ({
  logicalNode,
  userHasLibraryEditAccess,
  cardType,
  handleCardTypeChange,
}) => {
  const [lnForCard, setLnForCard] = useState<LogicalNodeTypeViewDto>();

  const handleOpenClick = useCallback(
    (lnId: number, type: CardType) => {
      Api.getLogicalNodeType(lnId).then((response) => {
        setLnForCard(response.data);
        handleCardTypeChange(type);
      });
    },
    [handleCardTypeChange]
  );

  const handleCloseCard = useCallback(
    (logicalNodeUpdate?: LogicalNodeTypeUpdateDto) => {
      if (logicalNodeUpdate) {
        logicalNode.performancePoints = logicalNodeUpdate.performancePoints;
      }
      setLnForCard(undefined);
    },
    [logicalNode]
  );

  return (
    <>
      <S.ClickedRow key={logicalNode.id} onClick={() => handleOpenClick(logicalNode.id, 'view')}>
        <ListWithoutMargin.Cell>{formatLnType(logicalNode.type)}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalNode.vendor.name}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalNode.physicalDevices}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalNode.clazz}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalNode.description}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalNode.performancePoints}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{formatMomentShort(logicalNode.editedAt)}</ListWithoutMargin.Cell>
        <ListWithoutMargin.CellWithLongContent title={logicalNode.editor.name}>
          {logicalNode.editor.name}
        </ListWithoutMargin.CellWithLongContent>
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={() => handleOpenClick(logicalNode.id, 'edit')}>
              <Icon name={'edit'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
      </S.ClickedRow>
      <ReactModal isOpen={lnForCard !== undefined} onRequestClose={() => handleCloseCard()} style={theme.modal}>
        {lnForCard && (
          <LogicalNodeCard
            cardType={cardType}
            handleCardTypeChange={handleCardTypeChange}
            onClose={handleCloseCard}
            logicalNode={lnForCard}
          />
        )}
      </ReactModal>
    </>
  );
};
