import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Frequency,
  ProjectCreateDto,
  ProjectCreationType,
  ProjectDto,
  ProjectStyleSelection,
} from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { InputField } from '../../widgets/InputField';
import { InputSelect } from '../../widgets/InputSelect';
import { InputRadio } from '../../widgets/InputRadio';
import { Styled as S } from './ProjectSettings.styled';
import { InputUpload } from '../../widgets/InputUpload';
import { Feedback } from '../../widgets/Feedback';
import { useOverlay } from '../../context/OverlayContext';
import { Icon } from '../../widgets/Icon';
import { useNotifications } from '../../context/NotificationContext';
import { SecondaryButton } from '../../widgets/SecondaryButton';

interface ProjectControlProps {
  project?: ProjectDto;
  onClose: (project?: ProjectCreateDto) => void;
}

const frequencies: { id: Frequency; name: string }[] = [
  { id: 'FIFTY', name: '50 Гц' },
  { id: 'SIXTY', name: '60 Гц' },
];

export const ProjectSettings: React.FC<ProjectControlProps> = ({ project: initProject, onClose }) => {
  const [project, setProject] = useState<ProjectCreateDto>({
    name: '',
    styleSelection: 'EU',
    creationType: 'MANUAL',
    frequency: 'FIFTY',
    customerName: initProject?.customerCompany,
    ...initProject,
  });

  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const redirect = useRoutes().redirectToStagePage;
  const update = useMemo(() => !!initProject, [initProject]);
  const nameRef = useRef<HTMLInputElement>(null);
  const notifications = useNotifications();

  const handleNameChange = useCallback((name: string) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, name };
    });
  }, []);

  const handleStationNameChange = useCallback((stationName: string) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, stationName: stationName };
    });
  }, []);

  const handleCustomerNameChange = useCallback((customerName: string) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, customerName };
    });
  }, []);

  const handleFrequencyChange = useCallback((frequency: Frequency) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, frequency };
    });
  }, []);

  const handleStyleSelectionChange = useCallback((styleSelection: ProjectStyleSelection) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, styleSelection };
    });
  }, []);

  const handleCreationTypeChange = useCallback((creationType: ProjectCreationType) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, creationType };
    });
  }, []);

  const handleImportFileChange = useCallback((importFile: File) => {
    setError(undefined);
    setProject((project) => {
      return { ...project, importFile };
    });
  }, []);

  const handleClose = useCallback(() => {
    setProject({});
    onClose();
  }, [onClose]);

  const handleCreate = useCallback(() => {
    setLoading(true);
    overlay.show();
    Api.createProjectWithFormData(project, { omitInterceptorErrorModal: true })
      .then((result) => {
        onClose();
        overlay.hide();
        redirect(result.data, 'SLD');
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [onClose, overlay, project, redirect]);

  const handleUpdate = useCallback(() => {
    const { name, stationName, customerName } = project;
    Api.updateProject(initProject!.id, { name, stationName, customerName }, { omitInterceptorErrorModal: true })
      .then(() => {
        onClose(project);
        overlay.hide();
        notifications.notifySuccess('SLD settings updated.');
      })
      .catch((error) => {
        setError(error.response.data);
        overlay.hide();
      });
  }, [initProject, notifications, onClose, overlay, project]);

  const handleNameEdit = useCallback(() => nameRef.current!.focus(), [nameRef]);
  const maxLength = 255;

  return (
    <S.Modal>
      <S.Scroll>
        <S.ScrollContent>
          <S.HeaderInput>
            <InputField
              ref={nameRef}
              value={project.name}
              placeholder={'Untitled'}
              onChange={handleNameChange}
              fitContent
              dataTestid={'projectName'}
              maxLength={maxLength}
            />
            <Icon name={'edit'} onClick={handleNameEdit} />
          </S.HeaderInput>
          <S.Main>
            <S.LeftPanel>
              <S.Title>Settings</S.Title>
              <S.Form>
                <InputField
                  label={'Station Name'}
                  value={project.stationName}
                  onChange={handleStationNameChange}
                  maxLength={60}
                />
                <InputField
                  label={'Customer company'}
                  value={project.customerName}
                  onChange={handleCustomerNameChange}
                  maxLength={maxLength}
                />
                <S.RadioGroup>
                  <InputRadio<ProjectStyleSelection>
                    value={'RU'}
                    name={'styleSelection'}
                    selected={project.styleSelection}
                    label={'Style selection RU'}
                    onChange={handleStyleSelectionChange}
                    disabled={update}
                  />
                  <InputRadio<ProjectStyleSelection>
                    value={'EU'}
                    name={'styleSelection'}
                    selected={project.styleSelection}
                    label={'Style selection EU'}
                    onChange={handleStyleSelectionChange}
                    disabled={update}
                  />
                </S.RadioGroup>
                <InputSelect
                  disabled={update}
                  label={'Frequency'}
                  selected={project.frequency}
                  options={frequencies}
                  onChange={handleFrequencyChange}
                />
                <S.RadioGroup>
                  <InputRadio<ProjectCreationType>
                    value={'MANUAL'}
                    name={'creationType'}
                    selected={project.creationType}
                    label={'SLD manual creation'}
                    onChange={handleCreationTypeChange}
                    disabled={update}
                  />
                  <InputRadio<ProjectCreationType>
                    value={'IMPORT'}
                    name={'creationType'}
                    selected={project.creationType}
                    label={'Import SSD file'}
                    onChange={handleCreationTypeChange}
                    disabled={update}
                  />
                </S.RadioGroup>
                <InputUpload
                  disabled={update || project.creationType === 'MANUAL'}
                  file={project?.importFile?.name}
                  onChange={handleImportFileChange}
                  accept=".ssd"
                />
              </S.Form>
            </S.LeftPanel>
            <S.RightPanel>
              <S.Description>
                <p>The station title is used for exporting documentation. You can edit it later.</p>
                <p>
                  The graphic set contains Single Line Diagram elements. Select a set of elements for your region.
                  Warning: a change in the working project leads to data loss
                </p>
                <p>ng.Grace allows you to create a new project in two ways.</p>
                <p>
                  You can load a Single Line Diagram using a SSD file or create a Single Line Diagram manually. Use the
                  option that you prefer.
                </p>
                <p>The SSD file must pass successful verification to be used.</p>
              </S.Description>
              {error && <Feedback error text={error} />}
            </S.RightPanel>
          </S.Main>
          <S.Footer>
            <SecondaryButton text={'Cancel'} onClick={handleClose} />
            <S.BtnPrimary onClick={update ? handleUpdate : handleCreate} disabled={loading}>
              {!loading ? (update ? 'Save' : 'Start') : 'Processing'}
            </S.BtnPrimary>
          </S.Footer>
        </S.ScrollContent>
      </S.Scroll>
    </S.Modal>
  );
};
