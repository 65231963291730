import { ProjectStyleSelection } from '../api/nggrace-back';

export interface LocalizedValue<T> {
  en: T;
  ru: T;
}

export const getStyleValue = <T>(style: ProjectStyleSelection, localizedValue: LocalizedValue<T>): T => {
  switch (style) {
    case 'EU':
      return localizedValue.en;
    case 'RU':
      return localizedValue.ru;
  }
};
