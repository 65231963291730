import { BaseModel } from '@projectstorm/react-canvas-core';
import { DefaultPlaceholder, Placeholder } from '../../placeholder/Placeholder';
import { DirectoryLogicDeviceModel } from '../../ssd/logic-device/LogicDeviceModel';
import { HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { Rectangle } from '@projectstorm/geometry';
import { ControllerModel } from './ControllerModel';
import { ScdNodeModel } from '../ScdModel';
import { BasePoint } from '../../geometry/Point';

export class ControllerPlaceholder
  extends BaseModel
  implements Placeholder<DirectoryLogicDeviceModel<ScdNodeModel>, ScdNodeModel> {
  private placeholder: Placeholder<DirectoryLogicDeviceModel<ScdNodeModel>, ScdNodeModel>;
  private controller: ControllerModel;

  constructor(controller: ControllerModel, relativeModel: ScdNodeModel) {
    super({});
    this.controller = controller;
    this.placeholder = new DefaultPlaceholder(this, this as any, (origin) => new HasRelativeParent(origin));
    this.setRelativeModel(relativeModel);
    this.setLocked(true);

    super.registerListener({
      childrenChanged: (event) => {
        this.getRelativeModel()?.fireEvent({}, 'payloadChanged');

        // remove controller placeholder if there are no children
        if (this.getChildren().length === 0) {
          this.remove();
        }

        this.controller.fireEvent(event, 'childrenChanged');
      },
    });
  }

  setRelativeModel(model: ScdNodeModel, index?: number): void {
    this.placeholder.setRelativeModel(model, index);
  }

  getRelativeModel(): ScdNodeModel | undefined {
    return this.placeholder.getRelativeModel();
  }

  addChild(childToAdd: DirectoryLogicDeviceModel<ScdNodeModel>, index?: number): void {
    this.placeholder.addChild(childToAdd, index);
  }

  getChildren(): DirectoryLogicDeviceModel<ScdNodeModel>[] {
    return this.placeholder.getChildren();
  }

  getRect(): Rectangle | undefined {
    return this.placeholder.getRect();
  }

  getCodeName(): string {
    return this.controller.getCodeName();
  }

  getShortName(): string {
    return this.controller.getProjectName();
  }

  getName(): string {
    return this.controller.getName();
  }

  getPosition(): BasePoint {
    return this.placeholder.getPosition();
  }

  getSize(): BasePoint {
    return this.placeholder.getSize();
  }

  setPosition(newValue: BasePoint): void {
    this.placeholder.setPosition(newValue);
  }
}
