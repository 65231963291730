import * as React from 'react';
import { LabelModel } from './LabelModel';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import styled from 'styled-components';
import { RotationHour } from '../geometry/RotationHour';

export interface LabelWidgetProps {
  label: LabelModel;
  node: RotatableNodeModel;
}

export const LabelLineHeight = 16;
export const LabelFontSize = 12;

const Label = styled.span<{ top: number; left: number; vertical: boolean; rotated: boolean }>`
  position: absolute;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
  white-space: nowrap;
  overflow: hidden;
  writing-mode: ${(p) => (p.vertical ? 'vertical-rl' : 'horizontal-tb')};
  transform: ${(p) => (p.rotated ? 'rotate(180deg)' : '')};
  text-orientation: sideways;
  font-family: ${(p) => p.theme.fonts.gost};
  line-height: ${LabelLineHeight}px;
  font-size: ${LabelFontSize}px;
  cursor: pointer;
`;

export const LabelWidget: React.FC<LabelWidgetProps> = ({ label, node }) => {
  const position = label.getRelativePosition();
  const nodeRotation = node.getRotation().getEnumValue();
  const textVertical = label.getDirection().isHorizontal();
  const textRotated =
    (textVertical && nodeRotation !== RotationHour.SIX && nodeRotation !== RotationHour.NINE) ||
    (!textVertical && nodeRotation !== RotationHour.ZERO && nodeRotation !== RotationHour.NINE);
  return (
    <Label top={position.y} left={position.x} vertical={textVertical} rotated={textRotated}>
      {label.getContent().text}
    </Label>
  );
};
