import styled, { css } from 'styled-components';
import { Styled as StyledInputField } from './InputField.styled';
import { Icon } from './Icon.styled';

const InputFilter = styled.input`
  margin: 9px 12px 9px 16px;
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  color: ${(props) => props.theme.colors.darkgrey};
  border-radius: 4px;
  width: calc(100% - 28px);
  height: 30px;
  font-size: 14px;
  padding-left: 9px;
`;

const InputOptions = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: calc(100% - 5px);
  left: 0;
  right: 0;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-top: 0;
  border-radius: 0 0 2px 2px;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
`;

const InputOption = styled.div<{ current?: boolean; disabled?: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 12px 16px;
  height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.colors.lightgrey};
    background-color: ${(props) => props.theme.colors.blue};
  }

  ${(props) =>
    props.current &&
    css`
      color: ${(props) => props.theme.colors.lightgrey};
      background-color: ${(props) => props.theme.colors.blue};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default !important;
      pointer-events: none !important;
      user-select: none !important;
      opacity: 0.3 !important;
      box-shadow: none !important;
    `}
`;

const InputSelected = styled(StyledInputField.InputContainer)<{ icon?: boolean }>`
  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 1px);
    right: 14px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5px solid ${(props) => props.theme.colors.blue};
    border-bottom: none;
    transform: translateY(-50%);
    margin-left: 14px;
  }

  ${(props) =>
    props.icon &&
    css`
      ${Icon} {
        position: absolute;
        right: 8px;
        color: ${(props) => props.theme.colors.darkgrey};
        margin-left: 14px;
      }

      &:after {
        content: none;
      }
    `}
`;

const InputSelectedValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

const InputSelect = styled(StyledInputField.InputField)<{ empty?: boolean; active?: boolean; hideBorder?: boolean }>`
  ${(props) =>
    props.active &&
    css`
      ${InputSelected} {
        border: 1px solid ${(props) => props.theme.colors.blue};

        ${Icon} {
          color: ${(props) => props.theme.colors.blue};
        }

        &:after {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: none !important;
          border-bottom: 5px solid ${(props) => props.theme.colors.blue};
        }
      }

      ${InputOptions} {
        display: block;
      }
    `}

  ${(props) =>
    props.active &&
    !props.empty &&
    css`
      ${StyledInputField.InputLabel} {
        color: ${(props) => props.theme.colors.blue};
      }
    `}

  ${(props) =>
    !props.empty &&
    css`
      ${InputSelected} ${Icon} {
        color: ${(props) => props.theme.colors.blue};
      }
    `}

  ${(props) =>
    !props.active &&
    props.hideBorder &&
    css`
      ${InputSelected} {
        border: 1px solid transparent;
      }
    `}
`;

export const Styled = {
  InputSelect,
  InputSelected,
  InputSelectedValue,
  InputOptions,
  InputOption,
  InputLabel: StyledInputField.InputLabel,
  InputFilter,
};
