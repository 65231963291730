import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 48px 0;
`;

export const Styled = {
  Content,
  Header,
};
