import { useEffect, useRef, useState } from 'react';

export const useComponentVisible = <T extends HTMLElement>(init: boolean) => {
  const [visible, setVisible] = useState<boolean>(init);
  const ref = useRef<T>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { visible, setVisible, ref };
};
