import { PortModel } from '@projectstorm/react-diagrams';
import { Point } from '@projectstorm/geometry';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { BaseModel, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { SmartLinkPointModel } from '../../point/SmartLinkPointModel';
import { NotAffectingPortsEditablePath } from './path/editable/NotAffectingPortsEditablePath';
import { RemovingEditablePath } from './path/editable/RemovingEditablePath';
import { DefaultEditablePath } from './path/editable/DefaultEditablePath';
import { ExtendingEditablePath } from './path/editable/ExtendingEditablePath';
import { EditablePath, PortDependentPath } from './path/Path';
import { DefaultPortDependentPath } from './path/port-dependent/DefaultPortDependentPath';
import { UpdatingDependenciesEditablePath } from './path/editable/UpdatingDepdendenciesEditablePath';
import { UpdatingDependenciesPortDependentPath } from './path/port-dependent/UpdatingDependenciesPortDependentPath';
import { DefaultRightAngledVector } from '../../geometry/Vector';
import { NgGraceLinkModel } from '../NgGraceLinkModel';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { AngledPath } from './path/default/AngledPath';
import { PortEndedPath } from './path/default/PortEndedPath';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { FallbackEditablePath } from './path/editable/FallbackEditablePath';
import { RestrictedDirectionPoint } from '../../geometry/Point';

export class SmartLinkModel extends NgGraceLinkModel {
  editablePath: EditablePath;
  portDependentPath: PortDependentPath;

  constructor();
  constructor(
    sourcePort: ConnectablePortModel,
    targetPort: ConnectablePortModel,
    existingPoints: SmartLinkPointModel[]
  );
  constructor(
    sourcePort?: ConnectablePortModel,
    targetPort?: ConnectablePortModel,
    existingPoints?: SmartLinkPointModel[]
  ) {
    super({
      type: 'smart',
      width: 1,
      color: 'black',
    });

    if (sourcePort && targetPort && existingPoints) {
      this.setSourcePort(sourcePort);
      this.setTargetPort(targetPort);
      this.setPoints(existingPoints);
    }

    this.editablePath = this.createEditablePath();
    this.portDependentPath = new UpdatingDependenciesPortDependentPath(new DefaultPortDependentPath(this), this);
  }

  setSourcePort(port: PortModel) {
    super.setSourcePort(port);
  }

  getSourcePort(): ConnectablePortModel {
    return super.getSourcePort() as ConnectablePortModel;
  }

  setTargetPort(port: PortModel) {
    super.setTargetPort(port);
  }

  getTargetPort(): ConnectablePortModel {
    return super.getTargetPort() as ConnectablePortModel;
  }

  moveSegment(startPointId: string, displacement: Point) {
    if (!this.isLocked()) {
      const startPoint = this.getPoints().find((point) => point.getID() === startPointId)!;
      this.editablePath.moveSegment(startPoint, displacement);
    }
  }

  getSegmentToDivide(segmentId: string): Segment | undefined {
    const segments = this.getSegments();
    const segment = segments.find((segment) => segment.getId() === segmentId)!;
    const connectorSegment = segments.indexOf(segment) === 0 || segments.indexOf(segment) === segments.length - 1;
    return !connectorSegment ? segment : undefined;
  }

  getSegments(): Segment[] {
    const result = [];
    const points = this.getPoints();
    for (let i = 0; i < points.length - 1; i++) {
      result.push(new Segment(points[i], points[i + 1], this.getOptions().width!));
    }
    return result;
  }

  portPositionChanged(port: ConnectablePortModel): void {
    if (!this.canReactOnPortChange(port)) {
      return;
    }

    this.setPoints(this.portDependentPath.getPoints(port));
    const eventKey = this.getSourcePort().getID() === port.getID() ? 'sourcePortChanged' : 'targetPortChanged';
    this.fireEvent({ port }, eventKey);
  }

  setPoints(points: PointModel[]) {
    super.setPoints(points);
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.points = event.data.points.map((point) => {
      const p = new SmartLinkPointModel({
        link: this,
        position: new Point(point.x, point.y),
      });
      p.deserialize({ ...event, data: { ...point } });
      return p;
    });
    Promise.all([event.getModel(event.data.sourcePort), event.getModel(event.data.targetPort)]).then(() => {
      this.editablePath = this.createEditablePath();
    });
  }

  serialize() {
    return {
      ...super.serialize(),
      points: this.getPoints().map((point) => point.serialize()),
      raw: false,
    };
  }

  getPoints(): SmartLinkPointModel[] {
    return super.getPoints() as SmartLinkPointModel[];
  }

  getSelectionEntities(): Array<BaseModel> {
    return [this, ...this.getPoints()];
  }

  isSelected(): boolean {
    return (
      super.isSelected() ||
      (this.getSourcePort() &&
        this.getTargetPort() &&
        this.getSourcePort().isSelected() &&
        this.getTargetPort().isSelected())
    );
  }

  isPersistent() {
    return true;
  }

  getColor() {
    return this.getOptions().color;
  }

  private canReactOnPortChange(port: ConnectablePortModel): boolean {
    return (
      !this.isLocked() &&
      this.getTargetPort() &&
      this.getSourcePort() &&
      !(this.getTargetPort().isSelected() && this.getSourcePort().isSelected()) &&
      this.getPoints()[0] !== undefined &&
      this.getPoints()[this.getPoints().length - 1] !== undefined &&
      !(port.getID() === this.getSourcePort().getID() && port.getCenter().equals(this.getPoints()[0].getPosition())) &&
      !(
        port.getID() === this.getTargetPort().getID() &&
        port.getCenter().equals(this.getPoints()[this.getPoints().length - 1].getPosition())
      )
    );
  }

  private createEditablePath() {
    return new FallbackEditablePath(
      new UpdatingDependenciesEditablePath(
        new NotAffectingPortsEditablePath(
          this,
          new RemovingEditablePath(this, new ExtendingEditablePath(this, new DefaultEditablePath(this)))
        ),
        this
      ),
      this,
      this.getFallbackPath()
    );
  }

  getFallbackPath() {
    return () =>
      new UpdatingDependenciesConnectingPath(
        new DefaultConnectingPath(
          new PortEndedPath(new PortEndedPath(new AngledPath(), this.getSourcePort()), this.getTargetPort()),
          this
        )
      ).connect(
        new RestrictedDirectionPoint(this.getSourcePort().getCenter()),
        new RestrictedDirectionPoint(this.getTargetPort().getCenter())
      );
  }
}

export class Segment {
  readonly start: SmartLinkPointModel;
  readonly end: SmartLinkPointModel;
  readonly width: number;

  constructor(start: SmartLinkPointModel, end: SmartLinkPointModel, width: number) {
    this.start = start;
    this.end = end;
    this.width = width;
  }

  getId() {
    return this.start.getID();
  }

  isVertical(): boolean {
    return !new DefaultRightAngledVector(this.start.getPosition(), this.end.getPosition())
      .getDirection()
      .isHorizontal();
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }

  getPath() {
    const start = this.start.getPosition();
    const end = this.end.getPosition();
    return `M ${start.x},${start.y} L ${end.x},${end.y}`;
  }
}
