import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import * as React from 'react';
import { LanLinkLayerModel, LanLinkLayerType } from './LanLinkLayerModel';
import { LinkLayerWidget } from '@projectstorm/react-diagrams';
import { LanEngine } from '../../LanEngine';

export class LanLinkLayerFactory extends AbstractReactFactory<LanLinkLayerModel, LanEngine> {
  constructor() {
    super(LanLinkLayerType);
  }

  generateModel(event: GenerateModelEvent) {
    return new LanLinkLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<LanLinkLayerModel>): JSX.Element {
    return <LinkLayerWidget layer={event.model as any} engine={this.engine as any} />;
  }
}
