export const ExportLang = {
  infoGroup: {
    header: {
      RU: 'Project info',
      EU: 'Diagram info',
    },
    id: {
      RU: 'Project ID',
      EU: 'Company Document Number',
      size: {
        RU: 45,
        EU: 40,
      },
    },

    contractorDocumentNumber: {
      label: 'Contractor Document Number',
      size: 40,
    },

    title: {
      firstLine: {
        RU: 'Title line 1',
        EU: 'Document Title Line 1',
      },
      secondLine: {
        RU: 'Title line 2',
        EU: 'Document Title Line 2',
      },
      size: {
        RU: 51,
        EU: 60,
      },
    },

    diagramName: {
      firstLine: 'Diagram name line 1',
      secondLine: 'Diagram name line 2',
      size: 30,
    },

    description: {
      label: 'Description',
      size: 50,
    },

    stationName: {
      label: 'Station Name',
    },

    revision: {
      label: 'Revision',
      size: 6,
    },

    status: {
      label: 'Status',
      size: 6,
    },
  },

  personGroup: {
    approved: {
      RU: 'Inspected by',
      EU: 'Approved by',
    },
  },

  logoTooltip: {
    RU: 'The logo must be in svg format and 14 pixels high',
    EU: 'The logo must be in svg format, 42 pixels wide and 15 pixels high',
  },

  exportPage: {
    lineDiagram: {
      title: {
        RU: 'SLD',
        EU: 'SLD',
      },
      subtitle: {
        EU: 'Single Line Diagram',
        RU: 'Single Line Diagram',
      },
    },

    specificationDescription: {
      title: {
        RU: 'SSD',
        EU: 'SSD',
      },
      subtitle: {
        EU: 'System Specification Description',
        RU: 'System Specification Description',
      },
    },
    configurationDescription: {
      title: {
        RU: 'SCD',
        EU: 'SCD',
      },
      subtitle: {
        EU: 'System Configuration Description',
        RU: 'System Configuration Description',
      },
    },
    lanStructure: {
      title: {
        RU: 'LAN structure',
        EU: 'LAN structure',
      },
      subtitle: {
        EU: 'Local area network structure',
        RU: 'Local area network structure',
      },
    },
    specification: {
      title: {
        RU: 'Specification',
        EU: 'Specification',
      },
    },
    cableList: {
      title: {
        RU: 'Cable list',
        EU: 'Cable list',
      },
    },
    total: {
      title: {
        RU: 'Total cost of ownership',
        EU: 'Total cost of ownership',
      },
    },
  },
};
