import { DirectoryLogicNodeModel } from '../../../editor/ssd/logic-device/LogicNodeModel';
import { ExtendableTreeNode, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import {
  DefaultHasExtendableParentModel,
  NavigatingSelectableTreeModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { SsdEngine } from '../../../editor/ssd/SsdEngine';
import { Factory } from '../../../../utils/factory';

export interface SsdStructureTreeLogicNodeProps {
  logicNode: DirectoryLogicNodeModel;
  parent: ExtendableTreeNode;
}

export const SsdStructureTreeLogicNodeFactory = (
  engine: SsdEngine
): Factory<SsdStructureTreeLogicNodeProps, TreeNodeState> => ({ logicNode, parent }) =>
  SsdStructureTreeLogicNode(logicNode, parent, engine);

const SsdStructureTreeLogicNode = (
  logicNode: DirectoryLogicNodeModel,
  parent: ExtendableTreeNode,
  engine: SsdEngine
): TreeNodeState => {
  const model = SsdStructureTreeLogicNodeModel(logicNode);
  return {
    ...model,
    ...DefaultHasExtendableParentModel(model, parent),
    ...NotExtendableModel(),
    ...NavigatingSelectableTreeModel(
      SelectableTreeBaseModel(logicNode, engine),
      logicNode.getParent().getRelativeModel().getID(),
      engine
    ),
  };
};

const SsdStructureTreeLogicNodeModel = (logicNode: DirectoryLogicNodeModel) => ({
  getName: () => logicNode.getCodeName(),
  getKey: () => logicNode.getID(),
  onChildrenChanged: () => () => {},
  getChildren: () => [],
});
