import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { ToolbarItem } from './Toolbar';
import { StageDto } from '../../api/nggrace-back';
import { theme } from '../../theme';
import { Confirmation } from '../widgets/Confirmation';
import { MessageModal } from '../widgets/MessageModal';

type LockToolbarItemProps = {
  stage: StageDto;
  onFinishStage(): void;
  onRollbackStage(): void;
};

export const LockToolbarItem: React.FC<LockToolbarItemProps> = ({ stage, onFinishStage, onRollbackStage }) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { finished, valid, type, deprecated } = stage.baseInfo;
  const active = stage.project.activeStage.type === type;
  const [deprecationModal, setDeprecationModal] = useState<string>();

  const handleClick = useCallback(() => {
    if (deprecated) {
      setDeprecationModal(
        'The stage has been deprecated due to a global update. If you want to edit the project, go to the SLD stage.'
      );
      return;
    }
    if (finished) {
      if (active) {
        onRollbackStage();
      } else {
        setConfirmationVisible(true);
      }
    } else {
      onFinishStage();
    }
  }, [active, finished, onFinishStage, onRollbackStage, deprecated]);

  const handleConfirm = useCallback(() => {
    onRollbackStage();
    setConfirmationVisible(false);
  }, [onRollbackStage]);

  const handleClose = useCallback(() => setConfirmationVisible(false), []);

  const handleDeprecationModalClose = useCallback(() => {
    setDeprecationModal(undefined);
  }, []);

  return (
    <ToolbarItem
      disabled={!valid}
      active={finished}
      icon={finished ? 'lock-fill' : 'unlock'}
      hint={'Ready flag'}
      onClick={handleClick}
    >
      <ReactModal isOpen={confirmationVisible} onRequestClose={handleClose} style={theme.modalInfo}>
        <Confirmation
          title={'Are you sure?'}
          text={`User changes to the following steps will be lost`}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      </ReactModal>
      <MessageModal
        error
        header="Deprecated stage"
        isOpen={!!deprecationModal}
        onRequestClose={handleDeprecationModalClose}
      >
        {deprecationModal}
      </MessageModal>
    </ToolbarItem>
  );
};
