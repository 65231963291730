import { BusPortModel } from './BusPortModel';
import {
  ConditionalResizeStrategy,
  DefaultResizeStrategy,
  NotAffectingPortsResizeStrategy,
  ResizeEdge,
  ResizeStrategy,
} from '../generics/ResizableNodeModel';
import { Point, Rectangle } from '@projectstorm/geometry';
import {
  RotatableNodeModel,
  RotatableNodeModelGenerics,
  RotatableNodeModelOptions,
  RotatableNodeModelPayload,
} from '../generics/RotatableNodeModel';
import { Coordinate, DirectionCoordinate, OppositeCoordinate } from '../geometry/Coordinate';
import { Toolkit } from '@projectstorm/react-canvas-core';
import { RotationHour, SimplifiedRotationHour, SmartRotationHour } from '../geometry/RotationHour';
import { NodeEntry, NodeEntryType } from '../directory/NodeDirectory';
import { LabelModel } from '../label/LabelModel';
import { Direction } from '../geometry/Direction';
import { NodeLabelModel } from '../label/NodeLabelModel';
import { NamedNodeModelPayload } from '../generics/NamedNodeModelPayload';

export interface BusNodeModelPayload extends RotatableNodeModelPayload, NamedNodeModelPayload {
  operationName?: string;
  voltageLevel?: string;
  colorIds?: string[];
}

export interface BusNodeModelOptions extends RotatableNodeModelOptions {
  payload: BusNodeModelPayload;
}

export interface BusNodeModelGenerics extends RotatableNodeModelGenerics {
  PAYLOAD: BusNodeModelPayload;
  OPTIONS: BusNodeModelOptions;
}

export const BusPortSize = 8;
export const BusPortVisibleSize = 3;
export const BusResizerSize = 10;

export const BusDefaultSize = new Point(200, BusPortSize);
export const BusMinSize = new Point(100, BusPortSize);

export enum BusResizerId {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const BusEntry: NodeEntry = {
  width: 60,
  height: 7,
  id: '',
  name: {
    en: 'Bus',
    ru: 'Шина',
  },
  svg: ['/svg/devices/Bus.svg'],
  labelDirection: Direction.TOP,
  type: NodeEntryType.BUS,
};

export class BusNodeModel extends RotatableNodeModel<BusNodeModelGenerics> {
  private readonly label: LabelModel;
  private readonly resizeStrategy: ResizeStrategy;

  constructor() {
    super({
      type: 'bus',
      payload: {
        hour: RotationHour.ZERO,
      },
      defaultSize: BusDefaultSize,
      resizers: [
        { id: BusResizerId.LEFT, edges: [ResizeEdge.LEFT] },
        { id: BusResizerId.RIGHT, edges: [ResizeEdge.RIGHT] },
      ],
    });

    const defaultStrategy = new BusBoundedResizeStrategy(new DefaultResizeStrategy(this), this);
    this.resizeStrategy = new NotAffectingPortsResizeStrategy(
      new BusPortAffectingResizeStrategy(
        new ConditionalResizeStrategy(
          () => this.getRotation().isAxisSwapped(),
          new BusRotatedResizeStrategy(defaultStrategy, this),
          defaultStrategy
        ),
        this
      ),
      this
    );

    this.label = new NodeLabelModel(BusEntry, this);
  }

  createPort(wantedPosition: Point): BusPortModel {
    const offsetCoordinate = this.getOffsetCoordinate();
    const position = this.rotatePoint(this.getPosition());
    const busOffset = wantedPosition[offsetCoordinate.getName()] - position[offsetCoordinate.getName()];
    const port = new BusPortModel(Toolkit.UID(), busOffset);
    this.addPort(port);
    return port;
  }

  getPorts(): { [key: string]: BusPortModel } {
    return Object.entries(super.getPorts())
      .map(([id, port]) => ({ id, port: port as BusPortModel }))
      .filter(({ id, port }) => Object.values(port.getLinks()).length)
      .reduce((obj, { id, port }) => {
        obj[id] = port;
        return obj;
      }, {} as { [key: string]: BusPortModel });
  }

  getResizeStrategy(): ResizeStrategy {
    return this.resizeStrategy;
  }

  getRotation(): SmartRotationHour {
    return new SimplifiedRotationHour(super.getRotation());
  }

  getOffsetCoordinate(): Coordinate {
    return new OppositeCoordinate(new DirectionCoordinate(this.getRotation().getDirection()));
  }

  serialize() {
    return {
      ...super.serialize(),
      ports: Object.values(this.getPorts()).map((port) => port.serialize()),
    };
  }

  getLabel() {
    return this.label;
  }
}

export class BusBoundedResizeStrategy implements ResizeStrategy {
  private origin: ResizeStrategy;
  private model: BusNodeModel;

  constructor(origin: ResizeStrategy, model: BusNodeModel) {
    this.origin = origin;
    this.model = model;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    const minRect = this.getMinRect();
    const boundedPosition = resizePosition.clone();
    if (edges.indexOf(ResizeEdge.RIGHT) >= 0) {
      boundedPosition.x = Math.max(minRect.getBottomRight().x, resizePosition.x);
    }

    if (edges.indexOf(ResizeEdge.BOTTOM) >= 0) {
      boundedPosition.y = Math.max(minRect.getBottomRight().y, resizePosition.y);
    }

    let rightBottomBoundary: Point;
    if (!Object.values(this.model.getPorts()).length) {
      rightBottomBoundary = new Point(
        this.model.getPosition().x + this.model.getSize().x,
        this.model.getPosition().y + this.model.getSize().y
      );
    } else {
      rightBottomBoundary = minRect.getBottomRight();
    }

    const minTopLeft = new Point(
      rightBottomBoundary.x - minRect.getWidth(),
      rightBottomBoundary.y - minRect.getHeight()
    );

    if (edges.indexOf(ResizeEdge.LEFT) >= 0) {
      boundedPosition.x = Math.min(minTopLeft.x, resizePosition.x);
    }

    if (edges.indexOf(ResizeEdge.TOP) >= 0) {
      boundedPosition.y = Math.min(minTopLeft.y, resizePosition.y);
    }

    return this.origin.resize(edges, boundedPosition);
  }

  getMinRect(): Rectangle {
    if (!Object.values(this.model.getPorts()).length) {
      return new Rectangle(this.model.getPosition().x, this.model.getPosition().y, BusMinSize.x, BusMinSize.y);
    }

    const portPositions = Object.values(this.model.getPorts()).map((port) =>
      this.model.inversePointRotation(port.getPosition())
    );
    const margin = BusPortSize;
    const minX = Math.min(...portPositions.map((position) => position.x)) - margin;
    const minY = Math.min(...portPositions.map((position) => position.y));
    const maxX = Math.max(...portPositions.map((position) => position.x + BusPortSize)) + margin;
    const maxY = Math.max(...portPositions.map((position) => position.y + BusPortSize));
    return new Rectangle(new Point(minX, minY), new Point(maxX, minY), new Point(maxX, maxY), new Point(minX, maxY));
  }
}

export class BusPortAffectingResizeStrategy implements ResizeStrategy {
  private origin: ResizeStrategy;
  private model: BusNodeModel;

  constructor(origin: ResizeStrategy, model: BusNodeModel) {
    this.origin = origin;
    this.model = model;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    if (edges.indexOf(ResizeEdge.RIGHT) >= 0) {
      return this.origin.resize(edges, resizePosition);
    }

    const positionBefore = this.model.getPosition();
    const affectedEdges = this.origin.resize(edges, resizePosition);
    const positionAfter = this.model.getPosition();
    const portDisplacement = positionAfter.x - positionBefore.x + positionAfter.y - positionBefore.y;
    Object.values(this.model.getPorts()).forEach((port) => port.setBusOffset(port.getBusOffset() - portDisplacement));
    return affectedEdges;
  }
}

export class BusRotatedResizeStrategy implements ResizeStrategy {
  private origin: ResizeStrategy;
  private model: RotatableNodeModel;

  constructor(origin: ResizeStrategy, model: RotatableNodeModel) {
    this.origin = origin;
    this.model = model;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    const centerBefore = this.model.getCenter();
    const affectedEdges = this.origin.resize(edges, this.model.inversePointRotation(resizePosition));
    const centerAfter = this.model.getCenter();
    const centerDisplacement = centerAfter.x - centerBefore.x;
    this.model.setPosition(
      this.model.getPosition().x - centerDisplacement,
      this.model.getPosition().y + centerDisplacement
    );
    return affectedEdges.map((edge) => this.getRotatedResizeEdge(edge, this.model.getRotation()));
  }

  private getRotatedResizeEdge = (edge: ResizeEdge, hour: SmartRotationHour): ResizeEdge => {
    const intDirectionMapping: { [key in Direction]: number } = {
      [Direction.TOP]: 0,
      [Direction.RIGHT]: 1,
      [Direction.BOTTOM]: 2,
      [Direction.LEFT]: 3,
    };
    const intResizeEdgeMapping: { [key in ResizeEdge]: number } = {
      [ResizeEdge.TOP]: 0,
      [ResizeEdge.RIGHT]: 1,
      [ResizeEdge.BOTTOM]: 2,
      [ResizeEdge.LEFT]: 3,
    };
    const intResult = (intDirectionMapping[hour.getDirection().getEnumValue()] + intResizeEdgeMapping[edge]) % 4;
    return Object.entries(intResizeEdgeMapping).find(([key, value]) => value === intResult)![0] as ResizeEdge;
  };
}
