import { PortModel, PortModelAlignment, PortModelGenerics, PortModelOptions } from '@projectstorm/react-diagrams';
import { Point, Rectangle } from '@projectstorm/geometry';
import { SmartLinkModel } from '../link/smart/SmartLinkModel';
import { ConnectingSmartLinkModel } from '../link/smart/ConnectingSmartLinkModel';
import { RotatableNodeModel } from './RotatableNodeModel';
import { BasePoint, RestrictedDirectionPoint } from '../geometry/Point';
import { DefaultRightAngledVector } from '../geometry/Vector';

export interface ConnectablePortModelGenerics extends PortModelGenerics {
  PARENT: RotatableNodeModel;
}

export abstract class ConnectablePortModel<
  G extends ConnectablePortModelGenerics = ConnectablePortModelGenerics
> extends PortModel<G> {
  protected constructor();
  protected constructor(options: PortModelOptions);
  protected constructor(options?: PortModelOptions) {
    super({ name: '', maximumLinks: 1, ...options });
  }

  abstract getSize(): number;

  createLinkModel(): ConnectingSmartLinkModel | null {
    return this.getMaximumLinks() <= Object.values(this.getLinks()).length ? null : new ConnectingSmartLinkModel(this);
  }

  getCenter(): BasePoint {
    const center = this.getPosition().clone();
    //add 1/2 as port center is inside port position pixel
    center.translate(this.getSize() / 2 + 1 / 2, this.getSize() / 2 + 1 / 2);
    return this.getParent().rotatePoint(center);
  }

  getAlignment(): PortModelAlignment {
    return this.getOptions().alignment as PortModelAlignment;
  }

  reportPosition() {
    this.fireEvent(
      {
        entity: this,
      },
      'reportInitialPosition'
    );
    this.notifyLinks();
  }

  setPosition(point: Point): void;
  setPosition(x: number, y: number): void;
  setPosition(x: Point | number, y?: number): void {
    if (typeof x === 'object') {
      this.position = x;
    } else {
      this.position = new Point(x, y!);
    }
    this.fireEvent({}, 'positionChanged');
    this.notifyLinks();
  }

  updateCoords(coords: Rectangle) {
    this.width = coords.getWidth();
    this.height = coords.getHeight();
    this.setPosition(this.getParent().inversePointRotation(coords.getTopLeft()));
    this.reportedPosition = true;
    this.reportPosition();
  }

  notifyLinks() {
    Object.values(this.getLinks()).forEach(
      (link) => link instanceof SmartLinkModel && (link as SmartLinkModel).portPositionChanged(this)
    );
  }

  isSelected(): boolean {
    return this.parent.isSelected();
  }

  setSelected(selected?: boolean) {
    super.setSelected(selected);
    Object.values(this.links).forEach((link) => link.fireEvent({}, 'selectionChanged'));
  }

  getConnectorLength() {
    return 1;
  }

  getConnector() {
    const length = this.getConnectorLength();
    const portAlignment = this.getEffectiveAlignment();
    const portCenter = this.getCenter();
    const connector = portCenter.clone();
    if (portAlignment === PortModelAlignment.LEFT) {
      connector.x -= length;
    }

    if (portAlignment === PortModelAlignment.RIGHT) {
      connector.x += length;
    }

    if (portAlignment === PortModelAlignment.TOP) {
      connector.y -= length;
    }

    if (portAlignment === PortModelAlignment.BOTTOM) {
      connector.y += length;
    }

    return new RestrictedDirectionPoint(
      connector,
      new DefaultRightAngledVector(connector, portCenter).getDirection().getEnumValue()
    );
  }

  getEffectiveAlignment(): PortModelAlignment {
    const rotation = this.getParent().getRotation().getEnumValue();
    const portAlignment: PortModelAlignment = this.getAlignment();
    const alignmentIntRepresentation: { [key: string]: number } = {
      [PortModelAlignment.TOP]: 0,
      [PortModelAlignment.RIGHT]: 1,
      [PortModelAlignment.BOTTOM]: 2,
      [PortModelAlignment.LEFT]: 3,
    };
    const resultInt = (rotation + alignmentIntRepresentation[portAlignment]) % 4;

    return Object.entries(alignmentIntRepresentation).find((entry) => entry[1] === resultInt)![0] as PortModelAlignment;
  }

  canLinkToPort(port: PortModel): boolean {
    return super.canLinkToPort(port) && !Object.values(port.getLinks()).length;
  }

  serialize() {
    return {
      ...super.serialize(),
      raw: false,
    };
  }
}
