import styled from 'styled-components';
import { Styled as StyledInputField } from '../../../../../widgets/InputField.styled';
import { Styled as StyledFeedback } from '../../../../../widgets/Feedback.styled';
import { Styled as StyledForm } from './CardContent.styled';
import { Styled as StyledSecBtn } from '../../../../../widgets/SecondaryButton.styled';
import { Icon } from '../../../../../widgets/Icon.styled';
import { CardType } from '../HardwareCard';

const InputAndButton = styled.div<{ cardType: CardType }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${StyledForm.Form} {
    ${StyledInputField.InputField} {
      ${StyledInputField.InputLabel} {
        color: ${(props) => (props.cardType === 'view' ? props.theme.colors.blue : props.theme.colors.darkgrey)};
      }
    }
  }

  ${StyledSecBtn.SecondaryButton} {
    margin-left: -151px;
    margin-bottom: 45px;
    height: 40px;
    z-index: 99;
  }
`;

const IcdFileEdit = styled.div<{ cardType: CardType }>`
  font-family: ${(props) => props.theme.fonts.secondary};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 16px;

  & p {
    font-style: bold;
    margin-bottom: 19px;
    font-weight: 700;
  }

  & h1 {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 13px;
  }

  ${StyledSecBtn.SecondaryButton} {
    margin-bottom: ${(props) => (props.cardType === 'create' ? '0' : '16px')};
  }

  ${StyledFeedback.Feedback} {
    position: relative;
    max-width: 523px;
    height: auto;
  }
`;

const FeedbackAndButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${StyledFeedback.Feedback} {
    margin-top: -3px;
    margin-right: 33px;

    ${Icon} {
      height: 25px;
      width: 25px;
      margin-top: -2px;
    }
  }
`;

export const Styled = {
  FeedbackAndButton,
  InputAndButton,
  IcdFileEdit,
  Btn: StyledSecBtn.SecondaryButton,
};
