export class SvgOptimizer {
  private readonly parser = new DOMParser();
  private readonly serializer = new XMLSerializer();

  optimize(source: string): string {
    return this.serialize(this.parse(source));
  }

  private parse(source: string): Document {
    return this.parser.parseFromString(source, 'image/svg+xml');
  }

  private serialize(document: Document): string {
    return this.serializer.serializeToString(document.documentElement);
  }
}
