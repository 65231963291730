import React, { PropsWithChildren, useCallback } from 'react';

export interface DraggableElement {
  element: Element;
  x: number;
  y: number;
}

export interface DraggableProps<PAYLOAD> {
  getDragElement: () => DraggableElement | undefined;
  formatId: string;
  payload: PAYLOAD;
}

export interface DraggableEventData<PAYLOAD> {
  payload: PAYLOAD;
  relativeX: number;
  relativeY: number;
}

export const Draggable = <PAYLOAD extends object>({
  getDragElement,
  children,
  formatId,
  payload,
}: PropsWithChildren<DraggableProps<PAYLOAD>>) => {
  const dragStartHandler = useCallback(
    (event: React.DragEvent) => {
      const dragElement = getDragElement();
      if (dragElement) {
        event.dataTransfer.setDragImage(dragElement.element, dragElement.x, dragElement.y);
      }
      const data: DraggableEventData<PAYLOAD> = {
        payload: payload,
        relativeX: dragElement?.x || 0,
        relativeY: dragElement?.y || 0,
      };
      event.dataTransfer!.setData(formatId, JSON.stringify(data));
    },
    [getDragElement, formatId, payload]
  );

  return (
    <div draggable={true} onDragStart={dragStartHandler}>
      {children}
    </div>
  );
};
