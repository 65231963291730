import { DefaultDirection, Direction, SmartDirection } from './Direction';
import { PortModel, PortModelAlignment } from '@projectstorm/react-diagrams';

export enum RotationHour {
  ZERO,
  THREE,
  SIX,
  NINE,
}

export interface SmartRotationHour {
  getEnumValue(): RotationHour;

  getNumericAngle(): number;

  isAxisSwapped(): boolean;

  isAxisReversed(): boolean;

  getDirection(): SmartDirection;
}

export class DefaultSmartRotationHour implements SmartRotationHour {
  private hour: RotationHour;

  constructor(hour: RotationHour) {
    this.hour = hour;
  }

  isAxisSwapped() {
    return this.hour === RotationHour.THREE || this.hour === RotationHour.NINE;
  }

  isAxisReversed() {
    return this.hour === RotationHour.SIX || this.hour === RotationHour.NINE;
  }

  getEnumValue() {
    return this.hour;
  }

  getNumericAngle() {
    if (this.hour === RotationHour.ZERO) {
      return 0;
    } else if (this.hour === RotationHour.THREE) {
      return 90;
    } else if (this.hour === RotationHour.SIX) {
      return 180;
    } else {
      return 270;
    }
  }

  getDirection(): DefaultDirection {
    let value;
    if (this.hour === RotationHour.ZERO) {
      value = Direction.TOP;
    } else if (this.hour === RotationHour.THREE) {
      value = Direction.RIGHT;
    } else if (this.hour === RotationHour.SIX) {
      value = Direction.BOTTOM;
    } else {
      value = Direction.LEFT;
    }
    return new DefaultDirection(value);
  }
}

export class SimplifiedRotationHour implements SmartRotationHour {
  private origin: SmartRotationHour;

  constructor(origin: SmartRotationHour) {
    this.origin = origin;
  }

  isAxisSwapped() {
    return this.origin.isAxisSwapped();
  }

  getEnumValue(): RotationHour.ZERO | RotationHour.THREE {
    const value = this.origin.getEnumValue();
    if (value === RotationHour.SIX) {
      return RotationHour.ZERO;
    } else if (value === RotationHour.NINE) {
      return RotationHour.THREE;
    } else {
      return value;
    }
  }

  isAxisReversed() {
    return new DefaultSmartRotationHour(this.getEnumValue()).isAxisReversed();
  }

  getNumericAngle() {
    return new DefaultSmartRotationHour(this.getEnumValue()).getNumericAngle();
  }

  getDirection(): DefaultDirection {
    return new DefaultSmartRotationHour(this.getEnumValue()).getDirection();
  }
}

export class CompositeRotationHour implements SmartRotationHour {
  resultHour: SmartRotationHour;

  constructor(hour: SmartRotationHour, anotherHour: SmartRotationHour) {
    this.resultHour = new DefaultSmartRotationHour((hour.getEnumValue() + anotherHour.getEnumValue()) % 4);
  }

  getDirection(): SmartDirection {
    return this.resultHour.getDirection();
  }

  getEnumValue(): RotationHour {
    return this.resultHour.getEnumValue();
  }

  getNumericAngle(): number {
    return this.resultHour.getNumericAngle();
  }

  isAxisReversed(): boolean {
    return this.resultHour.isAxisReversed();
  }

  isAxisSwapped(): boolean {
    return this.resultHour.isAxisSwapped();
  }
}

export class PortAlignmentRotationHour implements SmartRotationHour {
  origin: SmartRotationHour;

  constructor(port: PortModel) {
    const alignment = port.getOptions().alignment;
    let hour;
    if (alignment === PortModelAlignment.TOP) {
      hour = RotationHour.ZERO;
    } else if (alignment === PortModelAlignment.LEFT) {
      hour = RotationHour.NINE;
    } else if (alignment === PortModelAlignment.RIGHT) {
      hour = RotationHour.THREE;
    } else {
      hour = RotationHour.SIX;
    }

    this.origin = new DefaultSmartRotationHour(hour);
  }
  getDirection(): SmartDirection {
    return this.origin.getDirection();
  }

  getEnumValue(): RotationHour {
    return this.origin.getEnumValue();
  }

  getNumericAngle(): number {
    return this.origin.getNumericAngle();
  }

  isAxisReversed(): boolean {
    return this.origin.isAxisReversed();
  }

  isAxisSwapped(): boolean {
    return this.origin.isAxisSwapped();
  }
}

export class ZeroingRotationHour implements SmartRotationHour {
  origin: SmartRotationHour;

  constructor(origin: SmartRotationHour) {
    this.origin = new DefaultSmartRotationHour(4 - origin.getEnumValue());
  }
  getDirection(): SmartDirection {
    return this.origin.getDirection();
  }

  getEnumValue(): RotationHour {
    return this.origin.getEnumValue();
  }

  getNumericAngle(): number {
    return this.origin.getNumericAngle();
  }

  isAxisReversed(): boolean {
    return this.origin.isAxisReversed();
  }

  isAxisSwapped(): boolean {
    return this.origin.isAxisSwapped();
  }
}
