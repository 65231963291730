import { GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { DirectoryLogicDeviceModel } from '../../ssd/logic-device/LogicDeviceModel';
import { LogicDeviceFactory } from '../../ssd/logic-device/LogicDeviceFactory';
import { DefaultPlaceholder } from '../../placeholder/Placeholder';
import { DefaultHasRelativeModel } from '../../placeholder/HasRelativeModel';
import { BrickHasSize } from '../../placeholder/size-computation/BrickHasSize';
import { DefaultHasRect } from '../../placeholder/HasRect';

export class ScdLogicDeviceFactory extends LogicDeviceFactory {
  generateModel(event: GenerateModelEvent): DirectoryLogicDeviceModel {
    const data = event.initialConfig;
    return new DirectoryLogicDeviceModel(
      this.deviceDirectory.getEntry(data.directoryId),
      this.getNodes(data),
      (device) =>
        new DefaultPlaceholder(
          device,
          device as any,
          () => new DefaultHasRelativeModel(),
          () => new BrickHasSize({ getCodeName: () => device.getCodeName() }),
          undefined,
          ({ hasPosition, hasSize }) => new DefaultHasRect(hasPosition, hasSize)
        )
    );
  }

  generateReactWidget(event: GenerateWidgetEvent<DirectoryLogicDeviceModel>): JSX.Element {
    return <></>;
  }
}
