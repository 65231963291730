import { WheelEvent } from 'react';
import { Action, InputType } from '@projectstorm/react-canvas-core';
import { ObservableEngine } from '../ObservableEngine';

export interface ZoomCanvasActionOptions {
  inverseZoom?: boolean;
}

export class ZoomAction extends Action<ObservableEngine> {
  constructor(options: ZoomCanvasActionOptions = {}) {
    super({
      type: InputType.MOUSE_WHEEL,
      fire: ({ event: e }) => {
        const event = e as WheelEvent;
        event.stopPropagation();
        let deltaY = Math.sign(event.deltaY) * 2;
        if (Math.abs(event.deltaY) >= 10) {
          deltaY *= 5;
        }
        const scrollDelta = options.inverseZoom ? -deltaY : deltaY;
        this.engine.zoom(scrollDelta, { ...event }, event.ctrlKey);
      },
    });
  }
}
