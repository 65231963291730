import React, { useCallback, useRef } from 'react';
import { Styled as S } from './InputUpload.styled';

interface InputUploadProps {
  file?: string;
  accept?: string;
  disabled?: boolean;
  label?: string;

  onChange(file: File): void;
}

export const InputUpload: React.FC<InputUploadProps> = ({ file, accept, disabled, label, onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const files = event.target.files;
      if (files && files.length > 0) {
        onChange(files[0]);
      }
    },
    [onChange]
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // allow uploading same file again
      fileInputRef.current.click();
    }
  }, []);

  return (
    <S.InputUpload disabled={disabled} empty={!file}>
      <S.InputValue>{file || (!label && 'Upload a file')}</S.InputValue>
      <S.InputBrowseBtn disabled={disabled} onClick={handleClick}>
        Browse
      </S.InputBrowseBtn>
      <input type="file" accept={accept} ref={fileInputRef} onChange={fileChangeCallback} />
    </S.InputUpload>
  );
};
