import React from 'react';
import { Styled as S } from './ReliabilityCard.styled';
import { InfoPanel } from '../../widgets/InfoPanel';
import { theme } from '../../../theme';
import TeX from '@matejmazur/react-katex';
import { ReliabilityType } from './types';

type ReliabilityCardProps = {
  reliability: ReliabilityType;
};

const fkmaxX = [178, 178, 178, 178];
const fkmaxY = [32, 64, 102, 137];
const fkMaxLength = Math.sqrt(Math.pow(fkmaxX[3] - fkmaxX[0], 2) + Math.pow(fkmaxY[3] - fkmaxY[0], 2));
const fkmaxAngle = 0;

const pkffX = [320, 288, 260, 230];
const pkffY = [115, 131, 150, 167];
const pkffLength = Math.sqrt(Math.pow(pkffX[3] - pkffX[0], 2) + Math.pow(pkffY[3] - pkffY[0], 2));
const pkffAngle = Math.PI / 3;

const mkffX = [320, 288, 260, 230];
const mkffY = [280, 264, 245, 228];
const mkffLength = Math.sqrt(Math.pow(mkffX[3] - mkffX[0], 2) + Math.pow(mkffY[3] - mkffY[0], 2));
const mkffAngle = (2 / 3) * Math.PI;

const pfttX = [178, 178, 178, 178];
const pfttY = [361, 329, 291, 257];
const pfttLength = Math.sqrt(Math.pow(pfttX[3] - pfttX[0], 2) + Math.pow(pfttY[3] - pfttY[0], 2));
const pfttAngle = Math.PI;

const qalX = [36, 68, 96, 126];
const qalY = [280, 264, 245, 228];
const qalLength = Math.sqrt(Math.pow(qalX[3] - qalX[0], 2) + Math.pow(qalY[3] - qalY[0], 2));
const qalAngle = (4 / 3) * Math.PI;

const mtxX = [36, 68, 96, 126];
const mtxY = [115.625, 131.358, 150.291, 167.623];
const mtxLength = Math.sqrt(Math.pow(mtxX[3] - mtxX[0], 2) + Math.pow(mtxY[3] - mtxY[0], 2));
const mtxAngle = (5 / 3) * Math.PI;

export const ReliabilityCard: React.FC<ReliabilityCardProps> = ({ reliability }) => {
  const { fkmax, pftt, mkff, pkff, qal, mtx } = reliability;

  const oX = [
    fkmaxX[3] - fkmax.outerHexagonPoint * fkMaxLength * Math.sin(fkmaxAngle),
    pkffX[3] + pkff.outerHexagonPoint * pkffLength * Math.sin(pkffAngle),
    mkffX[3] + mkff.outerHexagonPoint * mkffLength * Math.sin(mkffAngle),
    pfttX[3] + pftt.outerHexagonPoint * pfttLength * Math.sin(pfttAngle),
    qalX[3] + qal.outerHexagonPoint * qalLength * Math.sin(qalAngle),
    mtxX[3] + mtx.outerHexagonPoint * mtxLength * Math.sin(mtxAngle),
  ];

  const oY = [
    fkmaxY[3] - fkmax.outerHexagonPoint * fkMaxLength * Math.cos(fkmaxAngle),
    pkffY[3] - pkff.outerHexagonPoint * pkffLength * Math.cos(pkffAngle),
    mkffY[3] - mkff.outerHexagonPoint * mkffLength * Math.cos(mkffAngle),
    pfttY[3] - pftt.outerHexagonPoint * pfttLength * Math.cos(pfttAngle),
    qalY[3] - qal.outerHexagonPoint * qalLength * Math.cos(qalAngle),
    mtxY[3] - mtx.outerHexagonPoint * mtxLength * Math.cos(mtxAngle),
  ];

  const mX = [
    fkmaxX[3] - fkmax.innerHexagonPoint * fkMaxLength * Math.sin(fkmaxAngle),
    pkffX[3] + pkff.innerHexagonPoint * pkffLength * Math.sin(pkffAngle),
    mkffX[3] + mkff.innerHexagonPoint * mkffLength * Math.sin(mkffAngle),
    pfttX[3] + pftt.innerHexagonPoint * pfttLength * Math.sin(pfttAngle),
    qalX[3] + qal.innerHexagonPoint * qalLength * Math.sin(qalAngle),
    mtxX[3] + mtx.innerHexagonPoint * mtxLength * Math.sin(mtxAngle),
  ];

  const mY = [
    fkmaxY[3] - fkmax.innerHexagonPoint * fkMaxLength * Math.cos(fkmaxAngle),
    pkffY[3] - pkff.innerHexagonPoint * pkffLength * Math.cos(pkffAngle),
    mkffY[3] - mkff.innerHexagonPoint * mkffLength * Math.cos(mkffAngle),
    pfttY[3] - pftt.innerHexagonPoint * pfttLength * Math.cos(pfttAngle),
    qalY[3] - qal.innerHexagonPoint * qalLength * Math.cos(qalAngle),
    mtxY[3] - mtx.innerHexagonPoint * mtxLength * Math.cos(mtxAngle),
  ];

  return (
    <S.ReliabilityCard>
      <S.Graph viewBox="0 0 380 400" xmlns="http://www.w3.org/2000/svg">
        <foreignObject x="160px" y="0px" width="60px" height="30px">
          <TeX math="K^{\prime}" />
        </foreignObject>
        <foreignObject x="325px" y="100px" width="60px" height="30px">
          <TeX math="C_{max}^{2}" />
        </foreignObject>
        <foreignObject x="325px" y="270px" width="60px" height="30px">
          <TeX math="K_{ЛЭП}^{ВН}" />
        </foreignObject>
        <foreignObject x="165px" y="365px" width="60px" height="30px">
          <TeX math="K_{Тр}^{ВН}" />
        </foreignObject>
        <foreignObject x="7px" y="275px" width="60px" height="24px">
          <TeX math="K_{СШ}^{ВН}" />
        </foreignObject>
        <foreignObject x="0" y="90px" width="60px" height="24px">
          <TeX math="K_{Пр}^{НН}" />
        </foreignObject>

        {[0, 1, 2, 3].map((i) => (
          <polygon
            key={i}
            opacity="0.5"
            points={`${mtxX[i]},${mtxY[i]} ${fkmaxX[i]},${fkmaxY[i]} ${pkffX[i]},${pkffY[i]} ${mkffX[i]},${mkffY[i]} ${pfttX[i]},${pfttY[i]} ${qalX[i]},${qalY[i]}`}
            fill={theme.colors.grey}
            stroke={theme.colors.blue}
          />
        ))}

        <polygon
          fillOpacity="0.5"
          points={`${mX[0]},${mY[0]} ${mX[1]},${mY[1]} ${mX[2]},${mY[2]} ${mX[3]},${mY[3]} ${mX[4]},${mY[4]} ${mX[5]},${mY[5]}`}
          fill={theme.colors.darkorange}
          stroke={theme.colors.darkorange}
          strokeWidth="2"
        />

        <polygon
          points={`${oX[0]},${oY[0]} ${oX[1]},${oY[1]} ${oX[2]},${oY[2]} ${oX[3]},${oY[3]} ${oX[4]},${oY[4]} ${oX[5]},${oY[5]}`}
          fill="none"
          stroke={theme.colors.turquoise}
          strokeWidth="6"
        />
      </S.Graph>
      <S.Legend>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.turquoise} />
          <S.LegendLabel>probabilities</S.LegendLabel>
        </S.LegendItem>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.orange} />
          <S.LegendLabel>min possible values</S.LegendLabel>
        </S.LegendItem>
      </S.Legend>
      <S.Content>
        <InfoPanel title={<TeX math="K^{\prime}" />} value={fkmax.value}>
          Усредненный интегральный коэффициент готовности комплекса РЗА Показатель позволяет сравнить надежность
          комплексов РЗА для подстанций с разными схемами соединений.
          <TeX math="K^{\prime} = 1 - \sum \frac{p_j}k" block />
          где <TeX math="p_j" /> – принадлежит множеству вероятностей всех сценариев, в которых отказала хотя бы одна
          функция, а <TeX math="k" /> – количество защищаемых элементов (трансформаторы, реакторы, СШ, ЛЭП и пр.).
        </InfoPanel>
        <InfoPanel title={<TeX math="C_{max}^{2}" />} value={pkff.value}>
          Усредненное максимальное количество функций (одновременный отказ 2 элементов).
          <TeX math="C_{max}^{2}=\frac{M}k" block />
          where <TeX math="M" /> где M – максимальное количество отказавших функций по всем сценариям, в которых
          отказало ровно два устройства и/или линии связи,
          <TeX math="k" /> – количество защищаемых элементов (трансформаторов, линий, шин и проч. по всем классам
          напряжений).
        </InfoPanel>
        <InfoPanel title={<TeX math="K_{ЛЭП}^{ВН}" />} value={mkff.value}>
          Усредненный коэффициент критической готовности функций РЗА ЛЭП 110 кВ и выше
          <TeX math="K_{ЛЭП}^{ВН}=1 - \frac {\sum{p_j}}{l}" block />
          где <TeX math="p_j" /> – принадлежит множеству всех сценариев, в которых отказали все функции хотя бы одной
          ЛЭП с уровнем напряжения 110 кВ и более, <TeX math="l" /> – количество ЛЭП 110 кВ и выше.
        </InfoPanel>
        <InfoPanel title={<TeX math="K_{Тр}^{ВН}" />} value={pftt.value}>
          Усредненный коэффициент критической готовности функций РЗА трансформаторного оборудования с высшим напряжением
          110 кВ и выше
          <TeX math="K_{Тр}^{ВН}=1 - \frac {\sum{p_j}}{t}" block />
          где <TeX math="p_j" /> – принадлежит множеству всех сценариев, в которых отказали все функции хотя бы одного Т
          (АТ) с уровнем напряжения ВН 110 кВ и более, <TeX math="t" /> – количество AT(T) 110 кВ и выше.
        </InfoPanel>
        <InfoPanel title={<TeX math="K_{СШ}^{ВН}" />} value={qal.value}>
          <TeX math="K_{СШ}^{ВН}=1 - \frac {\sum{p_j}}{b}" block />
          где <TeX math="p_j" /> – принадлежит множеству всех сценариев, в которых отказали все функции хотя бы одной
          секции шин с уровнем напряжения 110 кВ и более, <TeX math="b" /> – количество секций шин 110 кВ и выше.
        </InfoPanel>
        <InfoPanel title={<TeX math="K_{Пр}^{НН}" />} value={mtx.value}>
          Service flow parameter for each type of PAC equipment:
          <TeX math="K_{Пр}^{НН}=1 - \frac {\sum{p_j}}{n}" block />
          где <TeX math="p_j" /> – принадлежит множеству всех сценариев, в которых отказали все функции хотя бы одного
          присоединения 35 кВ и ниже, <TeX math="n" /> – количество присоединений 35 кВ и ниже.
        </InfoPanel>
      </S.Content>
    </S.ReliabilityCard>
  );
};
