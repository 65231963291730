import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { NodeLayerModel } from '@projectstorm/react-diagrams';
import { NgGraceNodeLayerWidget } from './NgGraceNodeLayerWidget';
import { NgGraceNodeLayerModel } from './NgGraceNodeLayerModel';
import { DiagramEngine } from '../engine/DiagramEngine';

export class NgGraceNodeLayerFactory extends AbstractReactFactory<NodeLayerModel, DiagramEngine> {
  constructor() {
    super('diagram-nodes');
  }

  generateModel(event: GenerateModelEvent): NodeLayerModel {
    return new NgGraceNodeLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<NgGraceNodeLayerModel>): JSX.Element {
    return <NgGraceNodeLayerWidget layer={event.model} engine={this.engine} />;
  }
}
