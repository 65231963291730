import React from 'react';

import { formatMomentShort } from '../../../../../../utils/date-utils';
import { useUser } from '../../../../login/UserContext';
import { CardType } from '../LogicalDeviceCard';
import { Styled as S } from './EditInfo.styled';

interface EditInfoProps {
  cardType: CardType;
  editedAt?: number;
  editorName?: string;
  performancePoints?: number;
}

export const EditInfo: React.FC<EditInfoProps> = ({ cardType, editedAt, editorName, performancePoints }) => {
  const user = useUser()!.user!;

  return (
    <>
      <S.EditInfo>
        {cardType !== 'create' && editedAt !== undefined && (
          <div>
            Edit date:<span>{formatMomentShort(editedAt)}</span>
          </div>
        )}
        <div>
          Author:<span>{cardType !== 'create' ? editorName : user.name}</span>
        </div>
        {performancePoints && (
          <div>
            Performance points:<span>{performancePoints}</span>
          </div>
        )}
      </S.EditInfo>
    </>
  );
};
