import styled from 'styled-components';

import { Styled as Tree } from '../../../../../../../../widgets/tree/Tree.styled';
import { Styled as LnComposition } from '../../../../../../hardware/card/lncomposition/LnComposition.styled';

const TreeRow = styled(LnComposition.Header)`
  grid-template-columns: minmax(0, 1fr);
  font-weight: 400;

  ${Tree.Title} {
    & * {
      font-size: 12px;
    }
  }
`;

export const Styled = {
  TreeRow,
};
