import React, { useCallback, useEffect, useState } from 'react';
import { Btn } from '../../widgets/Btn.styled';
import { Styled as S } from './ExportFormPanel.styled';
import { ExportFormDto, ProjectExportDto, ProjectStyleSelection } from '../../../api/nggrace-back';
import { ProjectInfoGroup } from './form/ProjectInfoGroup';
import { ResponsibleSpecialistsGroup } from './form/ResponsibleSpecialistsGroup';
import { CreatedGroup } from './form/CreatedGroup';
import { CompanyInfoGroup } from './form/CompanyInfoGroup';
import { Tab, Tabs } from '../../widgets/Tabs';
import { FormatsGroup } from './form/FormatsGroup';
import { useNotifications } from '../../context/NotificationContext';

type ExportFormPanelProps = {
  initialExportForm?: ExportFormDto;
  style: ProjectStyleSelection;
  project: ProjectExportDto;

  onSave(stageId: number, exportForm: ExportFormDto): Promise<void>;
};

type ExportTabType = 'projectInfo' | 'responsibleSpecialists' | 'created' | 'companyInfo' | 'formats';

const tabs: Tab<ExportTabType>[] = [
  { id: 'projectInfo', name: 'Project Info' },
  { id: 'responsibleSpecialists', name: 'Responsible Specialists' },
  { id: 'created', name: 'Created' },
  { id: 'companyInfo', name: 'Company Info' },
  { id: 'formats', name: 'Formats' },
];

export const ExportFormPanel: React.FC<ExportFormPanelProps> = ({ initialExportForm, style, project, onSave }) => {
  const [exportForm, setExportForm] = useState(initialExportForm);
  const [activeTab, setActiveTab] = useState('projectInfo' as ExportTabType);
  const [dirty, setDirty] = useState(false);
  const notifications = useNotifications();

  useEffect(() => {
    if (initialExportForm !== undefined) {
      setExportForm({
        stage: 'WORKING_DOC',
        ...initialExportForm,
      });
    }
  }, [initialExportForm]);

  const handleChange = useCallback((action: (exportForm: ExportFormDto) => ExportFormDto) => {
    setExportForm((exportForm) => exportForm && action(exportForm));
    setDirty(true);
  }, []);

  const handleSave = useCallback(async () => {
    if (exportForm) {
      await onSave(exportForm.id!!, exportForm);
      setDirty(false);
      notifications.notifySuccess('Export settings updated.');
    }
  }, [exportForm, notifications, onSave]);

  return (
    <S.ExportFormPanel>
      <S.Header>Documents export</S.Header>
      {exportForm && (
        <>
          <S.Content>
            <Tabs<ExportTabType> activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
            {activeTab === 'projectInfo' && (
              <ProjectInfoGroup style={style} form={exportForm} project={project} onChange={handleChange} />
            )}
            {activeTab === 'responsibleSpecialists' && (
              <ResponsibleSpecialistsGroup style={style} form={exportForm} onChange={handleChange} />
            )}
            {activeTab === 'created' && <CreatedGroup form={exportForm} onChange={handleChange} />}
            {activeTab === 'companyInfo' && (
              <CompanyInfoGroup style={style} form={exportForm} project={project} onChange={handleChange} />
            )}
            {activeTab === 'formats' && <FormatsGroup form={exportForm} onChange={handleChange} />}
          </S.Content>
          <S.Footer>
            <Btn onClick={handleSave} disabled={!dirty}>
              Save
            </Btn>
          </S.Footer>
        </>
      )}
      {!exportForm && (
        <S.InfoContent>
          <S.Info>Information</S.Info>
          <div>Here you can select the packages to export.</div>
          <div>
            The data for each documentation package must be entered manually, if this data has not been entered
            previously.
          </div>
        </S.InfoContent>
      )}
    </S.ExportFormPanel>
  );
};
