import { isDirectoryNodeModel, isNetworkNodeModel, isRotatableNodeModel } from '../../../NgGraceModel';
import { NodeDirectory } from '../../../directory/NodeDirectory';
import { SvgOptimizer } from '../../SvgOptimizer';
import { NodeModel } from '@projectstorm/react-diagrams';
import { BasePoint } from '../../../geometry/Point';
import axios from 'axios';
import { ExportNode } from './ExportNode';
import * as React from 'react';
import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import {
  NetworkDeviceDirectoryEntry,
  PropertiesDirectory,
  VoltageLevelDirectoryEntry
} from '../../../directory/PropertiesDirectory';

export class ExportNodeLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: NodeModel[];
  private readonly directory: NodeDirectory;
  private readonly voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
  private readonly networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>;
  private readonly svgCache: { [nodeId: string]: any } = {};
  private readonly svgOptimizer = new SvgOptimizer();

  constructor(
    layer: ExportLayer,
    directory: NodeDirectory,
    voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>,
    networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>,
    models: NodeModel[]
  ) {
    this.layer = layer;
    this.directory = directory;
    this.voltageLevelDirectory = voltageLevelDirectory;
    this.networkDeviceDirectory = networkDeviceDirectory;
    this.models = models;
  }

  private updateNodeBoundary = (node: NodeModel) => {
    if (isRotatableNodeModel(node)) {
      const topLeft = node.getPositionToRender();
      const bottomRight = new BasePoint(topLeft.x + node.getRotatedSize().x, topLeft.y + node.getRotatedSize().y);
      this.boundary.extendBoundary(topLeft);
      this.boundary.extendBoundary(bottomRight);
    }
  };

  private async fillSvgCache() {
    const promises: Promise<any>[] = [];
    this.models.map((node) => {
      this.updateNodeBoundary(node);
      if (isDirectoryNodeModel(node)) {
        const record = this.directory.getEntry(node.getDirectoryEntryId());
        promises.push(...record.svg.map((svg, index) => axios.get(svg).then((response) => {
          this.svgCache[node.getDirectoryEntryId() + index] = this.svgOptimizer.optimize(response.data);
        })));
      }
      if (isNetworkNodeModel(node) && node.getImage()) {
        promises.push(axios.get(node.getImage()!.src).then((response) => {
          this.svgCache[node.getDirectoryEntryId()] = this.svgOptimizer.optimize(response.data);
        }));
      }
      promises.push(Promise.resolve());
    });
    return await Promise.all(promises);
  }

  async build(): Promise<any> {
    await this.fillSvgCache();
    return this.layer.build();
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.map((node) => (
          <ExportNode directory={this.directory} voltageLevelDirectory={this.voltageLevelDirectory} svgCache={this.svgCache} node={node} key={node.getID()} />
        ))}
      </>
    );
  }
}
