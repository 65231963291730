import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import { NodeDirectory, NodeEntry } from '../../editor/directory/NodeDirectory';
import { DraggableElement } from '../../editor/dnd/Draggable';
import { SvgNodeWidget } from '../../editor/SvgNodeWidget';
import { BusEntry } from '../../editor/bus/BusNodeModel';
import { DirectoryNodeDndFormatId } from '../../editor/dnd/DndCanvas';
import { Library } from '../../widgets/library/Library';
import { DirectoryNodeDraggable } from '../../editor/dnd/DirectoryNodeDraggable';

type SldLibraryProps = {
  directory: NodeDirectory;
};

type SldLibraryItemProps = {
  entry: NodeEntry;
  nameWidget: ReactNode;
};

export const SldLibrary: React.FC<SldLibraryProps> = ({ directory }) => {
  const items = useMemo(() => {
    return [BusEntry].concat(directory.getAll());
  }, [directory]);

  const widgetFactory = useCallback((entry: NodeEntry, nameWidget: ReactNode) => {
    return <SldLibraryItem entry={entry} nameWidget={nameWidget} />;
  }, []);

  return <Library<NodeEntry> header="Library" items={items} widgetFactory={widgetFactory} />;
};

const SldLibraryItem: React.FC<SldLibraryItemProps> = ({ entry, nameWidget }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const getDragElementHandler = useCallback((): DraggableElement | undefined => {
    if (imageRef.current) {
      return {
        element: imageRef.current,
        x: entry.width,
        y: entry.height,
      };
    }
  }, [entry.height, entry.width]);

  return (
    <DirectoryNodeDraggable
      getDragElement={getDragElementHandler}
      formatId={DirectoryNodeDndFormatId}
      payload={{ ...entry, directoryId: entry.id }}
    >
      {entry.svg.map((svg, index) => (<SvgNodeWidget
          position={index !== 0 ? 'absolute' : 'relative'}
          ref={imageRef} width={32} height={32} source={svg}
      />))}
      {nameWidget}
    </DirectoryNodeDraggable>
  );
};
