import React from 'react';
import { Styled as S } from './IcdFileField.styled';
import { CardType } from '../HardwareCard';
import { InputField } from './InputField';
import { Feedback } from '../../../../../widgets/Feedback';
import { InputUpload } from './InputUpload';
import { IcdReadDto } from '../../../../../../api/nggrace-back';
import { SecondaryButton } from '../../../../../widgets/SecondaryButton';

interface IcdFileFieldProps {
  cardType: CardType;
  fileName?: string;
  icdFileContent: IcdReadDto | undefined;
  icdLoading: boolean;
  handleIcdContentDownload(): void;
  handleIcdFileChange: (icdFile: File) => void;
}

export const IcdFileField: React.FC<IcdFileFieldProps> = ({
  cardType,
  fileName,
  icdFileContent,
  icdLoading,
  handleIcdContentDownload,
  handleIcdFileChange,
}) => {
  return (
    <>
      {cardType === 'view' && (
        <S.InputAndButton cardType={cardType}>
          <InputField onChange={() => {}} cardType={'view'} label={'ICD file'} value={fileName} />
          <SecondaryButton border text={'Download'} iconName={'download'} onClick={handleIcdContentDownload} />
        </S.InputAndButton>
      )}
      {cardType === 'edit' && (
        <S.IcdFileEdit cardType={cardType}>
          <p>ICD file</p>
          <h1>{fileName}</h1>
          <S.FeedbackAndButton>
            <SecondaryButton border text={'Download'} iconName={'download'} onClick={handleIcdContentDownload} />
          </S.FeedbackAndButton>
        </S.IcdFileEdit>
      )}
      {cardType === 'create' && (
        <S.IcdFileEdit cardType={cardType}>
          <p>ICD file</p>
          <InputUpload
            cardType={cardType}
            accept={'.icd'}
            file={fileName ? fileName : 'ICD file'}
            onChange={handleIcdFileChange}
            disabled={false}
          />
          {icdFileContent && !icdLoading && (
            <Feedback
              error={!icdFileContent.success}
              success={icdFileContent.success}
              text={
                icdFileContent.success
                  ? 'Verification completed'
                  : icdFileContent.message
                  ? icdFileContent.message.en
                  : 'Verification failed'
              }
            />
          )}
          {icdLoading && <Feedback loading={icdLoading} text={'Please wait...'} />}
        </S.IcdFileEdit>
      )}
    </>
  );
};
