import styled from 'styled-components';

const TcoCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.white};
  flex: 1 0 400px;
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 32px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Styled = {
  TcoCard,
  Title,
  Content,
};
