import { BaseModel, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { DefaultHasChildren, HasChildren } from '../../placeholder/HasChildren';
import { HasRelativeModel, HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { HasName } from './HasName';
import { RackModel } from '../rack/Rack';
import { BuildingModel } from './BuildingModel';

export class RoomModel extends BaseModel implements HasChildren<RackModel>, HasName, HasRelativeModel<BuildingModel> {
  private hasName: HasName;
  private hasChildren: HasChildren<RackModel>;
  private hasRelativeModel: HasRelativeModel<BuildingModel>;

  constructor(hasName: HasName) {
    super({});
    this.hasName = hasName;
    this.hasChildren = new DefaultHasChildren(this as any);
    this.hasRelativeModel = new HasRelativeParent<BaseModel, BuildingModel>(this);
  }

  addChild(childToAdd: RackModel, index?: number): void {
    this.hasChildren.addChild(childToAdd, index);
  }

  getChildren(): RackModel[] {
    return this.hasChildren.getChildren();
  }

  getName(): string {
    return this.hasName.getName();
  }

  getRelativeModel(): BuildingModel | undefined {
    return this.hasRelativeModel.getRelativeModel();
  }

  setRelativeModel(model: BuildingModel, index?: number): void {
    this.hasRelativeModel.setRelativeModel(model, index);
  }

  serialize() {
    return {
      ...super.serialize(),
      name: this.getName(),
      racks: this.getChildren().map((child) => child.getID()),
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.registerModel(this);
    event.data.racks.forEach((rackId) => {
      event.getModel<RackModel>(rackId).then((rack) => rack.setRoom(this));
    });
  }

  remove() {
    super.remove();
    this.getChildren().forEach((child) => child.remove());
  }

  notCascadeRemove() {
    super.remove();
  }
  canRemove() {
    return (
      !this.isLocked() && !this.getChildren().some((child) => !(child.asComposite() || child.asSingle())!.canRemove())
    );
  }
}
