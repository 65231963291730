import { BaseObserver } from '@projectstorm/react-canvas-core';
import { Listenable } from './Listenable';

export type HasChildrenListener<CHILD> = {
  childrenChanged?: (event: { created?: boolean; child: CHILD }) => void;
};

export interface HasChildren<CHILD extends BaseObserver> {
  getChildren(): CHILD[];

  addChild(childToAdd: CHILD, index?: number): void;
}

export class DefaultHasChildren<CHILD extends BaseObserver> implements HasChildren<CHILD> {
  private readonly children: CHILD[] = [];
  private eventDelegate: Listenable<HasChildrenListener<CHILD>>;

  constructor(eventDelegate: Listenable<HasChildrenListener<CHILD>>) {
    this.eventDelegate = eventDelegate;
  }

  addChild(childToAdd: CHILD, index: number = this.children.length): void {
    this.children.splice(index, 0, childToAdd);

    const handleChildRemoved = childToAdd.registerListener({
      entityRemoved: () => {
        const index = this.children.indexOf(childToAdd);
        if (index !== -1) {
          this.children.splice(index, 1);
          this.eventDelegate.fireEvent({ child: childToAdd, created: false }, 'childrenChanged');
        }

        handleChildRemoved.deregister();
      },
    });

    this.eventDelegate.fireEvent({ child: childToAdd, created: true }, 'childrenChanged');
  }

  getChildren(): CHILD[] {
    return [...this.children];
  }
}
