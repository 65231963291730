import { DefaultDisposed, Disposed, Graph } from '../Graph';
import { Link } from '../../Link';
import { Node } from '../../node/Node';
import { Rectangle } from '@projectstorm/geometry';
import { NgGraceModel } from '../../../NgGraceModel';
import { BusDefaultSize, BusNodeModel, BusPortSize } from '../../../bus/BusNodeModel';
import { Busses } from './Busses';
import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { DisposedPortCenterPoint } from '../../point/DisposedPortCenterPoint';
import { TranslatedNode } from '../../node/TranslatedNode';
import { BasePoint } from '../../../geometry/Point';
import { BusPortModel } from '../../../bus/BusPortModel';

export class BusPortUpdatingGraph implements Graph {
  private bus: Node;
  private busses: Busses;
  private model: NgGraceModel;
  private disposedGraph: Graph;
  private disposed: Disposed;

  constructor(bus: Node, busses: Busses, model: NgGraceModel, disposedGraph: Graph) {
    this.busses = busses;
    this.bus = bus;
    this.model = model;
    this.disposedGraph = disposedGraph;
    this.disposed = new DefaultDisposed(this);
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const disposedNodes = this.disposedGraph.getNodes();
    const busModel = this.model.getNode(this.bus.getID()) as BusNodeModel;
    const busPortsWithPositions = this.getDisposedPortsXPositions(busModel, disposedNodes);
    const busSides =
      busPortsWithPositions.length === 0
        ? emptySides
        : this.getBusXSides(busPortsWithPositions.map((entry) => entry.disposedPosition));
    const disposedBus = new TranslatedNode(
      disposedNodes.find((node) => node.getID() === busModel.getID())!,
      this.getBusCenterTranslation(busSides)
    );
    this.updateBusSize(busModel, busSides);

    this.rearrangePorts(disposedBus, busPortsWithPositions);
    return [...disposedNodes.filter((node) => node.getID() !== busModel.getID()), disposedBus];
  }

  getRect(): Rectangle {
    return this.disposedGraph.getRect();
  }

  private updateBusSize(busModel: BusNodeModel, xSides: Sides) {
    const { left, right } = xSides;
    busModel.setSize(new BasePoint(Math.max(BusDefaultSize.x, right - left), BusDefaultSize.y));
  }

  private rearrangePorts(disposedBus: Node, busPortsWithPositions: { busPort: BusPortModel; disposedPosition: number }[]) {
    const busRect = disposedBus.getRect();
    const busWidth = busRect.getWidth();
    const portCenter = BusPortSize / 2;
    busPortsWithPositions.forEach(({ busPort, disposedPosition }) => {
      const portOffset = disposedPosition - busRect.getTopLeft().x;
      const busWillBeReversed = !this.busses.getBusRows()[0].find((node) => node.getID() === this.bus.getID());
      busPort.setBusOffset((busWillBeReversed ? busWidth - portOffset : portOffset) - portCenter);
    });
  }

  private getDisposedPortsXPositions(
    busModel: BusNodeModel,
    disposedNodes: Node[]
  ): { busPort: BusPortModel; disposedPosition: number }[] {
    return Object.values(busModel.getPorts())
      .map((busPort) => {
        const link = busPort.getLink();
        if (!link) {
          return undefined;
        }

        const connectedPort = (link.getSourcePort().getID() === busPort.getID()
          ? link.getTargetPort()
          : link.getSourcePort()) as ConnectablePortModel;
        const disposedNode = disposedNodes.find((node) => node.getID() === connectedPort.getParent().getID());
        if (!disposedNode) {
          return undefined;
        }
        return { busPort, disposedPosition: new DisposedPortCenterPoint(connectedPort, disposedNode).x };
      })
      .filter((point): point is { busPort: BusPortModel; disposedPosition: number } => point !== undefined);
  }

  private getBusXSides(portsXPositions: number[]): Sides {
    const padding = 40;
    const busLeftSide = Math.min(...portsXPositions) - padding;
    const busRightSide = Math.max(...portsXPositions) + padding;
    return { left: busLeftSide, right: busRightSide };
  }

  private getBusCenterTranslation(xSides: Sides) {
    const { left, right } = xSides;
    return new BasePoint(left + (right - left) / 2, 0);
  }
}

interface Sides {
  left: number;
  right: number;
}

const emptySides = {
  left: 0,
  right: 0,
};
