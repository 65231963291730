import React, { useCallback, useEffect, useState } from 'react';
import { Styled as S } from './ExportFormModal.styled';
import { ExportFormatType, ExportFormDto, ExportFormType, ProjectDto } from '../../../api/nggrace-back';
import { InputGroup } from '../../widgets/InputGroup.styled';
import { InputRadioGroup, OptionType } from '../../widgets/InputRadioGroup';
import { Api } from '../../../api/Api';
import { ExportLang as $s } from './Export.lang';
import { Feedback } from '../../widgets/Feedback';
import { ProjectInfoGroup } from './form/ProjectInfoGroup';
import { ResponsibleSpecialistsGroup } from './form/ResponsibleSpecialistsGroup';
import { CreatedGroup } from './form/CreatedGroup';
import { CompanyInfoGroup } from './form/CompanyInfoGroup';
import { SecondaryButton } from '../../widgets/SecondaryButton';

type ExportFormModalProps = {
  project: ProjectDto;
  type: ExportFormType;

  onClose(): void;
  onExport(dto: ExportFormDto, format: ExportFormatType): void;
};

const formats: OptionType<ExportFormatType>[] = [
  { id: 'SVG', name: 'SVG' },
  // { id: 'PDF', name: 'PDF', disabled: true },
  { id: 'SCL', name: 'SCL' },
];

export const ExportFormModal: React.FC<ExportFormModalProps> = ({ project, type, onClose, onExport }) => {
  const [exportForm, setExportForm] = useState<ExportFormDto>();
  const [format, setFormat] = useState<ExportFormatType>('SVG');
  const [error, setError] = useState<string>();

  useEffect(() => {
    Api.getExportForm(project.id, type).then((response) =>
      setExportForm({
        stage: 'WORKING_DOC',
        ...response.data,
      })
    );
  }, [project.id, type]);

  const handleExport = useCallback(async () => {
    if (exportForm) {
      Api.updateExportForm(exportForm.id, exportForm, { omitInterceptorErrorModal: true })
        .then(() => onExport(exportForm, format))
        .then(onClose)
        .catch((error) => {
          setError(error.response.data);
        });
    }
  }, [exportForm, format, onClose, onExport]);

  const handleChange = useCallback((updater: (form: ExportFormDto) => ExportFormDto) => {
    setExportForm((exportForm) => exportForm && updater(exportForm));
  }, []);

  const style = project.styleSelection;

  return (
    <S.Modal>
      <S.Scroll>
        <S.ScrollContent>
          <S.Header>
            <b>Export</b>&nbsp;{type}
          </S.Header>
          {exportForm && (
            <S.Content>
              <S.Group>
                <S.GroupTitle>{$s.infoGroup.header[style]}</S.GroupTitle>
                <ProjectInfoGroup style={style} form={exportForm} project={project} onChange={handleChange} />
              </S.Group>
              <S.Group>
                <S.GroupTitle>Responsible Specialists</S.GroupTitle>
                <ResponsibleSpecialistsGroup style={style} form={exportForm} onChange={handleChange} />
              </S.Group>
              <S.Group>
                <S.GroupTitle>Created</S.GroupTitle>
                <CreatedGroup form={exportForm} onChange={handleChange} />
              </S.Group>
              <S.Group>
                <S.GroupTitle>Company info</S.GroupTitle>
                <CompanyInfoGroup style={style} form={exportForm} project={project} onChange={handleChange} />
              </S.Group>
              <S.Group>
                <S.GroupTitle>Format</S.GroupTitle>
                <InputGroup>
                  <InputRadioGroup<ExportFormatType>
                    value={format}
                    options={formats}
                    onChange={setFormat}
                    name="format"
                    hideBorder
                  />
                </InputGroup>
              </S.Group>
              {error && (
                <S.Error>
                  <Feedback error text={error} />
                </S.Error>
              )}
            </S.Content>
          )}
          <S.Footer>
            <SecondaryButton text={'Cancel'} onClick={onClose} />
            <S.ExportBtn onClick={handleExport}>Export</S.ExportBtn>
          </S.Footer>
        </S.ScrollContent>
      </S.Scroll>
    </S.Modal>
  );
};
