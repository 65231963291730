import { DeserializeEvent, Toolkit } from '@projectstorm/react-canvas-core';
import { LinkLayerModel } from '@projectstorm/react-diagrams';
import { ConnectingSmartLinkModelType } from '../../link/smart/ConnectingSmartLinkModel';

export class DefaultLinkLayerModel extends LinkLayerModel {
  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(this.getDeserializationEventWithFilteredLinks(event));
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }

  private getDeserializationEventWithFilteredLinks(event: DeserializeEvent<this>) {
    const filteredModels = Object.values(event.data.models)
      .filter((link) => link.type !== ConnectingSmartLinkModelType)
      .reduce((obj: { [key: string]: any }, link) => {
        obj[link.id] = link;
        return obj;
      }, {});
    return { ...event, data: { ...event.data, models: filteredModels } };
  }
}
