import styled from 'styled-components';
import { Styled as StyledInputField } from '../../../../../../../widgets/InputField.styled';
import { CardType } from '../../../../../hardware/card/HardwareCard';
import { Styled as InputSelect } from '../../../../../../../widgets/InputSelect.styled';

const Form = styled.div<{ cardType?: CardType }>`
  width: 100%;

  ${StyledInputField.InputField} {
    width: 100%;
    margin-bottom: 30px;
    opacity: ${(props) => (props.cardType === 'view' ? '1 !important' : 'inherit')};
  }

  ${InputSelect.InputSelect} {
    ${InputSelect.InputOptions} {
      max-height: 302px;
    }
    ${InputSelect.InputOption} {
      [type='checkbox'] {
        margin-right: 19px;
      }
    }

    ${InputSelect.InputSelected} {
      &:after {
        border-top: ${(props) => (props.cardType === 'view' ? 'none' : `5px solid ${props.theme.colors.blue}`)};
      }
    }
  }
`;

export const Styled = {
  InputSelect: InputSelect.InputSelect,
  InputSelected: InputSelect.InputSelected,
  InputSelectedValue: InputSelect.InputSelectedValue,
  InputOptions: InputSelect.InputOptions,
  InputOption: InputSelect.InputOption,
  InputLabel: InputSelect.InputLabel,
  InputFilter: InputSelect.InputFilter,
  Form,
};
