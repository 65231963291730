import styled from 'styled-components';
import { Styled as StyledInputField } from './InputField.styled';
import { Hint } from './Tooltip';
import { InputRow } from './InputRow.styled';

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 570px;
  position: relative;

  ${StyledInputField.InputField}, ${InputRow} {
    width: 100%;
  }

  ${Hint} {
    position: absolute;
    right: -32px;
    bottom: 12px;
  }
`;
