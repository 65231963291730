import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import 'tippy.js/dist/tippy.css';
import 'simplebar/dist/simplebar.min.css';
import 'katex/dist/katex.min.css';
import './assets/styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
  });
}

ReactModal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
