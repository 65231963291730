import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Direction, UserDto, UserSortProperty } from '../../../api/nggrace-back';
import { User } from './User';
import { SortState } from './UsersPage';
import { Styled as S } from './UserList.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from '../../widgets/List.styled';
import { SortCell } from '../../widgets/SortCell';
import { hasCompaniesAccess } from '../../../utils/role-utils';
import { useUser } from '../login/UserContext';
import { InputSelect } from '../../widgets/InputSelect';
import { OptionType } from '../../widgets/InputRadioGroup';

interface UserListProps {
  users: UserDto[];
  setSort: (sort: SortState) => void;
  handleUpdate: (user: UserDto) => void;
  handleDelete: (user: UserDto) => void;
}

export const UserList: React.FC<UserListProps> = ({ users, setSort, handleUpdate, handleDelete }) => {
  const user = useUser()!.user!;
  const userHasCompaniesAccess = useMemo(() => hasCompaniesAccess(user), [user]);
  const [companies, setCompanies] = useState<OptionType<string>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [showsUsers, setShowsUsers] = useState<UserDto[]>(users);

  const [state, setState] = useState<{ sortProperty: UserSortProperty; sortDirection: Direction }>({
    sortProperty: 'CREATED_AT',
    sortDirection: 'DESC',
  });

  useEffect(() => {
    setCompanies(
      [...new Set(users.map((user) => user.company.name))].map((company) => ({ id: company, name: company }))
    );
  }, [users]);

  useEffect(() => {
    if (selectedCompany === undefined) {
      setShowsUsers(users);
      return;
    }

    setShowsUsers(users.filter((user) => (user.company.name === selectedCompany ? user : undefined)));
  }, [users, selectedCompany]);

  const direction = (property: UserSortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback(
    (property: UserSortProperty, direction: Direction) => {
      setSort({ property: property, direction: direction });
      setState({ sortProperty: property, sortDirection: direction });
      return { ...state, sortProperty: property, sortDirection: direction };
    },
    [setSort, state]
  );

  return (
    <S.UserList>
      <InfiniteScroll
        dataLength={users.length}
        next={() => {}}
        hasMore={true}
        scrollableTarget="scrollableTarget"
        loader={null}
      >
        <S.Grid id="scrollableTarget" showCompany={userHasCompaniesAccess}>
          <List.HeaderRow>
            <SortCell<UserSortProperty> type={'NAME'} onSort={handleSort} direction={direction('NAME')}>
              Name
            </SortCell>
            <SortCell<UserSortProperty> type={'EMAIL'} onSort={handleSort} direction={direction('EMAIL')}>
              Email
            </SortCell>
            {userHasCompaniesAccess && (
              <List.FilterCell>
                <InputSelect
                  filter
                  label={'Company'}
                  hideLabel
                  hideBorder
                  onChange={(company) => setSelectedCompany(company?.toString())}
                  selected={selectedCompany}
                  options={companies}
                />
              </List.FilterCell>
            )}
            <SortCell<UserSortProperty> type={'CREATED_AT'} onSort={handleSort} direction={direction('CREATED_AT')}>
              Created
            </SortCell>
            <SortCell<UserSortProperty> type={'STATUS'} onSort={handleSort} direction={direction('STATUS')}>
              Status
            </SortCell>
            <List.HeaderCell center>Edit</List.HeaderCell>
            <List.HeaderCell center>Delete</List.HeaderCell>
          </List.HeaderRow>

          {showsUsers.map((user, index) => (
            <User
              key={index}
              user={user}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              companyShown={userHasCompaniesAccess}
            />
          ))}
        </S.Grid>
      </InfiniteScroll>
    </S.UserList>
  );
};
