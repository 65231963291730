import { UserDto, UserRole } from '../api/nggrace-back';

export const hasLibrariesAccess = (user: UserDto): boolean => {
  return user.role === 'ROLE_SYSTEM_ADMIN' || user.role === 'ROLE_COMPANY_ADMIN' || user.role === 'ROLE_COMPANY_USER';
};

export const hasCompaniesAccess = (user: UserDto): boolean => {
  return user.role === 'ROLE_SYSTEM_ADMIN';
};

export const hasProjectsAccess = (user: UserDto): boolean => {
  return user.role === 'ROLE_SYSTEM_ADMIN' || user.role === 'ROLE_COMPANY_ADMIN' || user.role === 'ROLE_COMPANY_USER';
};

export const hasUsersAccess = (user: UserDto): boolean => {
  return user.role === 'ROLE_SYSTEM_ADMIN' || user.role === 'ROLE_COMPANY_ADMIN';
};

export const hasLibraryEditAccess = (user: UserDto): boolean => {
  return user.role === 'ROLE_SYSTEM_ADMIN' || user.role === 'ROLE_COMPANY_ADMIN';
};

export const convertRole = (role: UserRole): string => {
  switch (role) {
    case 'ROLE_SYSTEM_ADMIN':
      return 'System Administrator';
    case 'ROLE_COMPANY_ADMIN':
      return 'Company Administrator';
    case 'ROLE_COMPANY_USER':
      return 'User';
  }
};
