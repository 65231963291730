import React, { useCallback } from 'react';
import { UserDto } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { Confirmation } from '../../widgets/Confirmation';
import { useNotifications } from '../../context/NotificationContext';

interface UserControlProps {
  onHide: () => void;
  user: UserDto;
  loadPageHandler: () => void;
}

export const UserDeleteConfirmation: React.FC<UserControlProps> = ({ onHide, user, loadPageHandler }) => {
  const notifications = useNotifications();
  const handleDeleteUser = useCallback(() => {
    Api.deleteUser({ userId: user.id }).then(() => {
      loadPageHandler();
      notifications.notifySuccess('User deleted.');
    });
    onHide();
  }, [loadPageHandler, notifications, onHide, user.id]);

  return (
    <Confirmation
      title={'Remove user'}
      text={`Are you sure you want to remove ${user.name}?`}
      onClose={onHide}
      onConfirm={handleDeleteUser}
    />
  );
};
