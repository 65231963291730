import { Point } from '@projectstorm/geometry';
import { RotationHour } from '../../../geometry/RotationHour';
import { LanNodeModel } from '../LanNodeModel';
import { PropertiesDirectoryName, SwitchDirectoryEntry } from '../../../directory/PropertiesDirectory';
import { SwitchPortModel } from './SwitchPortModel';
import { LanPortModel } from '../port/LanPortModel';

export const DefaultSwitchSize = new Point(30, 30);
export const SwitchNodeType = 'switch';

export class SwitchNodeModel extends LanNodeModel {
  constructor(directoryEntry: SwitchDirectoryEntry) {
    super(
      {
        type: SwitchNodeType,
        payload: {
          hour: RotationHour.ZERO,
        },
        defaultSize: DefaultSwitchSize,
        resizers: [],
      },
      directoryEntry
    );
  }

  createPort(label: string): LanPortModel {
    return new SwitchPortModel({ directoryEntry: this.getDirectoryEntry(), label });
  }

  getDirectory(): PropertiesDirectoryName {
    return 'SwitchDirectory';
  }
}