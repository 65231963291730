import React, {useCallback, useState} from 'react';
import { Page } from '../../Page';
import { Overlay } from '../../widgets/Overlay';
import { useParams } from 'react-router-dom';
import { ProjectSidebar } from '../project/ProjectSidebar';
import { MessageModal } from '../../widgets/MessageModal';
import { EngineProvider } from '../../editor/EngineContext';
import { useStage } from '../../hooks/useStage';
import { DiagramModelProvider } from '../../editor/DiagramModelContext';
import { LanDiagramEditor } from './LanDiagramEditor';
import { LanEngine } from '../../editor/lan/LanEngine';
import { useDirectory } from '../../hooks/useDirectory';
import { useNotifications } from '../../context/NotificationContext';
import { useNextStage } from '../../hooks/useNextStage';
import { TcoSettings } from '../tco/TcoSettings';
import { LanModel } from '../../editor/lan/LanModel';

export const LanStagePage: React.FC = () => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [validation, setValidation] = useState<string>();
  const { projectId } = useParams<{ projectId?: string }>();
  const { stage, updateStage, validateStage, finishStage, rollbackStage, reloadStage, updateLanStage } = useStage(projectId!, 'LAN');
  const { switchDirectory, networkDeviceTypeDirectory, networkDeviceDirectory, controllerDirectory } = useDirectory();
  const { nextStage, showNextStageModal, closeNextStageModal } = useNextStage(stage);

  const handleValidateStage = useCallback(async () => {
    setOverlayVisible(true);
    const result = await validateStage().finally(() => setOverlayVisible(false));

    if (!result.success) {
      setValidation(result.message?.en || 'Unknown validation error');
      return false;
    }
    return true;
  }, [validateStage]);

  const handleValidationClose = useCallback(() => {
    setValidation(undefined);
  }, []);

  const notifications = useNotifications();
  const handleEngineFactory = useCallback(() => {
    return new LanEngine(
      switchDirectory,
      networkDeviceTypeDirectory,
      networkDeviceDirectory,
      controllerDirectory,
      notifications.notifyError
    );
  }, [switchDirectory, networkDeviceTypeDirectory, networkDeviceDirectory, controllerDirectory, notifications]);

  const handleStageInit = useCallback(
    (model) => {
      return new Promise((resolve) =>
        setTimeout(async () => {
          (model as LanModel).clearSelection();
          resolve(undefined);
        })
      );
    },
    [updateStage]
  );

  return (
    <Page hasSidebar loading={!stage}>
      {stage && (
        <>
          <ProjectSidebar project={stage.project} current={stage.baseInfo.type} />

          <EngineProvider
            key={stage.baseInfo.id} // important here, we need to re-init engine on new stage
            stage={stage}
            engineFactory={handleEngineFactory}
            onInitStage={handleStageInit}
            onUpdateStage={updateLanStage}
          >
            <DiagramModelProvider>
              <LanDiagramEditor
                stage={stage}
                onNextStage={nextStage}
                onValidateStage={handleValidateStage}
                onFinishStage={finishStage}
                onRollbackStage={rollbackStage}
                onReloadStage={reloadStage}
              />
            </DiagramModelProvider>
          </EngineProvider>
          {overlayVisible && <Overlay />}
          <MessageModal error header="Validation failed" isOpen={!!validation} onRequestClose={handleValidationClose}>
            {validation}
          </MessageModal>
          {showNextStageModal && <TcoSettings stage={stage} onClose={closeNextStageModal} />}
        </>
      )}
    </Page>
  );
};