import styled, { css } from 'styled-components';
import { Icon } from '../../../../../../widgets/Icon.styled';

const indent = 16;
const rowHeight = 36;

const TreeNode = styled.div<{ indentLevel: number }>`
  position: relative;
`;

const Header = styled.div<{
  highlight: boolean;
  canHighlight?: boolean;
  hasParent: boolean;
  last: boolean;
  indentLevel: number;
}>`
  width: 100%;
  height: ${rowHeight}px;

  padding-left: 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ highlight, theme }) => (highlight ? theme.colors.white : theme.colors.black)};
  background-color: ${({ highlight, theme }) => highlight && theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  ${({ highlight, theme }) =>
    !highlight &&
    css`
      :hover {
        background-color: ${theme.colors.solitude};
      }
    `}

  ${(props) =>
    !props.canHighlight &&
    css`
      cursor: default;
    `}
`;

const TreeToggle = styled.div<{ hasParent: boolean; indentLevel: number }>`
  z-index: 1;
`;

const Toggle = styled(TreeToggle)<{ open: boolean; highlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;

  ${Icon} {
    width: 20px;
    height: 20px;
    transform: ${(p) => (p.open ? '' : 'rotate(270deg)')};
  }

  ${({ open }) =>
    !open &&
    css`
      :after {
        display: none;
      }
    `}
`;

const EmptyToggle = styled(TreeToggle)<{ highlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  flex-shrink: 0;

  ${Icon} {
    width: 20px;
    height: 20px;
  }

  :before {
    width: 12px;
  }

  :after {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
`;

const SubTree = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
`;

const Indent = styled.div<{ level: number }>`
  width: ${({ level }) => indent * level}px;
  flex-shrink: 0;
`;

const Cell = styled.div``;

const RowInfo = styled.div`
  display: grid;
  grid-template-columns: 83px 81px 222px 83px 189px 222px;

  & ${Cell} {
    margin-left: -8px;
  }
`;

export const Styled = {
  TreeNode,
  Header,
  TreeToggle,
  Toggle,
  EmptyToggle,
  Title,
  SubTree,
  Indent,
  RowInfo,
  Cell,
};
