import * as React from 'react';
import { BusNodeModel, BusPortVisibleSize } from '../../../bus/BusNodeModel';
import { ExportBusPort } from './ExportBusPort';
import { ExportBusNodeLabel } from './ExportBusNodeLabel';
import {PropertiesDirectory, VoltageLevelDirectoryEntry} from "../../../directory/PropertiesDirectory";

interface ExportBusNodeProps {
  node: BusNodeModel;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
}

const ExportBusNodeRadius = 1;

export const ExportBusNode: React.FC<ExportBusNodeProps> = ({ node, voltageLevelDirectory }) => {
  return (
    <g transform={`translate(${node.getPositionToRender().x},${node.getPositionToRender().y})`}>
      <ExportBusNodeLabel node={node} />
      <rect
        x={0}
        y={0}
        rx={ExportBusNodeRadius}
        ry={ExportBusNodeRadius}
        height={node.getRotatedSize().y}
        width={node.getRotatedSize().x}
        fill={node.getPayload().colorIds
            ? voltageLevelDirectory.getEntry(node.getPayload().colorIds![0]).color
            : 'black'
        }
      />
      {Object.values(node.getPorts()).map((port) => (
        <ExportBusPort key={port.getID()} port={port} size={BusPortVisibleSize} />
      ))}
    </g>
  );
};
