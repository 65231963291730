import styled from '@emotion/styled';
import * as React from 'react';
import { BusNodeModel, BusPortSize, BusResizerId, BusResizerSize } from './BusNodeModel';
import { BusPortWidget } from './BusPortWidget';
import { NodeResizer } from '../NodeResizer';
import { LabelWidget } from '../label/LabelWidget';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import { PropertiesDirectory, VoltageLevelDirectoryEntry } from '../directory/PropertiesDirectory';

export interface BusNodeWidgetProps {
  node: BusNodeModel;
  engine: DiagramEngine;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
}

const Node = styled.div<{ selected: boolean; width: number; height: number }>`
  cursor: pointer;

  position: relative;
  left: 0;
  right: 0;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  transform-origin: center;
`;

const LeftResizer = styled.div<{ horizontal: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  cursor: ${(p) => (p.horizontal ? 'ew-resize' : 'ns-resize')};
`;

const RightResizer = styled.div<{ horizontal: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  cursor: ${(p) => (p.horizontal ? 'ew-resize' : 'ns-resize')};
`;

const SelectionBorder = styled.div<{ width: number; height: number }>`
  border: 1px solid deepskyblue;
  border-radius: 1px;
  position: absolute;

  top: ${-1}px;
  left: ${-1}px;

  height: ${(p) => p.height + 2}px;
  width: ${(p) => p.width + 2}px;
`;

export const BusNodeWidget: React.FC<BusNodeWidgetProps> = ({ node, engine, voltageLevelDirectory }) => {
  const size = node.getSize();
  const height = size.y;
  const width = size.x;
  const resizerWidth = BusResizerSize;
  const ports = Object.values(node.getPorts());
  const horizontal = !node.getRotation().getDirection().isHorizontal();
  const color =
    node.getPayload().colorIds && node.getPayload().colorIds!.length !== 0
      ? voltageLevelDirectory.getEntry(node.getPayload().colorIds![0]).color
      : 'black';
  return (
    <>
      <Node selected={node.isSelected()} height={height} width={width} style={{ backgroundColor: `${color}` }}>
        {node.isSelected() && <SelectionBorder height={height} width={width} />}
        <LeftResizer horizontal={horizontal}>
          <NodeResizer width={resizerWidth} height={height} resizerId={BusResizerId.LEFT} color={color} />
        </LeftResizer>

        <RightResizer horizontal={horizontal}>
          <NodeResizer width={resizerWidth} height={height} resizerId={BusResizerId.RIGHT} color={color} />
        </RightResizer>

        {ports.map((port) => (
          <BusPortWidget port={port} engine={engine} size={BusPortSize} key={port!.getName()} />
        ))}
      </Node>
      <LabelWidget label={node.getLabel()} node={node} />
    </>
  );
};
