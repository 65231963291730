import * as React from 'react';
import { PortModel, PortWidget } from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import { ConnectivityNodeSize } from './ConnectivityNodeModel';
import { ConnectivityPortSize } from './ConnectivityPortModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export interface ConnectivityPortWidgetProps {
  port: PortModel;
  engine: DiagramEngine;
}

export const Port = styled.div<{ size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
  border-radius: 8px;
  cursor: pointer;
`;

export const ConnectivityPortWidget: React.FC<ConnectivityPortWidgetProps> = ({ port, engine }) => {
  return (
    <PortWidget
      style={{
        top: ConnectivityNodeSize / 2,
        left: ConnectivityNodeSize / 2,
        position: 'absolute',
      }}
      port={port}
      engine={engine as any}
    >
      <Port size={ConnectivityPortSize} />
    </PortWidget>
  );
};
