import { Point } from '@projectstorm/geometry';
import { Direction, DefaultDirection, SmartDirection } from './Direction';
import { BasePoint } from './Point';
import { DirectionCoordinate } from './Coordinate';

export interface RightAngledVector {
  getStart(): BasePoint;

  getEnd(): BasePoint;

  getDirection(): SmartDirection;
}

export class Vector {
  private readonly start: Point;
  private readonly end: Point;

  constructor(start: Point, end: Point) {
    this.start = start;
    this.end = end;
  }

  getDirections(): { x: SmartDirection; y: SmartDirection } {
    const start = this.start;
    const end = this.end;

    return {
      y: new DefaultDirection(end.y - start.y >= 0 ? Direction.BOTTOM : Direction.TOP),
      x: new DefaultDirection(end.x - start.x >= 0 ? Direction.RIGHT : Direction.LEFT),
    };
  }
}

export class DefaultRightAngledVector implements RightAngledVector {
  private readonly start: BasePoint;
  private readonly end: BasePoint;

  constructor(start: Point, end: Point) {
    this.start = new BasePoint(start);
    this.end = new BasePoint(end);

    if (!this.numbersClose(start.x, end.x) && !this.numbersClose(start.y, end.y)) {
      throw new Error(
        'line is directed not vertical or horizontal\n ' + JSON.stringify(start) + '\n ' + JSON.stringify(end)
      );
    }
  }

  getStart(): BasePoint {
    return this.start;
  }

  getEnd(): BasePoint {
    return this.end;
  }

  getDirection(): SmartDirection {
    const start = this.start;
    const end = this.end;

    if (this.numbersClose(start.x, end.x)) {
      return new DefaultDirection(end.y > start.y ? Direction.BOTTOM : Direction.TOP);
    }

    if (this.numbersClose(start.y, end.y)) {
      return new DefaultDirection(end.x > start.x ? Direction.RIGHT : Direction.LEFT);
    }

    throw new Error('line is directed not vertical or horizontal');
  }

  private numbersClose(a: number, b: number) {
    const accuracy = 0.1;
    return Math.abs(a - b) <= accuracy;
  }
}

export class DirectedVector implements RightAngledVector {
  private readonly direction: SmartDirection;
  private readonly start: BasePoint;
  private readonly length: number;

  constructor(direction: SmartDirection, start: BasePoint, length: number) {
    this.direction = direction;
    this.start = start;
    this.length = length;
  }

  getDirection(): SmartDirection {
    return this.direction;
  }

  getEnd(): BasePoint {
    const end = new BasePoint(this.start);
    const directionCoordinate = new DirectionCoordinate(this.getDirection());
    const directedAddition = this.getDirection().isIncreasing() ? this.length : -this.length;
    end[directionCoordinate.getName()] = this.start[directionCoordinate.getName()] + directedAddition;
    return end;
  }

  getStart(): BasePoint {
    return this.start;
  }
}
