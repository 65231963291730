import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

interface StatusTagProps {
  active: boolean;
  onClick: (active: boolean) => void;
  disabled?: boolean;
}

const Tag = styled.span<{ active: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 100px;
  width: 78px;
  height: 24px;

  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkgrey};

  &:hover {
    background: ${(props) => ` ${props.theme.colors.hoverTag},  ${props.theme.colors.darkgrey}`};
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(prop) => prop.theme.colors.turquoise};

      &:hover {
        background: ${(prop) => `${prop.theme.colors.hoverTag}, ${prop.theme.colors.turquoise}`};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: white;
      color: black;
      cursor: initial;

      &:hover {
        background: white;
      }
    `}
`;

export const StatusTag: React.FC<StatusTagProps> = ({ active, onClick, disabled = false }) => {
  const onClickTag = useCallback(() => onClick(!active), [active, onClick]);

  return (
    <Tag active={active} onClick={disabled ? () => {} : onClickTag} disabled={disabled}>
      {active ? 'active' : 'inactive'}
    </Tag>
  );
};
