import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Direction,
  HardwareViewForListDto,
  HardwareSortProperty,
  HardwareSortDto,
  HardwareType,
  VendorViewDto,
  UserBaseDto,
  HardwareViewDto,
  HardwareDto,
  SvgBaseDto,
} from '../../../../api/nggrace-back';
import { Icon } from '../../../widgets/Icon';
import { List } from '../../../widgets/List.styled';
import { ListWithoutMargin } from '../ListWithoutMargin.styled';
import { Api } from '../../../../api/Api';
import { theme } from '../../../../theme';
import { Confirmation } from '../../../widgets/Confirmation';
import ReactModal from 'react-modal';
import { useNotifications } from '../../../context/NotificationContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Styled as S } from '../LibraryList.styled';
import { SortCellWithoutMargin as SortCell } from '../SortCellWithoutMargin';
import { InputSelect } from '../../../widgets/InputSelect';
import { formatMomentShort } from '../../../../utils/date-utils';
import { EmptyList } from '../../../widgets/EmptyList';
import { CardType, HardwareCard } from './card/HardwareCard';
import { useUser } from '../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../utils/role-utils';

const hasIcdFileNameColumn = (hardwareType: HardwareType): boolean =>
  (['IED', 'IED_MU', 'MU'] as HardwareType[]).includes(hardwareType);

interface HardwareRowProps {
  hardwareType: HardwareType;
  hardware: HardwareViewForListDto;
  cardType: CardType;
  hasIcd: boolean;
  vendors: VendorViewDto[];
  userHasLibraryEditAccess: boolean;
  handleCardTypeChange: (type: CardType) => void;
  remove: (hardwareId: number) => void;
  duplicate: (hardwareId: number) => void;
}

interface HardwareListState {
  hardware: HardwareViewForListDto[];
  hasMore: boolean;
  page: number;
  size: number;
  vendorId?: number;
  editorId?: number;
  icdFileName?: string;
  svgId?: number;
  sortProperty: HardwareSortProperty;
  sortDirection: Direction;
  vendors: VendorViewDto[];
  editors: UserBaseDto[];
  icdFileNames: string[];
  svgList: SvgBaseDto[];
}

interface HardwareListProps {
  hardwareType: HardwareType;
  hardwareCreationVisibility: boolean;
  hideHardwareCreation: () => void;
}

export const HardwareList: React.FC<HardwareListProps> = ({
  hardwareType,
  hardwareCreationVisibility,
  hideHardwareCreation,
}) => {
  const [state, setState] = useState<HardwareListState>({
    hardware: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'NAME',
    sortDirection: 'ASC',
    vendors: [],
    editors: [],
    icdFileNames: [],
    svgList: [],
  });
  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);

  const notifications = useNotifications();
  const hasIcd = useMemo(() => hasIcdFileNameColumn(hardwareType), [hardwareType]);
  const [isListEmpty, setListEmpty] = useState(true);
  const [cardType, setCardType] = useState<CardType>('view');

  useLayoutEffect(() => {
    Api.getHardwareFilterOptions(hardwareType).then((response) => {
      setState((state) => ({
        ...state,
        vendors: response.data.vendors,
        editors: response.data.editors,
        icdFileNames: response.data.icdNames,
        svgList: response.data.svg,
      }));
    });
  }, [hardwareType]);

  useLayoutEffect(() => {
    Api.isThereAnyHardware({ hardwareType }).then((response) => setListEmpty(!response.data));
  }, [hardwareType]);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: HardwareSortDto = {
        property: state.sortProperty,
        direction: state.sortDirection,
        vendorId: state.vendorId,
        editorId: state.editorId,
        icdFilename: state.icdFileName,
        svgId: state.svgId,
      };
      Api.getHardware(sort, { hardwareType, page, size }).then((response) => {
        setState((state) => ({
          ...state,
          hardware: reload ? response.data.content || [] : state.hardware.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
        setListEmpty(response.data.totalElements === 0);
      });
    },
    [
      state.sortDirection,
      state.sortProperty,
      state.vendorId,
      state.editorId,
      state.icdFileName,
      state.svgId,
      hardwareType,
    ]
  );

  const reload = useCallback(() => {
    setState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, state.size, true);
  }, [fetch, state.size]);

  useEffect(reload, [reload]);

  const direction = (property: HardwareSortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback((property: HardwareSortProperty, direction: Direction) => {
    setState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const handleNext = useCallback(() => {
    fetch(state.page + 1, state.size);
    setState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, state.page, state.size]);

  const handleDelete = useCallback(
    (hardwareId) => {
      Api.deleteHardware(hardwareId)
        .then(() => {
          fetch(state.hardware.length - 1, 1);
          notifications.notifySuccess('Hardware deleted.');
        })
        .then(() =>
          setState((state) => ({
            ...state,
            hardware: state.hardware.filter((it) => it.id !== hardwareId),
          }))
        );
    },
    [fetch, notifications, state.hardware.length]
  );

  const handleVendorChange = useCallback((vendorId: number) => {
    setState((state) => {
      return { ...state, vendorId };
    });
  }, []);

  const handleEditorChange = useCallback((editorId: number) => {
    setState((state) => {
      return { ...state, editorId };
    });
  }, []);

  const handleIcdFileNameChange = useCallback((icdFileName: string) => {
    setState((state) => {
      return { ...state, icdFileName };
    });
  }, []);

  const handleSvgChange = useCallback((svgId: number) => {
    setState((state) => {
      return { ...state, svgId };
    });
  }, []);

  const handleCreate = useCallback(
    (hardware?: HardwareDto) => {
      hardware && reload();
      hideHardwareCreation();
    },
    [hideHardwareCreation, reload]
  );

  const duplicateHardware = useCallback(
    (id: number) => {
      Api.cloneHardware(id).then((response) => {
        setState((state) => ({
          ...state,
          hardware: [response.data, ...state.hardware],
        }));
        notifications.notifySuccess('Hardware duplicated');
      });
    },
    [notifications]
  );

  return (
    <>
      {!isListEmpty && (
        <S.LibraryList>
          <InfiniteScroll
            dataLength={state.hardware.length}
            next={handleNext}
            hasMore={state.hasMore}
            scrollableTarget="scrollableTarget"
            loader={null}
          >
            <S.HardwareGrid id="scrollableTarget" hasIcd={hasIcd} userHasLibraryEditAccess={userHasLibraryEditAccess}>
              <List.HeaderRow>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Vendor'}
                    hideLabel
                    hideBorder
                    selected={state.vendorId}
                    options={state.vendors.map((it) => ({ id: it.id, name: it.name }))}
                    onChange={handleVendorChange}
                  />
                </ListWithoutMargin.FilterCell>
                <SortCell<HardwareSortProperty> type={'NAME'} direction={direction('NAME')} onSort={handleSort}>
                  Name
                </SortCell>
                <SortCell<HardwareSortProperty> type={'MODEL'} direction={direction('MODEL')} onSort={handleSort}>
                  Model
                </SortCell>
                <SortCell<HardwareSortProperty>
                  type={'EDITED_AT'}
                  direction={direction('EDITED_AT')}
                  onSort={handleSort}
                >
                  Edited
                </SortCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Editor'}
                    hideLabel
                    hideBorder
                    selected={state.editorId}
                    options={state.editors}
                    onChange={handleEditorChange}
                  />
                </ListWithoutMargin.FilterCell>
                {hasIcd && (
                  <ListWithoutMargin.FilterCell>
                    <InputSelect
                      filter
                      label={'ICD file name'}
                      hideLabel
                      hideBorder
                      selected={state.icdFileName}
                      options={state.icdFileNames.map((it) => ({ id: it, name: it }))}
                      onChange={handleIcdFileNameChange}
                    />
                  </ListWithoutMargin.FilterCell>
                )}
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'SVG file name'}
                    hideLabel
                    hideBorder
                    selected={state.svgId}
                    options={state.svgList}
                    onChange={handleSvgChange}
                  />
                </ListWithoutMargin.FilterCell>
                {userHasLibraryEditAccess && (
                  <ListWithoutMargin.HeaderCell center>Duplicate</ListWithoutMargin.HeaderCell>
                )}
                {userHasLibraryEditAccess && <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>}
                {userHasLibraryEditAccess && <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>}
              </List.HeaderRow>
              {state.hardware.map((hardware) => (
                <HardwareRow
                  key={hardware.id}
                  hardwareType={hardwareType}
                  hardware={hardware}
                  hasIcd={hasIcd}
                  cardType={cardType}
                  vendors={state.vendors}
                  handleCardTypeChange={setCardType}
                  remove={handleDelete}
                  duplicate={duplicateHardware}
                  userHasLibraryEditAccess={userHasLibraryEditAccess}
                />
              ))}
            </S.HardwareGrid>
          </InfiniteScroll>
        </S.LibraryList>
      )}
      {isListEmpty && <EmptyList height={'calc(100vh - 341px)'} />}
      <ReactModal isOpen={hardwareCreationVisibility} onRequestClose={hideHardwareCreation} style={theme.modal}>
        <HardwareCard cardType={'create'} hardwareType={hardwareType} onClose={handleCreate} />
      </ReactModal>
    </>
  );
};

const HardwareRow: React.FC<HardwareRowProps> = ({
  hardwareType,
  hardware,
  cardType,
  hasIcd,
  vendors,
  userHasLibraryEditAccess,
  handleCardTypeChange,
  remove,
  duplicate,
}) => {
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [hardwareForCard, setHardwareForCard] = useState<HardwareViewDto>();

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const handleCloseCard = useCallback(
    (hardwareUpdate?: HardwareDto, svgFileName?: string) => {
      if (hardwareUpdate) {
        hardware.name = hardwareUpdate.commonFields.name;
        if (hardwareUpdate.commonFields.model) hardware.model = hardwareUpdate.commonFields.model;
        hardware.svgFilename = svgFileName;
        hardware.vendor = vendors.filter((v) => v.id === hardwareUpdate.commonFields.vendorId)[0];
      }
      setHardwareForCard(undefined);
    },
    [hardware, vendors]
  );

  const handleOpenClick = useCallback(
    (hardwareId: number, type: CardType) => {
      Api.getOneHardware(hardwareId).then((response) => {
        setHardwareForCard(response.data);
        handleCardTypeChange(type);
      });
    },
    [handleCardTypeChange]
  );

  const handleConfirmDelete = useCallback(() => {
    remove(hardware.id);
    hideConfirmationModal();
  }, [hardware.id, hideConfirmationModal, remove]);

  return (
    <>
      <S.ClickedRow key={hardware.id} onClick={() => handleOpenClick(hardware.id, 'view')}>
        <ListWithoutMargin.Cell>{hardware.vendor.name}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{hardware.name}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{hardware.model}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{formatMomentShort(hardware.editedAt)}</ListWithoutMargin.Cell>
        <ListWithoutMargin.CellWithLongContent title={hardware.editor.name}>
          {hardware.editor.name}
        </ListWithoutMargin.CellWithLongContent>
        {hasIcd && <ListWithoutMargin.Cell>{hardware.icdFilename}</ListWithoutMargin.Cell>}
        <ListWithoutMargin.Cell>{hardware.svgFilename}</ListWithoutMargin.Cell>
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={() => duplicate(hardware.id)}>
              <Icon name={'copy'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={() => handleOpenClick(hardware.id, 'edit')}>
              <Icon name={'edit'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={showConfirmationModal}>
              <Icon name={'trash'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
      </S.ClickedRow>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Remove hardware'}
          text={`Are you sure you want to remove ${hardware.name}?`}
          onClose={hideConfirmationModal}
          onConfirm={handleConfirmDelete}
        />
      </ReactModal>
      <ReactModal isOpen={hardwareForCard !== undefined} onRequestClose={() => handleCloseCard()} style={theme.modal}>
        <HardwareCard
          cardType={cardType}
          hardwareType={hardwareType}
          handleCardTypeChange={handleCardTypeChange}
          onClose={handleCloseCard}
          hardware={hardwareForCard}
        />
      </ReactModal>
    </>
  );
};
