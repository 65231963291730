import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import ReactModal from 'react-modal';
import { ToolbarItem } from './Toolbar';
import { ExportFormatType, ExportFormDto, ExportFormType, StageDto } from '../../api/nggrace-back';
import { theme } from '../../theme';
import { ExportFormModal } from '../pages/export/ExportFormModal';
import { exportStage } from '../editor/export/Export';
import { EngineContext } from '../editor/EngineContext';
import { useDirectory } from '../hooks/useDirectory';

export const ExportToolbarItem: React.FC<{ stage: StageDto }> = ({ stage }) => {
  const engine = useContext(EngineContext);
  const [visible, setVisible] = useState<boolean>(false);
  const { networkDeviceDirectory, voltageLevelDirectory, getNodeDirectory } = useDirectory();
  const nodeDirectory = getNodeDirectory(stage.project.styleSelection);

  const handleExportOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleExportClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleExport = useCallback(
    async (dto: ExportFormDto, format: ExportFormatType) => {
      return exportStage(nodeDirectory, voltageLevelDirectory, networkDeviceDirectory, engine.getModel(), stage, dto, format);
    },
    [engine, networkDeviceDirectory, nodeDirectory, stage]
  );

  return (
    <ToolbarItem icon={'share'} hint={'Export'} onClick={handleExportOpen}>
      <ReactModal isOpen={visible} onRequestClose={handleExportClose} style={theme.modal}>
        <ExportFormModal
          onClose={handleExportClose}
          onExport={handleExport}
          project={stage.project}
          type={stage.baseInfo.type as ExportFormType}
        />
      </ReactModal>
    </ToolbarItem>
  );
};
