import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { Point } from '@projectstorm/geometry';
import { AddedPoint, BasePoint } from '../../../geometry/Point';
import { LanNodeBorderWidth } from '../switch/SwitchNodeWidget';
import { DeserializeEvent, Toolkit } from '@projectstorm/react-canvas-core';
import { PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { LanLinkModel } from '../../link/LanLinkModel';
import { LinkModel, PortModel } from '@projectstorm/react-diagrams';
import { LanNodeDirectoryEntry, LanNodePort, PropertiesDirectory } from '../../../directory/PropertiesDirectory';

export const LanPortSize = 10;
export const LanHorizontalPortsYPosition = 10;

export enum LanPortHighlight {
  ALLOW,
  FORBID,
  NONE,
}

export interface LanPortModelProps {
  directory?: PropertiesDirectory<LanNodeDirectoryEntry>;
  directoryEntry?: LanNodeDirectoryEntry;
  label?: string;
}

export class LanPortModel extends ConnectablePortModel {
  private label: string;
  private highlight: LanPortHighlight = LanPortHighlight.NONE;
  private directory?: PropertiesDirectory<LanNodeDirectoryEntry>;
  private directoryEntry?: LanNodeDirectoryEntry;
  private portDirectoryEntry?: LanNodePort;

  constructor(type: string = 'lan', { directory, directoryEntry, label }: LanPortModelProps) {
    super({
      type: type,
      name: Toolkit.UID(),
    });
    this.directory = directory;
    this.label = label || '';
    this.directoryEntry = directoryEntry;
    this.portDirectoryEntry = this.directoryEntry?.ports.find((port) => port.name === this.label);
  }

  getSize(): number {
    return LanPortSize;
  }

  getRelativePosition() {
    const node = this.getParent();
    const index = Object.values(node.getPorts()).indexOf(this);
    const nodeSize = node.getSize();

    const portWidthWithMargin = LanPortSize * 2;
    return new BasePoint(LanPortSize + portWidthWithMargin * index, nodeSize.y - LanNodeBorderWidth);
  }

  getPosition(): Point {
    const relativeToNodePosition = this.getRelativePosition();
    const nodePosition = this.getParent().getPosition();
    return new AddedPoint(nodePosition, relativeToNodePosition);
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.label = event.data.label;
    this.directoryEntry = this.directory?.getEntry(event.data.directoryId);
    this.portDirectoryEntry = this.directoryEntry?.ports.find((port) => port.name === this.label);
  }

  serialize() {
    return { ...super.serialize(), label: this.label, directoryId: this.directoryEntry!.id };
  }

  getAlignment(): PortModelAlignment {
    return PortModelAlignment.BOTTOM;
  }

  getConnectorLength(): number {
    const link = Object.values(this.getLinks())[0];
    return link && link instanceof LanLinkModel ? link.getConnectorLength() : 40;
  }

  getLanType() {
    return this.portDirectoryEntry?.type || '';
  }

  getPortColor() {
    return this.portDirectoryEntry?.color || getColorByCableType(this.label);
  }

  canLinkToPort(port: PortModel): boolean {
    if (!(port instanceof LanPortModel) || !super.canLinkToPort(port)) {
      return false;
    }

    return port.getLanType() === this.getLanType();
  }

  setHighlight(type: LanPortHighlight) {
    this.highlight = type;
    this.fireEvent({}, 'highlightChanged');
  }

  getHighLight() {
    return this.highlight;
  }

  getLabelOffset() {
    return new Point(-4, -3);
  }

  addLink(link: LinkModel) {
    super.addLink(link);
    this.fireEvent({}, 'linksChanged');
  }

  removeLink(link: LinkModel) {
    super.removeLink(link);
    this.fireEvent({}, 'linksChanged');
  }

  getLanLink() {
    return Object.values(this.links)[0];
  }
}

const getColorByCableType = (cableType?: string) => {
  if (cableType === 'rj45Gbit' || cableType === 'rj45Mbit') {
    return '#04D305';
  }

  if (cableType === 'sfpGbit' || cableType === 'sfpMbit') {
    return '#5CA1B8';
  }

  return 'black';
};
