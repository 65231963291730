import { SsdModel } from '../ssd/SsdModel';
import { DiagramModelGenerics } from '@projectstorm/react-diagrams';
import { NgGraceModel } from '../NgGraceModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { ControllerLayerModel } from './layer/controller/ControllerLayerModel';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { NodeWithPlaceholders } from '../placeholder/NodeWithPlaceholders';
import { ControllerPlaceholder } from './controller/ControllerPlaceholder';
import { ControllerModel } from './controller/ControllerModel';
import { Listenable } from '../placeholder/Listenable';
import { HasSizedChildrenListener } from '../placeholder/HasSize';

export type ScdNodeModel = RotatableNodeModel &
  NodeWithPlaceholders<ControllerPlaceholder> &
  Listenable<HasSizedChildrenListener>;

export class ScdModel extends SsdModel {
  private controllerLayer: ControllerLayerModel;

  constructor(options?: DiagramModelGenerics['OPTIONS']) {
    super(options);
    this.controllerLayer = new ControllerLayerModel();
    this.addLayer(this.controllerLayer);
    this.lockStaticLayers();
  }

  getControllerLayer(): ControllerLayerModel {
    return this.controllerLayer;
  }

  generateModel(): NgGraceModel {
    return new ScdModel();
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    const deserializedLayer = this.getLayers().find((layer) => layer instanceof ControllerLayerModel);

    if (deserializedLayer) {
      this.controllerLayer = deserializedLayer as ControllerLayerModel;
    } else {
      this.controllerLayer = new ControllerLayerModel();
      this.addLayer(this.controllerLayer);
    }
    this.lockStaticLayers();
  }

  lockStaticLayers() {
    super.lockStaticLayers();
    this.getLogicDeviceLayer().setLocked(true);
  }
}

export const isControllerModel = (item: unknown): item is ControllerModel => item instanceof ControllerModel;
