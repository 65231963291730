import React, { useCallback, useState } from 'react';
import { Page } from '../../Page';
import { Overlay } from '../../widgets/Overlay';
import { useParams } from 'react-router-dom';
import { ProjectSidebar } from '../project/ProjectSidebar';
import { MessageModal } from '../../widgets/MessageModal';
import { EngineProvider } from '../../editor/EngineContext';
import { useStage } from '../../hooks/useStage';
import { useDirectory } from '../../hooks/useDirectory';
import { ProjectStyleSelection } from '../../../api/nggrace-back';
import { ScdDiagramEditor } from './ScdDiagramEditor';
import { ScdEngine } from '../../editor/scd/ScdEngine';
import { DiagramModelProvider } from '../../editor/DiagramModelContext';
import { LanSettings } from '../lan/LanSettings';
import { useNextStage } from '../../hooks/useNextStage';
import { NotificationProvider } from '../../context/NotificationContext';

export const ScdStagePage: React.FC = () => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [validation, setValidation] = useState<string>();
  const { projectId } = useParams<{ projectId?: string }>();
  const { stage, updateStage, validateStage, finishStage, rollbackStage, reloadStage } = useStage(projectId!, 'SCD');
  const { voltageLevelDirectory, logicDeviceDirectory, logicNodeDirectory, controllerDirectory, getNodeDirectory } = useDirectory();
  const { nextStage, showNextStageModal, closeNextStageModal } = useNextStage(stage);

  const handleValidateStage = useCallback(async () => {
    setOverlayVisible(true);
    const result = await validateStage().finally(() => setOverlayVisible(false));

    if (!result.success) {
      setValidation(result.message?.en || 'Unknown validation error');
      return false;
    }
    return true;
  }, [validateStage]);

  const handleValidationClose = useCallback(() => {
    setValidation(undefined);
  }, []);

  const handleEngineFactory = useCallback(
    (styleSelection: ProjectStyleSelection) => {
      return new ScdEngine(
        getNodeDirectory(styleSelection),
        voltageLevelDirectory,
        logicDeviceDirectory,
        logicNodeDirectory,
        controllerDirectory
      );
    },
    [controllerDirectory, getNodeDirectory, logicDeviceDirectory, logicNodeDirectory]
  );

  const handleStageInit = useCallback(() => Promise.resolve(), []);

  return (
    <Page hasSidebar loading={!stage}>
      {stage && (
        <>
          <ProjectSidebar project={stage.project} current={stage.baseInfo.type} />
          <NotificationProvider>
            <EngineProvider
              key={stage.baseInfo.id} // important here, we need to re-init engine on new stage
              stage={stage}
              engineFactory={handleEngineFactory}
              onInitStage={handleStageInit}
              onUpdateStage={updateStage}
            >
              <DiagramModelProvider>
                <ScdDiagramEditor
                  stage={stage}
                  onNextStage={nextStage}
                  onValidateStage={handleValidateStage}
                  onFinishStage={finishStage}
                  onRollbackStage={rollbackStage}
                  onReloadStage={reloadStage}
                />
              </DiagramModelProvider>
            </EngineProvider>
          </NotificationProvider>
          {overlayVisible && <Overlay />}
          <MessageModal error header="Validation failed" isOpen={!!validation} onRequestClose={handleValidationClose}>
            {validation}
          </MessageModal>
          {showNextStageModal && <LanSettings stage={stage} onClose={closeNextStageModal} />}
        </>
      )}
    </Page>
  );
};
