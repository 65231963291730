import styled, { css } from 'styled-components';
import { Styled as Tree } from '../../libraries/hardware/card/lncomposition/widget/Tree.styled';
import { Icon } from '../../../widgets/Icon.styled';

const Accordion = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button<{ expanded?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 16px 0 56px;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => (props.expanded ? props.theme.colors.turquoise : props.theme.colors.grey)};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 1px);
    left: 23px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: ${(props) => (props.expanded ? 'none' : `5px solid ${props.theme.colors.grey}`)};
    border-bottom: ${(props) => (props.expanded ? `5px solid ${props.theme.colors.turquoise}` : 'none')};
    transform: translateY(-50%);
  }
`;

const Title = styled.span`
  font-weight: 700;
`;

const Subtitle = styled.span``;

const Content = styled.div`
  width: 100%;
  padding: 0 10px;
  height: 200px;
  overflow: scroll;
`;

const Toggle = styled(Tree.Toggle)`
  ${Icon} {
    width: 12px;
    height: 5px;
    color: ${(props) => props.theme.colors.turquoise};
    transform: ${(p) => (p.open ? '' : 'rotate(180deg)')};
  }
`;

const EmptyToggle = styled(Tree.EmptyToggle)``;

const Header = styled(Tree.Header)`
  border-bottom: none;
  ${({ highlight, theme }) =>
    !highlight &&
    css`
      :hover {
        background-color: transparent;
      }
    `}
`;

const Indent = styled(Tree.Indent)`
  width: 0;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${Title} {
    font-weight: 400;
    margin-right: 10px;
    text-overflow: ellipsis;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SubTree = styled(Tree.SubTree)`
  width: 100%;
  padding-left: 10px;
`;

export const Styled = {
  Accordion,
  Button,
  Title,
  Subtitle,
  Text,
  Content,
  TreeNode: Tree.TreeNode,
  Header,
  TreeToggle: Tree.TreeToggle,
  Toggle,
  EmptyToggle,
  SubTree,
  Indent,
};
