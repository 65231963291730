import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { ConnectivityNodeWidget } from './ConnectivityNodeWidget';
import { ConnectivityNodeModel } from './ConnectivityNodeModel';
import { RawConnectivityNodeModel } from './RawConnectivityNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import {PropertiesDirectory, VoltageLevelDirectoryEntry} from "../directory/PropertiesDirectory";

export class ConnectivityNodeFactory extends AbstractReactFactory<ConnectivityNodeModel, DiagramEngine> {
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;

  constructor(voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>) {
    super('connectivity');
    this.voltageLevelDirectory = voltageLevelDirectory;
  }

  generateReactWidget(event: { model: ConnectivityNodeModel }): JSX.Element {
    const model = event.model;
    return <ConnectivityNodeWidget engine={this.engine} node={model} voltageLevelDirectory={this.voltageLevelDirectory} />;
  }

  generateModel(event: any) {
    return new RawConnectivityNodeModel();
  }
}
