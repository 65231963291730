import React, { PropsWithChildren, useState } from 'react';

export const DroppableContext = React.createContext<{
  enteredId: string | null;
  setEnteredId: (value: string | null) => void;
}>({
  enteredId: null,
  setEnteredId: () => {},
});

interface DroppableProviderProps extends PropsWithChildren<{}> {}

export const DroppableProvider: React.FC<DroppableProviderProps> = ({ children }) => {
  const [enteredId, setEnteredId] = useState<string | null>(null);
  return <DroppableContext.Provider value={{ enteredId, setEnteredId }}>{children}</DroppableContext.Provider>;
};
