import { Path } from '../Path';
import { BasePoint, RestrictedDirectionPoint } from '../../../../geometry/Point';

export class NormalizingAngledPath implements Path {
  private origin: Path;

  constructor(origin: Path) {
    this.origin = origin;
  }

  getPoints(startPoint: RestrictedDirectionPoint, endPoint: RestrictedDirectionPoint): BasePoint[] {
    const start = startPoint.x <= endPoint.x ? startPoint : endPoint;
    const end = startPoint.x <= endPoint.x ? endPoint : startPoint;
    const reversed = startPoint.x > endPoint.x;
    const result = this.origin.getPoints(start, end);
    if (reversed) {
      result.reverse();
    }

    return result;
  }
}
