import React from 'react';
import { StageBaseDto, StageType } from '../../../api/nggrace-back';
import { Styled as S } from './ProjectSidebar.styled';
import { Icon } from '../../widgets/Icon';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { getStageNumber, isStageAvailable } from '../../../utils/project-utils';
import { Indicator } from '../../widgets/Indicator.styled';

interface ProjectSidebarProps {
  project: { id: number; activeStage: StageBaseDto };
  current: StageType;
}

export const ProjectSidebar: React.FC<ProjectSidebarProps> = ({ project, current }) => {
  const { stagePageLink } = useRoutes();
  const activeStage = project.activeStage.type;

  const createStageLink = (target: StageType) => {
    const isActive = project.activeStage.type === target;
    const isAvailable = isStageAvailable(activeStage, target);
    const isCurrent = target === current;
    const isCompleted = isAvailable && (!isActive || project.activeStage.finished);
    const to = stagePageLink(project.id, target);

    return (
      <S.StageLink $active={isCurrent} disabled={!isAvailable} $completed={isCompleted} to={to}>
        <Indicator completed={isCompleted} active={isActive}>
          {isCompleted ? <Icon name={'checkmark'} /> : getStageNumber(target)}
        </Indicator>
        {target}
      </S.StageLink>
    );
  };

  return (
    <S.ProjectSidebar>
      {createStageLink('SLD')}
      {createStageLink('SSD')}
      {createStageLink('SCD')}
      {createStageLink('LAN')}
      {createStageLink('TCO')}
      {createStageLink('EXPORT')}
    </S.ProjectSidebar>
  );
};
