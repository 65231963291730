import React from 'react';
import { Styled as S } from './Libraries.styled';

interface CornerProps {
  position: CornerPosition;
}

export type CornerPosition = 'left' | 'right';

export const Corner: React.FC<CornerProps> = ({ position }) => {
  return (
    <S.Corner position={position}>
      <svg height="56" width="32" viewBox="0 0 32 56">
        <path fill="#FFFFFF" d="M32,0v56H0c8.8,0,16-7.2,16-16V16C16,7.2,23.1,0,32,0L32,0z" />
      </svg>
    </S.Corner>
  );
};
