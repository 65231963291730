import * as React from 'react';
import { BusPortModel } from '../../../bus/BusPortModel';
import { BasePoint } from '../../../geometry/Point';

interface ExportBusPortProps {
  port: BusPortModel;
  size: number;
}

export const ExportBusPort: React.FC<ExportBusPortProps> = ({ port, size }) => {
  const bus = port.getParent();
  const offsetCoord = bus.getOffsetCoordinate();
  const result = new BasePoint(port.getSize() / 2, port.getSize() / 2);
  result[offsetCoord.getName()] += port.getBusOffset();
  return (
    <rect
      x={result.x - size / 2}
      y={result.y - size / 2}
      rx={'50%'}
      ry={'50%'}
      width={size}
      height={size}
      fill={'white'}
    />
  );
};
