import { Graph } from './Graph';
import { Link } from '../Link';
import { Node } from '../node/Node';
import { Rectangle } from '@projectstorm/geometry';

export type CacheEvictHandle = { date: number };

export class CachingGraph implements Graph {
  private origin: Graph;
  private rectEntry: CacheEntry<Rectangle>;
  private nodesEntry: CacheEntry<Node[]>;
  private linksEntry: CacheEntry<Link[]>;

  constructor(handle: CacheEvictHandle, origin: Graph) {
    this.origin = origin;
    this.rectEntry = new CacheEntry<Rectangle>(() => origin.getRect(), handle);
    this.nodesEntry = new CacheEntry<Node[]>(() => origin.getNodes(), handle);
    this.linksEntry = new CacheEntry<Link[]>(() => origin.getLinks(), handle);
  }

  getLinks(): Link[] {
    return this.linksEntry.get();
  }

  getNodes(): Node[] {
    return this.nodesEntry.get();
  }

  getRect(): Rectangle {
    return this.rectEntry.get();
  }
}

class CacheEntry<T> {
  private supplier: () => T;
  private handle: CacheEvictHandle;
  private value?: T;
  private lastDate?: number;

  constructor(supplier: () => T, handle: CacheEvictHandle) {
    this.supplier = supplier;
    this.handle = handle;
  }

  get(): T {
    if (this.value && this.lastDate && this.lastDate > this.handle.date) {
      return this.value;
    }

    this.lastDate = Date.now();
    this.value = this.supplier();
    return this.value;
  }
}
