import { BaseModel } from '@projectstorm/react-canvas-core';
import { ExtendableTreeNode, HasExtendableParent, SelectableTreeNode, TreeNodeModel } from './TreeState';
import { NgGraceEngine } from '../../../editor/NgGraceEngine';

export const SelectableTreeBaseModel = (model: BaseModel, engine: NgGraceEngine): SelectableTreeNode =>
  <SelectableTreeNode>{
    canSelect: () => true,
    isSelected: () => model.isSelected(),
    setSelected: (selected) => {
      engine.getModel().clearSelection();
      model.setSelected(selected);
    },

    onSelectionChanged: (cb: () => void) => model.registerListener({ selectionChanged: cb }).deregister,
  };

export const NavigatingSelectableTreeModel = (
  origin: SelectableTreeNode,
  nodeNavigateToId: string,
  engine: NgGraceEngine
): SelectableTreeNode => {
  return {
    ...origin,
    setSelected: (selected) => {
      origin.setSelected(selected);
      if (selected) {
        engine.highlightNode(nodeNavigateToId);
      }
    },
  };
};

export const NotSelectableModel = (): SelectableTreeNode => ({
  canSelect: () => false,
  isSelected: () => false,
  setSelected: () => {},
  onSelectionChanged: () => () => {},
});

export const NotExtendableModel = () => ({
  canAddChild: () => false,
  addChild: () => {},
});

export const DefaultHasExtendableParentModel = (
  model: TreeNodeModel,
  parent: ExtendableTreeNode
): HasExtendableParent => ({
  canAddNeighbour: (payload) => parent.canAddChild(payload),
  addNeighbour: (payload) => parent.addChild(payload, model),
});

export const HasNotExtendableParentModel = (): HasExtendableParent => ({
  canAddNeighbour: () => false,
  addNeighbour: () => {},
});

export const TreeNodeModelComparator = (a: TreeNodeModel, b: TreeNodeModel) =>
  a.getName().localeCompare(b.getName(), undefined, {
    numeric: true,
    ignorePunctuation: true,
  });
