import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { DefaultHasExtendableParentModel, SelectableTreeBaseModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { LanModel } from '../../../editor/lan/LanModel';
import { RackModel } from '../../../editor/lan/rack/Rack';
import { LanStructureTreeLanNodeProps } from './LanStructureTreeLanNode';
import { Directory } from '../../../editor/directory/Directory';
import {
  NetworkDeviceDirectoryEntry,
  NetworkDeviceTypeDirectoryEntry,
  PropertiesDirectoryName,
  SwitchDirectoryEntry,
} from '../../../editor/directory/PropertiesDirectory';
import { SwitchNodeModel } from '../../../editor/lan/node/switch/SwitchNodeModel';
import { RpaNetworkZoneTypes, ZoneType } from '../../../editor/lan/zone/ZoneModel';
import { LanNodeModel } from '../../../editor/lan/node/LanNodeModel';
import { LanEngine } from '../../../editor/lan/LanEngine';
import { DefaultHasName } from '../../../editor/lan/building/HasName';
import { ZoneLayerModel } from '../../../editor/lan/zone/layer/ZoneLayerModel';
import { NodeModel } from '@projectstorm/react-diagrams';
import { NetworkNodeModel } from '../../../editor/lan/node/network/NetworkNodeModel';
import { LanControllerModel } from '../../../editor/lan/node/controller/LanControllerModel';

export const LanStructureTreeRackFactory = (
  engine: LanEngine,
  lanModel: LanModel,
  switchDirectory: Directory<SwitchDirectoryEntry>,
  networkDeviceTypeDirectory: Directory<NetworkDeviceTypeDirectoryEntry>,
  networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>,
  lanNodeFactory: Factory<LanStructureTreeLanNodeProps, TreeNodeState>,
  repaintCanvas: () => void
): Factory<LanStructureTreeRackProps, TreeNodeState> => ({ model, parent }) =>
  LanStructureTreeRack(
    engine,
    parent,
    model,
    lanModel,
    switchDirectory,
    networkDeviceTypeDirectory,
    networkDeviceDirectory,
    lanNodeFactory,
    repaintCanvas
  );

export interface LanStructureTreeRackProps {
  model: RackModel;
  parent: ExtendableTreeNode;
}

export type LanStructureTreeRackModelFactoryType = Factory<
  { composite: boolean; zoneId: string; name: string; zoneLayer: ZoneLayerModel },
  RackModel
>;
export const LanStructureTreeRackModelFactory = (engine: LanEngine): LanStructureTreeRackModelFactoryType => ({
  composite,
  zoneId,
  name,
  zoneLayer,
}) => {
  if (composite) {
    return engine.getCompositeRackFactory().createNewLanModel(name, zoneLayer);
  } else {
    const zone = zoneLayer.getModel(zoneId);
    return engine.getSingleRackFactory().createNewModel(new DefaultHasName(name), zone);
  }
};

export const LanStructureTreeRack = (
  engine: LanEngine,
  parent: ExtendableTreeNode,
  rack: RackModel,
  lanModel: LanModel,
  switchDirectory: Directory<SwitchDirectoryEntry>,
  networkDeviceTypeDirectory: Directory<NetworkDeviceTypeDirectoryEntry>,
  networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>,
  lanNodeFactory: Factory<LanStructureTreeLanNodeProps, TreeNodeState>,
  repaintCanvas: () => void
): TreeNodeState => {
  const extendable = ExtendableLanStructureTreeRackNode(
    rack,
    lanModel,
    switchDirectory,
    networkDeviceTypeDirectory,
    networkDeviceDirectory,
    repaintCanvas
  );
  const model = LanStructureTreeRackModel(
    extendable,
    rack,
    (rackModel: RackModel) =>
      LanStructureTreeRack(
        engine,
        extendable,
        rackModel,
        lanModel,
        switchDirectory,
        networkDeviceTypeDirectory,
        networkDeviceDirectory,
        lanNodeFactory,
        repaintCanvas
      ),
    lanNodeFactory
  );

  return {
    ...model,
    ...extendable,
    ...DefaultHasExtendableParentModel(model, parent),
    ...SelectableTreeBaseModel(rack, engine),
  };
};

const LanStructureTreeRackModel = (
  parent: ExtendableTreeNode,
  rack: RackModel,
  rackMapper: (rack: RackModel) => TreeNodeState,
  lanNodeFactory: Factory<LanStructureTreeLanNodeProps, TreeNodeState>
): TreeNodeModel => {
  return {
    getName: () => RackNameBuilder(rack),
    getKey: () => rack.getID(),
    onChildrenChanged: (callback) => {
      return rack.registerListener({
        childrenChanged: (event: { child: LanNodeModel; created: boolean }) =>
          callback(lanNodeFactory({ model: event.child, parent }), event.created),
      } as any).deregister;
    },
    getChildren: () => {
      if (rack.asSingle()) {
        return rack
          .asSingle()!
          .getChildren()
          .map((model) => lanNodeFactory({ model, parent }));
      } else {
        return Object.values(rack.asComposite()!.getRacks()).map(rackMapper);
      }
    },
  };
};

const RackNameBuilder = (model: RackModel): string => {
  if (model.asSingle()) {
    const zoneType = model.asSingle()!.getZoneType();
    if (zoneType && RpaNetworkZoneTypes.includes(zoneType)) {
      switch (zoneType) {
        case 'RPA_PRIMARY_NETWORK_STATION':
          return 'Primary Station Network';
        case 'RPA_PRIMARY_NETWORK_PROCESS':
          return 'Primary Process Network';
        case 'RPA_BACKUP_NETWORK_STATION':
          return 'Backup Station Network';
        case 'RPA_BACKUP_NETWORK_PROCESS':
          return 'Backup Process Network';
      }
    }
  }
  return model.getName();
};

const ExtendableLanStructureTreeRackNode = (
  rack: RackModel,
  lanModel: LanModel,
  switchDirectory: Directory<SwitchDirectoryEntry>,
  networkDeviceTypeDirectory: Directory<NetworkDeviceTypeDirectoryEntry>,
  networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>,
  repaintCanvas: () => void
): ExtendableTreeNode => ({
  canAddChild: (childPayload) => {
    if (childPayload.type === TreeDraggableType.LanNode && !!rack.asSingle()) {
      if (isNetworkRack()) {
        if (isNetworkDevice()) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;

    function isNetworkRack() {
      const zoneType = rack.asSingle()?.getZoneType()!;
      return RpaNetworkZoneTypes.includes(zoneType) || zoneType === ZoneType.LOW_NETWORK;
    }

    function isNetworkDevice() {
      if (!childPayload.modelId) {
        return isAllowedDirectoryEntry(childPayload.directory, childPayload.directoryId);
      } else {
        const model = Object.values(lanModel.getActiveNodeLayer().getNodes())
          .filter(canShowNodeInTree)
          .find((lanNode) => lanNode.getID() === childPayload.modelId);
        return model && isAllowedDirectoryEntry(model.getDirectory(), model.getDirectoryEntryId());
      }
    }

    function isAllowedDirectoryEntry(directory?: PropertiesDirectoryName, directoryId?: string) {
      if (directory === 'SwitchDirectory') {
        return true;
      }
      if (directory === 'NetworkDeviceDirectory') {
        const entry = networkDeviceDirectory.getEntry(directoryId!);
        const typeEntry = networkDeviceTypeDirectory.getEntry(entry.deviceType);
        return typeEntry.allowedInNetworkRack;
      }
      return false;
    }
  },
  addChild: (childPayload: TreeDraggablePayload, childToAddAfter?: TreeNodeModel) => {
    const exists = childPayload.modelId;
    const lanNode = !exists
      ? createLanNode()
      : Object.values(lanModel.getActiveNodeLayer().getNodes())
          .filter(canShowNodeInTree)
          .find((lanNode) => lanNode.getID() === childPayload.modelId)!;

    // remove from layer but preserve links
    exists && lanNode.notCascadeRemove();

    const indexToAdd = childToAddAfter
      ? rack
          .asSingle()!
          .getChildren()
          .findIndex((child) => child.getID() === childToAddAfter.getKey()) + 1
      : 0;

    //we have to add node through lanModel(not nodeLayer strictly) because model has to fire 'nodesUpdated' event
    lanModel.addNode(lanNode);

    rack.asSingle()!.addChild(lanNode, indexToAdd);

    function createLanNode() {
      switch (childPayload.directory) {
        case 'SwitchDirectory':
          return new SwitchNodeModel(switchDirectory.getEntry(childPayload.directoryId!));
        case 'NetworkDeviceDirectory':
          const entry = networkDeviceDirectory.getEntry(childPayload.directoryId!);
          return new NetworkNodeModel(networkDeviceTypeDirectory.getEntry(entry.deviceType), entry);
        default:
          throw new Error(`Unsupported directory: ${childPayload.directory}`);
      }
    }
  },
});

const canShowNodeInTree = (node: NodeModel): node is LanNodeModel => {
  return node instanceof NetworkNodeModel || node instanceof SwitchNodeModel || node instanceof LanControllerModel;
};
