import React from 'react';
import { Styled as S } from './SvgFileField.styled';
import { CardType } from '../../HardwareCard';
import { InputField } from '../InputField';
import { SvgContainer } from './SvgContainer';
import { OptionType, SvgInputSelect } from './SvgInputSelect';
import { SvgDto } from '../../../../../../../api/nggrace-back';

interface SvgFileFieldProps {
  cardType: CardType;
  svg?: SvgDto;
  setSvg: (svgDto: SvgDto) => void;
  options: OptionType[];
  onSvgChange: (svg: OptionType) => void;
  onSvgFileUpload: (file: File) => void;
  scrollToBottom: () => void;
  onSvgDelete: (svgId: number) => void;
}

export const SvgFileField: React.FC<SvgFileFieldProps> = ({
  cardType,
  svg,
  setSvg,
  options,
  onSvgChange,
  onSvgFileUpload,
  scrollToBottom,
  onSvgDelete,
}) => {
  const handleSvgChange = (svg: OptionType) => {
    setSvg({
      id: svg.id,
      name: svg.name,
      content: svg.content,
      basic: svg.basic,
    });
    onSvgChange(svg);
  };

  return (
    <>
      {cardType === 'view' && (
        <S.InputAndSvgImg>
          <InputField onChange={() => {}} cardType={cardType} label={'SVG file'} value={svg?.name} />
          <SvgContainer cardType={cardType} image={svg?.content} />
        </S.InputAndSvgImg>
      )}
      {cardType !== 'view' && (
        <S.SvgFileSelect>
          <p>SVG file</p>
          <h1>Select the suggested file or attach your own (inside the drop-down list)</h1>
          <S.Form cardType={cardType}>
            <SvgInputSelect
              disabled={false}
              label={'SVG file'}
              selected={svg?.name}
              options={options}
              onChange={handleSvgChange}
              onSvgFileUpload={onSvgFileUpload}
              scrollToBottom={scrollToBottom}
              onSvgDelete={onSvgDelete}
            />
          </S.Form>
          <SvgContainer cardType={cardType} image={svg?.content} />
        </S.SvgFileSelect>
      )}
    </>
  );
};
