import { TreeNodeState } from '../../../widgets/tree/state/TreeState';
import { VoltageLevelDirectoryEntry } from '../../../editor/directory/PropertiesDirectory';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { SsdStructureTreeNodeModelType } from './SsdStructureTreeNode';
import { Factory } from '../../../../utils/factory';

export const SsdStructureTreeVoltageLevelFactory = (
  treeSsdNodeFactory: Factory<SsdStructureTreeNodeModelType, TreeNodeState>
): Factory<SsdStructureTreeVoltageLevelProps, TreeNodeState> => ({ voltageLevel, nodes }) =>
  SsdStructureTreeVoltageLevel(voltageLevel, nodes, treeSsdNodeFactory);

const SsdStructureTreeVoltageLevel = (
  voltageLevel: VoltageLevelDirectoryEntry,
  nodes: SsdStructureTreeNodeModelType[],
  treeSsdNodeFactory: Factory<SsdStructureTreeNodeModelType, TreeNodeState>
): TreeNodeState => {
  return {
    ...SsdStructureTreeVoltageLevelModel(voltageLevel, nodes, treeSsdNodeFactory),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
    ...NotSelectableModel(),
  };
};

const SsdStructureTreeVoltageLevelModel = (
  voltageLevel: VoltageLevelDirectoryEntry,
  nodes: SsdStructureTreeNodeModelType[],
  treeSsdNodeFactory: Factory<SsdStructureTreeNodeModelType, TreeNodeState>
) => ({
  getName: () => voltageLevel.name.en,
  getKey: () => voltageLevel.id,
  onChildrenChanged: () => () => {},
  getChildren: () => nodes.map(treeSsdNodeFactory),
});

export interface SsdStructureTreeVoltageLevelProps {
  voltageLevel: VoltageLevelDirectoryEntry;
  nodes: SsdStructureTreeNodeModelType[];
}
