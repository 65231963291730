import React, { useCallback, useContext, useEffect } from 'react';
import { EngineContext } from './EngineContext';

export const CopyPasteEditor: React.FC = ({ children }) => {
  const engine = useContext(EngineContext);
  const copyCallback = useCallback(
    async (event: Event) => {
      if (isEngineCopyEventTarget(event.target!)) {
        event.preventDefault();
        await engine.copy();
      }
    },
    [engine]
  );

  const pasteCallback = useCallback(
    ({ clipboardData, target }: ClipboardEvent) => {
      if (engine.getModel().isLocked()) {
        return;
      }

      if (clipboardData && isEnginePasteEventTarget(target)) {
        engine
          .paste(clipboardData.getData('text/plain'))
          .catch(() => alert('Paste nodes has failed. Clipboard value is invalid.'));
      }
    },
    [engine]
  );

  useEffect(() => {
    document.addEventListener('copy', copyCallback);
    document.addEventListener('paste', pasteCallback);
    return () => {
      document.removeEventListener('copy', copyCallback);
      document.removeEventListener('paste', pasteCallback);
    };
  }, [copyCallback, pasteCallback]);

  return <>{children}</>;
};

const isEngineCopyEventTarget = (target: EventTarget | null) =>
  !target || (!(target instanceof HTMLLabelElement) && !(target instanceof HTMLInputElement));

const isEnginePasteEventTarget = (target: EventTarget | null) => !target || !(target instanceof HTMLInputElement);
