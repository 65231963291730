import styled, { css } from 'styled-components';
import { Styled as StyledInputField } from '../../../../../../widgets/InputField.styled';
import { BtnIcon as ButtonIcon } from '../../../../../../widgets/Btn.styled';
import { Btn as Button } from '../../../../../../widgets/Btn.styled';
import { Icon } from '../../../../../../widgets/Icon.styled';

const InputFilter = styled.input`
  margin: 9px 12px 9px 16px;
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  color: ${(props) => props.theme.colors.darkgrey};
  border-radius: 4px;
  width: calc(100% - 28px);
  height: 30px;
  font-size: 14px;
  padding-left: 9px;
`;

const InputOptions = styled.div`
  display: none;
  position: absolute;
  z-index: 1001;
  top: calc(100% - 5px);
  left: 0;
  right: 0;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-top: 0;
  border-radius: 0 0 2px 2px;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  max-height: 316px;
  overflow-y: auto;
`;

const Label = styled(Button)<{ basic: boolean }>`
  border: 2px solid ${(props) => (props.basic ? props.theme.colors.lightgreen : props.theme.colors.lightblue)};
  border-radius: 100px;
  color: ${(props) => (props.basic ? props.theme.colors.lightgreen : props.theme.colors.lightblue)};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  background: none;
  height: 24px;

  &:hover {
    background: none;
    color: ${(props) => (props.basic ? props.theme.colors.lightgreen : props.theme.colors.lightblue)};
  }
`;

const Dialogue = styled.div`
  & span:first-of-type {
    margin-left: 23px;
  }

  & span:first-of-type + span {
    margin-left: 16px;
  }

  & span {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const InputOption = styled.div<{ current?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  padding: 12px 16px;
  height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    color: ${(props) => props.theme.colors.lightgrey};
    background-color: ${(props) => props.theme.colors.blue};
  }

  ${(props) =>
    props.current &&
    css`
      color: ${(props) => props.theme.colors.lightgrey};
      background-color: ${(props) => props.theme.colors.blue};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default !important;
      pointer-events: none !important;
      user-select: none !important;
      opacity: 0.3 !important;
      box-shadow: none !important;
    `}
    
  ${Label} {
    margin-left 16px;
  }
  
  ${Icon} {
    position: absolute;
    margin-left: 470px;
    width: 20px;
    height: 20px;
  }
  
  ${Dialogue} {
    position: absolute;
    margin-left: 361px;
  }
  
  & img {
    padding: 4.76px;
    width: 28px;
    height: 28px;
    border: 2px solid ${(props) => props.theme.colors.sky};
    border-radius: 10%;
    margin-top: -2px;
    background: ${(props) => props.theme.colors.white};
  }
  
  & img + span {
    max-width: 200px;
    margin-left: 12px;
  }
  
`;

const UploadFileSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 12px 16px;
  height: 76px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => props.theme.colors.lightLightgrey};

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  color: ${(props) => props.theme.colors.black};

  input {
    display: none;
  }
`;

const InputSelected = styled(StyledInputField.InputContainer)<{ icon?: boolean }>`
  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 1px);
    right: 14px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5px solid ${(props) => props.theme.colors.blue};
    border-bottom: none;
    transform: translateY(-50%);
    margin-left: 14px;
  }

  ${(props) =>
    props.icon &&
    css`
      ${Icon} {
        position: absolute;
        right: 8px;
        color: ${(props) => props.theme.colors.darkgrey};
        margin-left: 14px;
      }

      &:after {
        content: none;
      }
    `}
`;

const InputSelectedValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

const InputSelect = styled(StyledInputField.InputField)<{ empty?: boolean; active?: boolean; hideBorder?: boolean }>`
  ${(props) =>
    props.active &&
    css`
      ${InputSelected} {
        border: 1px solid ${(props) => props.theme.colors.blue};

        ${Icon} {
          color: ${(props) => props.theme.colors.blue};
        }

        &:after {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: none !important;
          border-bottom: 5px solid ${(props) => props.theme.colors.blue};
        }
      }

      ${InputOptions} {
        display: block;
      }
    `}

  ${(props) =>
    props.active &&
    !props.empty &&
    css`
      ${StyledInputField.InputLabel} {
        color: ${(props) => props.theme.colors.blue};
      }
    `}

  ${(props) =>
    !props.empty &&
    css`
      ${InputSelected} ${Icon} {
        color: ${(props) => props.theme.colors.blue};
      }
    `}

  ${(props) =>
    !props.active &&
    props.hideBorder &&
    css`
      ${InputSelected} {
        border: 1px solid transparent;
      }
    `}
`;

export const Styled = {
  InputSelect,
  InputSelected,
  InputSelectedValue,
  InputOptions,
  InputOption,
  InputLabel: StyledInputField.InputLabel,
  InputFilter,
  Btn: ButtonIcon,
  UploadFileSection,
  Label,
  Dialogue,
};
