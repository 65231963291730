import { DefaultDisposed, DefaultGraph, Disposed, EnvironmentalLinkSet, Graph } from './Graph';
import { Node } from '../node/Node';
import createGraph from 'ngraph.graph';
import path from 'ngraph.path';
import { Link } from '../Link';
import { Rectangle } from '@projectstorm/geometry';
import { SimpleNodesSubGraph } from './SimpleNodesSubGraph';
import { CompositeGraph } from './CompositeGraph';
import { PathMapping } from '../mapping/Mapping';
import { Factory } from '../../../../utils/factory';

export const PathGraphFactory: Factory<Graph, PathMapping> = (environment: Graph) => {
  const graph = createGraph<Node, Link>({ multigraph: true });
  environment.getNodes().forEach((node) => graph.addNode(node.getID(), node));
  environment
    .getLinks()
    .forEach((link) => graph.addLink(link.getSourceNode().getID(), link.getTargetNode().getID(), link));
  const paths = path.aGreedy(graph, { oriented: false });
  return { map: ({ start, end }) => new DefaultPathGraph(start, end, paths) };
};

export class DefaultPathGraph implements Graph {
  private readonly start: Node;
  private readonly end: Node;
  private readonly disposed: Disposed;
  private path: path.PathFinder<Node>;

  constructor(start: Node, end: Node, path: path.PathFinder<Node>) {
    this.start = start;
    this.end = end;
    this.path = path;

    this.disposed = new DefaultDisposed(this);
  }

  getNodes(): Node[] {
    const result = this.path.find(this.start.getID(), this.end.getID()).map((n) => n.data);
    if (!result.length) {
      return result;
    }

    if (result[0].getID() !== this.start.getID()) {
      return result.reverse();
    } else {
      return result;
    }
  }

  getLinks(): Link[] {
    throw new Error('path graph getLinks not implemented');
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}

export class EnvironmentalPathGraph implements Graph {
  private readonly start: Node;
  private readonly end: Node;
  private readonly environment: Graph;
  private readonly disposed: Disposed;

  constructor(start: Node, end: Node, environment: Graph) {
    this.environment = environment;
    this.start = start;
    this.end = end;

    this.disposed = new DefaultDisposed(this);
  }

  getNodes(): Node[] {
    const graph = createGraph<Node, Link>({ multigraph: true });
    this.environment.getNodes().forEach((node) => graph.addNode(node.getID(), node));
    this.environment
      .getLinks()
      .forEach((link) => graph.addLink(link.getSourceNode().getID(), link.getTargetNode().getID(), link));
    const g = path.aGreedy(graph, { oriented: false });
    const result = g.find(this.start.getID(), this.end.getID()).map((n) => n.data);

    if (!result.length) {
      return result;
    }

    if (result[0].getID() !== this.start.getID()) {
      return result.reverse();
    } else {
      return result;
    }
  }

  getLinks(): Link[] {
    throw new Error('path graph getLinks not implemented');
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}

export class SimpleNodesPathGraph implements Graph {
  private readonly path: Graph;
  private readonly disposed: Disposed;
  private readonly linkSet: EnvironmentalLinkSet;
  private optimizedPath: Graph;

  constructor(start: Node, end: Node, environment: Graph, optimizedPathFactory: PathMapping) {
    this.optimizedPath = optimizedPathFactory.map({ start, end });
    this.disposed = new DefaultDisposed(this);
    this.linkSet = new EnvironmentalLinkSet(this, environment);
    const simpleEnvironment = new CompositeGraph(
      [new SimpleNodesSubGraph(environment), new DefaultGraph(start), new DefaultGraph(end)],
      environment
    );
    this.path = new EnvironmentalPathGraph(start, end, simpleEnvironment);
  }

  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    const optimizedResult = this.optimizedPath.getNodes();
    const intermediateNodes = optimizedResult.slice(1, -1);
    const simpleIntermediateNodes = new SimpleNodesSubGraph(new DefaultGraph(intermediateNodes, [])).getNodes();
    return intermediateNodes.length !== simpleIntermediateNodes.length ? [] : optimizedResult;
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}
