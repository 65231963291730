import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../../../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../../../../../utils/factory';
import { LnTemplateDto } from '../../../../../../../../api/nggrace-back';
import { stringValueFormatter } from '../../../../../hardware/card/lncomposition/LnComposition';
import { LnCompositionTreeDataObjectProps } from '../../../../../hardware/card/lncomposition/LnCompositionTreeDataObject';
import { LogicalNodeTreeModel } from './LnComposition';

export const LnCompositionTree = (
  lnTemplate: LnTemplateDto,
  instance: number,
  logicalNodeTypeId: number,
  lnCompositionTreeDataObjectFactory: Factory<LnCompositionTreeDataObjectProps, LogicalNodeTreeModel>
): LogicalNodeTreeModel => {
  return {
    ...LnCompositionTreeLnTemplateModel(lnTemplate, instance, logicalNodeTypeId, lnCompositionTreeDataObjectFactory),
    ...HasNotExtendableParentModel(),
    ...NotExtendableModel(),
    ...NotSelectableModel(),
  };
};

const LnCompositionTreeLnTemplateModel = (
  lnTemplate: LnTemplateDto,
  instance: number,
  logicalNodeTypeId: number,
  lnCompositionTreeDataObjectFactory: Factory<LnCompositionTreeDataObjectProps, LogicalNodeTreeModel>
) => ({
  getName: () => `${lnTemplate.type} ${instance}`,
  getKey: () => lnTemplate.type + instance,
  onChildrenChanged: () => () => {},
  getChildren: () => {
    return lnTemplate.dataObjects.map((dataObject) => lnCompositionTreeDataObjectFactory({ dataObject }));
  },

  getClazz: () => lnTemplate.clazz,
  getCommonDataClass: () => '',
  getDescription: () => stringValueFormatter(lnTemplate.desc),
  getType: () => lnTemplate.type,
  getBType: () => '',
  getFc: () => '',
  getInstance: () => instance,
  getLogicalNodeTypeId: () => logicalNodeTypeId,
});
