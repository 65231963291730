import styled from 'styled-components';

const Ieds = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 41px;
  width: auto;

  line-height: 110%;
  font-size: 14px;

  & span {
    font-weight: 700;
    margin-right: 23px;
    padding: 3px 0px;
  }
`;

const Ied = styled.div`
  margin-right: 8px;
  height: 21px;
  padding: 3px 10px;
  font-weight: 400;
  background: #f4f4f4;
  border-radius: 16px;
`;

export const Styled = {
  Ieds,
  Ied,
};
