import { SmartDirection } from './Direction';

export type Coord = 'x' | 'y';

export interface Coordinate {
  getName(): Coord;
}

export class DefaultCoordinate implements Coordinate {
  private readonly coord: Coord;

  constructor(coord: Coord) {
    this.coord = coord;
  }

  getName(): Coord {
    return this.coord;
  }
}

export class DirectionCoordinate implements Coordinate {
  private direction: SmartDirection;

  constructor(direction: SmartDirection) {
    this.direction = direction;
  }

  getName(): Coord {
    return this.direction.isHorizontal() ? 'x' : 'y';
  }
}

export class OppositeCoordinate implements Coordinate {
  private origin: Coordinate;

  constructor(origin: Coordinate) {
    this.origin = origin;
  }

  getName(): Coord {
    return this.origin.getName() === 'x' ? 'y' : 'x';
  }
}
