import React from 'react';
import { Styled as S } from './Overlay.styled';
import { Icon } from './Icon';

export const Overlay: React.FC = () => {
  return (
    <S.Overlay>
      <S.Loading>
        <Icon name={'loading'} />
      </S.Loading>
      PLEASE WAIT...
    </S.Overlay>
  );
};
