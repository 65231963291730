import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { StageType, UserDto } from '../../api/nggrace-back';
import { convertStageTypeToPath } from '../../utils/route-utils';

const projectsPath = '/projects';
const sldStagePath = '/project/:projectId/sld';
const ssdStagePath = '/project/:projectId/ssd';
const scdStagePath = '/project/:projectId/scd';
const lanStagePath = '/project/:projectId/lan';
const tcoPath = '/project/:projectId/tco';
const exportPath = '/project/:projectId/export';
const companiesPath = '/companies';
const librariesPath = '/libraries';
const usersPath = '/users';
const resetPasswordPath = '/reset_password/:passwordRecoveryToken';
const passwordRecoveryPath = '/password_recovery';
const landingPath = '/landing';
const loginPath = '/login';
const registerCompanyPath = '/register_company';
const registerUserPath = '/register_user/:userInviteToken';
const logoutPath = '/logout';

const stagePageLink = (projectId: number, stageType: StageType) =>
  `/project/${projectId}/${convertStageTypeToPath(stageType)}`;

export const useRoutes = () => {
  const history = useHistory();
  const location = useLocation();

  const getDefaultPath = useCallback((user?: UserDto) => {
    if (user) {
      return projectsPath;
    }
    return loginPath;
  }, []);

  const redirectToProjectsPage = useCallback(() => {
    history.push(projectsPath);
  }, [history]);

  const redirectToLogoutPage = useCallback(() => {
    history.push(logoutPath);
  }, [history]);

  const redirectToStagePage = useCallback(
    (projectId: number, stageType: StageType) => {
      history.push(stagePageLink(projectId, stageType));
    },
    [history]
  );

  const redirectToCompaniesPage = useCallback(() => {
    history.push(companiesPath);
  }, [history]);

  const redirectToLibrariesPage = useCallback(() => {
    history.push(librariesPath);
  }, [history]);

  const redirectToUsersPage = useCallback(() => {
    history.push(usersPath);
  }, [history]);

  const redirectToLandingPage = useCallback(() => {
    history.push(landingPath);
  }, [history]);

  const redirectToLoginPage = useCallback(() => {
    history.push(loginPath);
  }, [history]);

  const redirectToRegisterCompanyPage = useCallback(() => {
    history.push(registerCompanyPath);
  }, [history]);

  const redirectToDefaultPath = useCallback(
    (user?: UserDto) => {
      const defaultPath = getDefaultPath(user);
      location.pathname === defaultPath ? history.go(0) : history.push(defaultPath);
    },
    [getDefaultPath, history, location.pathname]
  );

  const redirectToRootPath = useCallback(() => {
    history.push('/');
  }, [history]);

  return {
    projectsPath,
    sldStagePath,
    ssdStagePath,
    scdStagePath,
    lanStagePath,
    tcoPath,
    exportPath,
    companiesPath,
    librariesPath,
    usersPath,
    passwordRecoveryPath,
    resetPasswordPath,
    landingPath,
    loginPath,
    logoutPath,
    registerCompanyPath,
    registerUserPath,
    getDefaultPath,
    redirectToProjectsPage,
    redirectToStagePage,
    redirectToCompaniesPage,
    redirectToLibrariesPage,
    redirectToUsersPage,
    redirectToLandingPage,
    redirectToLoginPage,
    redirectToLogoutPage,
    redirectToRegisterCompanyPage,
    redirectToDefaultPath,
    redirectToRootPath,
    stagePageLink,
  };
};
