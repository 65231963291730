import { Graph } from '../Graph';
import { Node, NodeType } from '../../node/Node';
import { Link } from '../../Link';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { Mapping } from '../../mapping/Mapping';

export class BusBranchWithSideNodes implements Graph {
  private bus: Node;
  private branch: Graph;
  private transformer: Node;
  private neighbourhoodMapping: Mapping<Node, Graph>;

  constructor(mainPathBranch: Graph, bus: Node, transformer: Node, neighbourhoodMapping: Mapping<Node, Graph>) {
    this.branch = mainPathBranch;
    this.bus = bus;
    this.transformer = transformer;
    this.neighbourhoodMapping = neighbourhoodMapping;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const pathNodes = this.branch.getNodes().slice(1, -1);

    const visited = [...pathNodes, this.bus, this.transformer];
    const sideNodes: Node[] = [];
    pathNodes.forEach((node) => {
      const neighbours = this.getAllSecondaryNeighboursForNode(node, visited);
      visited.push(...neighbours);
      sideNodes.push(...neighbours);
    });

    return [...pathNodes, ...sideNodes];
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }

  private getAllSecondaryNeighboursForNode(node: Node, excepted: Node[]): Node[] {
    let neighbours: Node[] = [];
    let nodesToSearchFrom = [node];
    let currentExcepted = [...excepted];

    let newNeighboursCanBeFound = true;
    while (newNeighboursCanBeFound) {
      const newFound = nodesToSearchFrom.flatMap((nodeToSearchFrom) =>
        this.getNeighboursForNode(nodeToSearchFrom, currentExcepted)
      );
      neighbours.push(...newFound);
      currentExcepted.push(...newFound);
      nodesToSearchFrom = newFound;
      if (newFound.length === 0) {
        newNeighboursCanBeFound = false;
      }
    }
    return neighbours;
  }

  private getNeighboursForNode(node: Node, excepted: Node[]): Node[] {
    return this.neighbourhoodMapping
      .map(node)
      .getNodes()
      .filter((neighbourModel) => !excepted.some((exceptedNode) => exceptedNode.getID() === neighbourModel.getID()))
      .filter((neighbour) => neighbour.getType() !== NodeType.TR && neighbour.getType() !== NodeType.BUS);
  }
}
