import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Styled as S } from '../../Modal.styled';
import { PacsArchitectureDirectoryId, ScdCreationDto, StageDto } from '../../../api/nggrace-back';
import { InputSelect, OptionType } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';
import { Feedback } from '../../widgets/Feedback';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Confirmation } from '../../widgets/Confirmation';
import { useNotifications } from '../../context/NotificationContext';
import { SecondaryButton } from '../../widgets/SecondaryButton';

type ScdSettingsProps = {
  stage: StageDto;
  onReloadStage?: () => void;
  onClose(): void;
};

export const ScdSettings: React.FC<ScdSettingsProps> = ({ stage, onReloadStage, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<ScdCreationDto>({
    iedVendorId: stage.project.iedVendor.id,
    muVendorId: stage.project.muVendor.id,
    pacsArchitectureDirectoryId: stage.project.pacsArchitectureDirectoryId,
  });
  const { pacsArchDirectory } = useDirectory();
  const { redirectToStagePage } = useRoutes();
  const [iedVendors, setIedVendors] = useState<OptionType[]>([]);
  const [muVendors, setMuVendors] = useState<OptionType[]>([]);
  const update = useMemo(() => stage.baseInfo.type === 'SCD', [stage]);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const notifications = useNotifications();

  useEffect(() => {
    Api.getMuVendors().then((result) => setMuVendors(result.data as OptionType[]));
    Api.getIedVendors().then((result) => setIedVendors(result.data as OptionType[]));
  }, []);

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const pacsArchOptions = useMemo(() => {
    return pacsArchDirectory.getAll().map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [pacsArchDirectory]);

  const handlePacsArchChange = useCallback((pacsArchitectureDirectoryId: string) => {
    setState((state) => ({
      ...state,
      pacsArchitectureDirectoryId: pacsArchitectureDirectoryId as PacsArchitectureDirectoryId,
    }));
  }, []);

  const handleIedVendorChange = useCallback((iedVendorId: number) => {
    setState((state) => ({ ...state, iedVendorId }));
  }, []);

  const handleMuVendorChange = useCallback((muVendorId: number) => {
    setState((state) => ({ ...state, muVendorId }));
  }, []);

  const handleSynthesisScd = useCallback(() => {
    setLoading(true);
    Api.createScdStage(stage.project.id, state, { omitInterceptorErrorModal: true })
      .then(() => {
        if (update) {
          onReloadStage!!();
          setLoading(false);
          onClose();
          notifications.notifySuccess('SCD settings updated.');
        } else {
          redirectToStagePage(stage.project.id, 'SCD');
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  }, [stage.project.id, state, update, onReloadStage, onClose, notifications, redirectToStagePage]);

  const handleUpdateScd = useCallback(() => {
    hideConfirmationModal();
    handleSynthesisScd();
  }, [handleSynthesisScd, hideConfirmationModal]);

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <>
      <ReactModal isOpen onRequestClose={handleClose} style={theme.modal}>
        <S.Modal>
          <S.Header>SCD Settings</S.Header>
          <S.Main>
            <S.LeftPanel>
              <S.Form>
                <InputSelect
                  label="PACS Architecture"
                  selected={state.pacsArchitectureDirectoryId}
                  options={pacsArchOptions}
                  onChange={handlePacsArchChange}
                />
                <InputSelect
                  label="IED Vendor"
                  selected={state.iedVendorId}
                  options={iedVendors}
                  onChange={handleIedVendorChange}
                />
                <InputSelect
                  label="MU Vendor"
                  selected={state.muVendorId}
                  options={muVendors}
                  onChange={handleMuVendorChange}
                />
              </S.Form>
            </S.LeftPanel>
            <S.RightPanel>
              <S.Description>
                <p>You must select the parameters for SCD PACS system synthesis.</p>
                <p>The PACS architecture defines the variations of possible designed systems.</p>
                <p>
                  You must select your preferred system hardware vendors from the list of possible vendors. IPACS is
                  selected by default.
                </p>
              </S.Description>
              {error && <Feedback error text={error} />}
            </S.RightPanel>
          </S.Main>
          <S.Footer>
            <SecondaryButton text={'Cancel'} onClick={handleClose} />
            <S.BtnPrimary onClick={update ? showConfirmationModal : handleSynthesisScd} disabled={loading}>
              {!loading ? 'Synthesis' : 'Processing'}
            </S.BtnPrimary>
          </S.Footer>
        </S.Modal>
      </ReactModal>{' '}
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Are you sure?'}
          text={`All changes made to diagram would be lost`}
          onClose={hideConfirmationModal}
          onConfirm={handleUpdateScd}
        />
      </ReactModal>
    </>
  );
};
