import styled, { css } from 'styled-components';
import { Styled as TabStyled } from '../../../../../../../widgets/Tabs.styled';

const Tab = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 32px;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.darkgrey)};
  border-right: 1px solid ${(props) => props.theme.colors.grey};
  height: 100%;

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.darkwhite};
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: ${(props) => props.theme.colors.grey};
      border-radius: 16px 16px 0px 0px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 4px;
        top: 95%;
        width: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(180deg, #0cbaa1 0%, #103c8b 100%);
        border-radius: 50px;
        margin: 0 auto;
        transition: all 1s ease-out;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        height: 1px;
        background: ${(props) => props.theme.colors.darkwhite};
      }
    `};
  }

  &:first-child {
    border-width: 0 1px 0 0;
  }

  &:last-child {
    border-right: none;
  }
`;

const Tabs = styled(TabStyled.Tabs)``;

export const Styled = {
  Tabs,
  Tab,
};
