import { Mapping, PathMapping } from './Mapping';
import { DefaultGraph, Graph } from '../graph/Graph';
import { Node } from '../node/Node';

export class BusTransformersMapping implements Mapping<Node, Graph> {
  private bussesTransformers: BussesTransformers;

  constructor(busses: Graph, transformers: Graph, pathMapping: PathMapping) {
    this.bussesTransformers = new BussesTransformers(busses, transformers, pathMapping);
  }

  map(from: Node): Graph {
    return new DefaultGraph([...this.bussesTransformers.getSets().get(from.getID())!.transformers], []);
  }
}

class BussesTransformers {
  private busses: Graph;
  private transformers: Graph;

  private computedValues?: Map<string, { bus: Node; transformers: Node[] }>;
  private pathMapping: PathMapping;

  constructor(busses: Graph, transformers: Graph, pathMapping: PathMapping) {
    this.busses = busses;
    this.transformers = transformers;
    this.pathMapping = pathMapping;
  }

  getSets(): Map<string, { bus: Node; transformers: Node[] }> {
    if (!this.computedValues) {
      this.computedValues = this.getMapping();
    }

    return this.computedValues;
  }

  private getMapping() {
    const result = new Map<string, { bus: Node; transformers: Node[] }>();

    const busses = this.busses.getNodes();
    busses.forEach((bus) => result.set(bus.getID(), { bus, transformers: [] }));

    this.transformers.getNodes().forEach((tr) => {
      const trBusses = busses.filter((bus) => this.pathMapping.map({ start: bus, end: tr }).getNodes().length);
      const minIndex = Math.min(...trBusses.map((bus) => busses.findIndex((b) => b.getID() === bus.getID())));
      if (minIndex === Infinity) {
        return;
      }

      const busToAddTr = busses[minIndex];
      result.get(busToAddTr.getID())!.transformers.push(tr);
    });
    return result;
  }
}
