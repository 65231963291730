import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { ProjectList } from './ProjectList';
import { Page } from '../../Page';
import { ProjectSettings } from './ProjectSettings';
import { Styled as S } from '../TablePage.styled';
import { Header } from '../../widgets/Header.styled';
import { theme } from '../../../theme';
import { BtnIcon } from '../../widgets/Btn.styled';
import { Icon } from '../../widgets/Icon';
import { NotificationProvider } from '../../context/NotificationContext';

export const ProjectsPage: React.FC = () => {
  const [showProjectCreation, setShowProjectCreation] = useState<boolean>(false);

  const handleCreate = useCallback(() => {
    setShowProjectCreation(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowProjectCreation(false);
  }, []);

  return (
    <NotificationProvider>
      <Page active={'projects'}>
        <S.Content>
          <S.Header>
            <Header>Projects</Header>
            <BtnIcon onClick={handleCreate}>
              <Icon name={'plus'} /> New
            </BtnIcon>
          </S.Header>
          <ProjectList />
        </S.Content>
        <ReactModal isOpen={showProjectCreation} onRequestClose={handleClose} style={theme.modal}>
          <ProjectSettings onClose={handleClose} />
        </ReactModal>
      </Page>
    </NotificationProvider>
  );
};
