import styled from 'styled-components';
import { Styled as StyledForm } from './CardContent.styled';
import { Styled as StyledInputField } from '../../../../../widgets/InputField.styled';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 507px;

  ${StyledForm.Form} {
    width: 248px;
    ${StyledInputField.InputField} {
      width: 248px;
    }
  }
`;

export const Styled = {
  Container,
};
