import { EditablePath } from '../Path';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { Point } from '@projectstorm/geometry';
import { SmartLinkModel } from '../../SmartLinkModel';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';

//fix for #282
export class FallbackEditablePath implements EditablePath {
  private origin: EditablePath;
  private link: SmartLinkModel;
  private fallbackPath: () => SmartLinkPointModel[];

  constructor(origin: EditablePath, link: SmartLinkModel, fallbackPath: () => SmartLinkPointModel[]) {
    this.origin = origin;
    this.link = link;
    this.fallbackPath = fallbackPath;
  }

  moveSegment(startPoint: PointModel, displacement: Point): void {
    this.origin.moveSegment(startPoint, displacement);
    const segments = this.link.getSegments();
    try {
      segments.forEach((segment) => segment.isVertical());
    } catch {
      this.link.setPoints(this.fallbackPath());
    }
  }
}
