import { NgGraceModel } from '../NgGraceModel';
import { LogicDeviceLayerModel } from './layer/logic-device/LogicDeviceLayerModel';
import { DiagramModelGenerics } from '@projectstorm/react-diagrams';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { SsdNodeLayerModel } from './layer/node/SsdNodeLayerModel';
import { BusNodeWithPlaceholdersModel } from '../bus/BusNodeWithPlaceholdersModel';
import { DirectoryNodeWithPlaceholdersModel } from '../directory/DirectoryNodeWithPlaceholdersModel';
import { DefaultCoordinate } from '../geometry/Coordinate';
import { BusDefaultSize } from '../bus/BusNodeModel';
import { BasePoint } from '../geometry/Point';

export class SsdModel extends NgGraceModel {
  private logicDeviceLayer: LogicDeviceLayerModel;

  constructor(options?: DiagramModelGenerics['OPTIONS']) {
    super(options);
    this.logicDeviceLayer = new LogicDeviceLayerModel();
    this.addLayer(this.logicDeviceLayer);
    this.addLayer(new SsdNodeLayerModel());
    this.lockStaticLayers();
  }

  getLogicDeviceLayer(): LogicDeviceLayerModel {
    return this.logicDeviceLayer;
  }

  generateModel(): NgGraceModel {
    return new SsdModel();
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    const deserializedLayer = this.getLayers().find((layer) => layer instanceof LogicDeviceLayerModel);

    if (deserializedLayer) {
      this.logicDeviceLayer = deserializedLayer as LogicDeviceLayerModel;
    } else {
      this.logicDeviceLayer = new LogicDeviceLayerModel();
      this.addLayer(this.logicDeviceLayer);
    }

    this.lockStaticLayers();

    const busResizer = () => this.resizeBuses();

    this.getNodes().forEach((node) => {
      node.registerListener({
        sizeChanged: busResizer,
        positionChanged: busResizer,
      });
    });
  }

  resizeBuses() {
    this.getNodes()
      .filter((node) => node.getType() === 'bus')
      .forEach((bus) => this.resizeBus(bus as BusNodeWithPlaceholdersModel<any>));
  }

  resizeBus(bus: BusNodeWithPlaceholdersModel<any>) {
    const portsToMove = Object.values(bus.getPorts())
      // .filter((port) => !isLinkedWith(port, (node) => node.getType() === 'bus' && node.getID() !== bus.getID()))
      .map((port) => {
        const link = port.getLink();
        const nodePort = link.getTargetPort().getID() === port.getID() ? link.getSourcePort() : link.getTargetPort();
        const node = nodePort.getNode() as DirectoryNodeWithPlaceholdersModel<any>;
        const portToNodeOffset = nodePort.getCenter().x - node.getPosition().x - port.getSize() / 2;
        return { port, offset: node.getOffsetPosition().x + portToNodeOffset };
      });

    if (portsToMove.length) {
      // compute and update new offset for the bus
      // it can be changed if we need to increase width of the bus to the left
      const currentBusOffset = bus.getOffsetPosition().x;
      const offsets = portsToMove.map((portToMove) => portToMove.offset - currentBusOffset);
      const minOffset = Math.min(...offsets);
      const newBusOffset = minOffset < 0 ? currentBusOffset + minOffset - 40 : currentBusOffset;
      const deltaBusOffset = newBusOffset - currentBusOffset;

      // update bus offset if any
      if (newBusOffset !== currentBusOffset) {
        bus.setOffset(newBusOffset - bus.getStaticPosition().x, new DefaultCoordinate('x'));
        return;
      }

      // update bus size
      const busOffsets = Object.values(bus.getPorts()).map((p) => p.getBusOffset());
      const minBusOffset = Math.min(...busOffsets);
      const maxBusOffset = Math.max(...busOffsets);

      const currentWidth = bus.getSize().x;
      const newWidth = Math.max(BusDefaultSize.x, maxBusOffset + minBusOffset + 80);
      if (currentWidth !== newWidth) {
        bus.setSize(new BasePoint(newWidth, BusDefaultSize.y));
        return;
      }

      // update port bus offset
      Object.values(bus.getPorts()).forEach((port) => {
        const portToMove = portsToMove.find((ptm) => ptm.port === port);
        if (portToMove) {
          port.setBusOffset(portToMove.offset - newBusOffset);
        } else {
          port.setBusOffset(port.getBusOffset() - deltaBusOffset);
        }
      });
    }
  }

  lockStaticLayers() {
    this.getActiveNodeLayer().setLocked(true);
  }
}
