import {
  AbstractDisplacementState,
  AbstractDisplacementStateEvent,
  Action,
  ActionEvent,
  InputType,
  State,
} from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { SmartLinkModel } from '../link/smart/SmartLinkModel';
import { SmartLinkSegmentClassName, SmartLinkSegmentIdAttributeName } from '../link/smart/SmartLinkWidget';
import { Point } from '@projectstorm/geometry';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import { Coord } from '../geometry/Coordinate';

export class SmartLinkDragState extends AbstractDisplacementState<DiagramEngine> {
  link?: SmartLinkModel;
  segmentId?: string;
  lastEventPosition?: Point;
  moved?: boolean;
  segmentCoord?: Coord;

  constructor() {
    super({ name: 'smart-link-drag-state' });

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const mouseEvent: MouseEvent = event.event as MouseEvent;
          this.link = this.engine.getMouseElement(mouseEvent) as SmartLinkModel;
          this.engine.getModel().clearSelection();
          this.link.setSelected(true);
          this.segmentId = this.getSegmentId(mouseEvent.target as Element);
          this.lastEventPosition = this.engine.getRelativeMousePoint(mouseEvent);
          this.moved = false;
        },
      })
    );
  }

  fireMouseMoved(event: AbstractDisplacementStateEvent): any {
    const eventPosition = this.engine.getRelativeMousePoint(event.event);
    const segment = this.link!.getSegments().find((segment) => segment.getId() === this.segmentId);
    if (!segment) {
      return this.eject();
    }
    const currentSegmentCoord: Coord = segment.isVertical() ? 'y' : 'x';

    if (this.segmentCoord && this.segmentCoord !== currentSegmentCoord) {
      return this.eject();
    }

    this.segmentCoord = currentSegmentCoord;
    this.link!.moveSegment(
      this.segmentId!,
      new Point(
        Math.round(eventPosition.x - this.lastEventPosition!.x),
        Math.round(eventPosition.y - this.lastEventPosition!.y)
      )
    );
    this.lastEventPosition = eventPosition;
    this.moved = true;
    this.link?.fireEvent({}, 'pointsChanged');
  }

  getSegmentId(element: Element) {
    return element.closest(`.${SmartLinkSegmentClassName}`)!.getAttribute(SmartLinkSegmentIdAttributeName)!;
  }

  deactivated(next: State) {
    if (this.moved && !this.link!.isLocked()) {
      this.link!.fireEvent({}, 'positionChanged');
    }

    this.segmentCoord = undefined;
    super.deactivated(next);
  }
}
