import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Styled as S } from '../ssd/logic-device/PlaceholderWidget.styled';
import { SsdEngine } from '../ssd/SsdEngine';
import { PlaceholderBrickWidget } from './PlaceholderBrickWidget';
import { PlaceholderBaseChild, PlaceholderBaseRelativeModel, PlaceholderOrigin } from './Placeholder';
import { NamedNodeModelPayload } from '../generics/NamedNodeModelPayload';

export type PlaceholderModel = {
  getCodeName(): string;
  getShortName(): string;
} & PlaceholderOrigin<
  PlaceholderBaseChild & { getCodeName(): string; getBrickColor(): string },
  PlaceholderBaseRelativeModel
>;

export interface PlaceholderWidgetProps {
  placeholderModel: PlaceholderModel;
  engine: SsdEngine;
}

export const PlaceholderWidget: React.FC<PlaceholderWidgetProps> = React.memo(({ placeholderModel, engine }) => {
  const [nodes, setNodes] = useState([...placeholderModel.getChildren()]);
  const [selected, setSelected] = useState(placeholderModel.isSelected());
  const [position, setPosition] = useState(placeholderModel.getPosition());
  const [size, setSize] = useState(placeholderModel.getSize());
  const relativeModel = useMemo(() => placeholderModel.getRelativeModel(), [placeholderModel]);

  useEffect(() => {
    setSelected(placeholderModel.isSelected());
    setNodes([...placeholderModel.getChildren()]);
    setPosition(placeholderModel.getPosition());
    setSize(placeholderModel.getSize());
    return placeholderModel.registerListener({
      childrenChanged: () => setNodes([...placeholderModel.getChildren()]),
      selectionChanged: () => setSelected(placeholderModel.isSelected()),
      positionChanged: () => setPosition(placeholderModel.getPosition()),
      sizeChanged: () => setSize(placeholderModel.getSize()),
    }).deregister;
  }, [placeholderModel, relativeModel]);

  return (
    <S.PlaceholderWithHeader selected={selected} left={position.x} top={position.y} height={size.y}>
      <S.PrimaryHeader selected={selected}>{placeholderModel.getShortName()}</S.PrimaryHeader>
      <S.LeftHeader selected={selected}>{placeholderModel.getCodeName()}</S.LeftHeader>
      <S.RightHeader selected={selected}>
        {(relativeModel?.getPayload() as NamedNodeModelPayload).projectName}
      </S.RightHeader>
      <S.Content selected={selected}>
        {nodes.map((brickModel) => (
          <PlaceholderBrickWidget
            brickModel={brickModel}
            engine={engine}
            key={brickModel.getID()}
            parent={placeholderModel}
          />
        ))}
      </S.Content>
    </S.PlaceholderWithHeader>
  );
});
