import { BasePoint } from '../geometry/Point';
import { Coord, Coordinate } from '../geometry/Coordinate';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { DefaultHasChildren, HasChildren, HasChildrenListener } from './HasChildren';
import { Listenable } from './Listenable';
import { PayloadNodeModelListener } from '../generics/PayloadNodeModel';
import { DefaultHasOffsetPosition, HasOffsetPosition } from './HasOffsetPosition';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasSize, HasSizeListener } from './HasSize';
import { PlaceholderGroupHasSize } from './size-computation/PlaceholderGroupHasSize';
import { HasPosition } from './HasPosition';
import { PlaceholderGroupCachingHasSize } from './size-computation/PlaceholderGroupCachingHasSize';
import { StageType } from '../../../api/nggrace-back';

export type NodeWithPlaceholdersListener<CHILD> = HasChildrenListener<CHILD> & {
  placeholdersSizeChanged?: () => any;
  updateRect?: () => void;
  childRectChanged?: () => void;
};

export type StagedSerializedNodeWithPlaceholder = {
  [key in StageType]?: SerializedNodeWithPlaceholder;
};

export type SerializedNodeWithPlaceholder = {
  offsetPosition: BasePoint;
  placeholdersSize: { x: number; y: number };
};

export interface NodeWithPlaceholders<CHILD extends BaseObserver> extends HasChildren<CHILD>, HasOffsetPosition {
  getPlaceholdersSize(): BasePoint;

  serialize(): StagedSerializedNodeWithPlaceholder;

  getLayerIndexes(): LayerIndexes;

  setLayerIndex(coord: Coordinate, index: number): void;
}

export type NodeEventDelegate<CHILD> = Listenable<
  NodeWithPlaceholdersListener<CHILD> & Partial<PayloadNodeModelListener> & HasSizeListener
>;

export type LayerIndexes = { [key in Coord]: number };

export class DefaultNodeWithPlaceholders<CHILD extends BaseObserver & HasSize & HasPosition>
  implements NodeWithPlaceholders<CHILD>, HasSize {
  private readonly eventDelegate: NodeEventDelegate<CHILD>;
  private readonly hasChildren: HasChildren<CHILD>;
  private readonly hasOffsetPosition: HasOffsetPosition;
  private readonly hasSize: HasSize;
  private readonly stage: StageType;
  private layerIndexes: LayerIndexes = { x: 0, y: 0 };

  constructor(
    eventDelegate: NodeEventDelegate<CHILD>,
    diagramNodeModel: RotatableNodeModel,
    stage: StageType,
    serialized?: SerializedNodeWithPlaceholder
  ) {
    this.eventDelegate = eventDelegate;
    this.stage = stage;
    this.hasChildren = new DefaultHasChildren(eventDelegate as any);
    this.hasSize = new PlaceholderGroupCachingHasSize(
      new PlaceholderGroupHasSize(eventDelegate as any, this.hasChildren),
      eventDelegate as any,
      serialized ? new BasePoint(serialized.placeholdersSize) : undefined
    );
    this.hasOffsetPosition = new DefaultHasOffsetPosition(
      diagramNodeModel,
      diagramNodeModel,
      serialized ? new BasePoint(serialized.offsetPosition) : undefined
    );

    this.eventDelegate.registerListener({
      childrenChanged: () => this.eventDelegate.fireEvent(undefined, 'payloadChanged'),
      sizeChanged: () => this.eventDelegate.fireEvent(undefined, 'placeholdersSizeChanged'),
    });
  }

  getChildren(): CHILD[] {
    return this.hasChildren.getChildren();
  }

  addChild(childToAdd: CHILD, index: number): void {
    this.hasChildren.addChild(childToAdd, index);
  }

  serialize(): StagedSerializedNodeWithPlaceholder {
    const result: StagedSerializedNodeWithPlaceholder = {};
    result[this.stage] = {
      offsetPosition: this.hasOffsetPosition.getOffsetPosition(),
      placeholdersSize: this.hasSize.getSize(),
    };
    return result;
  }

  getPlaceholdersSize() {
    return this.hasSize.getSize();
  }

  getOffsetPosition(): BasePoint {
    return this.hasOffsetPosition.getOffsetPosition();
  }

  getStaticPosition(): BasePoint {
    return this.hasOffsetPosition.getStaticPosition();
  }

  setOffset(offset: number, coordinate: Coordinate): void {
    this.hasOffsetPosition.setOffset(offset, coordinate);
  }

  getSize(): BasePoint {
    return this.hasSize.getSize();
  }

  setLayerIndex(coord: Coordinate, index: number) {
    this.layerIndexes[coord.getName()] = index;
  }

  getLayerIndexes() {
    return this.layerIndexes;
  }
}
