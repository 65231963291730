import React, { useCallback } from 'react';
import { Styled as S } from './Tabs.styled';

export type Tab<ID extends string> = {
  id: ID;
  name: string;
};

type TabsProps<ID extends string> = {
  activeTab: ID;
  tabs: Tab<ID>[];
  onChange(tab: ID): void;
};

type TabProps<ID extends string> = {
  tab: Tab<ID>;
  active: boolean;
  onClick(tab: ID): void;
};

export const Tabs = <ID extends string>({ activeTab, tabs, onChange }: TabsProps<ID>) => {
  return (
    <S.Tabs>
      {tabs.map((tab) => (
        <TabBtn key={tab.id} tab={tab} active={tab.id === activeTab} onClick={onChange} />
      ))}
    </S.Tabs>
  );
};

const TabBtn = <ID extends string>({ tab, active, onClick }: TabProps<ID>) => {
  const handleClick = useCallback(() => {
    onClick(tab.id);
  }, [onClick, tab.id]);

  return (
    <S.Tab onClick={handleClick} active={active}>
      {tab.name}
    </S.Tab>
  );
};
