import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  HardwareInterfaceDto,
  HardwareDto,
  HardwareViewDto,
  IcdReadDto,
  HardwareType,
  SvgDto,
} from '../../../../../api/nggrace-back';
import { Styled as S } from './HardwareCard.styled';
import { EditInfo } from '../../EditInfo';
import { Icon } from '../../../../widgets/Icon';
import { Api } from '../../../../../api/Api';
import { useNotifications } from '../../../../context/NotificationContext';
import { InputField as InField } from '../../../../widgets/InputField';
import { CardContent } from './cardcontent/CardContent';
import { LnComposition } from './lncomposition/LnComposition';
import { useUser } from '../../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../../utils/role-utils';
import { Feedback } from '../../../../widgets/Feedback';
import { SecondaryButton } from '../../../../widgets/SecondaryButton';

export type CardType = 'create' | 'edit' | 'view';
export const hasIcd = (hardwareType: HardwareType): boolean => ['IED', 'MU', 'IED_MU'].includes(hardwareType);

interface HardwareCardProps {
  cardType: CardType;
  hardwareType: HardwareType;
  handleCardTypeChange?: (type: CardType) => void;
  hardware?: HardwareViewDto;
  onClose: (hardwareUpdate?: HardwareDto, svgFileName?: string) => void;
}

interface FeedbackMessage {
  error?: boolean;
  success?: boolean;
  text: string;
}

export const HardwareCard: React.FC<HardwareCardProps> = ({
  cardType,
  hardwareType,
  handleCardTypeChange,
  hardware: initHardware,
  onClose,
}) => {
  const initHardwareUpdate = initHardware
    ? ({
        commonFields: {
          type: hardwareType,
          vendorId: initHardware.vendor.id,
          model: initHardware.model!,
          name: initHardware.name,
        },
        restFields: initHardware.restFields,
      } as HardwareDto)
    : ({
        commonFields: {
          type: hardwareType,
        },
        restFields: {
          interfaces: [{}] as HardwareInterfaceDto[],
          performancePoints: 10,
          svgInfo: { sourceType: 'DIRECTORY' },
        },
      } as HardwareDto);

  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);
  const hardwareHasIcd = useMemo(() => hasIcd(hardwareType), [hardwareType]);

  const [showLnComposition, setShowLnComposition] = useState(false);
  const [icdFileContent, setIcdFileContent] = useState<IcdReadDto | undefined>(
    initHardware?.icdTemplates ? { success: true, logicalNodeComposition: initHardware.icdTemplates } : undefined
  );
  const [hardware, setHardware] = useState<HardwareDto>(initHardwareUpdate);
  const [hardwareBeforeEdit, setHardwareBeforeEdit] = useState<HardwareDto>(
    JSON.parse(JSON.stringify(initHardwareUpdate))
  );
  const [updated, setUpdated] = useState<boolean>(false);
  const [scroll, setScroll] = useState<'top' | 'bottom' | 'middle'>('top');
  const [icdLoading, setIcdLoading] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackMessage | undefined>(undefined);
  const [editFromViewCard, setEditFromViewCard] = useState<boolean>(false);

  const [svg, setSvg] = useState<SvgDto | undefined>(initHardware?.svg);
  const [svgBeforeEdit, setSvgBeforeEdit] = useState<SvgDto | undefined>(initHardware?.svg);

  const notifications = useNotifications();
  const nameRef = useRef<HTMLInputElement>(null);
  const maxNameLength = 255;

  const handleOnEdit = () => {
    if (handleCardTypeChange) handleCardTypeChange('edit');
    setEditFromViewCard(true);
    setFeedback(undefined);
  };

  const handleNameEdit = useCallback(() => nameRef.current!.focus(), [nameRef]);

  const handleNameChange = useCallback((name: string) => {
    setHardware((hardware) => ({ ...hardware, commonFields: { ...hardware.commonFields, name } }));
    setUpdated(true);
  }, []);

  const handleIcdReadAndValidate = useCallback(
    (icd: File, onFulfilled: () => void) => {
      setIcdLoading(true);
      Api.validateAndReadIcdFile(icd)
        .then((response) => {
          setIcdFileContent(response.data);
          onFulfilled();
          setIcdLoading(false);
        })
        .catch(() => {
          notifications.notifyError('Unable to validate an ICD file', 'Server error');
        });
    },
    [notifications]
  );

  const handleUpdate = useCallback(() => {
    if (!updated) {
      onClose(undefined);
      return;
    }
    try {
      Api.updateHardware(initHardware!.id, hardware!, { omitInterceptorErrorModal: true })
        .then(() => {
          if (editFromViewCard) {
            handleCardTypeChange && handleCardTypeChange('view');
            setHardwareBeforeEdit(JSON.parse(JSON.stringify(hardware)));
            svg && setSvgBeforeEdit({ ...svg });
            setFeedback({ success: true, text: 'Hardware edited' });
          } else {
            onClose(hardware, svg?.name);
            notifications.notifySuccess('Hardware edited');
          }
        })
        .catch((error) => {
          setFeedback({ error: true, text: error.response.data });
        });
    } catch (e) {
      notifications.notifyError('Internal server error', 'Error');
    }
  }, [editFromViewCard, handleCardTypeChange, hardware, initHardware, notifications, onClose, svg, updated]);

  const handleCreate = useCallback(() => {
    try {
      Api.createHardwareWithFormData(hardware!, { omitInterceptorErrorModal: true })
        .then(() => {
          onClose(hardware, svg?.name);
          notifications.notifySuccess('Hardware saved.');
          setEditFromViewCard(false);
        })
        .catch((error) => {
          setFeedback({ error: true, text: error.response.data });
        });
    } catch (e) {
      notifications.notifyError('Internal server error', 'Error');
    }
  }, [hardware, notifications, onClose, svg?.name]);

  const handleOnHardwareChange = useCallback((hardware: HardwareDto) => {
    setHardware(hardware);
    setUpdated(true);
  }, []);

  const handleScrollChange = (scrollTop: number, scrollHeight: number) => {
    if (Math.abs(scrollTop - scrollHeight) < 3) {
      setScroll('bottom');
    } else if (scrollTop < 3) {
      setScroll('top');
    } else {
      setScroll('middle');
    }
  };

  const onShowLnCompositionChange = (show: boolean) => {
    setShowLnComposition(show);
    setScroll('top');
  };

  const handleCancel = () => {
    setFeedback(undefined);
    if (editFromViewCard) {
      handleOnHardwareChange(hardwareBeforeEdit);
      svgBeforeEdit && setSvg({ ...svgBeforeEdit });
      handleCardTypeChange && handleCardTypeChange('view');
    } else {
      onClose(undefined);
    }
  };

  const handleOk = () => {
    setFeedback(undefined);
    setEditFromViewCard(false);
    onClose(hardware, svg?.name);
  };

  return (
    <S.Settings>
      <S.HeaderInput notTransparent={cardType === 'view' || showLnComposition}>
        <InField
          ref={nameRef}
          value={hardware.commonFields.name}
          placeholder={'Untitled'}
          onChange={handleNameChange}
          fitContent
          dataTestid={'hardwareName'}
          maxLength={maxNameLength}
          disabled={cardType === 'view' || showLnComposition}
        />
        {cardType !== 'view' && !showLnComposition && <Icon name={'edit'} onClick={handleNameEdit} />}
        {cardType === 'view' && !showLnComposition && userHasLibraryEditAccess && (
          <S.BtnIcon onClick={handleOnEdit}>
            <Icon name={'edit'} />
            <span>Edit Parameters</span>
          </S.BtnIcon>
        )}
      </S.HeaderInput>
      <EditInfo cardType={cardType} editedAt={initHardware?.editedAt!} editorName={initHardware?.editor.name!} />
      <S.Shelf type={'top'} scroll={scroll} />
      {!showLnComposition && (
        <CardContent
          hardwareId={initHardware?.id}
          hardwareHasIcd={hardwareHasIcd}
          cardType={cardType}
          hardware={hardware}
          icdFileContent={icdFileContent}
          svg={svg}
          setSvg={setSvg}
          icdLoading={icdLoading}
          handleIcdReadAndValidate={handleIcdReadAndValidate}
          handleOnHardwareChange={handleOnHardwareChange}
          handleScrollChange={handleScrollChange}
          icdFileName={initHardware?.icdFilename}
        />
      )}
      {showLnComposition && icdFileContent && (
        <LnComposition icdFileContent={icdFileContent} handleScrollChange={handleScrollChange} />
      )}
      <S.Shelf type={'bottom'} scroll={scroll} />
      <S.Footer>
        {feedback && <Feedback error={feedback.error} success={feedback.success} text={feedback.text} />}
        <S.Buttons>
          {(cardType === 'create' || cardType === 'edit') && !showLnComposition && (
            <SecondaryButton text={'Cancel'} onClick={handleCancel} />
          )}
          {hardwareHasIcd &&
            !showLnComposition &&
            (cardType === 'create' ? icdFileContent && icdFileContent.success : true) && (
              <SecondaryButton text={'View LN list'} border onClick={() => onShowLnCompositionChange(true)} />
            )}
          {!showLnComposition && cardType === 'view' && <S.Btn onClick={handleOk}>OK</S.Btn>}
          {!showLnComposition && cardType === 'edit' && <S.Btn onClick={handleUpdate}>Save</S.Btn>}
          {!showLnComposition && cardType === 'create' && <S.Btn onClick={handleCreate}>Create</S.Btn>}
          {showLnComposition && <SecondaryButton text={'Back'} onClick={() => onShowLnCompositionChange(false)} />}
        </S.Buttons>
      </S.Footer>
    </S.Settings>
  );
};
