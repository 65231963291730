import { Node, NodeType } from './Node';
import { NgGraceModel } from '../../NgGraceModel';
import { Rectangle } from '@projectstorm/geometry';
import { SmartRotationHour } from '../../geometry/RotationHour';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { DisposedPortCenterPoint } from '../point/DisposedPortCenterPoint';

export class PortAlignedNode implements Node {
  private node: Node;
  private previousNode: Node;
  private model: NgGraceModel;

  constructor(node: Node, previousNode: Node, model: NgGraceModel) {
    this.node = node;
    this.previousNode = previousNode;
    this.model = model;
  }

  getHour(): SmartRotationHour {
    return this.node.getHour();
  }

  getID(): string {
    return this.node.getID();
  }

  getRect(): Rectangle {
    if (this.node.getRect().getOrigin().x - this.previousNode.getRect().getOrigin().x > 50) {
      return this.node.getRect();
    }
    const nodeModel = this.model.getNode(this.node.getID());
    const nodePort = Object.values(nodeModel.getPorts()).find((port) =>
      Object.values(port.getLinks()).find(
        (link) =>
          link.getSourcePort().getParent().getID() === this.previousNode.getID() ||
          link.getTargetPort().getParent().getID() === this.previousNode.getID()
      )
    )!;
    const link = Object.values(nodePort.getLinks())[0];
    const previousPort = (link.getSourcePort().getParent().getID() === this.node.getID()
      ? link.getTargetPort()
      : link.getSourcePort()) as ConnectablePortModel;
    const port = (link.getSourcePort().getParent().getID() !== this.node.getID()
      ? link.getTargetPort()
      : link.getSourcePort()) as ConnectablePortModel;

    const previousPortDisposedCenter = new DisposedPortCenterPoint(previousPort, this.previousNode);
    const portDisposedCenter = new DisposedPortCenterPoint(port, this.node);
    const originalRect = this.node.getRect().clone();
    originalRect.translate(previousPortDisposedCenter.x - portDisposedCenter.x, 0);
    return originalRect;
  }

  getType(): NodeType {
    return this.node.getType();
  }
}
