import React from 'react';
import { InputSelect as InSelect, OptionType } from '../../../../../widgets/InputSelect';
import { Styled as S } from './CardContent.styled';
import { CardType } from '../HardwareCard';

interface FieldProps {
  cardType: CardType;
  label: string;
  selected?: string | number;
  items: OptionType[];
  onChange(value?: string | number): void;
}

export const InputSelect: React.FC<FieldProps> = ({ cardType, label, items, selected, onChange }) => {
  return (
    <S.Form cardType={cardType}>
      <InSelect disabled={cardType === 'view'} label={label} selected={selected} options={items} onChange={onChange} />
    </S.Form>
  );
};
