import styled from 'styled-components';
import { Btn } from '../../widgets/Btn.styled';
import { Hint } from '../../widgets/Tooltip';
import SimpleBar from 'simplebar-react';

const Modal = styled.div`
  width: 1064px;
`;

const Scroll = styled(SimpleBar)`
  height: 100%;
`;

const ScrollContent = styled.div`
  padding: 32px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Header = styled.div`
  display: flex;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  display: flex;
  padding: 32px 0 22px 0;
`;

const Group = styled.div`
  display: flex;
  padding: 32px 0 22px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  ${Hint} {
    position: absolute;
    right: -42px;
    bottom: 12px;
  }
`;

const GroupTitle = styled.div`
  padding: 14px;
  flex-basis: 350px;
  min-width: 350px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 48px 0 0 0;
`;

const ExportBtn = styled(Btn)`
  height: 40px;
  width: 131px;
`;

export const Styled = {
  Modal,
  Scroll,
  ScrollContent,
  Header,
  Content,
  Footer,
  Error,
  Group,
  GroupTitle,
  ExportBtn,
};
