import { DefaultPortDependantPointsCount, EditablePath } from '../Path';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';
import { SmartLinkModel } from '../../SmartLinkModel';
import { DirectionCoordinate, OppositeCoordinate } from '../../../../geometry/Coordinate';
import { DefaultRightAngledVector } from '../../../../geometry/Vector';

export class RemovingEditablePath implements EditablePath {
  private readonly link: SmartLinkModel;
  private readonly origin: EditablePath;

  constructor(link: SmartLinkModel, origin: EditablePath) {
    this.link = link;
    this.origin = origin;
  }

  moveSegment(startPoint: SmartLinkPointModel, displacement: Point): void {
    const link = this.link;
    const existingPoints = link.getPoints();
    const endPoint = existingPoints[existingPoints.indexOf(startPoint) + 1];
    const canRemoveAhead = existingPoints.indexOf(endPoint) + DefaultPortDependantPointsCount < existingPoints.length;
    const canRemoveBehind = existingPoints.indexOf(startPoint) - DefaultPortDependantPointsCount >= 0;
    const changedCoord = new DirectionCoordinate(
      new DefaultRightAngledVector(startPoint.getPosition(), endPoint.getPosition()).getDirection()
    );
    const changedCoordName = new OppositeCoordinate(changedCoord).getName();
    const oppositeCoordName = changedCoord.getName();
    const currentCoordPosition = startPoint.getPosition()[changedCoordName];
    const movedCoordPosition = currentCoordPosition + displacement[changedCoordName];
    if (canRemoveAhead) {
      const pointToRemove = existingPoints[existingPoints.indexOf(endPoint) + 1];
      if (this.isBetween(currentCoordPosition, movedCoordPosition, pointToRemove.getPosition()[changedCoordName])) {
        const newEndPoint = existingPoints[existingPoints.indexOf(endPoint) + 2];
        endPoint.getPosition()[oppositeCoordName] = newEndPoint.getPosition()[oppositeCoordName];
        endPoint.getPosition()[changedCoordName] = newEndPoint.getPosition()[changedCoordName];
        startPoint.getPosition()[changedCoordName] = newEndPoint.getPosition()[changedCoordName];
        pointToRemove.remove();
        newEndPoint.remove();
      }
    }

    if (canRemoveBehind) {
      const pointToRemove = existingPoints[existingPoints.indexOf(startPoint) - 1];
      if (this.isBetween(currentCoordPosition, movedCoordPosition, pointToRemove.getPosition()[changedCoordName])) {
        const newStartPoint = existingPoints[existingPoints.indexOf(startPoint) - 2];
        startPoint.getPosition()[oppositeCoordName] = newStartPoint.getPosition()[oppositeCoordName];
        startPoint.getPosition()[changedCoordName] = newStartPoint.getPosition()[changedCoordName];
        endPoint.getPosition()[changedCoordName] = newStartPoint.getPosition()[changedCoordName];
        pointToRemove.remove();
        newStartPoint.remove();
      }
    }

    this.origin.moveSegment(startPoint, displacement);
  }

  isBetween(start: number, end: number, point: number) {
    const absStart = Math.min(start, end);
    const absEnd = Math.max(start, end);

    return absStart <= point && point <= absEnd;
  }
}
