export const Deferred = <T extends any>() => {
  let resolve!: (value: T) => void;
  let reject!: () => void;
  const promise: Promise<T> = new Promise<T>((resolve1, reject1) => {
    resolve = resolve1;
    reject = reject1;
  });

  return {
    promise,
    resolve,
    reject,
  };
};
