import React, { useEffect, useState } from 'react';
import { CompositeRackModel } from './CompositeRackModel';
import { RackWidget, RackWidgetProps } from './RackWidget';

export interface CompositeRackWidgetProps {
  rack: CompositeRackModel;
}

export const CompositeRackWidget: React.FC<CompositeRackWidgetProps> = React.memo(({ rack }) => {
  return (
    <>
      {rack.getRacks().map((rack) => (
        <RackWithChildrenWidget rack={rack} key={rack.getID()} />
      ))}
    </>
  );
});

export const RackWithChildrenWidget: React.FC<RackWidgetProps> = React.memo(({ rack }) => {
  const [shown, setShown] = useState(!!rack.getChildren().length);
  useEffect(() => {
    setShown(!!rack.getChildren().length);
    return rack.registerListener({
      childrenChanged: () => setShown(!!rack.getChildren().length),
    }).deregister;
  }, [rack]);
  return shown ? <RackWidget rack={rack} /> : null;
});
