import { AbstractDisplacementState, AbstractDisplacementStateEvent, State } from '@projectstorm/react-canvas-core';

const MOVE_THRESHOLD = 1;

export interface DragCanvasStateOptions {
  allowDrag?: boolean;
  clearSelectionOnClick?: boolean;
}

export class DragCanvasState extends AbstractDisplacementState {
  // store this as we drag the canvas
  initialCanvasX!: number;
  initialCanvasY!: number;
  config: DragCanvasStateOptions;
  moved = false;

  constructor(options: DragCanvasStateOptions = {}) {
    super({
      name: 'drag-canvas',
    });
    this.config = {
      allowDrag: true,
      clearSelectionOnClick: true,
      ...options,
    };
  }

  async activated(prev: State) {
    super.activated(prev);
    this.engine.getCanvas().style.cursor = 'move';
    this.moved = false;
    this.initialCanvasX = this.engine.getModel().getOffsetX();
    this.initialCanvasY = this.engine.getModel().getOffsetY();
    await this.engine.repaintCanvas(true);

    // we can block layer rendering because we are only targeting the transforms
    for (let layer of this.engine.getModel().getLayers()) {
      layer.allowRepaint(false);
    }
  }

  deactivated(next: State) {
    super.deactivated(next);
    this.engine.getCanvas().style.removeProperty('cursor');
    if (this.config.clearSelectionOnClick && !this.moved) {
      this.engine.getModel().clearSelection();
    }
    for (let layer of this.engine.getModel().getLayers()) {
      layer.allowRepaint(true);
    }
  }

  fireMouseMoved(event: AbstractDisplacementStateEvent) {
    if (this.config.allowDrag) {
      if (this.isMoved(event)) {
        this.moved = true;
      }
      this.engine
        .getModel()
        .setOffset(this.initialCanvasX + event.displacementX, this.initialCanvasY + event.displacementY);
      this.engine.repaintCanvas();
    }
  }

  isMoved(event: AbstractDisplacementStateEvent): boolean {
    return Math.sqrt(Math.pow(event.displacementX, 2) + Math.pow(event.displacementY, 2)) > MOVE_THRESHOLD;
  }
}
