import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  VendorViewDto,
  VendorSortProperty,
  VendorSortDto,
  VendorUpdateDto,
  Direction,
} from '../../../../api/nggrace-back';
import { Styled as S } from '../LibraryList.styled';
import { Icon } from '../../../widgets/Icon';
import { List } from '../../../widgets/List.styled';
import { ListWithoutMargin } from '../ListWithoutMargin.styled';
import { Api } from '../../../../api/Api';
import { theme } from '../../../../theme';
import { Confirmation } from '../../../widgets/Confirmation';
import ReactModal from 'react-modal';
import { useNotifications } from '../../../context/NotificationContext';
import { VendorSettings } from './VendorSettings';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SortCellWithoutMargin } from '../SortCellWithoutMargin';
import { EmptyList } from '../../../widgets/EmptyList';
import { useUser } from '../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../utils/role-utils';

interface VendorRowProps {
  vendor: VendorViewDto;
  remove: (vendorId: number) => void;
  userHasLibraryEditAccess: boolean;
}

interface VendorListState {
  vendors: VendorViewDto[];
  hasMore: boolean;
  page: number;
  size: number;
  sortProperty: VendorSortProperty;
  sortDirection: Direction;
}

interface VendorListProps {
  vendorCreationVisibility: boolean;
  hideVendorCreation: () => void;
}

export const VendorList: React.FC<VendorListProps> = ({ vendorCreationVisibility, hideVendorCreation }) => {
  const [state, setState] = useState<VendorListState>({
    vendors: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'VENDOR_NAME',
    sortDirection: 'ASC',
  });
  const notifications = useNotifications();
  const [isListEmpty, setListEmpty] = useState(true);

  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: VendorSortDto = {
        property: state.sortProperty,
        direction: state.sortDirection,
      };
      Api.getVendors(sort, { page, size }).then((response) => {
        setState((state) => ({
          ...state,
          vendors: reload ? response.data.content || [] : state.vendors.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
        setListEmpty(response.data.totalElements === 0);
      });
    },
    [state.sortDirection, state.sortProperty]
  );

  const reload = useCallback(() => {
    setState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, state.size, true);
  }, [fetch, state.size]);

  useEffect(reload, [reload]);

  const direction = (property: VendorSortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback((property: VendorSortProperty, direction: Direction) => {
    setState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const handleNext = useCallback(() => {
    fetch(state.page + 1, state.size);
    setState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, state.page, state.size]);

  const handleDelete = useCallback(
    (vendorId) => {
      Api.deleteVendor(vendorId)
        .then(() => {
          fetch(state.vendors.length - 1, 1);
          notifications.notifySuccess('Vendor deleted.');
        })
        .then(() =>
          setState((state) => ({
            ...state,
            vendors: state.vendors.filter((it) => it.id !== vendorId),
          }))
        );
    },
    [fetch, notifications, state.vendors.length]
  );

  const handleCreate = useCallback(
    (vendor?: VendorUpdateDto) => {
      vendor && reload();
      hideVendorCreation();
    },
    [hideVendorCreation, reload]
  );

  return (
    <>
      {!isListEmpty && (
        <S.LibraryList>
          <InfiniteScroll
            dataLength={state.vendors.length}
            next={handleNext}
            hasMore={state.hasMore}
            scrollableTarget="scrollableTarget"
            loader={null}
          >
            <S.VendorsGrid id="scrollableTarget" userHasLibraryEditAccess={userHasLibraryEditAccess}>
              <List.HeaderRow>
                <SortCellWithoutMargin<VendorSortProperty>
                  type={'VENDOR_NAME'}
                  direction={direction('VENDOR_NAME')}
                  onSort={handleSort}
                >
                  Vendor
                </SortCellWithoutMargin>
                {userHasLibraryEditAccess && <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>}
                {userHasLibraryEditAccess && <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>}
              </List.HeaderRow>
              {state.vendors.map((vendor) => (
                <VendorRow
                  key={vendor.id}
                  vendor={vendor}
                  remove={handleDelete}
                  userHasLibraryEditAccess={userHasLibraryEditAccess}
                />
              ))}
            </S.VendorsGrid>
          </InfiniteScroll>
        </S.LibraryList>
      )}
      {isListEmpty && <EmptyList height={'calc(100vh - 355px)'} />}
      <ReactModal isOpen={vendorCreationVisibility} onRequestClose={hideVendorCreation} style={theme.modal}>
        <VendorSettings onClose={handleCreate} />
      </ReactModal>
    </>
  );
};

const VendorRow: React.FC<VendorRowProps> = ({ vendor, remove, userHasLibraryEditAccess }) => {
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [settingsVisibility, setSettingsVisibility] = useState(false);

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const showSettings = useCallback(() => {
    setSettingsVisibility(true);
  }, []);

  const handleCloseSettings = useCallback(
    (vendorUpdate?: VendorUpdateDto) => {
      if (vendorUpdate) {
        vendor.name = vendorUpdate.name!;
      }
      setSettingsVisibility(false);
    },
    [vendor]
  );

  const handleConfirmDelete = useCallback(() => {
    remove(vendor.id);
    hideConfirmationModal();
  }, [vendor.id, hideConfirmationModal, remove]);

  return (
    <>
      <List.Row key={vendor.id}>
        <ListWithoutMargin.Cell>{vendor.name}</ListWithoutMargin.Cell>
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center>
            <List.Button onClick={showSettings}>
              <Icon name={'edit'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center>
            <List.Button onClick={showConfirmationModal}>
              <Icon name={'trash'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
      </List.Row>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Remove vendor'}
          text={`Are you sure you want to remove ${vendor.name}?`}
          onClose={hideConfirmationModal}
          onConfirm={handleConfirmDelete}
        />
      </ReactModal>
      <ReactModal isOpen={settingsVisibility} onRequestClose={() => handleCloseSettings()} style={theme.modal}>
        <VendorSettings onClose={handleCloseSettings} vendor={vendor} />
      </ReactModal>
    </>
  );
};
