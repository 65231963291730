import styled from 'styled-components';
import { TabPosition } from './Libraries';
import { CornerPosition } from './Corner';
import { BtnIcon as ButtonIcon } from '../../widgets/Btn.styled';

const Container = styled.div`
  margin: 35px 0;
`;

const TabBlock = styled.div`
  display: flex;
  box-shadow: -5px -5px 20px 5px rgba(63, 63, 63, 0.065), 5px -5px 20px 5px rgba(63, 63, 63, 0.065);
  width: max-content;
  border-radius: 16px 16px 0 0;
`;

const Corner = styled.div<{ position: CornerPosition }>`
  height: 56px;
  width: 32px;
  margin-left: ${(props) => `${props.position === 'left' ? '-16px' : '0px'}`};
  transform: ${(props) => `${props.position === 'left' ? 'none' : 'scale(-1,1)'}`};
  z-index: 90;
  pointer-events: none;

  & svg {
    filter: drop-shadow(-10px 10px 10px rgba(63, 63, 63, 0.1));
  }
`;

const SpriteWhite = styled.div<{ position: 'left' | 'right' }>`
  height: 56px;
  width: 16px;
  background: ${(props) => props.theme.colors.white};
  margin-left: ${(props) => `${props.position === 'left' ? '0' : '-32px'}`};
  transform: ${(props) => `${props.position === 'left' ? 'none' : 'scale(-1,1)'}`};
  z-index: 99;
  pointer-events: none;
`;

const SpriteGrey = styled.div`
  height: 56px;
  width: 50px;
  position: absolute;
  background: ${(props) => props.theme.colors.lightgrey};
  margin-left: -32px;
  z-index: 30;
  pointer-events: none;
`;

const SpriteLong = styled.div`
  height: 20px;
  width: auto;
  position: relative;
  border-radius: 0 16px 0 0;
  background: ${(props) => props.theme.colors.white};
  z-index: 99;
`;

const TabButton = styled.button<{ active: boolean; position: TabPosition; marginLeft: number }>`
  padding: 17px 34px 15px 34px;
  height: 56px;
  margin-left: ${(props) => `${props.marginLeft}px`};
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.black)};
  background-color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.lightgrey)};
  line-height: 24px;
  border: none;
  border-right: ${(props) => (props.position === 'left' ? `1px solid ${props.theme.colors.grey}` : 'none')};
  border-left: ${(props) => (props.position === 'right' ? `1px solid ${props.theme.colors.grey}` : 'none')};
  border-radius: ${(props) => {
    if (!props.active) {
      if (props.position === 'left') return '16px 0 0 0';
      if (props.position === 'right') return '0 16px 0 0';
      return '0';
    }
    return '16px 16px 0 0';
  }};

  cursor: pointer;
  z-index: 20;

  &:after {
    content: '';
    margin-top: 11px;
    height: 4px;
    background: ${(props) => props.theme.colors.ngGradient};
    border-radius: 50px;
    display: block;
    opacity: ${(props) => (props.active ? '1' : '0')};
  }
`;

const TabButtonWrapper = styled.div`
  display: flex;
`;

const TabContentContainer = styled.div`
  flex-grow: 1;
`;

const TabContent = styled.div`
  width: 100%;
  margin-top: -20px;
  background: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  border-color: ${(props) => props.theme.colors.white};
  border-radius: 0px 16px 16px 16px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  padding: 0px 30px 30px 30px;
  box-shadow: 0 0 20px 10px rgba(63, 63, 63, 0.1);
`;

const Header = styled.div`
  display: flex;
  margin: 35px 0;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const BtnIcon = styled(ButtonIcon)`
  padding-left: 20px;
  padding-right: 20px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Styled = {
  Container,
  Corner,
  TabBlock,
  TabButton,
  TabButtonWrapper,
  SpriteWhite,
  SpriteGrey,
  SpriteLong,
  TabContentContainer,
  TabContent,
  Header,
  BtnIcon,
};
