import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { NgGraceNodeLayerModel } from '../../../insides/layer/NgGraceNodeLayerModel';
import { NgGraceNodeLayerWidget } from '../../../insides/layer/NgGraceNodeLayerWidget';
import * as React from 'react';
import { SsdNodeLayerModel } from './SsdNodeLayerModel';
import { SsdEngine } from '../../SsdEngine';

export class SsdNodeLayerFactory extends AbstractReactFactory<SsdNodeLayerModel, SsdEngine> {
  constructor() {
    super('diagram-nodes');
  }

  generateModel(event: GenerateModelEvent): SsdNodeLayerModel {
    return new SsdNodeLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<NgGraceNodeLayerModel>): JSX.Element {
    return <NgGraceNodeLayerWidget layer={event.model} engine={this.engine} />;
  }
}
