import { BasePoint, ClockwiseRotatedPoint } from '../../geometry/Point';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { Node } from '../node/Node';
import { isDirectoryNodeModel } from '../../NgGraceModel';
import { DirectoryPortModel } from '../../directory/DirectoryPortModel';
import { RotatableNodeModel } from '../../generics/RotatableNodeModel';

export class DisposedPortCenterPoint extends BasePoint {
  constructor(portModel: ConnectablePortModel, node: Node) {
    const nodeModel = portModel.getParent();
    let portRelativeCenter;
    if (isDirectoryNodeModel(nodeModel)) {
      const portCenterRelativeToNodeSize = (portModel as DirectoryPortModel).getRelativeCenter();
      const nodeHalfSize = new BasePoint(nodeModel.getSize().x / 2, nodeModel.getSize().y / 2);
      portRelativeCenter = new BasePoint(
        portCenterRelativeToNodeSize.x - nodeHalfSize.x,
        portCenterRelativeToNodeSize.y - nodeHalfSize.y
      );
    } else {
      const nodeCenter = (portModel.getParent() as RotatableNodeModel).getCenter();
      const portCenter = portModel.getCenter();
      portRelativeCenter = new BasePoint(portCenter.x - nodeCenter.x, portCenter.y - nodeCenter.y);
    }

    const disposedNodeCenter = node.getRect().getOrigin();
    const disposedNodeHour = node.getHour();
    const rotatedPortCenter = new ClockwiseRotatedPoint(portRelativeCenter, disposedNodeHour, new BasePoint(0, 0));

    rotatedPortCenter.translate(disposedNodeCenter.x, disposedNodeCenter.y);
    super(rotatedPortCenter);
  }
}
