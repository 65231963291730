import { HasSize } from '../../placeholder/HasSize';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasChildren } from '../../placeholder/HasChildren';
import { BasePoint } from '../../geometry/Point';

import { LanNodeModel } from '../node/LanNodeModel';
import { HasRelativeModel } from '../../placeholder/HasRelativeModel';

export const TopRackPadding = 30;
export const RackPadding = 80;

export class RackHasSize implements HasSize {
  private readonly hasChildren: HasChildren<LanNodeModel>;
  private readonly startOffsetX: number;
  private readonly origin: HasSize;

  constructor(
    origin: HasSize,
    eventDelegate: BaseObserver,
    hasChildren: HasChildren<LanNodeModel>,
    hasRelativeModel: HasRelativeModel<BaseObserver>,
    startOffsetX: number
  ) {
    this.origin = origin;
    this.hasChildren = hasChildren;
    this.startOffsetX = startOffsetX;
  }

  getSize(): BasePoint {
    return this.origin.getSize();
  }
}
