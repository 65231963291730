import React, { useCallback, useState } from 'react';
import { Styled as S } from './FunctionLib.styled';
import { Tab, Tabs } from '../../../widgets/Tabs';
import { LogicalNodeList } from './logicalnode/LogicalNodeList';
import { hasLibraryEditAccess } from '../../../../utils/role-utils';
import { Icon } from '../../../widgets/Icon';
import { useUser } from '../../login/UserContext';
import { LogicalDeviceList } from './logicaldevice/LogicalDeviceList';

type FunctionTabType = 'ld' | 'ln';

export interface FunctionName {
  short: string;
  full: string;
}

const tabs: Tab<FunctionTabType>[] = [
  { id: 'ln', name: 'Logical Nodes' },
  { id: 'ld', name: 'Logical Devices' },
];

export const FunctionsLib: React.FC = () => {
  const user = useUser()!.user!;

  const [activeTab, setActiveTab] = useState('ln' as FunctionTabType);

  const [ldCreationVisibility, setLdCreationVisibility] = useState(false);

  const showLdCreation = useCallback(() => {
    setLdCreationVisibility(true);
  }, []);

  const hideLdCreation = useCallback(() => {
    setLdCreationVisibility(false);
  }, []);

  return (
    <>
      <S.Header>
        <Tabs<FunctionTabType> activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
        {activeTab === 'ld' && hasLibraryEditAccess(user) && (
          <S.BtnIcon onClick={showLdCreation}>
            <Icon name={'plus'} />
            <span>Add new</span>
          </S.BtnIcon>
        )}
      </S.Header>
      {activeTab === 'ld' && (
        <LogicalDeviceList
          logicalDeviceCreationVisibility={ldCreationVisibility}
          hideLogicalDeviceCreation={hideLdCreation}
        />
      )}
      {activeTab === 'ln' && <LogicalNodeList />}
    </>
  );
};
