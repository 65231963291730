import styled from 'styled-components';
import { Styled as S } from '../Table.styled';

const Grid = styled(S.Grid)`
  grid-template-columns: minmax(0, 2fr) repeat(4, minmax(0, 1fr)) 76px 118px;
`;

export const Styled = {
  Grid,
  CompanyList: S.Content,
};
