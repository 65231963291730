import styled from 'styled-components';
import { Btn } from './Btn.styled';
import { Icon } from './Icon.styled';

const Background = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border-radius: 100px;

  ${Btn} {
    padding: 10px 16px;
    height: 36px;
    border: none;

    text-transform: none !important;

    color: ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const SecondaryButton = styled.div<{ border?: boolean }>`
  position: relative;
  border-radius: 100px;
  padding: 2px;
  background: ${(props) => (props.border ? props.theme.colors.blue : 'none')};

  &:hover {
    background: ${(props) => (props.border ? props.theme.colors.ngGradient : 'none')};

    ${Background} {
      ${Btn} {
        background: -webkit-linear-gradient(0deg, #0cbaa1 0%, #103c8b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        ${Icon} {
          & use {
            fill: url(#linear-gradient);
          }
        }
      }
    }
  }
`;

export const Styled = {
  Background,
  Button: Btn,
  SecondaryButton,
};
