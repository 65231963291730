import { GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { LinkLayerFactory } from '@projectstorm/react-diagrams-core';
import { LinkLayerModel } from '@projectstorm/react-diagrams';
import { DefaultLinkLayerModel } from './DefaultLinkLayerModel';

export class DefaultLinkLayerFactory extends LinkLayerFactory {
  generateModel(event: GenerateModelEvent): LinkLayerModel {
    return new DefaultLinkLayerModel();
  }
}
