import styled from 'styled-components';
import { Styled as StyledToolbar } from '../../toolbar/Toolbar.styled';
import SimpleBar from 'simplebar-react';

const TcoPage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${StyledToolbar.Toolbar} {
    box-shadow: none;
  }
`;

const Scroll = styled(SimpleBar)`
  display: block;
  width: 100%;
  height: ${(props) => `calc(100vh - 2*${props.theme.editor.headerHeight} )`};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;
  padding: 18px 48px;
`;

export const Styled = {
  TcoPage,
  Scroll,
  Content,
};
