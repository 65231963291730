import { useDirectory } from './useDirectory';
import { useMemo } from 'react';
import { getBrickColor } from '../widgets/Brick.styled';

export const useEntryColor = (entry: { code: string }, fixedColor?: string) => {
  const { logicNodeDirectory } = useDirectory();
  return useMemo(() => {
    if (fixedColor) {
      return fixedColor;
    }
    const codes = logicNodeDirectory.getAll().map((entry) => entry.code);
    return getBrickColor(codes, entry.code);
  }, [fixedColor, entry.code, logicNodeDirectory]);
};
