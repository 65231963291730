import { LogicDeviceDirectoryEntry } from '../../directory/PropertiesDirectory';
import { BaseModel, BaseModelGenerics, BaseModelListener, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { LogicDeviceLayerModel } from '../layer/logic-device/LogicDeviceLayerModel';
import { DirectoryLogicNodeModel } from './LogicNodeModel';
import { SsdNodeModel } from '../layer/node/SsdNodeLayerModel';
import { HasChildrenListener } from '../../placeholder/HasChildren';
import { Placeholder, PlaceholderBaseRelativeModel, StagedSerializedPlaceholder } from '../../placeholder/Placeholder';
import { Factory } from '../../../../utils/factory';
import { BasePoint } from '../../geometry/Point';
import { StageType } from '../../../../api/nggrace-back';
import { theme } from '../../../../theme';

export const LogicDeviceModelType = 'logic-device';

export type LogicDeviceListener = BaseModelListener &
  HasChildrenListener<DirectoryLogicNodeModel> & {
    childRectChanged: () => any;
    updateRect: () => void;
  };

export interface LogicDeviceModelGenerics extends BaseModelGenerics {
  PARENT: LogicDeviceLayerModel;
  CHILDREN: DirectoryLogicNodeModel;
  RELATIVE_MODEL: SsdNodeModel;
  LISTENERS: LogicDeviceListener;
}

export class DirectoryLogicDeviceModel<MODEL extends PlaceholderBaseRelativeModel = SsdNodeModel>
  extends BaseModel<LogicDeviceModelGenerics>
  implements Placeholder<DirectoryLogicNodeModel, MODEL> {
  private readonly directoryEntry: LogicDeviceDirectoryEntry;
  private readonly placeholder: Placeholder<DirectoryLogicNodeModel, MODEL>;
  private readonly stage: StageType;

  constructor(
    directoryEntry: LogicDeviceDirectoryEntry,
    children: DirectoryLogicNodeModel[],
    placeholderFactory: Factory<DirectoryLogicDeviceModel<MODEL>, Placeholder<DirectoryLogicNodeModel, MODEL>>,
    stage: StageType = 'SSD'
  ) {
    super({ type: LogicDeviceModelType });
    this.directoryEntry = directoryEntry;
    this.stage = stage;
    this.placeholder = placeholderFactory(this);
    children.forEach((child) => this.addChild(child));

    super.registerListener({
      childrenChanged: () => {
        this.getRelativeModel()?.fireEvent({}, 'payloadChanged');
      },
    });
  }

  getChildren() {
    return [...this.placeholder.getChildren()]!;
  }

  getDirectoryId(): string {
    return this.directoryEntry.id;
  }

  getCodeName(): string {
    return this.directoryEntry.code;
  }

  getBrickColor(): string {
    return theme.colors.blue;
  }

  getName(): string {
    return this.directoryEntry.name.en;
  }

  getShortName(): string {
    return this.directoryEntry.shortName.en;
  }

  setRelativeModel(model: MODEL, index?: number) {
    this.placeholder.setRelativeModel(model, index);
  }

  getRelativeModel() {
    return this.placeholder.getRelativeModel();
  }

  addChild(node: DirectoryLogicNodeModel, index?: number) {
    node.setParent(this);
    this.placeholder.addChild(node, index);
  }

  getRect() {
    return this.placeholder.getRect();
  }

  serialize() {
    const baseData = {
      ...super.serialize(),
      directoryId: this.getDirectoryId(),
      relativeModelId: this.getRelativeModel()!.getID(),
      nodes: this.getChildren()?.map((child) => child.serialize()),
      raw: false,
    };

    const stageData: StagedSerializedPlaceholder = {};

    stageData[this.stage] = {
      position: this.getPosition(),
      size: this.getSize(),
    };
    return { ...baseData, ...stageData };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.getModel<MODEL>(event.data.relativeModelId).then((model) => this.setRelativeModel(model));
    event.registerModel(this);
  }

  getSelectionEntities(): Array<BaseModel> {
    return [this, ...this.getChildren()];
  }

  getPosition(): BasePoint {
    return this.placeholder.getPosition();
  }

  getSize(): BasePoint {
    return this.placeholder.getSize();
  }

  setPosition(newValue: BasePoint): void {
    return this.placeholder.setPosition(newValue);
  }
}
