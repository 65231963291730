import { BasePoint } from '../geometry/Point';

export type HasSizeListener = {
  sizeChanged?: (event: { newSize: BasePoint }) => void;
};

export type HasSizedChildrenListener = {
  childSizeChanged?: () => void;
};

export interface HasSize {
  getSize(): BasePoint;
}

export class DefaultHasSize implements HasSize {
  savedSize: BasePoint;

  constructor(savedSize?: BasePoint) {
    this.savedSize = savedSize || new BasePoint(0, 0);
  }

  getSize(): BasePoint {
    return this.savedSize;
  }
}