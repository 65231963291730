import { Busses } from './Busses';
import { DefaultDisposed, Disposed, Graph } from '../Graph';
import { Link } from '../../Link';
import { Node } from '../../node/Node';
import { Rectangle } from '@projectstorm/geometry';
import { Mapping, PathMapping } from '../../mapping/Mapping';
import { NgGraceModel } from '../../../NgGraceModel';
import { BusNodeModel } from '../../../bus/BusNodeModel';
import { PropertiesDirectory, VoltageLevelDirectoryEntry } from '../../../directory/PropertiesDirectory';

const FirstRowVoltageLowThreshold = 35000;

type BusVoltage = { bus: Node; voltage: number };

export class OrderedBussesRows implements Busses {
  private origin: Busses;
  private disposed: Disposed;
  private busTrsMapping: Mapping<Node, Graph>;
  private model: NgGraceModel;
  private highVoltageComparator: HighVoltageComparator;
  private lowVoltageComparator: LowVoltageComparator;
  private voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
  private pathMapping: PathMapping;

  constructor(
    busses: Busses,
    busTrsMapping: Mapping<Node, Graph>,
    model: NgGraceModel,
    voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>,
    pathMapping: PathMapping
  ) {
    this.origin = busses;
    this.busTrsMapping = busTrsMapping;
    this.model = model;
    this.pathMapping = pathMapping;
    this.disposed = new DefaultDisposed(this);
    this.highVoltageComparator = new HighVoltageComparator(this.busTrsMapping);
    this.lowVoltageComparator = new LowVoltageComparator();
    this.voltageLevelDirectory = voltageLevelDirectory;
  }

  getBusRows(): Node[][] {
    const busses = this.origin.getNodes();

    const busVoltages: BusVoltage[] = busses.map((bus) => {
      const busModel = this.model.getNode(bus.getID()) as BusNodeModel;
      const voltageLevel = this.voltageLevelDirectory.getEntry(busModel.getPayload().voltageLevel!);

      if (!voltageLevel) {
        throw Error(`Invalid bus voltage level: ${busModel.getPayload().voltageLevel}`);
      }

      return { bus, voltage: voltageLevel.voltageInKilovolts };
    });

    let voltageBusses = new Map<number, Node[]>();

    busVoltages.forEach(({ bus, voltage }) => {
      const entry = voltageBusses.get(voltage);
      return entry ? entry.push(bus) : voltageBusses.set(voltage, [bus]);
    });

    voltageBusses = new Map(Array.from(voltageBusses.entries()).sort().reverse());
    const highVoltageBusses = busVoltages
      .filter(({ voltage }) => voltage >= FirstRowVoltageLowThreshold)
      .sort((a, b) => this.highVoltageComparator.compare(a, b));

    const bussesWithConnected = busses.map((bus) => {
      const busTrs = this.busTrsMapping.map(bus);
      const connectedBusses = busses
        .filter((otherBus) => bus.getID() !== otherBus.getID())
        .filter((otherBus) =>
          busTrs.getNodes().find((tr) => this.pathMapping.map({ start: otherBus, end: tr }).getNodes().length)
        );

      return { bus, connectedBusses };
    });

    const lowVoltageBusses = busVoltages
      .filter(({ voltage }) => voltage < FirstRowVoltageLowThreshold)
      .map((busToFindConnectedWith) => {
        const result = new Map<string, number>();
        bussesWithConnected.forEach((busWithConnected) => {
          const bus = busWithConnected.bus;
          const connectedBusses = busWithConnected.connectedBusses;
          if (bus.getID() === busToFindConnectedWith.bus.getID()) {
            connectedBusses.forEach((connected) => {
              const index = highVoltageBusses.findIndex((b) => b.bus.getID() === connected.getID());
              if (index !== -1) {
                result.set(connected.getID(), index);
              }
            });
          } else if (connectedBusses.find((connected) => connected.getID() === busToFindConnectedWith.bus.getID())) {
            const index = highVoltageBusses.findIndex((b) => b.bus.getID() === bus.getID());
            if (index !== -1) {
              result.set(bus.getID(), index);
            }
          }
        });
        const indexes = Array.from(result.values());
        if (!indexes.length) {
          return { ...busToFindConnectedWith, rank: Number.MAX_VALUE };
        }
        return { ...busToFindConnectedWith, rank: indexes.reduce((prev, cur) => prev + cur, 0) / indexes.length };
      })
      .sort((a, b) => this.lowVoltageComparator.compare(a, b));

    return [highVoltageBusses.map((b) => b.bus), lowVoltageBusses.map((b) => b.bus)];
  }

  getLinks(): Link[] {
    return this.origin.getLinks();
  }

  getNodes(): Node[] {
    return this.getBusRows().flat();
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}

class HighVoltageComparator {
  private busTrMapping: Mapping<Node, Graph>;

  constructor(busTrMapping: Mapping<Node, Graph>) {
    this.busTrMapping = busTrMapping;
  }

  compare(a: BusVoltage, b: BusVoltage): number {
    if (a.voltage === b.voltage) {
      const aTrsLength = this.busTrMapping.map(a.bus).getNodes().length;
      const bTrsLength = this.busTrMapping.map(b.bus).getNodes().length;
      return aTrsLength === bTrsLength ? 0 : aTrsLength > bTrsLength ? -1 : 0;
    }

    return a.voltage > b.voltage ? -1 : 1;
  }
}

class LowVoltageComparator {
  compare(a: BusVoltage & { bus: Node; rank: number }, b: BusVoltage & { bus: Node; rank: number }): number {
    if (a.voltage === b.voltage) {
      return a.rank === b.rank ? 0 : a.rank < b.rank ? -1 : 0;
    }

    return a.voltage > b.voltage ? -1 : 1;
  }
}
