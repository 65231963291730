import { Point } from '@projectstorm/geometry';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { ConnectivityPortModel } from './ConnectivityPortModel';

export class RawConnectivityPortModel extends ConnectivityPortModel {
  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    if (event.data.raw) {
      //TODO is there any other way to get alignment?
      this.options.alignment = this.options.name as PortModelAlignment;
      this.position = new Point(0, 0);
    }
  }
}
