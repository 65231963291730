import React from 'react';
import { Styled as S } from './AcceptableInput.styled';

interface AcceptableInputProps {
  handleConfirm: () => void;
  handleCancel: () => void;
}

export const AcceptableInput: React.FC<AcceptableInputProps> = ({ handleConfirm, handleCancel }) => {
  return (
    <S.AcceptableInput>
      <span>Sure?</span>
      <span onClick={handleConfirm}>Yes</span>
      <span onClick={handleCancel}>No</span>
    </S.AcceptableInput>
  );
};
