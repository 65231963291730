import styled from 'styled-components';
import { Styled as StyledInputField } from '../../widgets/InputField.styled';

const OpexForm = styled.form`
  display: flex;
  gap: 57px;
  color: #3a3a3a;
  padding-bottom: 70px;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 507px;
  justify-content: space-between;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 507px;
  justify-content: space-between;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledInputField.InputField} {
    width: 507px;
    margin-bottom: 16px;
  }
`;

const ExchangeRates = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckBoxUnit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;

  input[type='checkbox'] {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const ExchangeRateUnits = styled.div``;

const ExchangeRateUnit = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledInputField.InputField} {
    width: 49%;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

const UnitLegend = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
`;

export const Styled = {
  OpexForm,
  LeftSide,
  RightSide,
  InputFields,
  ExchangeRates,
  CheckBoxUnit,
  ExchangeRateUnits,
  ExchangeRateUnit,
  Description,
  UnitLegend,
};
