import { Action, ActionEvent, BaseModel, InputType, State } from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { LanEngine } from '../LanEngine';
import { DragCanvasState } from '../../states/DragCanvasState';
import { isPortModel, isSmartLinkModel } from '../../NgGraceModel';
import { LanCreateLinkState } from './LanCreateLinkState';

export class LanDefaultState extends State<LanEngine> {
  static readonly Name = 'starting-state';
  dragCanvas: DragCanvasState;
  createLink: LanCreateLinkState;

  constructor(notifyError: (message: string, title?: string) => void) {
    super({ name: LanDefaultState.Name });
    this.childStates = [];
    this.dragCanvas = new DragCanvasState();
    this.createLink = new LanCreateLinkState(notifyError, { allowLooseLinks: false });

    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const model: BaseModel | null = this.engine
            .getActionEventBus()
            .getModelForEvent(event as ActionEvent<MouseEvent>);

          if (isPortModel(model)) {
            this.transitionWithEvent(this.createLink, event);
            return;
          }

          if (isSmartLinkModel(model)) {
            this.engine.getModel().clearSelection();
            model.setSelected(true);
            return;
          }

          this.transitionWithEvent(this.dragCanvas, event);
          return;
        },
      })
    );
  }
}
