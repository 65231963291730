import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { CardType } from '../../../../hardware/card/HardwareCard';
import {
  Direction,
  LnTemplateDto,
  LogicalNodeTypeSortDto,
  LogicalNodeTypeSortProperty,
  LogicalNodeTypeViewDto,
  SecondStageStateDto,
  UserBaseDto,
} from '../../../../../../../api/nggrace-back';
import { Styled as S } from './SecondStageCardContent.styled';
import { Api } from '../../../../../../../api/Api';
import { List } from '../../../../../../widgets/List.styled';
import { ListWithoutMargin } from '../../../../ListWithoutMargin.styled';
import { InputSelect, OptionType } from '../../../../../../widgets/InputSelect';
import { formatMomentShort } from '../../../../../../../utils/date-utils';
import { Icon } from '../../../../../../widgets/Icon';
import { SelectableTreeNode } from '../../../../../../widgets/tree/state/TreeState';
import { SortCellWithoutMargin as SortCell } from '../../../../SortCellWithoutMargin';
import { LnCompositionTree } from './lntypetree/LnCompositionTreeLnTemplate';
import { lnTemplateFactory, LnTypeTree } from './lntypetree/LnTypeTree';
import { LogicalNodeTreeModel, LogicalNodeTreeNodeModel } from './lntypetree/LnComposition';
import { LnCompositionTreeDataObjectFactory } from './lntypetree/LnCompositionTreeDataObject';
import { LnCompositionTreeDataAttributeFactory } from './lntypetree/LnCompositionTreeDataAttribute';
import { CardTypeContext } from '../../LogicalDeviceList';
import { LogicalDeviceStateContext } from '../../LogicalDeviceCard';

export interface LogicalNodeTypeViewDtoConverted extends LogicalNodeTypeViewDto {
  count: number;
}

interface SecondStageCardContentProps {
  lnComposition: LogicalNodeTreeModel[];
  handleOnLogicalDeviceSecondStageChange: (
    lnComposition: LogicalNodeTreeModel[],
    secondStageStateDtoChanged: SecondStageStateDto
  ) => void;
}

interface LogicalNodeRowProps {
  cardType: CardType;
  logicalNode: LogicalNodeTypeViewDtoConverted;
  remove: (logicalNodeId: number, counter: string) => void;
}

const formatLnType = (lnType: string) => {
  const maxLength = 26;
  return lnType.length > maxLength ? `${lnType?.substring(0, maxLength)}...` : lnType;
};

export const SecondStageCardContent: React.FC<SecondStageCardContentProps> = ({
  lnComposition: tree,
  handleOnLogicalDeviceSecondStageChange,
}) => {
  const cardType = useContext(CardTypeContext);
  const { vendor } = useContext(LogicalDeviceStateContext);
  const [iedList, setIedList] = useState<string[]>([]);
  const [lnComposition, setLnComposition] = useState<LogicalNodeTreeModel[]>(tree);
  const [logicalNodeState, setLogicalNodeState] = useState<{
    logicalNodes: LogicalNodeTypeViewDtoConverted[];
    hasMore: boolean;
    page: number;
    size: number;
    sortProperty: LogicalNodeTypeSortProperty;
    sortDirection: Direction;
    editorId?: number;
    editors: UserBaseDto[];
    classId?: string;
    classes: OptionType[];
  }>({
    logicalNodes: [],
    hasMore: true,
    page: 0,
    size: 20,
    editors: [],
    classes: [],
    sortProperty: 'TYPE',
    sortDirection: 'ASC',
  });

  useEffect(() => {
    let cleanupFunction = false;
    Api.getLogicalNodeTypeFilterOptions().then((response) => {
      if (!cleanupFunction)
        setLogicalNodeState((state) => ({
          ...state,
          editors: response.data.editors,
          classes: response.data.classes.map((item) => ({ id: item, name: item })),
        }));
    });
    return () => {
      cleanupFunction = true;
    };
  }, []);

  useEffect(() => {
    let cleanupFunction = false;
    if (lnComposition.length > 0) {
      Api.getAvailableIedList([
        ...new Set(lnComposition.map((treeItem) => String(treeItem.getLogicalNodeTypeId()))),
      ]).then((iedList) => {
        if (!cleanupFunction) setIedList(iedList.data);
      });
    }
    return () => {
      cleanupFunction = true;
    };
  }, [lnComposition]);

  useEffect(() => {
    let cleanupFunction = false;
    if (vendor && vendor.id) {
      Api.getLogicalNodeTypesByVendor(vendor.id).then((response) => {
        if (!cleanupFunction)
          setLogicalNodeState((state) => ({
            ...state,
            logicalNodes: response.data.map((item) => ({ ...item, count: 0 })),
          }));
      });
    }
    return () => {
      cleanupFunction = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor?.id]);

  useEffect(() => {
    setLnComposition(tree);
  }, [tree]);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: LogicalNodeTypeSortDto = {
        editorId: logicalNodeState.editorId,
        clazz: logicalNodeState.classId,
        property: logicalNodeState.sortProperty,
        direction: logicalNodeState.sortDirection,
      };
      Api.getLogicalNodeTypes(sort, { page, size }).then((response) => {
        setLogicalNodeState((state) => ({
          ...state,
          logicalNodes: reload
            ? response.data.content?.map((item) => ({
                ...item,
                count: 0,
              })) || []
            : state.logicalNodes.concat(
                response.data.content?.map((item) => ({
                  ...item,
                  count: 0,
                })) || []
              ),
          hasMore: !response.data.last,
        }));
      });
    },
    [logicalNodeState.editorId, logicalNodeState.sortDirection, logicalNodeState.sortProperty, logicalNodeState.classId]
  );

  const reload = useCallback(() => {
    setLogicalNodeState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, logicalNodeState.size, true);
  }, [fetch, logicalNodeState.size]);

  useEffect(reload, [reload]);

  const handleClassesChange = (classId: string) => {
    setLogicalNodeState((state) => ({ ...state, classId }));
  };

  const handleSort = useCallback((property: LogicalNodeTypeSortProperty, direction: Direction) => {
    setLogicalNodeState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const direction = (property: LogicalNodeTypeSortProperty) => {
    return logicalNodeState.sortProperty === property ? logicalNodeState.sortDirection : undefined;
  };

  const handleIconAddClick = (logicalNodeId: number, counter: string) => {
    const logicalNodes =
      counter === 'add'
        ? logicalNodeState.logicalNodes.map((logicalNode) =>
            logicalNode.id === logicalNodeId ? { ...logicalNode, count: logicalNode.count + 1 } : logicalNode
          )
        : logicalNodeState.logicalNodes.map((logicalNode) =>
            logicalNode.id === logicalNodeId
              ? { ...logicalNode, count: logicalNode.count > 1 ? logicalNode.count - 1 : 0 }
              : logicalNode
          );
    setLogicalNodeState((state) => ({
      ...state,
      logicalNodes: logicalNodes,
    }));
  };

  const handleCreateLogicalNodesTreeClick = () => {
    let logicalNodesTree: LogicalNodeTreeModel[] = [];
    const logicalNodesWithoutNullableCount = logicalNodeState.logicalNodes.filter((ln) => ln.count > 0);
    logicalNodesWithoutNullableCount.forEach((logicalNode) => {
      const logicalNodeTemplates = logicalNode.icdTemplatesDto?.logicalNodeTemplates;
      const existedTree = lnComposition.filter((tree) =>
        logicalNodeTemplates?.some((lnTemplate: LnTemplateDto) => lnTemplate.type === tree.getType())
      );
      const count = existedTree ? logicalNode.count + existedTree.length : logicalNode.count;
      for (let i = existedTree ? existedTree.length + 1 : 1; i <= count; i++) {
        logicalNodesTree.push(
          ...(logicalNodeTemplates?.map<LogicalNodeTreeModel>((lnTemplate: LnTemplateDto) =>
            LnCompositionTree(
              lnTemplate,
              i,
              logicalNode.id,
              LnCompositionTreeDataObjectFactory(
                logicalNode.icdTemplatesDto?.dataObjectTemplates!,
                LnCompositionTreeDataAttributeFactory()
              )
            )
          ) as LogicalNodeTreeModel[])
        );
      }
      logicalNode.count = 0;
    });
    if (logicalNodesWithoutNullableCount.length > 0) {
      const logicalNodeCompositionTree = [...lnComposition, ...logicalNodesTree];
      const logicalNodesCreateDto = logicalNodeCompositionTree.map((treeItem) => ({
        instance: treeItem.getInstance(),
        logicalNodeTypeId: treeItem.getLogicalNodeTypeId(),
      }));
      handleOnLogicalDeviceSecondStageChange(
        _.sortBy(logicalNodeCompositionTree, (tree) => tree.getClazz()),
        { logicalNodes: logicalNodesCreateDto }
      );
    }
  };

  const handleIconDelClick = (model: LogicalNodeTreeNodeModel & SelectableTreeNode) => () => {
    const max = lnComposition
      .filter((treeItem) => treeItem.getType() === model.getType())
      .reduce((prev, current) => (prev.getInstance() > current.getInstance() ? prev : current));
    const newLnComposition = lnComposition.filter((model) => model.getKey() !== max.getKey());
    const logicalNodesCreateDto = newLnComposition.map((treeItem) => ({
      instance: treeItem.getInstance(),
      logicalNodeTypeId: treeItem.getLogicalNodeTypeId(),
    }));
    if (logicalNodesCreateDto) {
      handleOnLogicalDeviceSecondStageChange(
        lnComposition.filter((model) => model.getKey() !== max.getKey()),
        { logicalNodes: logicalNodesCreateDto }
      );
    }
  };

  return (
    <S.Container>
      <span>This LD can be used in the following IEDs: {iedList}</span>
      <S.ColumnContainer>
        <S.Column position={'left'}>
          <header>
            <h3>{vendor?.name} LN Library</h3>
            <S.BtnIcon onClick={handleCreateLogicalNodesTreeClick} disabled={cardType === 'view'}>
              <Icon name={'plus'} />
              <span>Add to LN List</span>
            </S.BtnIcon>
          </header>
          <S.LogicalDeviceGrid id="scrollableTarget">
            <S.HeaderRow>
              <SortCell<LogicalNodeTypeSortProperty> type={'TYPE'} direction={direction('TYPE')} onSort={handleSort}>
                LN Type
              </SortCell>
              <ListWithoutMargin.HeaderCell>Description</ListWithoutMargin.HeaderCell>
              <ListWithoutMargin.FilterCell>
                <InputSelect
                  filter
                  label={'Class'}
                  hideLabel
                  hideBorder
                  selected={logicalNodeState.classId}
                  options={logicalNodeState.classes}
                  onChange={handleClassesChange}
                />
              </ListWithoutMargin.FilterCell>
              <ListWithoutMargin.HeaderCell>Updated</ListWithoutMargin.HeaderCell>
              <ListWithoutMargin.HeaderCell center>Add</ListWithoutMargin.HeaderCell>
            </S.HeaderRow>
            {logicalNodeState.logicalNodes.map((logicalNode) => (
              <LogicalNodeRow
                key={logicalNode.id}
                cardType={cardType}
                logicalNode={logicalNode}
                remove={handleIconAddClick}
              />
            ))}
          </S.LogicalDeviceGrid>
        </S.Column>
        <S.Column position={'right'}>
          <header>
            <h3>LN List</h3>
          </header>
          <S.TreeContainer>
            <S.TreeHeaderRow>
              <ListWithoutMargin.HeaderCell>LN Type</ListWithoutMargin.HeaderCell>
              <ListWithoutMargin.HeaderCell>Del</ListWithoutMargin.HeaderCell>
            </S.TreeHeaderRow>
            <S.TreeContent>
              {lnComposition.map((model: LogicalNodeTreeNodeModel & SelectableTreeNode) => (
                <LnTypeTree
                  key={model.getKey()}
                  model={model}
                  childFactory={lnTemplateFactory}
                  hasParent={false}
                  indentLevel={0}
                  last
                  initialOpen={false}
                  showInLd
                  handleDelClick={handleIconDelClick(model)}
                />
              ))}
            </S.TreeContent>
          </S.TreeContainer>
        </S.Column>
      </S.ColumnContainer>
    </S.Container>
  );
};

const LogicalNodeRow: React.FC<LogicalNodeRowProps> = ({ cardType, logicalNode, remove: handleIconAddClick }) => {
  return (
    <>
      <S.Cell>{formatLnType(logicalNode.type)}</S.Cell>
      <S.Cell>{logicalNode.description}</S.Cell>
      <S.Cell>{logicalNode.clazz}</S.Cell>
      <S.Cell>{formatMomentShort(logicalNode.editedAt)}</S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        <List.Button disabled={cardType === 'view'} onClick={() => handleIconAddClick(logicalNode.id, 'minus')}>
          <Icon name={'minus'} />
        </List.Button>
        <span>{logicalNode.count}</span>
        <List.Button disabled={cardType === 'view'} onClick={() => handleIconAddClick(logicalNode.id, 'add')}>
          <Icon name={'plus'} />
        </List.Button>
      </S.Cell>
    </>
  );
};
