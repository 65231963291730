import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toolkit } from '@projectstorm/react-canvas-core';

import { DatasetsStyled as S } from './Datasets.styled';
import { Icon } from '../../../../../../../widgets/Icon';
import { ListWithoutMargin } from '../../../../../ListWithoutMargin.styled';
import { CardType } from '../../../../../hardware/card/HardwareCard';
import { List } from '../../../../../../../widgets/List.styled';
import {
  DataAttributeTemplateDto,
  DataObjectDto,
  DataSetDto,
  DataSetProtocol,
  DataSetType,
  IcdTemplatesDto,
  LnTemplateDto,
  LogicalNodeCreateDto,
  SignalDto,
} from '../../../../../../../../api/nggrace-back';
import { useDirectory } from '../../../../../../../hooks/useDirectory';
import { InputField } from '../../../../../../../widgets/InputField';
import { InputSelect } from '../../../../../../../widgets/InputSelect';
import { Api } from '../../../../../../../../api/Api';
import { LnTypeTree } from './lntype-tree/LnTypeTree';
import { AcceptableInput } from '../widget/AcceptableInput';
import { CardTypeContext } from '../../../LogicalDeviceList';
import { LogicalDeviceStateContext } from '../../../LogicalDeviceCard';

export interface MenuItem<T = any> {
  id?: string;
  title?: string;
  icon?: string;
  iconSize?: number;
  link?: string;
  isRoute?: boolean;
  queryParams?: Record<string, string>;
  expand?: boolean;
  disabled?: boolean;
  active?: boolean;
  selectable?: boolean;
  payload?: T;
  children?: MenuItem[];
}

interface DatasetsProps {}

const formatLnType = (lnType: string | undefined) => {
  const maxLength = 10;
  return lnType && lnType.length > maxLength ? `${lnType?.substring(0, maxLength)}...` : lnType;
};

interface ISignalDto extends SignalDto {
  id: string;
}

interface IDataSet extends DataSetDto {
  id: string;
  active: boolean;
  editable: boolean;
  remove: boolean;
  members: ISignalDto[];
}

export const Datasets: React.FC<DatasetsProps> = () => {
  const cardType = useContext(CardTypeContext);
  const { logicalNodes, vendor, logicalDeviceViewDtoName, thirdStageState, handleThirdStageStateChange } = useContext(
    LogicalDeviceStateContext
  );
  const [lnComposition, setLnComposition] = useState<MenuItem[]>([]);
  const [dataSetsDto, setDataSetsDto] = useState<IDataSet[]>(
    thirdStageState?.dataPackets?.dataSets
      ? thirdStageState.dataPackets?.dataSets.map<IDataSet>((dataSetsDto) => ({
          ...dataSetsDto,
          id: Toolkit.UID(),
          active: false,
          editable: false,
          remove: false,
          members: dataSetsDto.members
            ? dataSetsDto.members?.map<ISignalDto>((signalDto) => ({
                ...signalDto,
                id: Toolkit.UID(),
              }))
            : [],
        }))
      : []
  );
  const prevDataSetsDto = useRef<IDataSet[]>([]);
  const [isEditableState, setIsEditableState] = useState<boolean>(false);
  const [isRemovableState, setIsRemovableState] = useState<boolean>(false);
  const [isAnyActiveNode, setIsAnyActiveNode] = useState<boolean>(false);

  useEffect(() => {
    let cleanupFunction = false;
    if (vendor) {
      Api.getLogicalNodeTypesByVendor(vendor.id).then((response) => {
        const logicalNodesTree: any[] = [];
        logicalNodes.forEach((ln) => {
          const lnTypes = response.data.find((lnType) => lnType.id === ln.logicalNodeTypeId);
          if (lnTypes) {
            const icdTemplatesDto = lnTypes.icdTemplatesDto;
            if (icdTemplatesDto) {
              logicalNodesTree.push(...createTreeMenu(icdTemplatesDto, ln));
            }
          }
        });
        if (!cleanupFunction) setLnComposition(logicalNodesTree);
      });
      if (!cleanupFunction)
        setDataSetsDto(
          thirdStageState?.dataPackets?.dataSets
            ? thirdStageState.dataPackets?.dataSets.map<IDataSet>((dataSetsDto) => ({
                ...dataSetsDto,
                id: Toolkit.UID(),
                active: false,
                editable: false,
                remove: false,
                members: dataSetsDto.members
                  ? dataSetsDto.members?.map<ISignalDto>((signalDto) => ({
                      ...signalDto,
                      id: Toolkit.UID(),
                    }))
                  : [],
              }))
            : []
        );
    }
    return () => {
      cleanupFunction = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor?.id]);

  useEffect(() => {
    prevDataSetsDto.current = dataSetsDto;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetsDto.length]);

  const createTreeMenu = (icdTemplatesDto: IcdTemplatesDto, ln: LogicalNodeCreateDto) => {
    return icdTemplatesDto.logicalNodeTemplates.map<MenuItem>((lnTemplate: LnTemplateDto) => ({
      id: String(ln.logicalNodeTypeId),
      title: lnTemplate.type + ln.instance,
      expand: false,
      selectable: false,
      active: false,
      children: lnTemplate.dataObjects
        .filter((dataObject: DataObjectDto) =>
          Object.keys(icdTemplatesDto.dataObjectTemplates).some((key: string) => key === dataObject.type)
        )
        .map<MenuItem>((dataObject: DataObjectDto) => ({
          id: String(dataObject.type + dataObject.name + ln.instance),
          title: dataObject.name,
          expand: false,
          selectable: true,
          active: false,
          children: icdTemplatesDto.dataObjectTemplates[dataObject.type].dataAttributes.map<MenuItem>(
            (da: DataAttributeTemplateDto) => ({
              id: String(da.type + da.name + ln.instance),
              title: da.name,
              expand: false,
              selectable: true,
              active: false,
            })
          ),
        })),
    }));
  };

  const handleOnDatasetListChange = (dataSetDto: IDataSet) => {
    const newDataSetsDto = dataSetsDto.map((dataSet) => {
      if (dataSet.id === dataSetDto.id) {
        setIsEditableState(dataSetDto.editable);
        setIsRemovableState(dataSetDto.remove);
        if (dataSetDto.editable || dataSetDto.remove) {
          dataSetDto.active = false;
        }
        return dataSetDto;
      } else return { ...dataSet, active: false };
    });
    setDataSetsDto(newDataSetsDto);
  };

  const createDatasetList = () => {
    setDataSetsDto((prevState) => {
      const newDataSetsDto = [
        ...prevState,
        {
          id: Toolkit.UID(),
          name: `Dataset ${prevState.length}`,
          active: false,
          members: [],
          editable: false,
          remove: false,
        },
      ];
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          dataSets: newDataSetsDto,
        },
      });
      return newDataSetsDto;
    });
  };

  const handleOnDatasetListDelete = (id: string) => {
    setDataSetsDto((prevState) => {
      const newDataSetsDto = prevState.filter((dataset) => dataset.id !== id);
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          dataSets: newDataSetsDto,
        },
      });

      return newDataSetsDto;
    });
    setIsRemovableState(false);
  };

  const handleOnSignalDtoDelete = (id: string) => {
    setDataSetsDto((prevState) => {
      return prevState.map((dataset: IDataSet) => {
        return dataset.editable
          ? { ...dataset, members: dataset.members.filter((signalDto) => signalDto.id !== id) }
          : dataset;
      });
    });
  };

  const createSignalDtoList = () => {
    const newMembers: ISignalDto[] = [];
    lnComposition.forEach((ln) =>
      ln.children?.forEach((dataObject) =>
        dataObject.children
          ?.filter((da) => da.active)
          .forEach((da) =>
            newMembers.push({
              daBType: '',
              daName: da.title,
              daType: '',
              doName: dataObject.title,
              doType: '',
              fc: '',
              ldName: logicalDeviceViewDtoName,
              lnType: ln.title,
              tagIds: [],
              id: Toolkit.UID(),
            })
          )
      )
    );
    setDataSetsDto((prevDataSet) =>
      prevDataSet.map((dataset: IDataSet) => {
        if (dataset.editable) {
          dataset.members = Array.from(
            new Map<string, ISignalDto>(
              [...dataset.members, ...newMembers].map((val) => [val.lnType! + val.doName! + val.daName, val])
            ).values()
          );
          return dataset;
        } else return dataset;
      })
    );
  };

  const onTreeSelectChange = (isAnyActiveNode: boolean) => {
    setIsAnyActiveNode(isAnyActiveNode);
  };

  const handleEditCancel = () => {
    setDataSetsDto(
      prevDataSetsDto.current.map((dataSetsDto) =>
        dataSetsDto.editable ? { ...dataSetsDto, editable: false } : dataSetsDto
      )
    );
    setIsEditableState(false);
  };

  const handleEditConfirm = () => {
    prevDataSetsDto.current = dataSetsDto;
    setDataSetsDto((prevDataSetsDto) => {
      const newDataSetsDto = prevDataSetsDto.map((dataSetsDto) =>
        dataSetsDto.editable
          ? {
              ...dataSetsDto,
              editable: false,
            }
          : dataSetsDto
      );
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          dataSets: newDataSetsDto,
        },
      });

      return newDataSetsDto;
    });
    setIsEditableState(false);
  };

  return (
    <S.Container>
      <S.Column position={'left'}>
        <header>
          <h3>DataSet List</h3>
          <S.BtnIcon onClick={createDatasetList} disabled={cardType === 'view'}>
            <Icon name={'plus'} />
            <span>New</span>
          </S.BtnIcon>
        </header>
        <S.DatasetListGrid id="scrollableTarget">
          <S.HeaderRow>
            <ListWithoutMargin.HeaderCell>Name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>Type</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>Protocol</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Del</ListWithoutMargin.HeaderCell>
          </S.HeaderRow>
          {dataSetsDto.map((dataSetDto) => (
            <DatasetListRow
              key={dataSetDto.id}
              cardType={cardType}
              isEditableState={isEditableState}
              isRemovableState={isRemovableState}
              dataSetDto={dataSetDto}
              handleEditCancel={handleEditCancel}
              handleEditConfirm={handleEditConfirm}
              handleOnChange={handleOnDatasetListChange}
              remove={handleOnDatasetListDelete}
            />
          ))}
        </S.DatasetListGrid>
      </S.Column>
      <S.Column position={'right'}>
        <header>
          <h3>LN List</h3>
          <S.BtnIconLnList
            onClick={createSignalDtoList}
            disabled={cardType === 'view' || !isEditableState || !isAnyActiveNode}
          >
            <span>Add to DataSet </span>
            <Icon name={'right-arrow'} />
          </S.BtnIconLnList>
        </header>
        <S.TreeContainer>
          <S.TreeHeaderRow>
            <ListWithoutMargin.HeaderCell>LN Type</ListWithoutMargin.HeaderCell>
          </S.TreeHeaderRow>
          <S.TreeContent>
            {lnComposition &&
              lnComposition.map((model: MenuItem) => (
                <LnTypeTree
                  key={model.id}
                  model={model}
                  hasParent={false}
                  indentLevel={0}
                  last
                  initialOpen={model.expand}
                  onChange={onTreeSelectChange}
                />
              ))}
          </S.TreeContent>
        </S.TreeContainer>
      </S.Column>
      <S.Column position={'right'}>
        <header>
          <h3>DataSet</h3>
          <h3>{dataSetsDto.find((dataset) => dataset.editable || dataset.active)?.name}</h3>
        </header>
        <S.DatasetGrid id="scrollableTarget">
          <S.HeaderRow>
            <ListWithoutMargin.HeaderCell>LD name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>LN name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>DO name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>DA name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>FC</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>Del</ListWithoutMargin.HeaderCell>
          </S.HeaderRow>
          {dataSetsDto
            .find((dataset: IDataSet) => dataset.editable || dataset.active)
            ?.members.map((signalDto) => (
              <DatasetRow
                key={signalDto.id}
                cardType={cardType}
                signalDto={signalDto}
                isEditableState={isEditableState}
                remove={handleOnSignalDtoDelete}
              />
            ))}
        </S.DatasetGrid>
      </S.Column>
    </S.Container>
  );
};

interface DatasetListRowProps {
  cardType: CardType;
  dataSetDto: IDataSet;
  isEditableState: boolean;
  isRemovableState: boolean;
  handleOnChange: (dataSetDto: IDataSet) => void;
  handleEditCancel: () => void;
  handleEditConfirm: () => void;
  remove: (id: string) => void;
}

const DatasetListRow: React.FC<DatasetListRowProps> = ({
  cardType,
  dataSetDto,
  handleOnChange,
  isEditableState,
  isRemovableState,
  handleEditCancel,
  handleEditConfirm,
  remove: handleOnDelete,
}) => {
  const { datasetProtocolTypes } = useDirectory();

  const handleNameChange = (name: string) => {
    handleOnChange({ ...dataSetDto, name });
  };

  const handleTypeChange = (type: DataSetType) => {
    handleOnChange({ ...dataSetDto, type });
  };

  const handleProtocolChange = (protocol: DataSetProtocol) => {
    handleOnChange({ ...dataSetDto, protocol });
  };

  const handleActiveChange = (active: boolean) => {
    handleOnChange({ ...dataSetDto, active });
  };

  const handleEditableChange = (editable: boolean) => {
    handleOnChange({ ...dataSetDto, editable });
  };

  const handleRemoveChange = (remove: boolean) => {
    handleOnChange({ ...dataSetDto, remove });
  };

  const handleDelConfirm = () => {
    handleOnDelete(dataSetDto.id);
  };

  const handleDelCancel = () => {
    handleOnChange({ ...dataSetDto, remove: false });
  };

  return (
    <S.ClickedRow
      active={dataSetDto.active}
      editable={dataSetDto.editable}
      remove={dataSetDto.remove}
      onClick={isEditableState || isRemovableState ? () => {} : () => handleActiveChange(true)}
    >
      <S.Cell>
        <InputField type={'text'} onChange={handleNameChange} value={dataSetDto.name} disabled={!dataSetDto.editable} />
      </S.Cell>
      <S.Cell>
        <InputSelect
          onChange={handleTypeChange}
          label={'Type'}
          hideBorder={true}
          hideLabel={true}
          selected={dataSetDto.type}
          options={datasetProtocolTypes.getAll().map((it) => ({ id: it.id, name: it.name.en }))}
          disabled={!dataSetDto.editable}
        />
      </S.Cell>
      <S.Cell>
        {dataSetDto.type && datasetProtocolTypes.getEntry(dataSetDto.type).protocols && (
          <InputSelect
            onChange={handleProtocolChange}
            label={'Protocol'}
            hideBorder={true}
            hideLabel={true}
            selected={dataSetDto.protocol}
            options={datasetProtocolTypes.getEntry(dataSetDto.type).protocols!.map((it) => ({
              id: it.id,
              name: it.name.en,
            }))}
            disabled={!dataSetDto.editable}
          />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!dataSetDto.editable ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleEditableChange(true)}
          >
            <Icon name={'edit'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleEditConfirm} handleCancel={handleEditCancel} />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!dataSetDto.remove ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleRemoveChange(true)}
          >
            <Icon name={'trash'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleDelConfirm} handleCancel={handleDelCancel} />
        )}
      </S.Cell>
    </S.ClickedRow>
  );
};

interface DatasetRowProps {
  cardType: CardType;
  signalDto: ISignalDto;
  isEditableState: boolean;
  remove: (id: string) => void;
}

const DatasetRow: React.FC<DatasetRowProps> = ({ cardType, signalDto, isEditableState, remove: handleOnDelete }) => {
  return (
    <>
      <S.Cell>{signalDto.ldName}</S.Cell>
      <S.Cell>{formatLnType(signalDto.lnType)}</S.Cell>
      <S.Cell>{signalDto.doName}</S.Cell>
      <S.Cell>{signalDto.daName}</S.Cell>
      <S.Cell>{signalDto.fc}</S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        <List.Button disabled={cardType === 'view' || !isEditableState} onClick={() => handleOnDelete(signalDto.id)}>
          <Icon name={'trash'} />
        </List.Button>
      </S.Cell>
    </>
  );
};
