import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";

export interface SvgNodeWidgetProps {
  colorFilter?: string;
  position: 'absolute' | 'relative';
  width: number;
  height: number;
  source: string;
}

export const SvgNodeWidget = React.forwardRef<HTMLImageElement, SvgNodeWidgetProps>(
  ({ colorFilter, position, width, height, source }, ref) => {
    const [icon, setIcon] = useState(<></>);
    const preventDrag = useCallback((event) => event.preventDefault(), []);

    useEffect(() => {
      setIcon(
        <img
          ref={ref}
          draggable={'false'}
          onDragStart={preventDrag} //Firefox fix
          src={source}
          alt={''}
          width={width}
          height={height}
          style={{
              filter: `${colorFilter ? colorFilter : 'none'}`,
              position: position
          }}
        />
      );
    }, [height, ref, source, width, preventDrag, colorFilter]);

    return React.cloneElement(icon, {
      height: height,
      width: width,
    });
  }
);
