import React, { useCallback, useMemo, useState } from 'react';
import { CapexType } from './types';
import { Styled as S } from './CapexCard.styled';
import { theme } from '../../../theme';
import { RoundComparisonChart } from '../../widgets/RoundComparisonChart';
import { Accordion, AccordionChildFactory } from './accordion/Accordion';
import { Toolkit } from './accordion/Toolkit';

type CapexGraphProps = {
  currency: string;
  capex: CapexType;
};

export const CapexCard: React.FC<CapexGraphProps> = ({ currency, capex }) => {
  const [protectionExpanded, setProtectionExpanded] = useState(false);

  const handleToggleAccordion = useCallback(
    () => setProtectionExpanded((protectionExpanded) => !protectionExpanded),
    []
  );

  const protectionList = useMemo(() => {
    return {
      id: Toolkit.UID(),
      title: 'Protection',
      subtitle: '8 00 000 $',
      children: [
        {
          id: Toolkit.UID(),
          title: 'CAPEX',
          subtitle: '8 00 000 $',
          children: [
            { id: Toolkit.UID(), title: 'IEDs', subtitle: '10 Unit' },
            { id: Toolkit.UID(), title: 'Cubes', subtitle: '10 Unit' },
          ],
        },
        { id: Toolkit.UID(), title: 'Maintenance supply', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Design', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Construction and installation works', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Commissioning works', subtitle: '8 00 000 $' },
      ],
    };
  }, []);

  const scadaList = useMemo(() => {
    return {
      id: Toolkit.UID(),
      title: 'Scada',
      subtitle: '8 00 000 $',
      children: [
        {
          id: Toolkit.UID(),
          title: 'CAPEX',
          subtitle: '8 00 000 $',
          children: [
            { id: Toolkit.UID(), title: 'IEDs', subtitle: '10 Unit' },
            { id: Toolkit.UID(), title: 'Ethernet switchs', subtitle: '10 Unit' },
            { id: Toolkit.UID(), title: 'Cubes', subtitle: '10 Unit' },
            { id: Toolkit.UID(), title: 'Systems', subtitle: '10 Unit' },
            { id: Toolkit.UID(), title: 'WorkStations', subtitle: '10 Unit' },
          ],
        },
        { id: Toolkit.UID(), title: 'Maintenance supply', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Design', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Construction and installation works', subtitle: '8 00 000 $' },
        { id: Toolkit.UID(), title: 'Commissioning works', subtitle: '8 00 000 $' },
      ],
    };
  }, []);

  return (
    <S.CapexCard>
      <RoundComparisonChart
        comparableA={{
          label: 'SCADA',
          percent: capex.scada.percentage,
          color: theme.colors.purple,
        }}
        comparableB={{
          label: 'Protection',
          percent: capex.protection.percentage,
          color: theme.colors.turquoise,
        }}
      />
      <S.Legend>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.turquoise} />
          <S.LegendLabel>Protection</S.LegendLabel>
        </S.LegendItem>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.purple} />
          <S.LegendLabel>Scada</S.LegendLabel>
        </S.LegendItem>
      </S.Legend>
      <S.Content>
        <Accordion
          key={protectionList.id}
          model={protectionList}
          indentLevel={0}
          hasParent={false}
          last
          expanded={protectionExpanded}
          childFactory={treeChildAccordionFactory}
          onClick={handleToggleAccordion}
        />
        <Accordion
          key={scadaList.id}
          model={scadaList}
          indentLevel={0}
          hasParent={false}
          last
          expanded={!protectionExpanded}
          childFactory={treeChildAccordionFactory}
          onClick={handleToggleAccordion}
        />
      </S.Content>
      <S.Footer>
        {capex.total.cost} {currency}
      </S.Footer>
    </S.CapexCard>
  );
};

export const treeChildAccordionFactory: AccordionChildFactory = ({ model, indentLevel, last }) => (
  <Accordion
    key={model.id}
    model={model}
    indentLevel={indentLevel + 1}
    last={last}
    expanded={false}
    childFactory={childAccordionFactory}
    onClick={() => {}}
  />
);

const childAccordionFactory: AccordionChildFactory = ({ model, indentLevel, last }) => (
  <Accordion
    key={model.id}
    model={model}
    indentLevel={indentLevel + 1}
    last={last}
    expanded={false}
    onClick={() => {}}
  />
);
