import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import { TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import {
  HasNotExtendableParentModel,
  NavigatingSelectableTreeModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdStructureLogicDeviceModelProps } from './ScdStructureTreeLogicDevice';
import { ScdNodeModel } from '../../../editor/scd/ScdModel';
import { ControllerPlaceholder } from '../../../editor/scd/controller/ControllerPlaceholder';

export const ScdStructureTreeControllerFactory = (
  engine: ScdEngine,
  logicDeviceFactory: Factory<ScdStructureLogicDeviceModelProps, TreeNodeState>
): Factory<ScdStructureTreeControllerProps, TreeNodeState> => ({ controller }) =>
  ScdStructureTreeController(controller, engine, logicDeviceFactory);

export interface ScdStructureTreeControllerProps {
  controller: ControllerPlaceholder;
}

const ScdStructureTreeController = (
  controller: ControllerPlaceholder,
  engine: ScdEngine,
  logicDeviceFactory: Factory<ScdStructureLogicDeviceModelProps, TreeNodeState>
): TreeNodeState => {
  const logicDeviceMapping = (logicDevice: DirectoryLogicDeviceModel<ScdNodeModel>) =>
    logicDeviceFactory({ logicDevice });

  const relativeModelId = controller.getRelativeModel()!.getID();

  return {
    ...ScdStructureTreeControllerModel(controller, logicDeviceMapping),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
    ...NavigatingSelectableTreeModel(SelectableTreeBaseModel(controller, engine), relativeModelId, engine),
  };
};

const ScdStructureTreeControllerModel = (
  controller: ControllerPlaceholder,
  logicDeviceMapping: (node: DirectoryLogicDeviceModel<ScdNodeModel>) => TreeNodeState
): TreeNodeModel => {
  return {
    getName: () => controller.getCodeName(),
    getKey: () => controller.getID(),
    onChildrenChanged: (cb) =>
      controller.registerListener({
        childrenChanged: (event: { child: DirectoryLogicDeviceModel<ScdNodeModel>; created: boolean }) =>
          cb(logicDeviceMapping(event.child), event.created),
      } as any).deregister,
    getChildren: () => controller.getChildren().map(logicDeviceMapping),
  };
};
