import styled from 'styled-components';
import { Styled as StyledInputField } from '../../widgets/InputField.styled';
import { Styled as StyledInputSelect } from '../../widgets/InputSelect.styled';
import { Btn } from '../../widgets/Btn.styled';

const Properties = styled.form`
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};
  border-right: 1px solid ${(props) => props.theme.colors.grey};
  z-index: 88;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 48px;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lightgrey};
  border-top: 1px solid ${(props) => props.theme.colors.grey};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  padding: 0 16px;
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 0;

  ${Btn} {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.black};

  ${StyledInputField.InputField} {
    width: 100%;
    margin-top: 4px;
  }

  ${StyledInputField.Input}, ${StyledInputField.InputContainer} {
    min-height: 32px;
    padding: 4px 8px;
  }

  ${StyledInputSelect.InputOption} {
    height: 32px;
    padding: 4px 8px;
  }
`;

const ContentItemLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.blue};
`;

export const Styled = {
  Properties,
  Header,
  Content,
  Footer,
  ContentItem,
  ContentItemLabel,
};
