import React, { useState } from 'react';
import { Styled as S } from './ThirdStageCardContent.styled';

import { Tab, Tabs } from './tabs/Tabs';
import { Datasets } from './datasets/Datasets';
import { GooseCBList } from './goose-cb/GooseCB';
import { SvCBList } from './sv-cb/SvCB';
import { MmsReports } from './mms-reports/MmsReports';

type ReportsTabType = 'dataset' | 'goCb' | 'mms' | 'svCb';

interface ThirdStageCardContentProps {}

export const ThirdStageCardContent: React.FC<ThirdStageCardContentProps> = () => {
  const [activeTab, setActiveTab] = useState('dataset' as ReportsTabType);
  const tabs: Tab<ReportsTabType>[] = [
    { id: 'dataset', name: 'DATASETs' },
    { id: 'goCb', name: 'GOOSE CB' },
    { id: 'mms', name: 'MMS Reports' },
    { id: 'svCb', name: 'SV CB' },
  ];

  return (
    <S.Container>
      <Tabs<ReportsTabType> activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
      {activeTab === 'dataset' && <Datasets />}
      {activeTab === 'goCb' && <GooseCBList />}
      {activeTab === 'mms' && <MmsReports />}
      {activeTab === 'svCb' && <SvCBList />}
    </S.Container>
  );
};
