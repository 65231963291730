import { HasChildren } from './HasChildren';
import { BaseModel, BaseObserver } from '@projectstorm/react-canvas-core';

export type RelativeModel<CHILD extends BaseObserver> = HasChildren<CHILD> & BaseModel;

export interface HasRelativeModel<MODEL> {
  setRelativeModel(model: MODEL, index?: number): void;

  getRelativeModel(): MODEL | undefined;
}

export class HasRelativeParent<ORIGIN extends BaseObserver, MODEL extends HasChildren<ORIGIN> & BaseModel>
  implements HasRelativeModel<MODEL> {
  private readonly origin: ORIGIN;
  private relativeModel?: MODEL;

  constructor(origin: ORIGIN) {
    this.origin = origin;
  }

  setRelativeModel(model: MODEL, index?: number) {
    this.relativeModel = model;
    model.addChild(this.origin, index);
  }

  getRelativeModel(): MODEL | undefined {
    return this.relativeModel;
  }
}

export class DefaultHasRelativeModel<MODEL> implements HasRelativeModel<MODEL> {
  private relativeModel?: MODEL;

  setRelativeModel(model: MODEL, index?: number) {
    this.relativeModel = model;
  }

  getRelativeModel(): MODEL | undefined {
    return this.relativeModel!;
  }
}
