import React, { useCallback, useMemo, useState } from 'react';
import { Styled as S } from './VendorsLib.styled';
import { VendorList } from './VendorList';
import { Icon } from '../../../widgets/Icon';
import { useUser } from '../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../utils/role-utils';

export const VendorsLib: React.FC = () => {
  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);

  const [vendorElementCreationVisibility, setVendorElementCreationVisibility] = useState(false);

  const showVendorElementCreation = useCallback(() => {
    setVendorElementCreationVisibility(true);
  }, []);

  const hideVendorCreation = useCallback(() => {
    setVendorElementCreationVisibility(false);
  }, []);

  return (
    <>
      <S.Header>
        {userHasLibraryEditAccess && (
          <S.BtnIcon onClick={showVendorElementCreation}>
            <Icon name={'plus'} />
            <span>Add new</span>
          </S.BtnIcon>
        )}
      </S.Header>
      <VendorList vendorCreationVisibility={vendorElementCreationVisibility} hideVendorCreation={hideVendorCreation} />
    </>
  );
};
