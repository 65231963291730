import * as React from 'react';
import { SingleRackModel } from '../../../lan/rack/SingleRackModel';
import { Size, sizeFontMapping } from '../../../lan/label/LanLabel';

interface ExportRackProps {
  rack: SingleRackModel;
  size: Size;
}

export const ExportRack: React.FC<ExportRackProps> = ({ rack, size }) => {
  const rect = rack.getRect();
  const label = rack.getFullName();
  const labelSize = sizeFontMapping[size];

  return (
    <>
      <g transform={`translate(${rect!.getTopLeft().x},${rect!.getTopLeft().y})`}>
        <text x={0} y={-labelSize / 2} fontFamily="GOSTRUS" fontSize={`${labelSize}px`}>
          {label}
        </text>
        <rect x={0} y={0} height={rect!.getHeight()} width={rect!.getWidth()} stroke={'black'} fill={'transparent'} />
      </g>
    </>
  );
};
