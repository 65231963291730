import { ExportLink } from './ExportLink';
import { BasePoint } from '../../../geometry/Point';
import * as React from 'react';
import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import { LinkModel } from '@projectstorm/react-diagrams';

export class ExportLinkLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: LinkModel[];

  constructor(layer: ExportLayer, models: LinkModel[]) {
    this.layer = layer;
    this.models = models;
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.map((link) => (
          <ExportLink link={link} key={link.getID()} />
        ))}
      </>
    );
  }

  async build(): Promise<void> {
    this.models.forEach((link) =>
      link
        .getPoints()
        .map((pointModel) => pointModel.getPosition())
        .map((point) => new BasePoint(point))
        .forEach((point) => this.boundary.extendBoundary(point))
    );
    return this.layer.build();
  }
}
