import {
  LayerModelGenerics,
  LayerModel,
  FactoryBank,
  BaseEvent,
  BaseModelListener,
  DeserializeEvent,
  Toolkit,
} from '@projectstorm/react-canvas-core';
import { SsdEngine } from '../../SsdEngine';
import { SsdModel } from '../../SsdModel';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory';
import { DirectoryLogicDeviceModel } from '../../logic-device/LogicDeviceModel';

export const LogicDeviceLayerModelType = 'logic-device-layer';

export interface LogicDeviceLayerListener extends BaseModelListener {
  logicDeviceAdded: (event: BaseEvent) => any;
  logicDeviceRemoved: (event: BaseEvent) => any;
}

export interface LogicDeviceLayerModelGenerics extends LayerModelGenerics {
  PARENT: SsdModel;
  ENGINE: SsdEngine;
  CHILDREN: DirectoryLogicDeviceModel;
  LISTENERS: LogicDeviceLayerListener;
}

export class LogicDeviceLayerModel extends LayerModel<LogicDeviceLayerModelGenerics> {
  constructor(options: LogicDeviceLayerModelGenerics['OPTIONS'] = {}) {
    super({
      ...options,
      transformed: true,
      type: LogicDeviceLayerModelType,
    });
    this.models = {};
    this.repaintEnabled = true;
  }

  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }

  getChildModelFactoryBank(engine: LogicDeviceLayerModelGenerics['ENGINE']) {
    return (engine.getLogicDeviceFactories() as unknown) as FactoryBank<AbstractModelFactory>; //todo: typing shit again
  }

  addModel(model: LogicDeviceLayerModelGenerics['CHILDREN']) {
    super.addModel(model);
    model.registerListener({
      entityRemoved: () => {
        if (!this.isLocked()) {
          this.removeModel(model);
          this.fireEvent({ device: model }, 'logicDeviceRemoved');
        }
      },
    });

    this.fireEvent({ device: model }, 'logicDeviceAdded');
  }
}
