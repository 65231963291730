import styled from 'styled-components';
import { Styled as StyledInputField } from './InputField.styled';
import { Btn } from './Btn.styled';

const InputUpload = styled(StyledInputField.InputField)`
  display: flex;

  input {
    display: none;
  }
`;

const InputValue = styled(StyledInputField.InputContainer)`
  color: ${(props) => props.theme.colors.darkgrey};
`;

const InputBrowseBtn = styled(Btn)`
  height: 48px;
  width: 140px;
  margin-left: -45px;

  &:disabled {
    background-color: ${(props) => props.theme.colors.darkgrey};
    opacity: 1;
  }
`;

export const Styled = {
  InputUpload,
  InputValue,
  InputBrowseBtn,
  InputLabel: StyledInputField.InputLabel,
};
