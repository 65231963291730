import styled from 'styled-components';
import { LabelLineHeight } from '../../label/LabelWidget';

const Label = styled.span<{ fontSize: number; left: number; top: number; vertical: boolean }>`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  font-family: ${(p) => p.theme.fonts.gost};
  line-height: ${LabelLineHeight}px;
  font-size: ${({ fontSize }) => fontSize}px;
  writing-mode: ${(p) => (p.vertical ? 'vertical-rl' : 'horizontal-tb')};
  transform: ${(p) => (p.vertical ? 'rotate(180deg)' : '')};
  letter-spacing: -0.5px;
  height: 45px;
  display: flex;
  justify-content: end;
`;

export const Styled = {
  Label,
};
