import React, { useCallback, useMemo, useRef, useState } from 'react';
import { HardwareType, LogicalNodeTypeUpdateDto, LogicalNodeTypeViewDto } from '../../../../../api/nggrace-back';
import { Styled as S } from './LogicalNode.styled';
import { EditInfo } from '../../EditInfo';
import { Icon } from '../../../../widgets/Icon';
import { Api } from '../../../../../api/Api';
import { useNotifications } from '../../../../context/NotificationContext';
import { InputField as InField } from '../../../../widgets/InputField';
import { useUser } from '../../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../../utils/role-utils';
import { Feedback } from '../../../../widgets/Feedback';
import { CardType } from './LogicalNodeList';
import { LnComposition } from '../../hardware/card/lncomposition/LnComposition';
import { Ieds } from './Ieds';
import { InputField } from '../../hardware/card/cardcontent/InputField';
import { SecondaryButton } from '../../../../widgets/SecondaryButton';

export const hasIcd = (hardwareType: HardwareType): boolean => ['IED', 'MU', 'IED_MU'].includes(hardwareType);

interface HardwareCardProps {
  cardType: CardType;
  handleCardTypeChange?: (type: CardType) => void;
  logicalNode: LogicalNodeTypeViewDto;
  onClose: (logicalNodeUpdate?: LogicalNodeTypeUpdateDto) => void;
}

interface FeedbackMessage {
  error?: boolean;
  success?: boolean;
  text: string;
}

export const LogicalNodeCard: React.FC<HardwareCardProps> = ({
  cardType,
  handleCardTypeChange,
  logicalNode: initLogicalNode,
  onClose,
}) => {
  const initLogicalNodeUpdate = { performancePoints: initLogicalNode.performancePoints } as LogicalNodeTypeUpdateDto;

  const user = useUser()!.user!;
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);

  const [logicalNodeBeforeEdit, setLogicalNodeBeforeEdit] = useState<LogicalNodeTypeUpdateDto>(
    JSON.parse(JSON.stringify(initLogicalNodeUpdate))
  );
  const [logicalNodeUpdate, setLogicalNodeUpdate] = useState<LogicalNodeTypeUpdateDto>(initLogicalNodeUpdate);
  const [updated, setUpdated] = useState<boolean>(false);
  const [scroll, setScroll] = useState<'top' | 'bottom' | 'middle'>('top');
  const [feedback, setFeedback] = useState<FeedbackMessage | undefined>(undefined);
  const [editFromViewCard, setEditFromViewCard] = useState<boolean>(false);

  const notifications = useNotifications();
  const nameRef = useRef<HTMLInputElement>(null);
  const maxNameLength = 255;

  const handleOnEdit = () => {
    if (handleCardTypeChange) handleCardTypeChange('edit');
    setEditFromViewCard(true);
    setFeedback(undefined);
  };

  const handleUpdate = useCallback(() => {
    if (!updated) {
      onClose(undefined);
      return;
    }
    try {
      Api.updateLogicalNodeType(initLogicalNode!.id, logicalNodeUpdate!, { omitInterceptorErrorModal: true })
        .then(() => {
          if (editFromViewCard) {
            handleCardTypeChange && handleCardTypeChange('view');
            setLogicalNodeBeforeEdit(JSON.parse(JSON.stringify(logicalNodeUpdate)));
            setFeedback({ success: true, text: 'Logical node edited' });
          } else {
            onClose(logicalNodeUpdate);
            notifications.notifySuccess('Logical node edited');
          }
        })
        .catch((error) => {
          setFeedback({ error: true, text: error.response.data });
        });
    } catch (e) {
      notifications.notifyError('Internal server error', 'Error');
    }
  }, [editFromViewCard, handleCardTypeChange, initLogicalNode, logicalNodeUpdate, notifications, onClose, updated]);

  const handleOnLogicalNodeChange = useCallback((logicalNode: LogicalNodeTypeUpdateDto) => {
    setLogicalNodeUpdate(logicalNode);
    setUpdated(true);
  }, []);

  const handleScrollChange = (scrollTop: number, scrollHeight: number) => {
    if (Math.abs(scrollTop - scrollHeight) < 3) {
      setScroll('bottom');
    } else if (scrollTop < 3) {
      setScroll('top');
    } else {
      setScroll('middle');
    }
  };

  const handlePerformancePointsChange = (performancePoints: number) => {
    handleOnLogicalNodeChange({ performancePoints });
  };

  const handleCancel = () => {
    setFeedback(undefined);
    if (editFromViewCard) {
      handleOnLogicalNodeChange(logicalNodeBeforeEdit);
      handleCardTypeChange && handleCardTypeChange('view');
    } else {
      onClose(undefined);
    }
  };

  const handleOk = () => {
    setFeedback(undefined);
    setEditFromViewCard(false);
    onClose();
  };

  return (
    <S.Settings>
      <S.HeaderInput notTransparent={true}>
        <InField
          ref={nameRef}
          value={initLogicalNode.type}
          placeholder={'Untitled'}
          onChange={() => {}}
          fitContent
          dataTestid={'hardwareName'}
          maxLength={maxNameLength}
          disabled={true}
        />
        {cardType === 'view' && userHasLibraryEditAccess && (
          <S.BtnIcon onClick={handleOnEdit}>
            <Icon name={'edit'} />
            <span>Edit Parameters</span>
          </S.BtnIcon>
        )}
      </S.HeaderInput>
      <EditInfo cardType={cardType} editedAt={initLogicalNode.editedAt!} editorName={initLogicalNode.editor.name!} />
      <Ieds ieds={initLogicalNode.ieds} />
      <S.Fields>
        <InputField
          integer
          onChange={handlePerformancePointsChange}
          inputType={'number'}
          cardType={cardType}
          label={'Performance points'}
          value={logicalNodeUpdate.performancePoints}
        />
        {initLogicalNode.description && initLogicalNode.description.length !== 0 && (
          <InputField onChange={() => {}} cardType={'view'} label={'Description'} value={initLogicalNode.description} />
        )}
      </S.Fields>
      <S.Shelf type={'top'} scroll={scroll} />
      <LnComposition
        onlyLogicalNodeType={initLogicalNode.type}
        icdFileContent={{ success: true, logicalNodeComposition: initLogicalNode.icdTemplatesDto }}
        handleScrollChange={handleScrollChange}
      />
      <S.Shelf type={'bottom'} scroll={scroll} />
      <S.Footer>
        {feedback && <Feedback error={feedback.error} success={feedback.success} text={feedback.text} />}
        <S.Buttons>
          {cardType === 'edit' && <SecondaryButton text={'Cancel'} onClick={handleCancel} />}
          {cardType === 'view' && <S.Btn onClick={handleOk}>OK</S.Btn>}
          {cardType === 'edit' && <S.Btn onClick={handleUpdate}>Save</S.Btn>}
        </S.Buttons>
      </S.Footer>
    </S.Settings>
  );
};
