import { RotatableNodeModel } from '../../generics/RotatableNodeModel';
import { isBusNodeModel, isDirectoryNodeModel } from '../../NgGraceModel';
import { Rectangle } from '@projectstorm/geometry';
import { BasePoint } from '../../geometry/Point';
import { SmartRotationHour } from '../../geometry/RotationHour';

export enum NodeType {
  BUS,
  TR,
  OTHER,
}

export interface Node {
  getID(): string;

  getType(): NodeType;

  getRect(): Rectangle;

  getHour(): SmartRotationHour;
}

export class DefaultNode implements Node {
  model: RotatableNodeModel;

  constructor(model: RotatableNodeModel) {
    this.model = model;
  }

  getID() {
    return this.model.getID();
  }

  getType(): NodeType {
    if (isBusNodeModel(this.model)) {
      return NodeType.BUS;
    } else if (isDirectoryNodeModel(this.model) && this.model.getDirectoryEntry().transformer) {
      return NodeType.TR;
    } else {
      return NodeType.OTHER;
    }
  }

  getRect(): Rectangle {
    const center = this.model.getCenter();
    const size = this.model.getRotatedSize();
    const halfSize = new BasePoint(size.x / 2, size.y / 2);
    return new Rectangle(
      new BasePoint(center.x - halfSize.x, center.y - halfSize.y),
      new BasePoint(center.x + halfSize.x, center.y - halfSize.y),
      new BasePoint(center.x + halfSize.x, center.y + halfSize.y),
      new BasePoint(center.x - halfSize.x, center.y + halfSize.y)
    );
  }

  getHour(): SmartRotationHour {
    return this.model.getRotation();
  }
}
