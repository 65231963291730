import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toolkit } from '@projectstorm/react-canvas-core';

import { CardType } from '../../../../../hardware/card/HardwareCard';
import { GooseSBStyled as S } from './GooseSB.styled';
import { Icon } from '../../../../../../../widgets/Icon';
import { ListWithoutMargin } from '../../../../../ListWithoutMargin.styled';
import { InputField } from '../../../../../../../widgets/InputField';
import { List } from '../../../../../../../widgets/List.styled';
import { InputSelect } from '../../../../../../../widgets/InputSelect';
import { GooseControlBlockDto } from '../../../../../../../../api/nggrace-back';
import { AcceptableInput } from '../widget/AcceptableInput';
import { CardTypeContext } from '../../../LogicalDeviceList';
import { LogicalDeviceStateContext } from '../../../LogicalDeviceCard';

interface GooseCBProps {}

interface GooseCbListRowProps {
  cardType: CardType;
  gooseCb: IGooseControlBlockDto;
  isEditableState: boolean;
  isRemovableState: boolean;
  dataSet?: Array<{ id: string; name: string }>;
  handleOnChange: (gooseCbChanged: IGooseControlBlockDto) => void;
  handleEditCancel: () => void;
  handleEditConfirm: () => void;
  remove: (id: string) => void;
}

interface IGooseControlBlockDto extends GooseControlBlockDto {
  id: string;
  editable: boolean;
  remove: boolean;
}

export const GooseCBList: React.FC<GooseCBProps> = () => {
  const cardType = useContext(CardTypeContext);
  const { thirdStageState, handleThirdStageStateChange } = useContext(LogicalDeviceStateContext);
  const [gooseCbList, setGooseCbList] = useState<IGooseControlBlockDto[]>(
    thirdStageState?.dataPackets?.gooseControlBlocks
      ? thirdStageState.dataPackets?.gooseControlBlocks.map<IGooseControlBlockDto>((gooseCb) => ({
          ...gooseCb,
          id: Toolkit.UID(),
          editable: false,
          remove: false,
        }))
      : []
  );
  const prevGooseCbList = useRef<IGooseControlBlockDto[]>([]);
  const [isEditableState, setIsEditableState] = useState<boolean>(false);
  const [isRemovableState, setIsRemovableState] = useState<boolean>(false);

  useEffect(() => {
    prevGooseCbList.current = gooseCbList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gooseCbList.length]);

  const createGooseCbList = () => {
    setGooseCbList((prevStateGooseCbListState) => {
      const newGooseCbList = [
        ...prevStateGooseCbListState,
        {
          id: Toolkit.UID(),
          name: `gooseCbName ${gooseCbList.length}`,
          editable: false,
          remove: false,
        },
      ];
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          gooseControlBlocks: prevStateGooseCbListState,
        },
      });
      return newGooseCbList;
    });
  };

  const handleOnChange = (gooseCbChanged: IGooseControlBlockDto) => {
    setGooseCbList(
      gooseCbList.map((gooseCb) => {
        if (gooseCb.id === gooseCbChanged.id) {
          setIsEditableState(gooseCbChanged.editable);
          setIsRemovableState(gooseCbChanged.remove);
          return gooseCbChanged;
        } else return gooseCb;
      })
    );
  };

  const handleOnDelete = (id: string) => {
    setGooseCbList((prevStateGooseCbListState) => {
      const newGooseCbList = prevStateGooseCbListState.filter((gooseCb) => gooseCb.id !== id);
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          gooseControlBlocks: newGooseCbList,
        },
      });

      return newGooseCbList;
    });
    setIsRemovableState(false);
  };

  const handleEditConfirm = () => {
    prevGooseCbList.current = gooseCbList;
    setGooseCbList((prevGooseCbListState) => {
      const newGooseCbList = prevGooseCbListState.map((gooseCb) =>
        gooseCb.editable
          ? {
              ...gooseCb,
              editable: false,
            }
          : gooseCb
      );
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          gooseControlBlocks: newGooseCbList,
        },
      });

      return newGooseCbList;
    });
    setIsEditableState(false);
  };

  const handleEditCancel = () => {
    setGooseCbList(
      prevGooseCbList.current.map((gooseCb) => (gooseCb.editable ? { ...gooseCb, editable: false } : gooseCb))
    );
    setIsEditableState(false);
  };

  return (
    <S.Container>
      <S.Column position={'left'}>
        <header>
          <h3>GOOSE CB list</h3>
          <S.BtnIcon onClick={createGooseCbList} disabled={cardType === 'view'}>
            <Icon name={'plus'} />
            <span>New</span>
          </S.BtnIcon>
        </header>
        <S.GooseCBListGrid id="scrollableTarget">
          <S.HeaderRow>
            <ListWithoutMargin.HeaderCell>GOOSE CB Name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>DataSet Name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>
          </S.HeaderRow>
          {gooseCbList.map((gooseCb) => (
            <GooseCbListRow
              key={gooseCb.id}
              cardType={cardType}
              gooseCb={gooseCb}
              dataSet={thirdStageState?.dataPackets?.dataSets
                ?.filter((dataSetDto) => dataSetDto.type === 'GOOSE_CB')
                .map((dataSetDto) => ({
                  id: dataSetDto.name!,
                  name: dataSetDto.name!,
                }))}
              isEditableState={isEditableState}
              isRemovableState={isRemovableState}
              handleEditConfirm={handleEditConfirm}
              handleEditCancel={handleEditCancel}
              handleOnChange={handleOnChange}
              remove={handleOnDelete}
            />
          ))}
        </S.GooseCBListGrid>
      </S.Column>
    </S.Container>
  );
};

const GooseCbListRow: React.FC<GooseCbListRowProps> = ({
  cardType,
  gooseCb,
  isEditableState,
  isRemovableState,
  dataSet,
  handleEditConfirm,
  handleEditCancel,
  handleOnChange,
  remove: handleOnDelete,
}) => {
  const handleNameChange = (name: string) => {
    handleOnChange({ ...gooseCb, name });
  };

  const handleDatasetNameChange = (dataSetName: string) => {
    handleOnChange({ ...gooseCb, dataSetName });
  };

  const handleEditableChange = (editable: boolean) => {
    handleOnChange({ ...gooseCb, editable });
  };

  const handleRemoveChange = (remove: boolean) => {
    handleOnChange({ ...gooseCb, remove });
  };

  const handleDelConfirm = () => {
    handleOnDelete(gooseCb.id);
  };

  const handleDelCancel = () => {
    handleOnChange({ ...gooseCb, remove: false });
  };

  return (
    <S.ClickedRow remove={gooseCb.remove} editable={gooseCb.editable}>
      <S.Cell>
        <InputField type={'text'} onChange={handleNameChange} value={gooseCb.name} disabled={!gooseCb.editable} />
      </S.Cell>
      <S.Cell>
        {dataSet && (
          <InputSelect
            onChange={handleDatasetNameChange}
            label={'DataSet Name'}
            hideBorder={true}
            hideLabel={true}
            selected={gooseCb.dataSetName}
            options={dataSet}
            disabled={!gooseCb.editable}
          />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!gooseCb.editable ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleEditableChange(true)}
          >
            <Icon name={'edit'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleEditConfirm} handleCancel={handleEditCancel} />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!gooseCb.remove ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleRemoveChange(true)}
          >
            <Icon name={'trash'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleDelConfirm} handleCancel={handleDelCancel} />
        )}
      </S.Cell>
    </S.ClickedRow>
  );
};
