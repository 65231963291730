import { DefaultLinkModelGenerics } from '@projectstorm/react-diagrams';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { LanPortModel } from '../node/port/LanPortModel';
import { RawSmartLinkModel } from '../../link/smart/RawSmartLinkModel';

export const LanLinkType = 'lan-link';

export class LanLinkModel extends RawSmartLinkModel {
  label: string;

  constructor();
  constructor(source: ConnectablePortModel, target: ConnectablePortModel);
  constructor(source?: ConnectablePortModel, target?: ConnectablePortModel) {
    if (source && target) {
      super(source, target, []);
    } else {
      super();
    }

    this.options.type = LanLinkType;
    this.label = ''; // fills while deserialization;
  }

  setParent(parent: DefaultLinkModelGenerics['PARENT']) {
    super.setParent(parent);
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.label = event.data.label;
    this.options.color = event.data.color;
    return Promise.all([
      event.getModel<LanPortModel>(event.data.sourcePort),
      event.getModel<LanPortModel>(event.data.targetPort),
    ]).then(() => {});
  }

  serialize() {
    return { ...super.serialize(), label: this.label };
  }

  portPositionChanged(port: ConnectablePortModel) {}

  getConnectorLength() {
    return 40;
  }

  getLabel() {
    return this.label;
  }

}