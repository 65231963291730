import { useCallback } from 'react';
import { useNotifications } from '../context/NotificationContext';

export const useNotifyOnSuccessCallback = (checkSuccess: () => Promise<boolean>, message: string) => {
  const notifications = useNotifications();

  return useCallback(async () => {
    const completed = await checkSuccess();
    if (completed) {
      notifications.notifySuccess(message);
    }
  }, [checkSuccess, notifications, message]);
};
