import { BusNodeModel } from './BusNodeModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { Point } from '@projectstorm/geometry';

export class RawBusNodeModel extends BusNodeModel {
  deserialize(event: DeserializeEvent<this>) {
    const savedSize = this.size;
    const savedPayload = { ...this.payload };
    super.deserialize(event);
    if (event.data.raw) {
      this.position = new Point(0, 0);
      this.size = savedSize;
      this.payload = { ...savedPayload, ...this.payload };
    }
  }
}
