import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import * as React from 'react';
import { ControllerLayerModel, ControllerLayerModelType } from './ControllerLayerModel';
import { ControllerLayerWidget } from './ControllerLayerWidget';
import { ScdEngine } from '../../ScdEngine';

export class ControllerLayerFactory extends AbstractReactFactory<ControllerLayerModel, ScdEngine> {
  constructor() {
    super(ControllerLayerModelType);
  }

  generateModel(event: GenerateModelEvent): ControllerLayerModel {
    return new ControllerLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<ControllerLayerModel>): JSX.Element {
    return <ControllerLayerWidget layer={event.model} engine={this.engine} />;
  }
}
