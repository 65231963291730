import { BaseModel, BaseObserver, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { HasName } from '../building/HasName';
import { DefaultHasRect, HasRect } from '../../placeholder/HasRect';
import { DefaultHasPosition, HasPosition } from '../../placeholder/HasPosition';
import { BasePoint } from '../../geometry/Point';
import { Rectangle } from '@projectstorm/geometry';
import { BaseRackModel, Rack, RackHasRelativeRoomFactory } from './Rack';
import { CompositeRackModel } from './CompositeRackModel';
import { HasChildren } from '../../placeholder/HasChildren';
import { HasRelativeModel, HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { ZoneModel, ZoneType } from '../zone/ZoneModel';
import { RoomModel } from '../building/RoomModel';
import { LanNodeModel } from '../node/LanNodeModel';
import { RackHasSize } from './RackHasSize';
import { UpdatingChildrenHasPosition } from './UpdatingChildrenHasPosition';
import { Factory } from '../../../../utils/factory';
import { Coordinate, OppositeCoordinate } from '../../geometry/Coordinate';
import { WaypointGraph, WaypointGraphContainer } from '../waypoint/WaypointGraph';
import { onceForLoop } from '../../FunctionDecorators';
import {DefaultHasSize, HasSize} from "../../placeholder/HasSize";

export const SingleRackModelType = 'single-rack';

export type RackHasChildrenFactory = Factory<
  { hasPosition: HasPosition; eventDelegate: BaseObserver },
  HasChildren<LanNodeModel>
>;

export type RackHasRectFactory = Factory<
  {
    originalHasPosition: HasPosition;
    originalHasSize: HasSize;
    eventDelegate: BaseObserver;
    hasChildren: HasChildren<LanNodeModel>;
    hasRelativeZone: HasRelativeModel<BaseObserver>;
  },
  HasRect
>;

export class SingleRackModel
  extends BaseModel
  implements Rack, HasRect, HasChildren<LanNodeModel>, WaypointGraphContainer<string> {
  private readonly hasRect: HasRect;
  private readonly origin: Rack;
  private readonly hasChildren: HasChildren<LanNodeModel>;
  private readonly growCoordinate: Coordinate;
  private readonly hasRelativeZone: HasRelativeModel<ZoneModel>;
  private readonly hasRelativeRoom: HasRelativeModel<RoomModel>;
  private waypointGraph: WaypointGraph<string> | undefined;

  constructor(
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory,
    hasChildrenFactory: RackHasChildrenFactory,
    hasRect: RackHasRectFactory,
    growCoordinate: Coordinate,
    savedPosition: BasePoint,
    savedSize: BasePoint
  ); // deserialization
  constructor(
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory,
    hasChildrenFactory: RackHasChildrenFactory,
    hasRect: RackHasRectFactory,
    growCoordinate: Coordinate,
    savedPosition: BasePoint,
    savedSize: BasePoint,
    zone: ZoneModel
  );
  constructor(
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory,
    hasChildrenFactory: RackHasChildrenFactory,
    hasRect: RackHasRectFactory,
    growCoordinate: Coordinate,
    savedPosition?: BasePoint,
    savedSize?: BasePoint,
    zone?: ZoneModel
  ) {
    super({ type: SingleRackModelType });
    this.growCoordinate = growCoordinate;

    const eventDelegate = this as any;
    this.hasRelativeZone = new HasRelativeParent(eventDelegate);
    this.hasRelativeRoom = hasRelativeRoomFactory(this);

    const partialHasPosition = new DefaultHasPosition(eventDelegate, savedPosition);
    this.hasChildren = hasChildrenFactory({ hasPosition: partialHasPosition, eventDelegate });
    this.origin = new BaseRackModel(this.hasChildren, hasName, this.hasRelativeRoom);
    const partialHasSize = new DefaultHasSize(savedSize);
    this.hasRect = hasRect({
      originalHasPosition: partialHasPosition,
      originalHasSize: partialHasSize,
      eventDelegate,
      hasChildren: this.hasChildren,
      hasRelativeZone: this.hasRelativeZone,
    });

    if (zone) {
      this.hasRelativeZone.setRelativeModel(zone);
    }

    const updateWaypoints = onceForLoop(() => {});

    this.registerListener({
      positionChanged: updateWaypoints,
      sizeChanged: updateWaypoints,
    });
  }

  setRoom(room: RoomModel, index?: number): void {
    this.origin.setRoom(room, index);
  }

  asComposite(): CompositeRackModel | undefined {
    return undefined;
  }

  asSingle(): SingleRackModel | undefined {
    return this;
  }

  addChild(childToAdd: LanNodeModel, index?: number): void {
    this.hasChildren.addChild(childToAdd, index);
    childToAdd.setRelativeZone(this.hasRelativeZone.getRelativeModel()!);
  }

  getChildren(): LanNodeModel[] {
    return this.hasChildren.getChildren();
  }

  getPosition(): BasePoint {
    return this.hasRect.getPosition();
  }

  getRect(): Rectangle | undefined {
    return this.hasRect.getRect();
  }

  getSize(): BasePoint {
    return this.hasRect.getSize();
  }

  setPosition(newValue: BasePoint): void {
    this.hasRect.setPosition(newValue);
  }

  getFullName(): string {
    return this.origin.getFullName();
  }

  getName(): string {
    return this.origin.getName();
  }

  getZoneType(): ZoneType | undefined {
    return this.hasRelativeZone.getRelativeModel()?.getZoneType();
  }

  serialize() {
    return {
      ...super.serialize(),
      x: this.hasRect.getPosition().x,
      y: this.hasRect.getPosition().y,
      size: this.hasRect.getSize(),
      name: this.getName(),
      zones: [this.hasRelativeZone.getRelativeModel()?.getID()!],
      room: this.hasRelativeRoom.getRelativeModel()?.getID()!,
      nodes: this.hasChildren.getChildren().map((node) => node.getID()),
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.getModel<ZoneModel>(event.data.zones[0]).then((zone) => this.hasRelativeZone.setRelativeModel(zone));
    event.data.nodes.forEach((id) => event.getModel<LanNodeModel>(id).then((node) => this.addChild(node)));
    event.registerModel(this);
  }

  getGrowCoordinate() {
    return this.growCoordinate;
  }

  getLinkWaypointGraph() {
    return this.waypointGraph!;
  }

  notCascadeRemove() {
    const zone = this.hasRelativeZone.getRelativeModel();
    super.remove();
    if (zone) {
      this.hasRelativeZone.setRelativeModel(zone);
    }
  }

  remove() {
    super.remove();
    this.getChildren().forEach((child) => child.remove());
  }

  canRemove() {
    return !this.isLocked() && !this.getChildren().some((child) => !child.canRemove());
  }

}

export const DefaultRackHasRect: Factory<{ startOffsetX?: number }, RackHasRectFactory> = ({ startOffsetX }) => ({
  originalHasPosition,
  originalHasSize,
  eventDelegate,
  hasChildren,
  hasRelativeZone,
}) =>
  new DefaultHasRect(
    new UpdatingChildrenHasPosition(originalHasPosition, eventDelegate, hasChildren),
    new RackHasSize(originalHasSize, eventDelegate, hasChildren, hasRelativeZone, startOffsetX || 0)
  );
