import React from 'react';
import { Styled as S } from './SvgFileField.styled';
import { CardType } from '../../HardwareCard';

interface SvgContainerProps {
  image?: string;
  url?: string;
  cardType?: CardType;
}

export const SvgContainer: React.FC<SvgContainerProps> = ({ image, url, cardType }) => {
  return (
    <S.SvgContainer cardType={cardType}>
      {(url || image) && (
        <img
          src={image ? URL.createObjectURL(new Blob([image], { type: 'image/svg+xml' })) : url ? url : undefined}
          aria-hidden
          alt={'Picture can not be visualized'}
        />
      )}
      {!url && !image && <S.NoImage>Choose or download to see a preview</S.NoImage>}
    </S.SvgContainer>
  );
};
