import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { SmartLinkModel } from './SmartLinkModel';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { PortEndedPath } from './path/default/PortEndedPath';
import { AngledPath } from './path/default/AngledPath';
import { RestrictedDirectionPoint } from '../../geometry/Point';

export class RawSmartLinkModel extends SmartLinkModel {
  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);

    const portsPromise = Promise.all([
      event.getModel<ConnectablePortModel>(event.data.sourcePort),
      event.getModel<ConnectablePortModel>(event.data.targetPort),
    ]);

    if (!event.data.raw) {
      return portsPromise;
    }

    this.options = { ...savedOptions, id: event.data.id };
    if (!event.data.source || !event.data.target) {
      throw new Error('Source or target port not defined');
    }

    return portsPromise.then(([sourcePort, targetPort]) => {
      const path = new UpdatingDependenciesConnectingPath(
        new DefaultConnectingPath(new PortEndedPath(new PortEndedPath(new AngledPath(), sourcePort), targetPort), this)
      );
      const points = path.connect(
        new RestrictedDirectionPoint(sourcePort.getCenter()),
        new RestrictedDirectionPoint(targetPort.getCenter())
      );
      this.setPoints(points);
    });
  }
}
