import { NodeModel, NodeModelGenerics } from '@projectstorm/react-diagrams';
import { BaseModelListener, BasePositionModelOptions } from '@projectstorm/react-canvas-core';
import { BasePoint } from '../geometry/Point';

export interface NodePayload {}

export interface BaseNodeModelListener extends BaseModelListener {}

export interface BaseNodeModelOptions extends BasePositionModelOptions {}

export interface BaseNodeModelGenerics extends NodeModelGenerics {
  LISTENER: BaseNodeModelListener;
  OPTIONS: BaseNodeModelOptions;
}

export class BaseNodeModel<G extends BaseNodeModelGenerics = BaseNodeModelGenerics> extends NodeModel<G> {
  setSelected(selected?: boolean) {
    super.setSelected(selected);
    Object.values(this.getPorts()).forEach((port) => port.setSelected(selected));
  }

  getPositionToRender(): BasePoint {
    return new BasePoint(this.getPosition());
  }
}
