import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toolkit } from '@projectstorm/react-canvas-core';

import { CardType } from '../../../../../hardware/card/HardwareCard';
import { SvSBStyled as S } from './SvSB.styled';
import { Icon } from '../../../../../../../widgets/Icon';
import { ListWithoutMargin } from '../../../../../ListWithoutMargin.styled';
import { InputField } from '../../../../../../../widgets/InputField';
import { List } from '../../../../../../../widgets/List.styled';
import { InputSelect } from '../../../../../../../widgets/InputSelect';
import { SvControlBlockDto } from '../../../../../../../../api/nggrace-back';
import { AcceptableInput } from '../widget/AcceptableInput';
import { CardTypeContext } from '../../../LogicalDeviceList';
import { LogicalDeviceStateContext } from '../../../LogicalDeviceCard';

interface SvCBProps {}

interface SvCBListRowProps {
  cardType: CardType;
  svCb: ISvControlBlockDto;
  isEditableState: boolean;
  isRemovableState: boolean;
  dataSet?: Array<{ id: string; name: string }>;
  handleOnChange: (svCbChanged: ISvControlBlockDto) => void;
  handleEditCancel: () => void;
  handleEditConfirm: () => void;
  remove: (id: string) => void;
}

interface ISvControlBlockDto extends SvControlBlockDto {
  id: string;
  editable: boolean;
  remove: boolean;
  name?: string;
  dataSetName?: string;
}

export const SvCBList: React.FC<SvCBProps> = () => {
  const cardType = useContext(CardTypeContext);
  const { thirdStageState, handleThirdStageStateChange } = useContext(LogicalDeviceStateContext);
  const [svCbList, setSvCbList] = useState<ISvControlBlockDto[]>(
    thirdStageState?.dataPackets?.svControlBlocks
      ? thirdStageState.dataPackets?.svControlBlocks.map<ISvControlBlockDto>((svCb) => ({
          ...svCb,
          id: Toolkit.UID(),
          editable: false,
          remove: false,
        }))
      : []
  );
  const prevSvCbList = useRef<ISvControlBlockDto[]>([]);
  const [isEditableState, setIsEditableState] = useState<boolean>(false);
  const [isRemovableState, setIsRemovableState] = useState<boolean>(false);

  useEffect(() => {
    prevSvCbList.current = svCbList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svCbList.length]);

  const createGooseCbList = () => {
    setSvCbList((prevStateSvCbListState) => {
      const newSvSbList = [
        ...prevStateSvCbListState,
        {
          id: Toolkit.UID(),
          name: `svCbName ${svCbList.length}`,
          editable: false,
          remove: false,
        },
      ];
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          svControlBlocks: newSvSbList,
        },
      });
      return newSvSbList;
    });
  };

  const handleOnChange = (svCbChanged: ISvControlBlockDto) => {
    setSvCbList(
      svCbList.map((svCb) => {
        if (svCb.id === svCbChanged.id) {
          setIsEditableState(svCbChanged.editable);
          setIsRemovableState(svCbChanged.remove);
          return svCbChanged;
        } else return svCb;
      })
    );
  };

  const handleOnDelete = (id: string) => {
    setSvCbList((prevStateSvCbListState) => {
      const newSvCbList = prevStateSvCbListState.filter((svCb) => svCb.id !== id);
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          svControlBlocks: newSvCbList,
        },
      });

      return newSvCbList;
    });
    setIsRemovableState(false);
  };

  const handleEditConfirm = () => {
    prevSvCbList.current = svCbList;
    setSvCbList((prevSvCbListState) => {
      const newSvCbList = prevSvCbListState.map((svCb) =>
        svCb.editable
          ? {
              ...svCb,
              editable: false,
            }
          : svCb
      );
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          svControlBlocks: newSvCbList,
        },
      });

      return newSvCbList;
    });
    setIsEditableState(false);
  };

  const handleEditCancel = () => {
    setSvCbList(prevSvCbList.current.map((svCb) => (svCb.editable ? { ...svCb, editable: false } : svCb)));
    setIsEditableState(false);
  };

  return (
    <S.Container>
      <S.Column position={'left'}>
        <header>
          <h3>SV CB list</h3>
          <S.BtnIcon onClick={createGooseCbList} disabled={cardType === 'view'}>
            <Icon name={'plus'} />
            <span>New</span>
          </S.BtnIcon>
        </header>
        <S.SvCBListGrid id="scrollableTarget">
          <S.HeaderRow>
            <ListWithoutMargin.HeaderCell>SV CB Name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell>DataSet Name</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>
            <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>
          </S.HeaderRow>
          {svCbList.map((svCb) => (
            <SvCbListRow
              key={svCb.id}
              cardType={cardType}
              svCb={svCb}
              dataSet={thirdStageState?.dataPackets?.dataSets
                ?.filter((dataSetDto) => dataSetDto.type === 'SV_CB')
                .map((dataSetDto) => ({
                  id: dataSetDto.name!,
                  name: dataSetDto.name!,
                }))}
              isEditableState={isEditableState}
              isRemovableState={isRemovableState}
              handleEditConfirm={handleEditConfirm}
              handleEditCancel={handleEditCancel}
              handleOnChange={handleOnChange}
              remove={handleOnDelete}
            />
          ))}
        </S.SvCBListGrid>
      </S.Column>
    </S.Container>
  );
};

const SvCbListRow: React.FC<SvCBListRowProps> = ({
  cardType,
  svCb,
  isEditableState,
  isRemovableState,
  dataSet,
  handleEditConfirm,
  handleEditCancel,
  handleOnChange,
  remove: handleOnDelete,
}) => {
  const handleNameChange = (name: string) => {
    handleOnChange({ ...svCb, name });
  };

  const handleDatasetNameChange = (dataSetName: string) => {
    handleOnChange({ ...svCb, dataSetName });
  };

  const handleEditableChange = (editable: boolean) => {
    handleOnChange({ ...svCb, editable });
  };

  const handleRemoveChange = (remove: boolean) => {
    handleOnChange({ ...svCb, remove });
  };

  const handleDelConfirm = () => {
    handleOnDelete(svCb.id);
  };

  const handleDelCancel = () => {
    handleOnChange({ ...svCb, remove: false });
  };

  return (
    <S.ClickedRow remove={svCb.remove} editable={svCb.editable}>
      <S.Cell>
        <InputField type={'text'} onChange={handleNameChange} value={svCb.name} disabled={!svCb.editable} />
      </S.Cell>
      <S.Cell>
        {dataSet && (
          <InputSelect
            onChange={handleDatasetNameChange}
            label={'DataSet Name'}
            hideBorder={true}
            hideLabel={true}
            selected={svCb.dataSetName}
            options={dataSet}
            disabled={!svCb.editable}
          />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!svCb.editable ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleEditableChange(true)}
          >
            <Icon name={'edit'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleEditConfirm} handleCancel={handleEditCancel} />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!svCb.remove ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleRemoveChange(true)}
          >
            <Icon name={'trash'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleDelConfirm} handleCancel={handleDelCancel} />
        )}
      </S.Cell>
    </S.ClickedRow>
  );
};
