import React, { useContext, useEffect, useState } from 'react';

import {
  FirstStageStateDto,
  LnTemplateDto,
  LogicalDeviceViewDto,
  LogicalNodeCreateDto,
  SecondStageStateDto,
  ThirdStageStateDto,
  VendorViewDto,
} from '../../../../../api/nggrace-back';
import { Styled as S } from './LogicalDeviceCard.styled';
import { Feedback } from '../../../../widgets/Feedback';
import { SecondaryButton } from '../../../../widgets/SecondaryButton';
import { SecondStageCardContent } from './cardcontent/second-stage-content/SecondStageCardContent';
import { FirstStageCardContent } from './cardcontent/first-stage-content/FirstStageCardContent';
import { Api } from '../../../../../api/Api';
import { ThirdStageCardContent } from './cardcontent/third-stage-content/ThirdStageCardContent';
import { LnCompositionTree } from './cardcontent/second-stage-content/lntypetree/LnCompositionTreeLnTemplate';
import { LogicalNodeTreeModel } from './cardcontent/second-stage-content/lntypetree/LnComposition';
import { LnCompositionTreeDataObjectFactory } from './cardcontent/second-stage-content/lntypetree/LnCompositionTreeDataObject';
import { LnCompositionTreeDataAttributeFactory } from './cardcontent/second-stage-content/lntypetree/LnCompositionTreeDataAttribute';
import { STAGE_INFO_DATA, StageInfo } from './StageInfo.data';
import { EditInfo } from './widget/EditInfo';
import { CardTypeContext } from './LogicalDeviceList';

export type CardType = 'create' | 'edit' | 'view';

interface LogicalDeviceCardProps {
  logicalDeviceViewDto: LogicalDeviceViewDto | undefined;
  onClose: (logicalDeviceViewDtoUpdate?: LogicalDeviceViewDto) => void;
}

interface FeedbackMessage {
  error?: boolean;
  success?: boolean;
  text: string;
}

interface ThirdStageStateValue {
  thirdStageState: ThirdStageStateDto | undefined;
  vendor: VendorViewDto | undefined;
  logicalNodes: LogicalNodeCreateDto[];
  logicalDeviceViewDtoName?: string;

  handleThirdStageStateChange(thirdStageState: ThirdStageStateDto): void;
}

export const LogicalDeviceStateContext = React.createContext<ThirdStageStateValue>({
  thirdStageState: undefined,
  vendor: undefined,
  logicalNodes: [],
  handleThirdStageStateChange() {},
});

export const LogicalDeviceCard: React.FC<LogicalDeviceCardProps> = ({ onClose, logicalDeviceViewDto }) => {
  const cardType = useContext(CardTypeContext);
  const initLogicalDeviceViewDtoUpdate = logicalDeviceViewDto
    ? {
        categoryId: logicalDeviceViewDto.categoryId,
        description: logicalDeviceViewDto.description,
        name: logicalDeviceViewDto.name,
        primaryEquipmentIds: logicalDeviceViewDto.primaryEquipmentIds,
        vendorId: logicalDeviceViewDto.vendor.id,
        voltageIds: logicalDeviceViewDto.voltageIds,
      }
    : {
        primaryEquipmentIds: [],
        voltageIds: [],
      };
  const [firstStageState, setFirstStageState] = useState<FirstStageStateDto>(initLogicalDeviceViewDtoUpdate);
  const [secondStageState, setSecondStageState] = useState<SecondStageStateDto>({ logicalNodes: [] });
  const [thirdStageState, setThirdStageState] = useState<ThirdStageStateDto | undefined>({
    dataPackets: logicalDeviceViewDto?.dataPackets,
  });
  const [lnComposition, setLnComposition] = useState<LogicalNodeTreeModel[]>([]);
  const [feedback, setFeedback] = useState<FeedbackMessage | undefined>(undefined);
  const [activeStage, setActiveStage] = useState('FIRST_STAGE');
  const [vendors, setVendors] = useState<VendorViewDto[]>([]);

  const stageInfoData = STAGE_INFO_DATA.find(
    (item: StageInfo) => activeStage === item.activeStage && cardType === item.cardType
  );

  useEffect(() => {
    let cleanupFunction = false;
    Api.getAllVendors().then((response) => {
      if (!cleanupFunction) setVendors(() => response.data);
    });
    return () => {
      cleanupFunction = true;
    };
  }, [vendors.length]);

  useEffect(() => {
    let cleanupFunction = false;
    if (logicalDeviceViewDto) {
      Api.getLogicalNodeTypesByVendor(logicalDeviceViewDto.vendor.id).then((response) => {
        const logicalNodesTree: any[] = [];
        logicalDeviceViewDto.logicalNodes.forEach((ln) => {
          const lnTypes = response.data.find((lnType) => lnType.id === ln.logicalNodeType.id);

          if (lnTypes) {
            logicalNodesTree.push(
              ...(lnTypes.icdTemplatesDto?.logicalNodeTemplates.map((lnTemplate: LnTemplateDto) =>
                LnCompositionTree(
                  lnTemplate,
                  ln.instance,
                  ln.logicalNodeType.id,
                  LnCompositionTreeDataObjectFactory(
                    lnTypes.icdTemplatesDto?.dataObjectTemplates!,
                    LnCompositionTreeDataAttributeFactory()
                  )
                )
              ) as LogicalNodeTreeModel[])
            );
            const logicalNodesCreateDto = logicalNodesTree.map((treeItem) => ({
              instance: treeItem.getInstance(),
              logicalNodeTypeId: treeItem.getLogicalNodeTypeId(),
            }));
            if (!cleanupFunction) setSecondStageState({ logicalNodes: logicalNodesCreateDto });
          }
        });
        if (!cleanupFunction) setLnComposition(logicalNodesTree);
      });
    }
    return () => {
      cleanupFunction = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicalDeviceViewDto]);

  const handleThirdStageStateChange = (thirdStageState: ThirdStageStateDto) => {
    setThirdStageState(thirdStageState);
  };

  const handleOnLogicalDeviceChangeSecondStage = (
    lnCompositionChanged: LogicalNodeTreeModel[],
    secondStageStateDtoChanged: SecondStageStateDto
  ) => {
    setSecondStageState(secondStageStateDtoChanged);
    setLnComposition(lnCompositionChanged);
  };

  const handleOnLogicalDeviceFirstStageChange = (firstStageStateDtoChanged: FirstStageStateDto) => {
    setFirstStageState(firstStageStateDtoChanged);
  };

  const handleNext = () => {
    switch (activeStage) {
      case 'FIRST_STAGE':
        if (cardType !== 'view') {
          Api.validateFirstStage(firstStageState, { logicalDeviceId: logicalDeviceViewDto?.id })
            .then(() => {
              setActiveStage('SECOND_STAGE');
              setFeedback(undefined);
            })
            .catch((error) => {
              setFeedback({ text: error.response.data.split('\n').join('. '), error: true });
            });
        } else setActiveStage('SECOND_STAGE');
        break;
      case 'SECOND_STAGE':
        if (cardType !== 'view') {
          Api.validateSecondStage(secondStageState ? secondStageState : { logicalNodes: [] })
            .then(() => {
              setActiveStage('THIRD_STAGE');
              setFeedback(undefined);
            })
            .catch((error) => {
              setFeedback({ text: error.response.data.split('\n').join('. '), error: true });
            });
        } else setActiveStage('THIRD_STAGE');
        break;
      case 'THIRD_STAGE':
        if (cardType === 'create') {
          Api.createLogicalDevice({
            firstStage: firstStageState,
            secondStage: secondStageState,
            thirdStage: thirdStageState ? thirdStageState : {},
          }).then(() => onClose(undefined));
        } else if (cardType === 'edit') {
          Api.updateLogicalDevice(logicalDeviceViewDto?.id!, {
            firstStage: firstStageState,
            secondStage: secondStageState,
            thirdStage: thirdStageState ? thirdStageState : {},
          }).then(() => onClose(undefined));
        } else onClose(undefined);
        break;
    }
  };

  const handleBack = () => {
    switch (activeStage) {
      case 'SECOND_STAGE':
        setActiveStage('FIRST_STAGE');
        break;
      case 'THIRD_STAGE':
        setActiveStage('SECOND_STAGE');
        break;
      default:
        setActiveStage('FIRST_STAGE');
    }
  };

  const getActiveStage = () => {
    switch (activeStage) {
      case 'FIRST_STAGE':
        return (
          <FirstStageCardContent
            vendors={vendors}
            firstStageStateDto={firstStageState}
            handleOnLogicalDeviceFirstStageChange={handleOnLogicalDeviceFirstStageChange}
          />
        );
      case 'SECOND_STAGE':
        return (
          <SecondStageCardContent
            lnComposition={lnComposition}
            handleOnLogicalDeviceSecondStageChange={handleOnLogicalDeviceChangeSecondStage}
          />
        );
      case 'THIRD_STAGE':
        return <ThirdStageCardContent />;
      default:
        return (
          <FirstStageCardContent
            vendors={vendors}
            firstStageStateDto={firstStageState}
            handleOnLogicalDeviceFirstStageChange={handleOnLogicalDeviceFirstStageChange}
          />
        );
    }
  };

  return (
    <S.Settings>
      <S.HeaderInput>
        {stageInfoData && (
          <S.StageHeader>
            <S.StageIcon>
              <span>{stageInfoData.stageNumber}</span>
            </S.StageIcon>
            <span>{stageInfoData.content}</span>
          </S.StageHeader>
        )}
        <EditInfo
          cardType={cardType}
          editedAt={logicalDeviceViewDto?.editedAt}
          editorName={logicalDeviceViewDto?.editor?.name}
          performancePoints={logicalDeviceViewDto?.performancePoints}
        />
      </S.HeaderInput>
      <LogicalDeviceStateContext.Provider
        value={{
          logicalDeviceViewDtoName: firstStageState.name!,
          vendor: vendors.find((vendor) => vendor.id === firstStageState.vendorId),
          logicalNodes: secondStageState.logicalNodes!,
          thirdStageState,
          handleThirdStageStateChange,
        }}
      >
        {getActiveStage()}
      </LogicalDeviceStateContext.Provider>
      <S.Footer>
        {feedback && <Feedback error={feedback.error} success={feedback.success} text={feedback.text} />}
        <S.Buttons>
          {cardType !== 'view' && <SecondaryButton text={'Cancel'} onClick={() => onClose(undefined)} />}
          {activeStage !== 'FIRST_STAGE' && <S.Btn onClick={handleBack}>Back</S.Btn>}
          {cardType !== 'view' ? (
            <S.Btn onClick={handleNext}>{activeStage !== 'THIRD_STAGE' ? 'Next' : 'Save'}</S.Btn>
          ) : (
            <S.Btn onClick={handleNext}>{activeStage !== 'THIRD_STAGE' ? 'Next' : 'Ok'}</S.Btn>
          )}
        </S.Buttons>
      </S.Footer>
    </S.Settings>
  );
};
