import React, { useCallback, useState } from 'react';
import { AnonymUserPage } from '../../AnonymUserPage';
import { CompanyRegisterSettings } from './CompanyRegisterSettings';
import { Styled as S } from '../AuthFeedback.styled';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Icon } from '../../widgets/Icon';

export const CompanyRegisterPage: React.FC = () => {
  const [registerRequested, setRegisterRequested] = useState<boolean>(false);

  const handleRegisterRequest = useCallback(() => {
    setRegisterRequested(true);
  }, []);

  return (
    <AnonymUserPage>
      {!registerRequested ? <CompanyRegisterSettings onRegisterRequest={handleRegisterRequest} /> : <RegisterThanks />}
    </AnonymUserPage>
  );
};

const RegisterThanks: React.FC = () => {
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  return (
    <S.Form>
      <S.Main>
        <S.CheckMark>
          <Icon name={'checkmark'} />
        </S.CheckMark>
        <S.Title>Thank you!</S.Title>
        <S.Info>
          We've accepted your company registration request.
          <br />
          Company will be created after administrator approvement.
        </S.Info>
        <S.Button onClick={redirectToLoginPage}>Return to login</S.Button>
      </S.Main>
    </S.Form>
  );
};
