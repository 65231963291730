import React from 'react';
import { Styled as S } from '../pages/tco/CapexCard.styled';
import { Point } from '@projectstorm/geometry';

export type SegmentInfo = {
  label: string;
  percent: number;
  color: string;
};

export interface RoundComparisonChartProps {
  comparableA: SegmentInfo;
  comparableB: SegmentInfo;
  percentOffset?: number;
  valueBoundary?: number;
  size?: number;
}

export const RoundComparisonChart: React.FC<RoundComparisonChartProps> = ({
  comparableA,
  comparableB,
  percentOffset = 20,
  valueBoundary = 12,
  size = 386,
}) => {
  const coordsCirclePoint = (degrees: number, radius: number, center: number): Point => {
    const rad = (Math.PI * degrees) / 180;
    return new Point(radius * Math.cos(rad) + center, radius * Math.sin(rad) + center);
  };

  const percentToDegree = (percent: number): number => {
    return (percent * 360) / 100;
  };

  const secondaryRadius = size / 1.24 / 2; //100%
  const primaryRadius = (secondaryRadius * 7) / 10; // 70%
  const center = size / 2;

  const primary = comparableA.percent >= comparableB.percent ? comparableA : comparableB;
  const secondary = comparableA.percent < comparableB.percent ? comparableA : comparableB;

  return (
    <>
      <S.Graph height={size} width={size}>
        <Segment segmentInfo={secondary} center={center} percentOffset={percentOffset} circleRadius={secondaryRadius} />
        <LabelInside
          segmentInfo={primary}
          coords={coordsCirclePoint(
            percentToDegree(secondary.percent / 2 - percentOffset) + 180,
            primaryRadius / 2,
            center
          )}
        />
        {secondary.percent > valueBoundary ? (
          <LabelInside
            segmentInfo={secondary}
            coords={coordsCirclePoint(
              percentToDegree(secondary.percent / 2 - percentOffset),
              (secondaryRadius * 2) / 3,
              center
            )}
          />
        ) : (
          <LabelOutside
            coords={coordsCirclePoint(percentToDegree(secondary.percent - percentOffset), secondaryRadius, center)}
            segmentInfo={secondary}
          />
        )}
      </S.Graph>
      <S.GraphCircle radius={primaryRadius} top={size - primaryRadius * 2} color={primary.color} />
    </>
  );
};

interface SquareProps {
  coords: Point;
  segmentInfo: SegmentInfo;
}

const LabelOutside: React.FC<SquareProps> = ({ coords, segmentInfo }) => {
  const line = 20;
  const shift = 4;
  return (
    <>
      <S.GraphText color={segmentInfo.color} x={coords.x + shift} y={coords.y + line}>
        {segmentInfo.label}
      </S.GraphText>
      <S.GraphText color={segmentInfo.color} x={coords.x + shift * 2} y={coords.y + 2 * line}>
        {segmentInfo.percent}%
      </S.GraphText>
    </>
  );
};

interface LabelProps {
  segmentInfo: SegmentInfo;
  coords: Point;
}

const LabelInside: React.FC<LabelProps> = ({ segmentInfo, coords }) => {
  const line = 20;

  return (
    <>
      <S.GraphText textAnchor="middle" x={coords.x} y={coords.y}>
        {segmentInfo.label}
      </S.GraphText>
      <S.GraphText textAnchor="middle" x={coords.x} y={coords.y + line}>
        {segmentInfo.percent}%
      </S.GraphText>
    </>
  );
};

interface SegmentProps {
  segmentInfo: SegmentInfo;
  center: number;
  percentOffset: number;
  circleRadius: number;
  main?: boolean;
}

const Segment: React.FC<SegmentProps> = ({ segmentInfo, center, percentOffset, circleRadius, main }) => {
  const fillPercent = Math.max(main ? 100 : segmentInfo.percent, 0.5);

  const percentToLength = (percent: number, radius: number): number => {
    return (percent * Math.PI * radius) / 100;
  };

  return (
    <>
      <g opacity="0.8">
        <circle
          cx={center}
          cy={center}
          r={circleRadius / 2}
          fill="transparent"
          stroke={segmentInfo.color}
          strokeWidth={circleRadius}
          strokeDasharray={[
            percentToLength(fillPercent, circleRadius),
            percentToLength(100 - fillPercent, circleRadius),
          ].toString()}
          strokeDashoffset={percentToLength(percentOffset, circleRadius)}
        />
      </g>
    </>
  );
};
