import React from 'react';
import { Styled as S } from './OpexCard.styled';
import { theme } from '../../../theme';
import { TcoSettingsDto } from '../../../api/nggrace-back';
import { OpexType } from './types';
import { OpexAccordion } from './OpexAccordion';

type OpexCardProps = {
  currency: string;
  opexForm: TcoSettingsDto;
  opex: OpexType;
};

export const OpexCard: React.FC<OpexCardProps> = ({ currency, opex, opexForm }) => {
  return (
    <S.OpexCard>
      <S.GraphContainer>
        <S.Graph viewBox="0 30 540 110" xmlns="http://www.w3.org/2000/svg">
          <path d="M478.751 35H174V125H478.751L521.335 77.7041L478.751 35Z" fill={theme.colors.purple} />
          <path d="M141.729 52L174.665 35V125L141.729 111V52Z" fill={theme.colors.darkpurple} />
          <rect y="52" width="141.728" height="59" fill={theme.colors.purple} />
          <S.GraphNameText x="30px" y="88px">
            SCADA
          </S.GraphNameText>
          <S.GraphValueText x="220px" y="92px">
            {opex.scada.cost} {currency}/year
          </S.GraphValueText>
        </S.Graph>
        <S.Graph viewBox="0 30 540 110" xmlns="http://www.w3.org/2000/svg">
          <path d="M478.751 35H174V125H478.751L521.335 77.7041L478.751 35Z" fill={theme.colors.turquoise} />
          <path d="M141.729 52L174.665 35V125L141.729 111V52Z" fill={theme.colors.darkturquoise} />
          <rect y="52" width="141.728" height="59" fill={theme.colors.turquoise} />
          <S.GraphNameText x="30px" y="88px">
            Protection
          </S.GraphNameText>
          <S.GraphValueText x="220px" y="92px">
            {opex.protection.cost} {currency}/year
          </S.GraphValueText>
        </S.Graph>
      </S.GraphContainer>
      <S.Content>
        <OpexAccordion currency={currency} form={opexForm} />
      </S.Content>
      <S.Footer>10000000 {currency}</S.Footer>
    </S.OpexCard>
  );
};
