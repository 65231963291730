import React, { useCallback } from 'react';
import { Styled as S } from './TcoSettingsForm.styled';
import { InputField } from '../../widgets/InputField';
import { CheckboxField } from '../../widgets/CheckboxField';
import moment from 'moment';
import { CurrencyDirectoryId, TcoSettingsDto } from '../../../api/nggrace-back';
import { InputSelect } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';

type OpexFormProps = {
  currency: string;
  form: TcoSettingsDto;
  disabled?: boolean;
  onChange?(action: (form: TcoSettingsDto) => TcoSettingsDto): void;
};

export const TcoSettingsForm: React.FC<OpexFormProps> = ({ form, disabled, onChange }) => {
  const currency = useDirectory();

  const handleCurrencyChange = useCallback(
    (currencyDirectoryId: CurrencyDirectoryId) =>
      onChange &&
      onChange((form) => ({
        ...form,
        currencyDirectoryId,
      })),
    [onChange]
  );

  const handleCurrencyExchangeEnabledChange = useCallback(
    (autoCurrencyExchangeEnabled: boolean) =>
      onChange &&
      onChange((form) => ({
        ...form,
        autoCurrencyExchangeEnabled,
      })),
    [onChange]
  );

  const handleBillingPeriodChange = useCallback(
    (billingPeriodInYears: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        billingPeriodInYears,
      })),
    [onChange]
  );

  const handleBasicCostPerPersonHourChange = useCallback(
    (basicCostPerPersonHour: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        basicCostPerPersonHour,
      })),
    [onChange]
  );

  const handleDiscountRateChange = useCallback(
    (discountRate: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        discountRate,
      })),
    [onChange]
  );

  const handleExchangeRubToUsdChange = useCallback(
    (currencyExchangeRateRubToUsd: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        currencyExchangeRateRubToUsd,
      })),
    [onChange]
  );

  const handleExchangeUsdToRubChange = useCallback(
    (currencyExchangeRateUsdToRub: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        currencyExchangeRateUsdToRub,
      })),
    [onChange]
  );

  const handleExchangeRubToEurChange = useCallback(
    (currencyExchangeRateRubToEur: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        currencyExchangeRateRubToEur,
      })),
    [onChange]
  );

  const handleExchangeEurToRubChange = useCallback(
    (currencyExchangeRateEurToRub: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        currencyExchangeRateEurToRub,
      })),
    [onChange]
  );

  const handleMaintenanceSupplyScadaChange = useCallback(
    (maintenanceSupplyScada: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        maintenanceSupplyScada,
      })),
    [onChange]
  );

  const handleMaintenanceSupplyProtectionChange = useCallback(
    (maintenanceSupplyProtection: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        maintenanceSupplyProtection,
      })),
    [onChange]
  );

  const handleDesignScadaChange = useCallback(
    (designScada: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        designScada,
      })),
    [onChange]
  );

  const handleDesignProtectionChange = useCallback(
    (designProtection: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        designProtection,
      })),
    [onChange]
  );

  const handleConstructionAndInstallationWorksScadaChange = useCallback(
    (constructionAndInstallationWorksScada: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        constructionAndInstallationWorksScada,
      })),
    [onChange]
  );

  const handleConstructionAndInstallationWorksProtectionChange = useCallback(
    (constructionAndInstallationWorksProtection: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        constructionAndInstallationWorksProtection,
      })),
    [onChange]
  );

  const handleCommissioningWorksScadaChange = useCallback(
    (commissioningWorksScada: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        commissioningWorksScada,
      })),
    [onChange]
  );

  const handleCommissioningWorksProtectionChange = useCallback(
    (commissioningWorksProtection: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        commissioningWorksProtection,
      })),
    [onChange]
  );

  return (
    <S.OpexForm>
      <S.LeftSide>
        <S.InputFields>
          <InputSelect
            label={'Currency'}
            selected={form.currencyDirectoryId}
            options={currency.currencyDirectory.getAll().map((it) => ({ id: it.id, name: it.name.en }))}
            onChange={handleCurrencyChange}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.billingPeriodInYears}
            disabled={disabled}
            onChange={handleBillingPeriodChange}
            label={'Billing period, years'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.basicCostPerPersonHour}
            disabled={disabled}
            onChange={handleBasicCostPerPersonHourChange}
            label={'Basic cost per person / hour'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.discountRate}
            disabled={disabled}
            onChange={handleDiscountRateChange}
            label={'Discount Rate, %'}
          />
        </S.InputFields>
        <S.CheckBoxUnit>
          <S.UnitLegend>Currency exchange rate</S.UnitLegend>
          <CheckboxField
            value={form.autoCurrencyExchangeEnabled}
            disabled={disabled}
            onChange={handleCurrencyExchangeEnabledChange}
          ></CheckboxField>
          <S.UnitLegend>Central Bank of Russia {moment(new Date()).format('DD/MM/yyyy')}</S.UnitLegend>
        </S.CheckBoxUnit>
        <S.ExchangeRateUnits>
          <S.ExchangeRateUnit>
            <InputField
              type="number"
              min={0}
              step={1}
              value={form.currencyExchangeRateRubToUsd}
              disabled={true}
              onChange={handleExchangeRubToUsdChange}
              label={'RUB'}
            />
            <InputField
              type="number"
              min={0}
              step={1}
              value={form.currencyExchangeRateUsdToRub}
              disabled={form.autoCurrencyExchangeEnabled}
              onChange={handleExchangeUsdToRubChange}
              label={'USD'}
            />
          </S.ExchangeRateUnit>
          <S.ExchangeRateUnit>
            <InputField
              type="number"
              min={0}
              step={1}
              value={form.currencyExchangeRateRubToEur}
              disabled={true}
              onChange={handleExchangeRubToEurChange}
              label={'RUB'}
            />
            <InputField
              type="number"
              min={0}
              step={1}
              value={form.currencyExchangeRateEurToRub}
              disabled={form.autoCurrencyExchangeEnabled}
              onChange={handleExchangeEurToRubChange}
              label={'EUR'}
            />
          </S.ExchangeRateUnit>
        </S.ExchangeRateUnits>
      </S.LeftSide>
      <S.RightSide>
        <S.UnitLegend>
          <p>Maintenance supply</p>
        </S.UnitLegend>
        <S.ExchangeRateUnit>
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.maintenanceSupplyScada}
            disabled={disabled}
            onChange={handleMaintenanceSupplyScadaChange}
            label={'SCADA, %'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.maintenanceSupplyProtection}
            disabled={disabled}
            onChange={handleMaintenanceSupplyProtectionChange}
            label={'PACS, %'}
          />
        </S.ExchangeRateUnit>
        <S.UnitLegend>Design</S.UnitLegend>
        <S.ExchangeRateUnit>
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.designScada}
            disabled={disabled}
            onChange={handleDesignScadaChange}
            label={'SCADA, %'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.designProtection}
            disabled={disabled}
            onChange={handleDesignProtectionChange}
            label={'PACS, %'}
          />
        </S.ExchangeRateUnit>
        <S.UnitLegend>Construction and installation works</S.UnitLegend>
        <S.ExchangeRateUnit>
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.constructionAndInstallationWorksScada}
            disabled={disabled}
            onChange={handleConstructionAndInstallationWorksScadaChange}
            label={'SCADA, %'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.constructionAndInstallationWorksProtection}
            disabled={disabled}
            onChange={handleConstructionAndInstallationWorksProtectionChange}
            label={'PACS, %'}
          />
        </S.ExchangeRateUnit>
        <S.UnitLegend>Commissioning works</S.UnitLegend>
        <S.ExchangeRateUnit>
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.commissioningWorksScada}
            disabled={disabled}
            onChange={handleCommissioningWorksScadaChange}
            label={'SCADA, %'}
          />
          <InputField
            type="number"
            min={0}
            step={1}
            value={form.commissioningWorksProtection}
            disabled={disabled}
            onChange={handleCommissioningWorksProtectionChange}
            label={'PACS, %'}
          />
        </S.ExchangeRateUnit>
      </S.RightSide>
    </S.OpexForm>
  );
};
