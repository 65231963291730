import * as React from 'react';
import { PortModel, PortWidget } from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import { PortRecord } from './NodeDirectory';
import { DirectoryPortSize, DirectoryPortVisibleRadius } from './DirectoryPortModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export interface DirectoryPortWidgetProps {
  port: PortModel;
  engine: DiagramEngine;
  record: PortRecord;
}

export const PortPlacement = styled.div<{ placementSize: number }>`
  width: ${(p) => p.placementSize}px;
  height: ${(p) => p.placementSize}px;
  z-index: 10;
  cursor: pointer;
  position: absolute;
`;

export const PortOutline = styled.div<{ record: PortRecord; radius: number }>`
  width: ${(p) => p.radius * 2 + 1}px;
  height: ${(p) => p.radius * 2 + 1}px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: ${(p) => p.record.y - p.radius}px;
  left: ${(p) => p.record.x - p.radius}px;
`;

export const PortCenter = styled.div<{ radius: number; outlineSize: number }>`
  top: ${(p) => p.outlineSize}px;
  left: ${(p) => p.outlineSize}px;
  width: ${(p) => p.radius * 2 + 1 - p.outlineSize * 2}px;
  height: ${(p) => p.radius * 2 + 1 - p.outlineSize * 2}px;
  background: white;
  border-radius: 50%;
  position: relative;
`;

export const DirectoryPortWidget: React.FC<DirectoryPortWidgetProps> = ({ port, engine, record }) => {
  return (
    <>
      <PortWidget
        style={{
          top: record.y - (DirectoryPortSize - 1) / 2,
          left: record.x - (DirectoryPortSize - 1) / 2,
          width: DirectoryPortSize,
          height: DirectoryPortSize,
          position: 'absolute',
        }}
        port={port}
        engine={engine as any}
      >
        <PortPlacement placementSize={DirectoryPortSize} />
      </PortWidget>
      <PortOutline record={record} radius={DirectoryPortVisibleRadius}>
        <PortCenter radius={DirectoryPortVisibleRadius} outlineSize={1} />
      </PortOutline>
    </>
  );
};
