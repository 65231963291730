import { BaseModel } from '@projectstorm/react-canvas-core';
import { DefaultHasChildren, HasChildren, HasChildrenListener } from './HasChildren';
import { Listenable } from './Listenable';
import { DefaultHasRect, HasRect } from './HasRect';
import { HasRelativeModel } from './HasRelativeModel';
import { BasePoint } from '../geometry/Point';
import { Factory } from '../../../utils/factory';
import { DefaultHasPosition, HasPosition } from './HasPosition';
import { PlaceholderHasSize } from './size-computation/PlaceholderHasSize';
import { HasSize, HasSizedChildrenListener } from './HasSize';
import { PlaceholderCachingHasSize } from './size-computation/PlaceholderCachingHasSize';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { Point } from '@projectstorm/geometry';
import { StageType } from '../../../api/nggrace-back';
import { HasAbsoluteRect } from './size-computation/HasAbsoluteRect';

export const PlaceholderGroupOffset = new Point(15, 0);
export type PlaceholderBaseChild = BaseModel & HasRect;
export type StagedSerializedPlaceholder = { [key in StageType]?: { position: BasePoint; size: BasePoint } };

export type PlaceholderBaseRelativeModel = BaseModel & Listenable<HasSizedChildrenListener> & RotatableNodeModel;

export type PlaceholderHasSizeFactory<
  CHILD extends PlaceholderBaseChild,
  RELATIVE_MODEL extends PlaceholderBaseRelativeModel
> = Factory<
  {
    hasChildren: HasChildren<CHILD>;
    hasRelativeModel: HasRelativeModel<RELATIVE_MODEL>;
    eventDelegate: Listenable<HasChildrenListener<CHILD>>;
  },
  HasSize
>;

export type PlaceholderHasPositionFactory<CHILD extends PlaceholderBaseChild> = Factory<
  Listenable<HasChildrenListener<CHILD>>,
  HasPosition
>;

export type PlaceholderHasRectFactory<RELATIVE_MODEL extends PlaceholderBaseRelativeModel> = Factory<
  { hasPosition: HasPosition; hasSize: HasSize; hasRelativeModel: HasRelativeModel<RELATIVE_MODEL> },
  HasRect
>;

export interface Placeholder<CHILD extends PlaceholderBaseChild, MODEL extends PlaceholderBaseRelativeModel>
  extends HasChildren<CHILD>,
    HasRect,
    HasRelativeModel<MODEL> {}

export type PlaceholderOrigin<
  CHILD extends PlaceholderBaseChild,
  MODEL extends PlaceholderBaseRelativeModel
> = Placeholder<CHILD, MODEL> & BaseModel;

export class DefaultPlaceholder<CHILD extends PlaceholderBaseChild, RELATIVE_MODEL extends PlaceholderBaseRelativeModel>
  implements HasChildren<CHILD>, HasRect, HasRelativeModel<RELATIVE_MODEL> {
  private readonly hasChildren: HasChildren<CHILD>;
  private readonly hasRect: HasRect;
  private readonly origin: PlaceholderOrigin<CHILD, RELATIVE_MODEL>;
  private readonly hasRelativeModel: HasRelativeModel<RELATIVE_MODEL>;

  constructor(
    origin: PlaceholderOrigin<CHILD, RELATIVE_MODEL>,
    eventDelegate: Listenable<HasChildrenListener<CHILD>>,
    hasRelativeModelFactory: Factory<PlaceholderOrigin<CHILD, RELATIVE_MODEL>, HasRelativeModel<RELATIVE_MODEL>>,
    hasSizeFactory: PlaceholderHasSizeFactory<CHILD, RELATIVE_MODEL> = DefaultPlaceholderHasSizeFactory,
    hasPositionFactory: PlaceholderHasPositionFactory<CHILD> = DefaultPlaceholderHasPositionFactory,
    hasRectFactory: PlaceholderHasRectFactory<RELATIVE_MODEL> = DefaultPlaceholderHasRectFactory
  ) {
    this.origin = origin;
    this.hasChildren = new DefaultHasChildren<CHILD>(eventDelegate);
    this.hasRelativeModel = hasRelativeModelFactory(origin);
    this.hasRect = hasRectFactory({
      hasPosition: hasPositionFactory(eventDelegate),
      hasSize: hasSizeFactory({
        hasChildren: this.hasChildren,
        eventDelegate,
        hasRelativeModel: this.hasRelativeModel,
      }),
      hasRelativeModel: this.hasRelativeModel,
    });
  }

  getChildren() {
    return [...this.hasChildren.getChildren()]!;
  }

  setRelativeModel(model: RELATIVE_MODEL, index?: number) {
    this.hasRelativeModel.setRelativeModel(model, index);
  }

  getRelativeModel() {
    return this.hasRelativeModel.getRelativeModel();
  }

  addChild(child: CHILD, index?: number) {
    this.hasChildren.addChild(child, index);
  }

  getRect() {
    return this.hasRect.getRect();
  }

  getPosition(): BasePoint {
    return this.hasRect.getPosition();
  }

  getSize(): BasePoint {
    return this.hasRect.getSize();
  }

  setPosition(newValue: BasePoint): void {
    this.hasRect.setPosition(newValue);
  }
}

const DefaultPlaceholderHasSizeFactory: PlaceholderHasSizeFactory<any, any> = ({
  hasChildren,
  hasRelativeModel,
  eventDelegate,
}) => new PlaceholderCachingHasSize(new PlaceholderHasSize(hasChildren), eventDelegate, hasRelativeModel);

const DefaultPlaceholderHasPositionFactory: PlaceholderHasPositionFactory<any> = (eventDelegate) =>
  new DefaultHasPosition(eventDelegate as any);

const DefaultPlaceholderHasRectFactory: PlaceholderHasRectFactory<any> = ({ hasSize, hasPosition, hasRelativeModel }) =>
  new HasAbsoluteRect(new DefaultHasRect(hasPosition, hasSize), hasRelativeModel);
