import React from 'react';
import { Styled as S } from './EmptyList.styled';
import { keyframes } from 'styled-components';

export const EmptyList: React.FC<{ height: string }> = ({ height }) => {
  const animation = keyframes`
        0% {opacity: 0;}
        100% {opacity: 1;}
    `;
  return (
    <S.Container height={height} keyframes={animation}>
      <S.Logo>
        <S.Text>
          The list of items is empty.
          <br />
          Add the first element using the "New" button
        </S.Text>
      </S.Logo>
    </S.Container>
  );
};
