import styled from 'styled-components';
import background from '../assets/auth_background.png';

const Page = styled.div`
  height: 100vh;
  background: url(${background}) no-repeat;
  background-size: cover;
`;

const PageContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Styled = {
  Page,
  PageContent,
};
