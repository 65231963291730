import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 900px;
  width: 1115px;
  flex-direction: column;
  overflow-y: scroll;
`;

const ColumnName = styled.div``;

const Header = styled.div`
  display: grid;
  grid-template-columns: 226px 83px 81px 222px 83px 189px 206px;
  font-family: ${(props) => props.theme.fonts.onlyPrimary};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const Styled = {
  Container,
  Header,
  ColumnName,
};
