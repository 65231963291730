import { useEffect, useState } from 'react';
import { NgGraceEngine } from '../editor/NgGraceEngine';
import { StageDto } from '../../api/nggrace-back';

export const useStageState = <T>(engine: NgGraceEngine, stage: StageDto, callback: () => T) => {
  const [state, setState] = useState<T>(callback);

  // watch selection change
  useEffect(() => engine.onSelectionChange(() => setState(callback)), [callback, engine]);

  // watch change
  useEffect(() => {
    return engine.onChange(() => {
      // wait until other listeners finish their job (eg undo/redo listener)
      setTimeout(() => setState(callback));
    });
  }, [callback, engine]);

  // watch base info change
  useEffect(() => setState(callback), [callback, stage.baseInfo.finished]);

  return state;
};
