import React, { useCallback, useContext, useMemo } from 'react';
import { DiagramModelContext } from '../../../editor/DiagramModelContext';
import { SsdModel } from '../../../editor/ssd/SsdModel';
import { EngineContext } from '../../../editor/EngineContext';
import { SsdEngine } from '../../../editor/ssd/SsdEngine';
import { useDirectory } from '../../../hooks/useDirectory';
import { SsdStructureTreeStation } from './SsdStructureTreeStation';
import { SsdStructureTreeVoltageLevelFactory } from './SsdStructureTreeVoltageLevel';
import { SsdStructureTreeNodeFactory } from './SsdStructureTreeNode';
import {
  SsdStructureTreeLogicDeviceFactory,
  SsdStructureTreeLogicDeviceModelFactory,
} from './SsdStructureTreeLogicDevice';
import { SsdStructureTreeLogicNodeFactory } from './SsdStructureTreeLogicNode';
import { TreeNode, TreeNodeChildFactory } from '../../../widgets/tree/TreeNode';
import { Styled as S } from '../../../widgets/library/Library.styled';
import { Tree } from '../../../widgets/tree/Tree';
import { SelectableTreeNodeWidget } from '../../../widgets/tree/SelectableTreeNodeWidget';
import {
  ExtendableTreeNode,
  HasExtendableParent,
  TreeDraggableType,
  TreeNodeModel,
} from '../../../widgets/tree/state/TreeState';
import { ListDropHighlighter } from '../../../widgets/tree/ListDropHighlighter';
import { TreeDroppable } from '../../../widgets/tree/TreeDroppable';
import { TreeDraggable } from '../../../widgets/tree/TreeDraggable';

export const SsdStructureTreeDndFormatDragId: Partial<Record<TreeDraggableType, string>> = {
  LogicDevice: 'SsdStructureTreeDndFormatLd',
  LogicNode: 'SsdStructureTreeDndFormatLn',
};
export const SsdStructureTreeDndFormatDropId: Partial<Record<TreeDraggableType, string[]>> = {
  LogicDevice: [
    SsdStructureTreeDndFormatDragId[TreeDraggableType.LogicDevice]!,
    SsdStructureTreeDndFormatDragId[TreeDraggableType.LogicNode]!,
  ],
  LogicNode: [SsdStructureTreeDndFormatDragId[TreeDraggableType.LogicNode]!],
};

interface SsdStructureTreeProps {
  station?: string;
  locked?: boolean;
}

export const SsdStructureTree: React.FC<SsdStructureTreeProps> = ({ station, locked }) => {
  const model = useContext(DiagramModelContext) as SsdModel;
  const engine = useContext(EngineContext) as SsdEngine;
  const { voltageLevelDirectory, logicNodeDirectory, logicDeviceDirectory } = useDirectory();

  const stationNode = useMemo(() => {
    const logicDeviceLayer = model.getLogicDeviceLayer();
    return SsdStructureTreeStation(
      station || 'Station',
      model,
      voltageLevelDirectory,
      SsdStructureTreeVoltageLevelFactory(
        SsdStructureTreeNodeFactory(
          engine,
          SsdStructureTreeLogicDeviceModelFactory(logicDeviceDirectory, logicDeviceLayer, logicNodeDirectory),
          SsdStructureTreeLogicDeviceFactory(
            engine,
            logicDeviceLayer,
            logicNodeDirectory,
            SsdStructureTreeLogicNodeFactory(engine)
          )
        )
      )
    );
  }, [model, engine, logicDeviceDirectory, logicNodeDirectory, voltageLevelDirectory, station]);

  const voltageLevelFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
    <SsdStructureTreeDroppable key={model.getKey()} model={stationNode} type={TreeDraggableType.LogicDevice}>
      <TreeNode
        model={model}
        childFactory={locked ? lockedNodeFactory : nodeFactory}
        indentLevel={indentLevel + 1}
        last={last}
      />
    </SsdStructureTreeDroppable>
  );

  return (
    <S.Library>
      <S.Header>{'Station structure'}</S.Header>
      <Tree>
        <SsdStructureTreeDroppable model={stationNode} type={TreeDraggableType.LogicDevice}>
          <TreeNode
            model={stationNode}
            childFactory={voltageLevelFactory}
            hasParent={false}
            indentLevel={0}
            last
            initialOpen
          />
        </SsdStructureTreeDroppable>
      </Tree>
    </S.Library>
  );
};

const lockedLogicNodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode
      model={model}
      childFactory={lockedLogicNodeFactory}
      canHighlight
      indentLevel={indentLevel + 1}
      last={last}
    />
  </SelectableTreeNodeWidget>
);

const lockedLogicDeviceFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode
      model={model}
      childFactory={lockedLogicNodeFactory}
      canHighlight
      indentLevel={indentLevel + 1}
      last={last}
    />
  </SelectableTreeNodeWidget>
);

const lockedNodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode
      model={model}
      childFactory={lockedLogicDeviceFactory}
      canHighlight
      indentLevel={indentLevel + 1}
      last={last}
    />
  </SelectableTreeNodeWidget>
);

const logicNodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <SsdStructureTreeDraggable type={TreeDraggableType.LogicNode} model={model}>
      <SsdStructureTreeDroppable model={model} type={TreeDraggableType.LogicNode}>
        <TreeNode model={model} canHighlight indentLevel={indentLevel + 1} last={last} />
      </SsdStructureTreeDroppable>
    </SsdStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

const logicDeviceFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <SsdStructureTreeDraggable type={TreeDraggableType.LogicDevice} model={model}>
      <SsdStructureTreeDroppable model={model} type={TreeDraggableType.LogicDevice}>
        <TreeNode
          model={model}
          childFactory={logicNodeFactory}
          canHighlight
          indentLevel={indentLevel + 1}
          last={last}
        />
      </SsdStructureTreeDroppable>
    </SsdStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

const nodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <SsdStructureTreeDroppable model={model} type={TreeDraggableType.LogicDevice}>
      <TreeNode
        model={model}
        childFactory={logicDeviceFactory}
        canHighlight
        indentLevel={indentLevel + 1}
        last={last}
      />
    </SsdStructureTreeDroppable>
  </SelectableTreeNodeWidget>
);

interface SsdStructureTreeDraggableProps {
  type: TreeDraggableType;
  model: TreeNodeModel;
}

const SsdStructureTreeDraggable: React.FC<SsdStructureTreeDraggableProps> = ({ type, model, children }) => {
  const payload = useMemo(() => ({ type, modelId: model.getKey() }), [model, type]);
  const handleGetDragElement = useCallback(() => undefined, []);
  return (
    <TreeDraggable
      getDragElement={handleGetDragElement}
      formatId={SsdStructureTreeDndFormatDragId[type]!}
      payload={payload}
    >
      {children}
    </TreeDraggable>
  );
};

interface SsdStructureTreeDroppableProps {
  model: ExtendableTreeNode & HasExtendableParent;
  type: TreeDraggableType;
}

const SsdStructureTreeDroppable: React.FC<SsdStructureTreeDroppableProps> = ({ model, type, children }) => {
  return (
    <TreeDroppable formatId={SsdStructureTreeDndFormatDropId[type]!} model={model} dropChild={ListDropHighlighter}>
      {children}
    </TreeDroppable>
  );
};
