import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import { DirectoryLogicNodeModel } from '../../../editor/ssd/logic-device/LogicNodeModel';
import { TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import { ScdStructureTreeLogicNodeProps } from './ScdStructureTreeLogicNode';
import {
  HasNotExtendableParentModel,
  NavigatingSelectableTreeModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdNodeModel } from '../../../editor/scd/ScdModel';

export const ScdStructureTreeLogicDeviceFactory = (
  engine: ScdEngine,
  logicNodeFactory: Factory<ScdStructureTreeLogicNodeProps, TreeNodeState>
): Factory<ScdStructureLogicDeviceModelProps, TreeNodeState> => ({ logicDevice }) =>
  ScdStructureTreeLogicDevice(logicDevice, engine, logicNodeFactory);

export interface ScdStructureLogicDeviceModelProps {
  logicDevice: DirectoryLogicDeviceModel<ScdNodeModel>;
}

const ScdStructureTreeLogicDevice = (
  device: DirectoryLogicDeviceModel<ScdNodeModel>,
  engine: ScdEngine,
  logicNodeFactory: Factory<ScdStructureTreeLogicNodeProps, TreeNodeState>
): TreeNodeState => {
  const logicNodeMapping = (node: DirectoryLogicNodeModel) => logicNodeFactory({ logicNode: node });
  const model = ScdStructureTreeLogicDeviceModel(device, logicNodeMapping);

  return {
    ...model,
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
    ...NavigatingSelectableTreeModel(
      SelectableTreeBaseModel(device, engine),
      device.getRelativeModel()!.getID(),
      engine
    ),
  };
};

const ScdStructureTreeLogicDeviceModel = (
  device: DirectoryLogicDeviceModel<ScdNodeModel>,
  logicNodeMapping: (node: DirectoryLogicNodeModel) => TreeNodeState
): TreeNodeModel => ({
  getName: () => device.getCodeName(),
  getKey: () => device.getID(),
  onChildrenChanged: (cb) =>
    device.registerListener({
      childrenChanged: (event: { child: DirectoryLogicNodeModel; created: boolean }) =>
        cb(logicNodeMapping(event.child), event.created),
    } as any).deregister,
  getChildren: () => device.getChildren().map(logicNodeMapping),
});
