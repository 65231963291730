import React from 'react';
import { Styled as S } from './Toast.styled';
import { Icon, IconName } from './Icon';
import { theme } from '../../theme';

export enum ToastType {
  ERROR = 'ERROR',
  HINT = 'HINT',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

interface ToastTheme {
  icon: string;
  color: string;
}

const ToastThemes: { [key in ToastType]: ToastTheme } = {
  ERROR: { icon: 'error', color: theme.colors.red },
  INFO: { icon: 'info', color: theme.colors.blue },
  SUCCESS: { icon: 'checkmark', color: theme.colors.turquoise },
  HINT: { icon: 'question', color: theme.colors.dark },
};

interface ToastProps {
  title: string;
  text: React.ReactNode | string;
  type: ToastType;
}

export const Toast = React.forwardRef<HTMLDivElement, ToastProps>(({ type, title, text }, ref) => {
  const theme = ToastThemes[type];
  return (
    <S.Toast ref={ref} color={theme.color}>
      <S.Title type={type}>
        <Icon name={theme.icon as IconName} />
        {title}
      </S.Title>
      <S.Text>{text}</S.Text>
    </S.Toast>
  );
});
