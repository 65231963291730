import styled from 'styled-components';
import { Btn } from './Btn.styled';
import { Icon } from './Icon.styled';

const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.blue};
`;

const ConfirmationModalTitle = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  position: relative;

  ${Icon} {
    height: 30px;
    width: 30px;
    position: absolute;
    left: -48px;
  }
`;

const ConfirmationModelText = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PrimaryButton = styled(Btn)`
  width: 100px;
`;

export const Styled = {
  ConfirmationModal,
  ConfirmationModalTitle,
  ConfirmationModelText,
  Buttons,
  PrimaryButton,
  Button: Btn,
};
