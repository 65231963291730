import React, { useCallback, useState } from 'react';
import Logo from '../assets/logo.svg';
import { Styled as S } from './PageHeader.styled';
import { Icon } from './widgets/Icon';
import { useUser } from './pages/login/UserContext';
import { Api } from '../api/Api';
import { useRoutes } from '../routes/hooks/useRoutes';
import { convertRole } from '../utils/role-utils';
import { theme } from '../theme';
import { Confirmation } from './widgets/Confirmation';
import ReactModal from 'react-modal';

interface PageHeaderProps {
  onClickMenu: () => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ onClickMenu }) => {
  const { user, setUser } = useUser()!;
  const redirectToLogoutPage = useRoutes().redirectToLogoutPage;
  const [logoutConfirmVisibility, setLogoutConfirmVisibility] = useState(false);

  const handleLogout = useCallback(
    () =>
      Api.logout().then(() => {
        setUser(undefined);
        redirectToLogoutPage();
      }),
    [redirectToLogoutPage, setUser]
  );

  const handleCloseConfirmLogoutModal = () => {
    setLogoutConfirmVisibility(false);
  };
  const handleClickOnLogoutButton = () => {
    setLogoutConfirmVisibility(true);
  };

  return (
    <>
      <S.PageHeader>
        <S.MenuBtn onClick={onClickMenu}>
          <Icon name={'list'} />
        </S.MenuBtn>
        <S.Company>{user!.company.name}</S.Company>
        <S.LogoLink to="/">
          <img src={Logo} alt="ngGrace Logo" />
        </S.LogoLink>
        <S.Options>
          <S.Username>
            {user!.name} ({convertRole(user!.role)})
          </S.Username>
          <S.LogoutBtn onClick={handleClickOnLogoutButton}>
            <Icon name="logout" />
          </S.LogoutBtn>
        </S.Options>
      </S.PageHeader>
      <ReactModal
        isOpen={logoutConfirmVisibility}
        onRequestClose={handleCloseConfirmLogoutModal}
        style={theme.modalInfo}
      >
        <Confirmation
          title={'Logout'}
          text={`Are you sure you want to logout?`}
          onClose={handleCloseConfirmLogoutModal}
          onConfirm={handleLogout}
        />
      </ReactModal>
    </>
  );
};
