import React, { useCallback, useContext, useState } from 'react';
import { StageDto } from '../../../api/nggrace-back';
import { EngineContext } from '../../editor/EngineContext';
import { DiagramCanvas } from '../../editor/DiagramCanvas';
import { Styled as S } from '../../DiagramEditor.styled';
import { Properties } from '../../editor/properties/Properties';
import { SldLibrary } from './SldLibrary';
import { BusTransformersLayoutGraph } from '../../editor/auto-layout/BusTransformersLayoutGraph';
import { ZoomWidget } from '../../editor/ZoomWidget';
import { CopyPasteEditor } from '../../editor/CopyPasteEditor';
import { SldToolbar } from './SldToolbar';
import { useDirectory } from '../../hooks/useDirectory';
import { getApprovedBy } from '../../../utils/project-utils';
import { useNotifications } from '../../context/NotificationContext';
import { Overlay } from '../../widgets/Overlay';
import { useNotifyOnSuccessCallback } from '../../hooks/useNotifyOnSuccessCallback';
import {Api} from "../../../api/Api";

type SldDiagramEditorProps = {
  stage: StageDto;

  onNextStage(): void;
  onValidateStage(): Promise<boolean>;
  onFinishStage(): void;
  onRollbackStage(): void;
};

export const SldDiagramEditor: React.FC<SldDiagramEditorProps> = ({
  stage,
  onNextStage,
  onValidateStage,
  onFinishStage,
  onRollbackStage,
}) => {
  const engine = useContext(EngineContext);
  const { voltageLevelDirectory, getNodeDirectory } = useDirectory();
  const nodeDirectory = getNodeDirectory(stage.project.styleSelection);
  const header = 'Single Line Diagram' + getApprovedBy(stage.baseInfo);

  const notifications = useNotifications();
  const [overlayShown, setOverlayShown] = useState(false);

  const handleValidationStage = useNotifyOnSuccessCallback(onValidateStage, 'Validation completed');

  const handleLayout = useCallback(() => {
    setOverlayShown(true);
    setTimeout(async () => {
      try {
        const model = engine.getModel();
        const {data} =  await Api.layoutStageDiagram(stage.baseInfo.id);
        if (data.success) {
            const newModel = engine.buildNewModel();
            newModel.deserializeModel(JSON.parse(data.diagram!), engine).then(() => {
                model.getLinks().forEach((oldLink) => oldLink.remove());
                model.addAll(...newModel.getLinks());
                model.addAll(...newModel.getNodes())
                model.clearSelection();
                engine.repaintCanvas();
            });
            console.log("Layout has been done by back-end service")
        } else {
            const layout = new BusTransformersLayoutGraph(model, voltageLevelDirectory);
            layout.dispose();
            engine.repaintCanvas();
            console.log("Layout has been done by front-end script")
        }
      } catch (error) {
        notifications.notifyError(error.message, 'Auto-layout failed');
      } finally {
        setOverlayShown(false);
      }
    });
  }, [engine, notifications, voltageLevelDirectory]);

  return (
    <S.DiagramEditor>
      <CopyPasteEditor>
        <SldToolbar
          stage={stage}
          onNextStage={onNextStage}
          onValidateStage={handleValidationStage}
          onFinishStage={onFinishStage}
          onRollbackStage={onRollbackStage}
          onLayout={handleLayout}
        />
        <S.Canvas>
          <Properties directory={nodeDirectory} />
          <DiagramCanvas header={header} directory={nodeDirectory} />
          <SldLibrary directory={nodeDirectory} />
        </S.Canvas>
        <ZoomWidget />
      </CopyPasteEditor>
      {overlayShown && <Overlay />}
    </S.DiagramEditor>
  );
};
