import { HasSize, HasSizedChildrenListener, HasSizeListener } from '../HasSize';
import { BasePoint } from '../../geometry/Point';
import { Listenable } from '../Listenable';
import { HasChildrenListener } from '../HasChildren';

export class PlaceholderGroupCachingHasSize implements HasSize {
  private origin: HasSize;
  private cachedValue?: BasePoint;

  constructor(
    origin: HasSize,
    eventDelegate: Listenable<HasChildrenListener<any> & HasSizeListener & HasSizedChildrenListener>,
    deserializedSize?: BasePoint
  ) {
    this.origin = origin;
    this.cachedValue = deserializedSize;

    const childrenListener = () => {
      const newSize = this.origin.getSize();
      if (!this.cachedValue || !this.cachedValue.equals(newSize)) {
        this.cachedValue = newSize;
        eventDelegate.fireEvent({ newSize }, 'sizeChanged');
      }
    };

    const registerListener = () =>
      eventDelegate.registerListener({
        childrenChanged: childrenListener,
        childSizeChanged: childrenListener,
      });

    if (!this.cachedValue) {
      registerListener();
    } else {
      setTimeout(registerListener);
    }
  }

  getSize(): BasePoint {
    if (!this.cachedValue) {
      this.cachedValue = this.origin.getSize();
    }

    return this.cachedValue;
  }
}
