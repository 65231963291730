import { Node, NodeType } from './Node';
import { Rectangle } from '@projectstorm/geometry';
import { SmartRotationHour } from '../../geometry/RotationHour';

export class NormalizedNode implements Node {
  private origin: Node;

  constructor(node: Node) {
    this.origin = node;
  }

  getID(): string {
    return this.origin.getID();
  }

  getRect(): Rectangle {
    const original = this.origin.getRect();
    const width = original.getWidth();
    const height = original.getHeight();
    return new Rectangle(-width / 2, -height / 2, width, height);
  }

  getType(): NodeType {
    return this.origin.getType();
  }

  getHour(): SmartRotationHour {
    return this.origin.getHour();
  }
}
