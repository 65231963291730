import styled, { css } from 'styled-components';

import { Styled as SecondStageCardContentStyled } from '../../second-stage-content/SecondStageCardContent.styled';
import { Styled as InputField } from '../../../../../../../widgets/InputField.styled';
import { Styled as InputSelect } from '../../../../../../../widgets/InputSelect.styled';
import { List as L, List } from '../../../../../../../widgets/List.styled';
import { Icon } from '../../../../../../../widgets/Icon.styled';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  border-radius: 0 16px 16px 16px;
  justify-content: space-between;
  padding: 20px 22px;
`;

const Column = styled(SecondStageCardContentStyled.Column)`
  width: 32.5%;
  margin: 0;
  max-height: 539px;
`;

const DatasetListGrid = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 11px;
  }

  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1.5fr) minmax(0, 1.5fr)
    minmax(0, 0.75fr) minmax(0, 0.75fr);
`;

const DatasetGrid = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 10px;
  }

  grid-template-columns:
    minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

const Cell = styled(SecondStageCardContentStyled.Cell)`
  padding-left: 0;

  ${List.Button} {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.black};
  }

  ${InputField.InputContainer} {
    min-height: unset;
  }

  ${InputField.Input} {
    border: none;
    background: none;
    padding: 0;
    max-height: 36px;
    min-height: unset;
  }

  ${InputField.Input}:focus {
    border: none;
  }

  ${InputField.InputField},
  ${InputSelect.InputOption} {
    max-height: 36px;
    margin-top: 0;
    width: 100%;
  }

  ${InputSelect.InputSelect} {
    ${InputSelect.InputSelected} {
      max-height: 36px;

      &:after {
        content: none;
      }
    }

    ${InputSelect.InputOptions} {
      ${InputSelect.InputOption} {
        height: unset;
      }
    }
  }
`;

const ClickedRow = styled(L.Row)<{ active?: boolean; editable: boolean; remove: boolean }>`
  ${(props) =>
    props.active &&
    css`
      ${L.Cell} {
        background: ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.white};
      }

      &:hover ${L.Cell} {
        background: ${(props) => props.theme.colors.blue};
      }

      ${Icon} {
        color: ${(props) => props.theme.colors.white};
      }

      input:disabled {
        color: ${(props) => props.theme.colors.white};
      }
    `}
  ${(props) =>
    props.editable &&
    css`
            cursor: pointer;

            ${L.Cell} {
              border-top: 1px solid ${(props) => props.theme.colors.blue};
              border-bottom: 1px solid ${(props) => props.theme.colors.blue};
              color: ${(props) => props.theme.colors.blue};
            }

            ${Cell}:nth-child(4) {
              grid-column: span 2;
            }

            ${Cell}:nth-child(5) {
              display: none;
            }

            input {
              color: ${(props) => props.theme.colors.blue};
            }

            ${InputSelect.InputSelect} {
              ${InputSelect.InputSelected} {
                &:after {
                  content: '';
                }
              }
          `}
  ${(props) =>
    props.remove &&
    css`
      ${L.Cell} {
        border-top: 1px solid ${(props) => props.theme.colors.blue};
        border-bottom: 1px solid ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.blue};
      }

      input {
        color: ${(props) => props.theme.colors.blue};
      }

      ${Cell}:nth-child(4) {
        display: none;
      }

      ${Cell}:nth-child(5) {
        grid-column: span 2;
      }
    `}
  ${InputSelect.InputOptions} {
    ${InputSelect.InputOption} {
      opacity: 1 !important;
    }
  }

  ${InputField.InputField} {
    opacity: 1 !important;
  }
`;

const HeaderRow = styled(SecondStageCardContentStyled.HeaderRow)`
  //TODO: addme in z-index file
  ${List.HeaderCell} {
    z-index: 1;
  }
`;

const BtnIconLnList = styled(SecondStageCardContentStyled.BtnIcon)`
  ${Icon} {
    width: 14px;
    height: 12px;
    margin-left: 12px;
  }
`;

const TreeContainer = styled(SecondStageCardContentStyled.TreeContainer)`
  //TODO: set me in %
  height: 438px;
`;
export const DatasetsStyled = {
  Container,
  Column,
  BtnIconLnList,
  BtnIcon: SecondStageCardContentStyled.BtnIcon,
  DatasetListGrid,
  DatasetGrid,
  HeaderRow,
  TreeContainer,
  TreeHeaderRow: SecondStageCardContentStyled.TreeHeaderRow,
  TreeContent: SecondStageCardContentStyled.TreeContent,
  Cell,
  ClickedRow,
};
