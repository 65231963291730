import React from 'react';
import { Styled as S } from './IOAnalogueDigital.styled';
import { InputField } from './InputField';
import { CardType } from '../HardwareCard';

interface PhysicalInterfaceProps {
  analogueInputsOutputsNumber?: number;
  discreteInputsOutputsNumber?: number;
  cardType: CardType;
  handleAnalogueIONumberChange: (analogueInputsOutputsNumber: number) => void;
  handleDiscreteIONumberChange: (discreteInputsOutputsNumber: number) => void;
}

export const IOAnalogueDigital: React.FC<PhysicalInterfaceProps> = ({
  analogueInputsOutputsNumber,
  discreteInputsOutputsNumber,
  cardType,
  handleAnalogueIONumberChange,
  handleDiscreteIONumberChange,
}) => {
  return (
    <S.Container>
      <InputField
        integer
        onChange={handleDiscreteIONumberChange}
        cardType={cardType}
        label={'Amount of DI/DO'}
        value={discreteInputsOutputsNumber}
      />
      <InputField
        integer
        onChange={handleAnalogueIONumberChange}
        cardType={cardType}
        label={'Amount of AI/AO'}
        value={analogueInputsOutputsNumber}
      />
    </S.Container>
  );
};
