import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { EngineContext } from '../../editor/EngineContext';
import { Btn } from '../../widgets/Btn.styled';
import { isNodeModel, isRotatableNodeModel } from '../../editor/NgGraceModel';
import { StageDto } from '../../../api/nggrace-back';
import { Toolbar, ToolbarItem } from '../../toolbar/Toolbar';
import { useStageState } from '../../hooks/useStageState';
import { ExportToolbarItem } from '../../toolbar/ExportToolbarItem';
import { ToolbarModalProvider } from '../../toolbar/ToolbarModalContext';
import { SldSettingsToolbarItem } from './SldSettingsToolbarItem';
import { SldHelp } from './SldHelp';
import { LockToolbarItem } from '../../toolbar/LockToolbarItem';

type SldToolbarProps = {
  stage: StageDto;

  onNextStage(): void;
  onValidateStage(): void;
  onFinishStage(): void;
  onRollbackStage(): void;
  onLayout(): void;
};

export const SldToolbar: React.FC<SldToolbarProps> = ({
  stage,
  onNextStage,
  onValidateStage,
  onFinishStage,
  onRollbackStage,
  onLayout,
}) => {
  const engine = useContext(EngineContext);

  const computeStateCallback = useCallback(() => {
    const finished = stage.baseInfo.finished;

    const selectedEntities = engine.getModel().getSelectedEntities();
    const selectedNodes = selectedEntities.filter(isNodeModel);
    const selectedNode = selectedNodes.length === 1 ? selectedNodes[0] : undefined;
    const canRotate = !finished && isRotatableNodeModel(selectedNode) && selectedNode.canRotate();
    const canDelete = !finished && selectedEntities.length > 0;
    const canUndo = !finished && engine.canUndo();
    const canRedo = !finished && engine.canRedo();
    return { canUndo, canRedo, selectedNode, canRotate, canDelete };
  }, [stage.baseInfo.finished, engine]);

  const state = useStageState(engine, stage, computeStateCallback);
  const { finished } = stage.baseInfo;

  const [projectName, setProjectName] = useState<string>(stage.project.name);

  const handleRotateLeft = useCallback(() => {
    const node = state.selectedNode;
    if (isRotatableNodeModel(node)) {
      node.rotateCounterClockwise();
    }
  }, [state.selectedNode]);

  const handleRotateRight = useCallback(() => {
    const node = state.selectedNode;
    if (isRotatableNodeModel(node)) {
      node.rotateClockwise();
    }
  }, [state.selectedNode]);

  const handleUndo = useCallback(async () => {
    await engine.undo();
  }, [engine]);

  const handleRedo = useCallback(async () => {
    await engine.redo();
  }, [engine]);

  const handleDelete = useCallback(async () => {
    await engine.deleteSelected();
  }, [engine]);

  const handleSelectAll = useCallback(async () => {
    await engine.selectAll();
  }, [engine]);

  const handleFitToSelect = useCallback(async () => {
    await engine.zoomToFitNodes(50);
  }, [engine]);

  const nextBtn = (
    <Btn onClick={onNextStage} disabled={!finished}>
      Next
    </Btn>
  );

  return (
    <Toolbar title={projectName} btn={nextBtn}>
      <ToolbarItem icon={'undo'} hint={'Undo'} disabled={!state.canUndo} onClick={handleUndo} />
      <ToolbarItem icon={'redo'} hint={'Redo'} disabled={!state.canRedo} onClick={handleRedo} />
      <ToolbarItem icon={'rotate-left'} hint={'Rotate'} disabled={!state.canRotate} onClick={handleRotateLeft} />
      <ToolbarItem icon={'rotate-right'} hint={'Rotate'} disabled={!state.canRotate} onClick={handleRotateRight} />
      <ToolbarItem icon={'trash'} hint={'Delete'} disabled={!state.canDelete} onClick={handleDelete} />
      <ToolbarItem icon={'drag-handle-horizontal'} disabled={finished} hint={'Auto-layout'} onClick={onLayout} />
      <ToolbarItem icon={'move'} hint={'Fit to screen'} onClick={handleFitToSelect} />
      <ToolbarItem icon={'target'} hint={'Select all'} disabled={finished} onClick={handleSelectAll} />
      <ToolbarItem icon={'play'} hint={'Check'} disabled={finished} onClick={onValidateStage} />
      <LockToolbarItem stage={stage} onFinishStage={onFinishStage} onRollbackStage={onRollbackStage} />
      <SldSettingsToolbarItem stage={stage} handleSetUserName={setProjectName} />
      <ToolbarModalProvider icon={'question'} hint={'Help'}>
        <SldHelp />
      </ToolbarModalProvider>
      <ExportToolbarItem stage={stage} />
    </Toolbar>
  );
};
