import styled from 'styled-components';
import { Styled as StyledInputField } from '../../../../../widgets/InputField.styled';
import { Styled as StyledInputSelect } from '../../../../../widgets/InputSelect.styled';
import { Styled as StyledFeedback } from '../../../../../widgets/Feedback.styled';
import { CardType } from '../HardwareCard';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
`;

const Column = styled.div<{ position: 'left' | 'right' }>`
  width: 507px;
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.position === 'left' ? '57px' : '28px')};

  ${StyledFeedback.Feedback} {
    margin-top: -24px;
  }

  & p {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
    margin-top: 12px;
    margin-bottom: 15px;

    & span {
      margin-left: 19px;
      color: ${(props) => props.theme.colors.blue};
      cursor: pointer;
    }
  }
`;

const Form = styled.div<{ cardType?: CardType }>`
  width: 507px;
  ${StyledInputField.InputField} {
    width: 507px;
    margin-bottom: 24px;
    opacity: ${(props) => (props.cardType === 'view' ? '1 !important' : 'inherit')};

    ${StyledInputField.Input} {
      padding: ${(props) => (props.cardType === 'view' ? '12px 0px' : '12px 8px')};
      border: ${(props) => (props.cardType === 'view' ? 'none' : `1px solid ${props.theme.colors.darkgrey}`)};
      border-bottom: 1px solid ${(props) => props.theme.colors.darkgrey};
    }

    ${StyledInputField.InputLabel} {
      left: ${(props) => (props.cardType === 'view' ? '0' : '12px')};
      padding: ${(props) => (props.cardType === 'view' ? '0' : '0 4px')};
      color: ${(props) => (props.cardType === 'view' ? props.theme.colors.blue : props.theme.colors.darkgrey)};
      font-weight: 700;
      line-height: 14px;
    }

    ${StyledInputField.InputContainer} {
      padding: ${(props) => (props.cardType === 'view' ? '12px 0px' : '12px 8px')};
      border: ${(props) => (props.cardType === 'view' ? 'none' : `1px solid ${props.theme.colors.darkgrey}`)};
      border-bottom: 1px solid ${(props) => props.theme.colors.darkgrey};
    }
  }

  ${StyledInputSelect.InputSelect} {
    ${StyledInputSelect.InputSelected} {
      &:after {
        border-top: ${(props) => (props.cardType === 'view' ? 'none' : `5px solid ${props.theme.colors.blue}`)};
      }
    }
  }
`;

export const Styled = {
  Container,
  Column,
  Form,
};
