import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import { ExtendableTreeNode, TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import {
  DefaultHasExtendableParentModel,
  NavigatingSelectableTreeModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdNodeModel } from '../../../editor/scd/ScdModel';
import { NamedNodeModelPayload } from '../../../editor/generics/NamedNodeModelPayload';

export const ScdPacsTreeLogicDeviceFactory = (
  engine: ScdEngine
): Factory<ScdPacsTreeLogicDeviceModelProps, TreeNodeState> => ({ logicDevice, parent }) =>
  ScdPacsTreeLogicDevice(parent, logicDevice, engine);

export interface ScdPacsTreeLogicDeviceModelProps {
  logicDevice: DirectoryLogicDeviceModel<ScdNodeModel>;
  parent: ExtendableTreeNode;
}

const ScdPacsTreeLogicDevice = (
  parent: ExtendableTreeNode,
  device: DirectoryLogicDeviceModel<ScdNodeModel>,
  engine: ScdEngine
): TreeNodeState => {
  const model = ScdPacsTreeLogicDeviceModel(device);

  return {
    ...model,
    ...NotExtendableModel(),
    ...DefaultHasExtendableParentModel(model, parent),
    ...NavigatingSelectableTreeModel(
      SelectableTreeBaseModel(device, engine),
      device.getRelativeModel()!.getID(),
      engine
    ),
  };
};

const ScdPacsTreeLogicDeviceModel = (device: DirectoryLogicDeviceModel<ScdNodeModel>): TreeNodeModel => ({
  getName: () =>
    LogicDeviceNameBuilder(
      device.getCodeName(),
      (device.getRelativeModel()?.getPayload() as NamedNodeModelPayload).projectName
    ),
  getKey: () => device.getID(),
  onChildrenChanged: () => () => {},
  getChildren: () => [],
});

const LogicDeviceNameBuilder = (codeName: string, relativeModelProjectName?: string) => {
  return relativeModelProjectName ? `${codeName} - ${relativeModelProjectName}` : codeName;
};
