import moment from 'moment';

export const formatMomentShort = (timestamp: number) => {
  return moment(timestamp).format('DD.MM.YYYY');
};

export const formatFrameDateRu = (timestamp: number) => {
  return moment(timestamp).format('MM.YY');
};

export const formatFrameDateEu = (timestamp: number) => {
  return moment(timestamp).format('YYYY-MM-DD');
};
