import React from 'react';
import { StageDto } from '../../../api/nggrace-back';
import { DiagramCanvas } from '../../editor/DiagramCanvas';
import { Styled as S } from '../../DiagramEditor.styled';
import { ZoomWidget } from '../../editor/ZoomWidget';
import { useDirectory } from '../../hooks/useDirectory';
import { getApprovedBy } from '../../../utils/project-utils';
import { ScdToolbar } from './ScdToolbar';
import { ScdLibrary } from './ScdLibrary';
import { ScdStructureTree } from './tree/ScdStructureTree';
import { useNotifyOnSuccessCallback } from '../../hooks/useNotifyOnSuccessCallback';

type ScdDiagramEditorProps = {
  stage: StageDto;

  onNextStage(): void;
  onValidateStage(): Promise<boolean>;
  onFinishStage(): void;
  onRollbackStage(): void;
  onReloadStage(): void;
};

export const ScdDiagramEditor: React.FC<ScdDiagramEditorProps> = ({
  stage,
  onNextStage,
  onValidateStage,
  onFinishStage,
  onRollbackStage,
  onReloadStage,
}) => {
  const { getNodeDirectory } = useDirectory();
  const nodeDirectory = getNodeDirectory(stage.project.styleSelection);
  const header = 'SCD' + getApprovedBy(stage.baseInfo);
  const handleValidationStage = useNotifyOnSuccessCallback(onValidateStage, 'Validation completed');

  return (
    <S.DiagramEditor>
      <ScdToolbar
        stage={stage}
        onNextStage={onNextStage}
        onValidateStage={handleValidationStage}
        onFinishStage={onFinishStage}
        onRollbackStage={onRollbackStage}
        onReloadStage={onReloadStage}
      />
      <S.Canvas>
        <DiagramCanvas header={header} directory={nodeDirectory} />
        <ScdStructureTree station={stage.project.stationName} />
        <ScdLibrary project={stage.project} treeLocked={stage.baseInfo.finished} />
      </S.Canvas>
      <ZoomWidget />
    </S.DiagramEditor>
  );
};
