import { BaseListener, BaseObserver, ListenerHandle } from '@projectstorm/react-canvas-core';
import { Coordinate } from '../../geometry/Coordinate';
import { NodeWithPlaceholdersListener } from '../NodeWithPlaceholders';
import { DirectoryLogicDeviceModel } from '../../ssd/logic-device/LogicDeviceModel';
import { SsdNodeModel } from '../../ssd/layer/node/SsdNodeLayerModel';

export class NodeWithPlaceholdersEquivalentSet extends BaseObserver {
  private nodes: SsdNodeModel[];
  private previousSet?: NodeWithPlaceholdersEquivalentSet;
  private coordinate: Coordinate;
  private maxSize: number;
  private positionOffset?: number;

  constructor(nodes: SsdNodeModel[], coordinate: Coordinate, previousSet?: NodeWithPlaceholdersEquivalentSet) {
    super();
    this.coordinate = coordinate;
    this.nodes = nodes;
    this.previousSet = previousSet;
    this.maxSize = this.getMaxSize();
    this.positionOffset = this.previousSet?.getPositionOffset();

    nodes.forEach((node) =>
      node.registerListener({
        placeholdersSizeChanged: () => {
          const newSize = this.getMaxSize();
          if (this.maxSize !== newSize) {
            this.maxSize = newSize;
            this.fireEvent({}, 'placeholdersSizeChanged');
          }
        },
      } as any)
    );

    this.previousSet?.registerListener({
      placeholdersSizeChanged: () => {
        const newOffset = this.previousSet!.getPositionOffset();
        if (this.positionOffset !== newOffset) {
          this.positionOffset = newOffset;
          this.nodes.map((node) => node.setOffset(newOffset, coordinate));
          this.fireEvent({}, 'placeholdersSizeChanged');
        }
      },
    });
  }

  registerListener(listener: BaseListener & NodeWithPlaceholdersListener<DirectoryLogicDeviceModel>): ListenerHandle {
    return super.registerListener(listener);
  }

  getPositionOffset(): number {
    const previousOffset = this.previousSet ? this.previousSet.getPositionOffset() : 0;
    return previousOffset + this.getMaxSize();
  }

  private getMaxSize() {
    return Math.max(0, ...this.nodes.map((node) => node.getPlaceholdersSize()[this.coordinate.getName()]));
  }
}
