import React from 'react';
import { Styled as S } from './SecondaryButton.styled';
import { Icon, IconName } from './Icon';

type SecondaryButtonProps = {
  text: string;
  border?: boolean;
  iconName?: IconName;
  onClick: () => void;
};

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({ text, border, iconName, onClick }) => {
  return (
    <S.SecondaryButton border={border}>
      <S.Background>
        <S.Button onClick={onClick}>
          {iconName && <Icon name={iconName} />}
          {text}
        </S.Button>
      </S.Background>
    </S.SecondaryButton>
  );
};
