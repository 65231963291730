import React, { useCallback, useState } from 'react';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Styled as S } from '../AuthFeedback.styled';
import { Icon } from '../../widgets/Icon';
import { PasswordRecoverySettings } from './PasswordRecoverySettings';
import { AnonymUserPage } from '../../AnonymUserPage';

export const PasswordRecoveryPage: React.FC = () => {
  const [recoveryRequested, setRecoveryRequested] = useState<boolean>(false);

  const handleRecoveryRequest = useCallback(() => {
    setRecoveryRequested(true);
  }, []);

  return (
    <AnonymUserPage>
      {!recoveryRequested ? <PasswordRecoverySettings onRecoveryRequest={handleRecoveryRequest} /> : <RecoveryThanks />}
    </AnonymUserPage>
  );
};

const RecoveryThanks: React.FC = () => {
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  return (
    <S.Form>
      <S.Main>
        <S.CheckMark>
          <Icon name={'checkmark'} />
        </S.CheckMark>
        <S.Title>Thank you!</S.Title>
        <S.Info>
          We've sent password reset instructions to your email address. If no email is received within ten minutes,
          check that the submitted address is correct.
        </S.Info>
        <S.Button onClick={redirectToLoginPage}>Return to login</S.Button>
      </S.Main>
    </S.Form>
  );
};
