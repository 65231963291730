import React, { useCallback, useMemo, useState } from 'react';
import { VendorUpdateDto, VendorViewDto } from '../../../../api/nggrace-back';
import { Api } from '../../../../api/Api';
import { Btn } from '../../../widgets/Btn.styled';
import { InputField } from '../../../widgets/InputField';
import { Styled as S } from '../../Settings.styled';
import { Feedback } from '../../../widgets/Feedback';
import { useOverlay } from '../../../context/OverlayContext';
import { useNotifications } from '../../../context/NotificationContext';
import { EditInfo } from '../EditInfo';
import { SecondaryButton } from '../../../widgets/SecondaryButton';

interface VendorSettingsProps {
  vendor?: VendorViewDto;
  onClose: (vendor?: VendorUpdateDto) => void;
}

export const VendorSettings: React.FC<VendorSettingsProps> = ({ vendor: initVendor, onClose }) => {
  const [vendor, setVendor] = useState<VendorUpdateDto>({ ...initVendor });
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const update = useMemo(() => !!initVendor, [initVendor]);
  const notifications = useNotifications();

  const handleNameChange = useCallback((name: string) => {
    setError(undefined);
    setVendor((vendor) => ({ ...vendor, name }));
  }, []);

  const handleClose = useCallback(() => {
    setVendor({ ...initVendor });
    onClose();
  }, [initVendor, onClose]);

  const handleCreate = useCallback(() => {
    setLoading(true);
    overlay.show();
    Api.createVendor(vendor, { omitInterceptorErrorModal: true })
      .then(() => {
        onClose(vendor);
        overlay.hide();
        notifications.notifySuccess('Vendor created.');
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [vendor, notifications, onClose, overlay]);

  const handleUpdate = useCallback(() => {
    if (vendor.name === initVendor!.name) {
      onClose();
      return;
    }
    setLoading(true);
    overlay.show();
    Api.updateVendor(initVendor!.id, { name: vendor.name }, { omitInterceptorErrorModal: true })
      .then(() => {
        onClose(vendor);
        overlay.hide();
        notifications.notifySuccess('Vendor edited.');
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [vendor, initVendor, notifications, onClose, overlay]);

  return (
    <S.Settings>
      <S.Title>{update ? 'Edit' : 'Create'} a Vendor element</S.Title>
      <EditInfo
        cardType={update ? 'edit' : 'create'}
        editedAt={initVendor?.editedAt}
        editorName={initVendor?.editor.name}
      />
      <S.Form>
        <InputField label={'Vendor name'} value={vendor.name} onChange={handleNameChange} />
      </S.Form>
      <Feedback error={!!error} text={error ? error : ''} />
      <S.Buttons>
        <SecondaryButton text={'Cancel'} onClick={handleClose} />
        <Btn onClick={update ? handleUpdate : handleCreate} disabled={loading}>
          {!loading ? 'Ok' : 'Processing'}
        </Btn>
      </S.Buttons>
    </S.Settings>
  );
};
