import styled, { css } from 'styled-components';

import { Styled as SecondStageCardContentStyled } from '../../second-stage-content/SecondStageCardContent.styled';
import { DatasetsStyled } from '../datasets/Datasets.styled';
import { GooseSBStyled } from '../goose-cb/GooseSB.styled';
import { List as L, List } from '../../../../../../../widgets/List.styled';
import { Styled as InputSelect } from '../../../../../../../widgets/InputSelect.styled';
import { Styled as InputField } from '../../../../../../../widgets/InputField.styled';

const Container = styled(DatasetsStyled.Container)`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 25px 34px;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      margin: 0;
    }
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  height: 430px;
  overflow: scroll;
`;

const Column = styled.div<{ tableName: string }>`
  padding: 0;
  border: none;
  margin-right: 10px;

  header {
    margin: 0;
    background: ${(props) => props.theme.colors.darkblue};
    border-radius: 6px 6px 0 0;
    color: #ffffff;
    min-height: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;

    h3 {
      margin: 0 0 0 15px;
      font-size: 12px;
    }
  }
`;

const HeaderRow = styled(SecondStageCardContentStyled.HeaderRow)`
  background: ${(props) => props.theme.colors.darksky};

  ${List.HeaderCell} {
    background-color: ${(props) => props.theme.colors.darksky};
    color: ${(props) => props.theme.colors.white};

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  &:hover ${List.HeaderCell} {
    background: ${(props) => props.theme.colors.darksky};
  }
`;

const DatasetListGrid = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  overflow: unset;
  width: 352px;
  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 0.4fr) minmax(0, 0.4fr);

  ${HeaderRow} {
    ${List.HeaderCell} {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.black};
    }
  }

  ${DatasetsStyled.Cell} {
    ${InputField.InputField} {
      input {
        text-align: start;
        margin-left: 10px;
      }
    }
  }
`;

const ReportsControl = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  overflow: unset;
  width: 314px;
  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr);
`;

const RptEnabled = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  overflow: unset;
  width: 126px;
  height: 100%;
  grid-template-columns: minmax(0, 1fr);
`;

const TrigOps = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  overflow: unset;
  width: 272px;
  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr);
`;

const OptField = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  overflow: unset;
  width: 360px;
  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

const ClickedOptionRow = styled(L.Row)<{ editable: boolean; remove: boolean }>`
  ${(props) =>
    props.editable &&
    css`
            cursor: pointer;

            ${L.Cell} {
              border-top: 1px solid ${(props) => props.theme.colors.blue};
              border-bottom: 1px solid ${(props) => props.theme.colors.blue};
              color: ${(props) => props.theme.colors.blue};
            }

            input {
              color: ${(props) => props.theme.colors.blue};
            }

            ${InputSelect.InputSelect} {
              ${InputSelect.InputSelected} {
                &:after {
                  content: '';
                }
              }
          `}
  ${(props) =>
    props.remove &&
    css`
      ${L.Cell} {
        border-top: 1px solid ${(props) => props.theme.colors.blue};
        border-bottom: 1px solid ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.blue};
      }

      input {
        color: ${(props) => props.theme.colors.blue};
      }
    `}
  ${InputSelect.InputOptions} {
    ${InputSelect.InputOption} {
      opacity: 1 !important;
    }
  }

  ${InputField.InputField} {
    opacity: 1 !important;
  }
`;

const Cell = styled(DatasetsStyled.Cell)`
  ${InputField.InputField} {
    input {
      text-align: center;
    }
  }
`;

export const MmsReportsStyled = {
  Container,
  Columns,
  Content,
  Column,
  HeaderRow,
  DatasetListGrid,
  ReportsControl,
  RptEnabled,
  TrigOps,
  OptField,
  Cell,
  BtnIcon: SecondStageCardContentStyled.BtnIcon,
  ClickedRow: GooseSBStyled.ClickedRow,
  ClickedOptionRow,
};
