/* tslint:disable */
/* eslint-disable */

export interface HttpClient<O> {
  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: O;
  }): RestResponse<R>;
}

export class RestApplicationClient<O> {
  constructor(protected httpClient: HttpClient<O>) {}

  /**
   * HTTP POST /auth/login
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.authenticate
   */
  authenticate(queryParams: { username: string; password: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`auth/login`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /auth/logout
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.logout
   */
  logout(options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`auth/logout`, options: options });
  }

  /**
   * HTTP POST /auth/user
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.getCurrentUser
   */
  getCurrentUser(options?: O): RestResponse<UserDto | undefined> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`auth/user`, options: options });
  }

  /**
   * HTTP POST /companies
   * Java method: com.softaria.grace.ng.controller.CompanyController.getCompanies
   */
  getCompanies(
    companySortDto: CompanySortDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<CompanyDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`companies`,
      queryParams: queryParams,
      data: companySortDto,
      options: options,
    });
  }

  /**
   * HTTP GET /companies/preferences
   * Java method: com.softaria.grace.ng.controller.CompanyController.getAllCompanies
   */
  getAllCompanies(options?: O): RestResponse<CompanyBaseDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`companies/preferences`, options: options });
  }

  /**
   * HTTP POST /company
   * Java method: com.softaria.grace.ng.controller.CompanyController.createCompany
   */
  createCompany(companyUpdateDto: CompanyUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company`,
      data: companyUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /company/register
   * Java method: com.softaria.grace.ng.controller.CompanyController.registerCompany
   */
  registerCompany(companyRegisterDto: CompanyRegisterDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company/register`,
      data: companyRegisterDto,
      options: options,
    });
  }

  /**
   * HTTP DELETE /company/{companyId}
   * Java method: com.softaria.grace.ng.controller.CompanyController.deleteCompany
   */
  deleteCompany(companyId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`company/${companyId}`, options: options });
  }

  /**
   * HTTP POST /company/{companyId}
   * Java method: com.softaria.grace.ng.controller.CompanyController.updateCompany
   */
  updateCompany(companyId: number, companyUpdateDto: CompanyUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company/${companyId}`,
      data: companyUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /export/form/{exportFormId}
   * Java method: com.softaria.grace.ng.controller.ExportController.updateExportForm
   */
  updateExportForm(exportFormId: number, exportFormDto: ExportFormDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`export/form/${exportFormId}`,
      data: exportFormDto,
      options: options,
    });
  }

  /**
   * HTTP GET /export/project/{projectId}/type/{type}
   * Java method: com.softaria.grace.ng.controller.ExportController.getExportForm
   */
  getExportForm(projectId: number, type: ExportFormType, options?: O): RestResponse<ExportFormDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`export/project/${projectId}/type/${type}`,
      options: options,
    });
  }

  /**
   * HTTP POST /hardware
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.getHardware
   */
  getHardware(
    hardwareSortDto: HardwareSortDto,
    queryParams: { hardwareType: HardwareType; page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<HardwareViewForListDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`hardware`,
      queryParams: queryParams,
      data: hardwareSortDto,
      options: options,
    });
  }

  /**
   * HTTP GET /hardware/all-svg
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.getAllSvg
   */
  getAllSvg(options?: O): RestResponse<SvgDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`hardware/all-svg`, options: options });
  }

  /**
   * HTTP GET /hardware/any
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.isThereAnyHardware
   */
  isThereAnyHardware(queryParams: { hardwareType: HardwareType }, options?: O): RestResponse<boolean> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`hardware/any`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /hardware/create
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.createHardware
   */
  createHardware(options?: O): RestResponse<number> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`hardware/create`, options: options });
  }

  /**
   * HTTP GET /hardware/filter-options/{hardwareType}
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.getHardwareFilterOptions
   */
  getHardwareFilterOptions(hardwareType: HardwareType, options?: O): RestResponse<HardwareFilterOptionsDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`hardware/filter-options/${hardwareType}`,
      options: options,
    });
  }

  /**
   * HTTP POST /hardware/save-svg
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.saveSvg
   */
  saveSvg(options?: O): RestResponse<SvgDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`hardware/save-svg`, options: options });
  }

  /**
   * HTTP DELETE /hardware/svg/{svgId}
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.deleteSvg
   */
  deleteSvg(svgId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`hardware/svg/${svgId}`, options: options });
  }

  /**
   * HTTP POST /hardware/update/{hardwareId}
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.updateHardware
   */
  updateHardware(hardwareId: number, hardwareDto: HardwareDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`hardware/update/${hardwareId}`,
      data: hardwareDto,
      options: options,
    });
  }

  /**
   * HTTP POST /hardware/validate-and-read-icd
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.validateAndReadIcd
   */
  validateAndReadIcd(options?: O): RestResponse<IcdReadDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`hardware/validate-and-read-icd`,
      options: options,
    });
  }

  /**
   * HTTP DELETE /hardware/{hardwareId}
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.deleteHardware
   */
  deleteHardware(hardwareId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`hardware/${hardwareId}`, options: options });
  }

  /**
   * HTTP GET /hardware/{hardwareId}
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.getOneHardware
   */
  getOneHardware(hardwareId: number, options?: O): RestResponse<HardwareViewDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`hardware/${hardwareId}`, options: options });
  }

  /**
   * HTTP DELETE /hardware/{hardwareId}/clone
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.cloneHardware
   */
  cloneHardware(hardwareId: number, options?: O): RestResponse<HardwareViewForListDto> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`hardware/${hardwareId}/clone`,
      options: options,
    });
  }

  /**
   * HTTP GET /hardware/{hardwareId}/icd-file
   * Java method: com.softaria.grace.ng.controller.library.HardwareController.getIcdContent
   */
  getIcdContent(hardwareId: number, options?: O): RestResponse<StreamingResponseBody> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`hardware/${hardwareId}/icd-file`,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.getLogicalDevices
   */
  getLogicalDevices(
    logicalDeviceSortDto: LogicalDeviceSortDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<LogicalDeviceViewForListDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device`,
      queryParams: queryParams,
      data: logicalDeviceSortDto,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device/available-ied-list
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.getAvailableIedList
   */
  getAvailableIedList(logicalNodeTypeIds: string[], options?: O): RestResponse<string[]> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device/available-ied-list`,
      data: logicalNodeTypeIds,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device/create
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.createLogicalDevice
   */
  createLogicalDevice(logicalDeviceDto: LogicalDeviceDto, options?: O): RestResponse<number> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device/create`,
      data: logicalDeviceDto,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device/update/{logicalDeviceId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.updateLogicalDevice
   */
  updateLogicalDevice(logicalDeviceId: number, logicalDeviceDto: LogicalDeviceDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device/update/${logicalDeviceId}`,
      data: logicalDeviceDto,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device/validate/first-stage/
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.validateFirstStage
   */
  validateFirstStage(
    firstStageStateDto: FirstStageStateDto,
    queryParams?: { logicalDeviceId?: number },
    options?: O
  ): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device/validate/first-stage/`,
      queryParams: queryParams,
      data: firstStageStateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-device/validate/second-stage/
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.validateSecondStage
   */
  validateSecondStage(secondStageStateDto: SecondStageStateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-device/validate/second-stage/`,
      data: secondStageStateDto,
      options: options,
    });
  }

  /**
   * HTTP DELETE /logical-device/{logicalDeviceId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.deleteLogicalDevice
   */
  deleteLogicalDevice(logicalDeviceId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`logical-device/${logicalDeviceId}`,
      options: options,
    });
  }

  /**
   * HTTP GET /logical-device/{logicalDeviceId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalDeviceController.getLogicalDevice
   */
  getLogicalDevice(logicalDeviceId: number, options?: O): RestResponse<LogicalDeviceViewDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`logical-device/${logicalDeviceId}`,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-node-type
   * Java method: com.softaria.grace.ng.controller.library.LogicalNodeTypeController.getLogicalNodeTypes
   */
  getLogicalNodeTypes(
    logicalNodeTypeSortDto: LogicalNodeTypeSortDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<LogicalNodeTypeViewDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-node-type`,
      queryParams: queryParams,
      data: logicalNodeTypeSortDto,
      options: options,
    });
  }

  /**
   * HTTP GET /logical-node-type/all-by-vendor-id/{vendorId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalNodeTypeController.getLogicalNodeTypesByVendor
   */
  getLogicalNodeTypesByVendor(vendorId: number, options?: O): RestResponse<LogicalNodeTypeViewDto[]> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`logical-node-type/all-by-vendor-id/${vendorId}`,
      options: options,
    });
  }

  /**
   * HTTP GET /logical-node-type/filter-options
   * Java method: com.softaria.grace.ng.controller.library.LogicalNodeTypeController.getLogicalNodeTypeFilterOptions
   */
  getLogicalNodeTypeFilterOptions(options?: O): RestResponse<LogicalNodeTypeFilterOptionsDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`logical-node-type/filter-options`,
      options: options,
    });
  }

  /**
   * HTTP GET /logical-node-type/{logicalNodeId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalNodeTypeController.getLogicalNodeType
   */
  getLogicalNodeType(logicalNodeId: number, options?: O): RestResponse<LogicalNodeTypeViewDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`logical-node-type/${logicalNodeId}`,
      options: options,
    });
  }

  /**
   * HTTP POST /logical-node-type/{logicalNodeId}
   * Java method: com.softaria.grace.ng.controller.library.LogicalNodeTypeController.updateLogicalNodeType
   */
  updateLogicalNodeType(
    logicalNodeId: number,
    logicalNodeTypeUpdateDto: LogicalNodeTypeUpdateDto,
    options?: O
  ): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`logical-node-type/${logicalNodeId}`,
      data: logicalNodeTypeUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /password_recovery
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.createPasswordRecoveryLink
   */
  createPasswordRecoveryLink(queryParams: { email: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`password_recovery`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /password_recovery/create/{passwordRecoveryToken}
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.resetPassword
   */
  resetPassword(passwordRecoveryToken: string, queryParams: { password: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`password_recovery/create/${passwordRecoveryToken}`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /password_recovery/{passwordRecoveryToken}
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.getPasswordRecoveryLinkStatus
   */
  getPasswordRecoveryLinkStatus(passwordRecoveryToken: string, options?: O): RestResponse<PasswordRecoveryLinkStatus> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`password_recovery/${passwordRecoveryToken}`,
      options: options,
    });
  }

  /**
   * HTTP POST /project
   * Java method: com.softaria.grace.ng.controller.ProjectController.createProject
   */
  createProject(options?: O): RestResponse<number> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`project`, options: options });
  }

  /**
   * HTTP POST /project
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProjects
   */
  getProjects(
    projectSortingDto: ProjectSortingDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<ProjectViewDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project`,
      queryParams: queryParams,
      data: projectSortingDto,
      options: options,
    });
  }

  /**
   * HTTP GET /project/customer-companies
   * Java method: com.softaria.grace.ng.controller.ProjectController.getCustomerCompanies
   */
  getCustomerCompanies(options?: O): RestResponse<string[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/customer-companies`, options: options });
  }

  /**
   * HTTP GET /project/default-tco-settings
   * Java method: com.softaria.grace.ng.controller.ProjectController.getDefaultTcoSettings
   */
  getDefaultTcoSettings(options?: O): RestResponse<TcoSettingsDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/default-tco-settings`, options: options });
  }

  /**
   * HTTP GET /project/filter-options
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProjectFilterOptions
   */
  getProjectFilterOptions(options?: O): RestResponse<ProjectFilterOptionsDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/filter-options`, options: options });
  }

  /**
   * HTTP DELETE /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.deleteProject
   */
  deleteProject(projectId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`project/${projectId}`, options: options });
  }

  /**
   * HTTP GET /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProject
   */
  getProject(projectId: number, options?: O): RestResponse<ProjectDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/${projectId}`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.updateProject
   */
  updateProject(projectId: number, projectUpdateDto: ProjectUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}`,
      data: projectUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/copy
   * Java method: com.softaria.grace.ng.controller.ProjectController.cloneProject
   */
  cloneProject(projectId: number, options?: O): RestResponse<ProjectViewDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`project/${projectId}/copy`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.exportProject
   */
  exportProject(projectId: number, queryParams?: { files?: File[] }, options?: O): RestResponse<StreamingResponseBody> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/export`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /project/{projectId}/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProjectExport
   */
  getProjectExport(projectId: number, options?: O): RestResponse<ProjectExportDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/${projectId}/export`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}/stage/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.createExportStage
   */
  createExportStage(projectId: number, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/export`,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/lan
   * Java method: com.softaria.grace.ng.controller.ProjectController.createLanStage
   */
  createLanStage(projectId: number, lan: LanCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/lan`,
      data: lan,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/scd
   * Java method: com.softaria.grace.ng.controller.ProjectController.createScdStage
   */
  createScdStage(projectId: number, scd: ScdCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/scd`,
      data: scd,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/ssd
   * Java method: com.softaria.grace.ng.controller.ProjectController.createSsdStage
   */
  createSsdStage(projectId: number, ssd: SsdCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/ssd`,
      data: ssd,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/tco
   * Java method: com.softaria.grace.ng.controller.ProjectController.createTcoStage
   */
  createTcoStage(projectId: number, tcoSettings: TcoSettingsDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/tco`,
      data: tcoSettings,
      options: options,
    });
  }

  /**
   * HTTP GET /stage
   * Java method: com.softaria.grace.ng.controller.StageController.getStage
   */
  getStage(queryParams: { projectId: number; stageType: StageType }, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/clear
   * Java method: com.softaria.grace.ng.controller.StageController.clearStage
   */
  clearStage(queryParams: { projectId: number; stageType: StageType }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage/clear`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/export-model
   * Java method: com.softaria.grace.ng.controller.StageController.getStageModel
   */
  getStageModel(
    queryParams: { projectId: number; stageType: StageType; external?: boolean },
    options?: O
  ): RestResponse<string | undefined> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage/export-model`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/import-model
   * Java method: com.softaria.grace.ng.controller.StageController.updateStageFromExternalModel
   */
  updateStageFromExternalModel(
    json: string,
    queryParams: { projectId: number; stageType: StageType },
    options?: O
  ): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/import-model`,
      queryParams: queryParams,
      data: json,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/lan/{stageId}
   * Java method: com.softaria.grace.ng.controller.StageController.updateStageLan
   */
  updateStageLan(stageId: number, diagram: ValueDto, options?: O): RestResponse<DiagramDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/lan/${stageId}`,
      data: diagram,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/{stageId}
   * Java method: com.softaria.grace.ng.controller.StageController.updateStage
   */
  updateStage(stageId: number, diagram: ValueDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/${stageId}`,
      data: diagram,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/{stageId}/auto-layout
   * Java method: com.softaria.grace.ng.controller.StageController.layoutStageDiagram
   */
  layoutStageDiagram(stageId: number, options?: O): RestResponse<DiagramLayoutDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/${stageId}/auto-layout`,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/{stageId}/diagram
   * Java method: com.softaria.grace.ng.controller.StageController.getStageDiagram
   */
  getStageDiagram(stageId: number, options?: O): RestResponse<DiagramDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`stage/${stageId}/diagram`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/export-scl
   * Java method: com.softaria.grace.ng.controller.StageController.exportScl
   */
  exportScl(stageId: number, options?: O): RestResponse<ValueDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/export-scl`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/finish
   * Java method: com.softaria.grace.ng.controller.StageController.finishStage
   */
  finishStage(stageId: number, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/finish`, options: options });
  }

  /**
   * HTTP DELETE /stage/{stageId}/rollback
   * Java method: com.softaria.grace.ng.controller.StageController.rollbackToStage
   */
  rollbackToStage(stageId: number, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`stage/${stageId}/rollback`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/validate
   * Java method: com.softaria.grace.ng.controller.StageController.validateStage
   */
  validateStage(stageId: number, options?: O): RestResponse<StageValidationDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/validate`, options: options });
  }

  /**
   * HTTP DELETE /user
   * Java method: com.softaria.grace.ng.controller.UserController.deleteUser
   */
  deleteUser(queryParams: { userId: number }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`user`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /user/update/{userId}
   * Java method: com.softaria.grace.ng.controller.UserController.updateUser
   */
  updateUser(userId: number, userUpdateDto: UserUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user/update/${userId}`,
      data: userUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /user_invite
   * Java method: com.softaria.grace.ng.controller.UserInviteController.inviteUser
   */
  inviteUser(userInviteDto: UserInviteDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user_invite`,
      data: userInviteDto,
      options: options,
    });
  }

  /**
   * HTTP GET /user_invite/check/{userInviteToken}
   * Java method: com.softaria.grace.ng.controller.UserInviteController.isUserInvited
   */
  isUserInvited(userInviteToken: string, options?: O): RestResponse<boolean> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`user_invite/check/${userInviteToken}`,
      options: options,
    });
  }

  /**
   * HTTP POST /user_invite/register/{userInviteToken}
   * Java method: com.softaria.grace.ng.controller.UserInviteController.registerUserByInvite
   */
  registerUserByInvite(userInviteToken: string, userRegisterDto: UserRegisterDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user_invite/register/${userInviteToken}`,
      data: userRegisterDto,
      options: options,
    });
  }

  /**
   * HTTP POST /users
   * Java method: com.softaria.grace.ng.controller.UserController.getUsers
   */
  getUsers(
    userRequestDto: UserRequestDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<UserDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`users`,
      queryParams: queryParams,
      data: userRequestDto,
      options: options,
    });
  }

  /**
   * HTTP GET /users/preferences
   * Java method: com.softaria.grace.ng.controller.UserController.getAllUsers
   */
  getAllUsers(options?: O): RestResponse<UserBaseDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`users/preferences`, options: options });
  }

  /**
   * HTTP POST /vendor
   * Java method: com.softaria.grace.ng.controller.library.VendorController.getVendors
   */
  getVendors(
    vendorSortDto: VendorSortDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<VendorViewDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`vendor`,
      queryParams: queryParams,
      data: vendorSortDto,
      options: options,
    });
  }

  /**
   * HTTP POST /vendor/create
   * Java method: com.softaria.grace.ng.controller.library.VendorController.createVendor
   */
  createVendor(vendorCreateDto: VendorCreateDto, options?: O): RestResponse<number> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`vendor/create`,
      data: vendorCreateDto,
      options: options,
    });
  }

  /**
   * HTTP GET /vendor/vendors/all
   * Java method: com.softaria.grace.ng.controller.library.VendorController.getAllVendors
   */
  getAllVendors(options?: O): RestResponse<VendorViewDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`vendor/vendors/all`, options: options });
  }

  /**
   * HTTP GET /vendor/vendors/ied
   * Java method: com.softaria.grace.ng.controller.library.VendorController.getIedVendors
   */
  getIedVendors(options?: O): RestResponse<VendorViewDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`vendor/vendors/ied`, options: options });
  }

  /**
   * HTTP GET /vendor/vendors/mu
   * Java method: com.softaria.grace.ng.controller.library.VendorController.getMuVendors
   */
  getMuVendors(options?: O): RestResponse<VendorViewDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`vendor/vendors/mu`, options: options });
  }

  /**
   * HTTP GET /vendor/vendors/switch
   * Java method: com.softaria.grace.ng.controller.library.VendorController.getSwitchVendors
   */
  getSwitchVendors(options?: O): RestResponse<VendorViewDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`vendor/vendors/switch`, options: options });
  }

  /**
   * HTTP DELETE /vendor/{vendorId}
   * Java method: com.softaria.grace.ng.controller.library.VendorController.deleteVendor
   */
  deleteVendor(vendorId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`vendor/${vendorId}`, options: options });
  }

  /**
   * HTTP POST /vendor/{vendorId}
   * Java method: com.softaria.grace.ng.controller.library.VendorController.updateVendor
   */
  updateVendor(vendorId: number, vendorUpdateDto: VendorUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`vendor/${vendorId}`,
      data: vendorUpdateDto,
      options: options,
    });
  }
}

export interface Annotation {}

export interface AutoCloseable {}

export interface BaseStream<T, S> extends AutoCloseable {
  parallel?: boolean;
}

export interface CommonFieldsDto {
  model?: string;
  name?: string;
  type: HardwareType;
  vendorId?: number;
}

export interface Company extends Annotation {}

export interface CompanyBaseDto {
  id: number;
  name: string;
}

export interface CompanyDto {
  createdAt: number;
  creator: UserBaseDto;
  deleted: boolean;
  disabled: boolean;
  id: number;
  lastActivityAt: number;
  name: string;
}

export interface CompanyRegisterDto {
  administratorEmail: string;
  name: string;
}

export interface CompanySortDto {
  direction: Direction;
  property: CompanySortProperty;
}

export interface CompanyUpdateDto {
  disabled?: boolean;
  name?: string;
}

export interface CompanyValidator extends ConstraintValidator<Company, CompanyBaseDto> {}

export interface ConstraintValidator<A, T> {}

export interface DataAttributeTemplateDto {
  bType?: string;
  btype?: string;
  desc: string;
  fc: string;
  name: string;
  type?: string;
  value?: string;
}

export interface DataAttributeViewDto {
  bType: string;
  btype: string;
  name: string;
  type: string;
  value?: string;
}

export interface DataObjectDto {
  desc: string;
  name: string;
  type: string;
}

export interface DataObjectTemplateDto {
  cdc: string;
  dataAttributes: DataAttributeTemplateDto[];
  id: string;
  subDataObjects: DataObjectDto[];
}

export interface DataObjectViewDto {
  dataAttributes?: DataAttributeViewDto[];
  name: string;
  subDataObjects?: DataObjectViewDto[];
  type: string;
}

export interface DataPacketsDto {
  dataSets?: DataSetDto[];
  gooseControlBlocks?: GooseControlBlockDto[];
  inputs?: SignalDto[];
  outputs?: SignalDto[];
  reports?: ReportDto[];
  svControlBlocks?: SvControlBlockDto[];
}

export interface DataSetDto {
  members?: SignalDto[];
  name?: string;
  protocol?: DataSetProtocol;
  type?: DataSetType;
}

export interface DiagramDto {
  json?: string;
  type: StageType;
}

export interface DiagramLayoutDto {
  diagram?: string;
  success: boolean;
}

export interface ExportFormDto {
  chiefProjectEngineer?: string;
  code?: string;
  company?: string;
  companySecondLine?: string;
  contractNumber?: string;
  contractorDocumentNumber?: string;
  createdAt: number;
  description?: string;
  developedBy?: string;
  formats: ExportFormatType[];
  id: number;
  logo?: string;
  logoFilename?: string;
  revision?: string;
  schemaName?: string;
  schemaNameSecondLine?: string;
  stage?: DocStageType;
  status?: string;
  title?: string;
  titleSecondLine?: string;
  type: ExportFormType;
  verifiedBy?: string;
}

export interface FirstStageStateDto {
  categoryId?: string;
  description?: string;
  name?: string;
  primaryEquipmentIds?: string[];
  vendorId?: number;
  voltageIds?: string[];
}

export interface GooseControlBlockDto {
  appID?: number;
  dataSetName?: string;
  destinationMac?: string;
  maxTime?: number;
  minTime?: number;
  name?: string;
  vlanId?: number;
  vlanPriority?: number;
}

export interface HardwareBaseDto {
  id: number;
  name: string;
}

export interface HardwareDto {
  commonFields: CommonFieldsDto;
  icdFile?: File;
  restFields: RestFieldsDto;
}

export interface HardwareDtoKt {}

export interface HardwareFilterOptionsDto {
  editors: UserBaseDto[];
  icdNames: string[];
  svg: SvgBaseDto[];
  vendors: VendorViewDto[];
}

export interface HardwareInterfaceDto {
  directoryId?: string;
  number?: number;
}

export interface HardwareSortDto {
  direction: Direction;
  editorId?: number;
  icdFilename?: string;
  property: HardwareSortProperty;
  svgId?: number;
  vendorId?: number;
}

export interface HardwareViewDto {
  editedAt: number;
  editor: UserBaseDto;
  icdFilename?: string;
  icdTemplates?: IcdTemplatesDto;
  id: number;
  model: string;
  name?: string;
  restFields: RestFieldsDto;
  svg?: SvgDto;
  vendor: VendorViewDto;
}

export interface HardwareViewForListDto {
  editedAt: number;
  editor: UserBaseDto;
  icdFilename?: string;
  id: number;
  model: string;
  name?: string;
  svgFilename?: string;
  vendor: VendorViewDto;
}

export interface IcdReadDto {
  logicalNodeComposition?: IcdTemplatesDto;
  message?: MessageDto;
  success: boolean;
}

export interface IcdTemplatesDto {
  dataObjectTemplates: { [index: string]: DataObjectTemplateDto };
  logicalNodeTemplates: LnTemplateDto[];
}

export interface ImportFileValidator extends ConstraintValidator<ValidImportFile, ProjectCreateDto> {}

export interface Iterable<T> {}

export interface LanCreationDto {
  stationLevel?: string;
  switchVendorId?: number;
}

export interface LnTemplateDto {
  clazz: string;
  dataObjects: DataObjectDto[];
  desc: string;
  type: string;
}

export interface LogicalDeviceDto {
  firstStage: FirstStageStateDto;
  secondStage: SecondStageStateDto;
  thirdStage: ThirdStageStateDto;
}

export interface LogicalDeviceSortDto {
  categoryId?: string;
  direction: Direction;
  editorId?: number;
  property: LogicalDeviceSortProperty;
  vendorId?: number;
}

export interface LogicalDeviceViewDto {
  categoryId?: string;
  createdAt: number;
  creator: UserBaseDto;
  dataPackets?: DataPacketsDto;
  description?: string;
  editedAt?: number;
  editor?: UserBaseDto;
  finishedAt?: number;
  finishedBy?: UserBaseDto;
  id: number;
  logicalNodes: LogicalNodeViewDto[];
  name: string;
  performancePoints?: number;
  primaryEquipmentIds?: string[];
  vendor: VendorViewDto;
  voltageIds?: string[];
}

export interface LogicalDeviceViewForListDto {
  categoryId?: string;
  createdAt: number;
  creator: UserBaseDto;
  description?: string;
  editedAt?: number;
  editor?: UserBaseDto;
  finishedAt?: number;
  finishedBy?: UserBaseDto;
  id: number;
  name: string;
  performancePoints?: number;
  primaryEquipmentIds?: string[];
  vendor: VendorViewDto;
  voltageIds?: string[];
}

export interface LogicalNodeCreateDto {
  instance: number;
  logicalNodeTypeId: number;
}

export interface LogicalNodeTypeBoundedViewDto {
  id: number;
  type: string;
}

export interface LogicalNodeTypeFilterOptionsDto {
  classes: string[];
  editors: UserBaseDto[];
  hardwareList: HardwareBaseDto[];
  lnTypes: string[];
  vendors: VendorViewDto[];
}

export interface LogicalNodeTypeSortDto {
  clazz?: string;
  direction: Direction;
  editorId?: number;
  hardwareId?: number;
  lnType?: string;
  property: LogicalNodeTypeSortProperty;
  vendorId?: number;
}

export interface LogicalNodeTypeUpdateDto {
  performancePoints: number;
}

export interface LogicalNodeTypeViewDto {
  clazz: string;
  description?: string;
  editedAt: number;
  editor: UserBaseDto;
  icdTemplatesDto?: IcdTemplatesDto;
  id: number;
  ieds: string[];
  performancePoints: number;
  physicalDevices: string;
  type: string;
  vendor: VendorViewDto;
}

export interface LogicalNodeViewDto {
  instance: number;
  logicalNodeType: LogicalNodeTypeBoundedViewDto;
}

export interface MessageDto {
  en: string;
  ru: string;
}

export interface OptField {
  bufOvfl?: boolean;
  configRef?: boolean;
  entryId?: boolean;
  seqNum?: boolean;
  timeStamp?: boolean;
}

export interface Order extends Serializable {
  ascending?: boolean;
  descending?: boolean;
  direction?: Direction;
  ignoreCase?: boolean;
  nullHandling?: NullHandling;
  property?: string;
}

export interface Page<T> extends Slice<T> {
  totalElements?: number;
  totalPages?: number;
}

export interface Pageable {
  offset?: number;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  sort?: Sort;
  unpaged?: boolean;
}

export interface PasswordRecoveryDto {
  email: string;
  uuid: string;
}

export interface ProjectCreateDto {
  creationType?: ProjectCreationType;
  customerName?: string;
  frequency?: Frequency;
  importFile?: File;
  name?: string;
  stationName?: string;
  styleSelection?: ProjectStyleSelection;
}

export interface ProjectDto {
  activeStage: StageBaseDto;
  creationType: ProjectCreationType;
  customerCompany?: string;
  frequency: Frequency;
  id: number;
  iedVendor: VendorBaseDto;
  muVendor: VendorBaseDto;
  name: string;
  pacsArchitectureDirectoryId: PacsArchitectureDirectoryId;
  setOfRules?: string;
  stationLevel?: string;
  stationName?: string;
  styleSelection: ProjectStyleSelection;
  switchVendor: VendorBaseDto;
  uuid: string;
}

export interface ProjectExportDto {
  activeStage: StageBaseDto;
  customerCompany?: string;
  exportForms: ExportFormDto[];
  id: number;
  name: string;
  stages: StageExportDto[];
  stationName?: string;
  styleSelection: ProjectStyleSelection;
}

export interface ProjectFilterOptionsDto {
  authors: UserBaseDto[];
  companies?: CompanyBaseDto[];
  customerCompanies: string[];
}

export interface ProjectSortingDto {
  authorId?: number;
  companyId?: number;
  customerCompany?: string;
  direction: Direction;
  dtpsProjectId?: string;
  property: ProjectSortProperty;
}

export interface ProjectUpdateDto {
  customerName?: string;
  name?: string;
  stationName?: string;
}

export interface ProjectViewDto {
  createdAt: number;
  creator: UserBaseDto;
  customerCompany?: string;
  editedAt: number;
  editor: UserBaseDto;
  id: number;
  name: string;
  stage: StageViewDto;
}

export interface ReportConfigDto {
  cableExportForm: ExportFormDto;
  generalExportForm: ExportFormDto;
  projectName: string;
  styleSelection: ProjectStyleSelection;
  tcoExportForm: ExportFormDto;
}

export interface ReportDto {
  dataSetName?: string;
  name?: string;
  optField: OptField;
  reportsControl: ReportsControl;
  rptEnabled?: number;
  trigOps: TrigOps;
}

export interface ReportsControl {
  bufTime?: number;
  buffered?: boolean;
  indexed?: boolean;
  intgPd?: number;
}

export interface RestFieldsDto {
  analogueInputsOutputsNumber?: number;
  currency?: string;
  description?: string;
  discreteInputsOutputsNumber?: number;
  failureFlowParameter?: number;
  interfaces: HardwareInterfaceDto[];
  lifetime?: number;
  nameForEquipmentSpec?: string;
  orderCode?: string;
  performancePoints?: number;
  price?: number;
  recoveryTime?: number;
  svgId?: number;
}

export interface ScdCreationDto {
  iedVendorId?: number;
  muVendorId?: number;
  pacsArchitectureDirectoryId?: PacsArchitectureDirectoryId;
}

export interface SecondStageStateDto {
  logicalNodes?: LogicalNodeCreateDto[];
}

export interface Serializable {}

export interface SignalDto {
  daBType?: string;
  daName?: string;
  daType?: string;
  doName?: string;
  doType?: string;
  fc?: string;
  ldName?: string;
  lnType?: string;
  tagIds?: string[];
}

export interface Slice<T> extends Streamable<T> {
  content?: T[];
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  sort?: Sort;
}

export interface Sort extends Streamable<Order>, Serializable {
  sorted?: boolean;
  unsorted?: boolean;
}

export interface SsdCreationDto {
  setOfRules?: string;
}

export interface StageBaseDto {
  deprecated: boolean;
  finished: boolean;
  finishedAt?: number;
  finishedBy?: UserBaseDto;
  id: number;
  raw: boolean;
  type: StageType;
  valid: boolean;
}

export interface StageDto {
  baseInfo: StageBaseDto;
  json?: string;
  project: ProjectDto;
  tcoSettings?: TcoSettingsDto;
}

export interface StageExportDto {
  id: number;
  type: StageType;
}

export interface StageValidationDto {
  message?: MessageDto;
  stage: StageDto;
  success: boolean;
}

export interface StageViewDto {
  finishedAt?: number;
  id: number;
  type: StageType;
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
  empty?: boolean;
}

export interface StreamingResponseBody {}

export interface Supplier<T> {}

export interface SvControlBlockDto {
  appID?: number;
  dataSetName?: string;
  destinationMac?: string;
  name?: string;
  svID?: number;
  vlanId?: number;
  vlanPriority?: number;
}

export interface SvgBaseDto {
  id: number;
  name: string;
}

export interface SvgDto {
  basic: boolean;
  content: string;
  id: number;
  name: string;
}

export interface TcoSettingsDto {
  autoCurrencyExchangeEnabled: boolean;
  basicCostPerPersonHour?: number;
  billingPeriodInYears?: number;
  commissioningWorksProtection?: number;
  commissioningWorksScada?: number;
  constructionAndInstallationWorksProtection?: number;
  constructionAndInstallationWorksScada?: number;
  currencyDirectoryId?: CurrencyDirectoryId;
  currencyExchangeRateEurToRub?: number;
  currencyExchangeRateRubToEur?: number;
  currencyExchangeRateRubToUsd?: number;
  currencyExchangeRateUsdToRub?: number;
  designProtection?: number;
  designScada?: number;
  discountRate?: number;
  isAutoCurrencyExchangeEnabled?: boolean;
  maintenanceSupplyProtection?: number;
  maintenanceSupplyScada?: number;
}

export interface ThirdStageStateDto {
  dataPackets?: DataPacketsDto;
}

export interface TrigOps {
  dchg?: boolean;
  dupd?: boolean;
  gi?: boolean;
  period?: boolean;
}

export interface UserBaseDto {
  company: CompanyBaseDto;
  id: number;
  name: string;
}

export interface UserDto {
  company: CompanyBaseDto;
  createdAt: number;
  deleted: boolean;
  disabled: boolean;
  email: string;
  id: number;
  name: string;
  role: UserRole;
}

export interface UserInviteDto {
  company?: CompanyBaseDto;
  email: string;
  role: UserRole;
  uuid?: string;
}

export interface UserRegisterDto {
  name: string;
  password: string;
}

export interface UserRequestDto {
  companyName?: string;
  sortDirection: Direction;
  sortProperty: UserSortProperty;
}

export interface UserUpdateDto {
  company: CompanyBaseDto;
  disabled?: boolean;
  email: string;
  name: string;
  role: UserRole;
}

export interface ValidImportFile extends Annotation {}

export interface ValueDto {
  value: string;
}

export interface VendorBaseDto {
  id: number;
  name: string;
}

export interface VendorCreateDto {
  name?: string;
}

export interface VendorSortDto {
  direction: Direction;
  property: VendorSortProperty;
}

export interface VendorUpdateDto {
  name?: string;
}

export interface VendorViewDto {
  editedAt: number;
  editor: UserBaseDto;
  id: number;
  name: string;
}

export type CompanySortProperty = 'COMPANY_NAME' | 'CREATED_AT' | 'LAST_ACTIVITY_AT' | 'CREATOR_NAME' | 'STATUS';

export type CurrencyDirectoryId = 'EUR' | 'RUB' | 'USD';

export type DataSetProtocol = 'PROTOCOL_9_2' | 'PROTOCOL_9_2_LE';

export type DataSetType = 'GOOSE_CB' | 'SV_CB' | 'REPORTS';

export type Direction = 'ASC' | 'DESC';

export type DocStageType = 'PROJECT_DOC' | 'WORKING_DOC' | 'EXECUTIVE_DOC';

export type ExportFormType = 'SLD' | 'SSD' | 'SCD' | 'LAN' | 'GENERAL' | 'CABLE' | 'TCO';

export type ExportFormatType = 'SVG' | 'PDF' | 'SCL' | 'XLS';

export type Frequency = 'FIFTY' | 'SIXTY';

export type HardwareSortProperty = 'ID' | 'NAME' | 'MODEL' | 'EDITED_AT';

export type HardwareType =
  | 'IED'
  | 'MU'
  | 'IED_MU'
  | 'NETWORK'
  | 'PLC'
  | 'SERVERS'
  | 'TIME_SERVERS'
  | 'AWS'
  | 'PRINTERS';

export type LogicalDeviceSortProperty = 'ID' | 'NAME' | 'EDITED_AT' | 'PERFORMANCE_POINTS';

export type LogicalNodeTypeSortProperty = 'ID' | 'TYPE' | 'CLAZZ' | 'PERFORMANCE_POINTS' | 'EDITED_AT';

export type NullHandling = 'NATIVE' | 'NULLS_FIRST' | 'NULLS_LAST';

export type PacsArchitectureDirectoryId = 'decentralized' | 'centralized' | 'vpacs';

export type PasswordRecoveryLinkStatus = 'VALID' | 'EXPIRED' | 'NON_EXISTENT';

export type ProjectCreationType = 'MANUAL' | 'IMPORT';

export type ProjectSortProperty =
  | 'ID'
  | 'PROJECT_NAME'
  | 'CREATOR_NAME'
  | 'CREATED_AT'
  | 'EDITOR_NAME'
  | 'EDITED_AT'
  | 'CUSTOMER_COMPANY'
  | 'COMPANY_NAME'
  | 'STAGE_TYPE'
  | 'STAGE_FINISHED_AT';

export type ProjectStyleSelection = 'RU' | 'EU';

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type StageType = 'SLD' | 'SSD' | 'SCD' | 'LAN' | 'TCO' | 'EXPORT';

export type UserRole = 'ROLE_SYSTEM_ADMIN' | 'ROLE_COMPANY_ADMIN' | 'ROLE_COMPANY_USER';

export type UserSortProperty = 'NAME' | 'EMAIL' | 'CREATED_AT' | 'STATUS';

export type VendorSortProperty = 'VENDOR_NAME';

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
  let result = '';
  for (let i = 0; i < substitutions.length; i++) {
    result += template[i];
    result += encodeURIComponent(substitutions[i]);
  }
  result += template[template.length - 1];
  return result;
}

// Added by 'AxiosClientExtension' extension

import axios from 'axios';
import * as Axios from 'axios';

declare module 'axios' {
  export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
    data: R;
  }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {
  constructor(private axios: Axios.AxiosInstance) {}

  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: Axios.AxiosRequestConfig;
  }): RestResponse<R> {
    function assign(target: any, source?: any) {
      if (source != undefined) {
        for (const key in source) {
          if (source.hasOwnProperty(key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    }

    const config: Axios.AxiosRequestConfig = {};
    config.method = requestConfig.method as typeof config.method; // `string` in axios 0.16.0, `Method` in axios 0.19.0
    config.url = requestConfig.url;
    config.params = requestConfig.queryParams;
    config.data = requestConfig.data;
    assign(config, requestConfig.options);
    const copyFn = requestConfig.copyFn;

    const axiosResponse = this.axios.request(config);
    return axiosResponse.then((axiosResponse) => {
      if (copyFn && axiosResponse.data) {
        (axiosResponse as any).originalData = axiosResponse.data;
        axiosResponse.data = copyFn(axiosResponse.data);
      }
      return axiosResponse;
    });
  }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {
  constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
    axiosInstance.defaults.baseURL = baseURL;
    super(new AxiosHttpClient(axiosInstance));
  }
}
