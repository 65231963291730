import styled from 'styled-components';
import { Icon } from './Icon.styled';

const Feedback = styled.div<{ error?: boolean; success?: boolean; loading?: boolean }>`
  display: flex;
  align-items: flex-start;
  color: ${(props) => {
    if (props.error) {
      return props.theme.colors.red;
    } else if (props.success) {
      return props.theme.colors.turquoise;
    } else if (props.loading) {
      return props.theme.colors.blue;
    }
    return props.theme.colors.lightgrey;
  }};
  white-space: pre-wrap;

  ${Icon} {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    margin-top: 2px;
    fill: ${(props) => {
      if (props.error) {
        return props.theme.colors.red;
      } else if (props.success) {
        return props.theme.colors.turquoise;
      } else if (props.loading) {
        return props.theme.colors.blue;
      }
      return props.theme.colors.lightgrey;
    }};
    flex-shrink: 0;
  }
`;

export const Styled = {
  Feedback,
};
