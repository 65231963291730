import * as React from 'react';
import { PlaceholderWidgetSizes } from '../../../ssd/logic-device/PlaceholderWidget.styled';
import { ExportPlaceholderBrick } from './ExportPlaceholderBrick';
import { theme } from '../../../../../theme';
import { NamedNodeModelPayload } from '../../../generics/NamedNodeModelPayload';
import { Placeholder, PlaceholderBaseChild, PlaceholderBaseRelativeModel } from '../../../placeholder/Placeholder';

interface ExportPlaceholderProps {
  placeholderModel: Placeholder<
    PlaceholderBaseChild & { getBrickColor(): string; getCodeName(): string },
    PlaceholderBaseRelativeModel
  > & {
    getCodeName(): string;
    getShortName(): string;
  };
}

export const ExportPlaceholder: React.FC<ExportPlaceholderProps> = ({ placeholderModel }) => {
  const rect = placeholderModel.getRect()!;
  const { primaryHeaderRect, leftHeaderRect, rightHeaderRect, borderWidth } = PlaceholderWidgetSizes;
  const relativeModel = placeholderModel.getRelativeModel();
  return (
    <g transform={`translate(${rect.getTopLeft().x},${rect.getTopLeft().y})`}>
      <g stroke={`${theme.colors.grey}`} strokeWidth={borderWidth} fill="white">
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={rect.getWidth() - borderWidth}
          height={rect.getHeight() - borderWidth}
        />
        <path
          d={`M ${primaryHeaderRect.getBottomLeft().x} ${primaryHeaderRect.getBottomRight().y + borderWidth / 2} H ${
            primaryHeaderRect.getBottomRight().x
          }`}
        />
        <path
          d={`M ${leftHeaderRect.getBottomLeft().x} ${leftHeaderRect.getBottomLeft().y + borderWidth / 2} H ${
            rightHeaderRect.getBottomRight().x
          }`}
        />
        <path
          d={`M ${leftHeaderRect.getTopRight().x + borderWidth / 2} ${leftHeaderRect.getTopRight().y} V ${
            leftHeaderRect.getBottomRight().y
          }`}
        />
      </g>
      <g fontSize="10px" fontFamily="GOSTRUS, serif" textAnchor="middle" dominantBaseline="central">
        <text x={primaryHeaderRect.getOrigin().x} y={primaryHeaderRect.getOrigin().y}>
          {placeholderModel.getShortName()}
        </text>
        <text x={leftHeaderRect.getOrigin().x} y={leftHeaderRect.getOrigin().y}>
          {placeholderModel.getCodeName()}
        </text>
        <text x={rightHeaderRect.getOrigin().x} y={rightHeaderRect.getOrigin().y}>
          {(relativeModel?.getPayload() as NamedNodeModelPayload).projectName}
        </text>
      </g>
      <g>
        {placeholderModel.getChildren().map((logicNode) => (
          <ExportPlaceholderBrick brickModel={logicNode} key={logicNode.getID()} />
        ))}
      </g>
    </g>
  );
};
