import React from 'react';
import { Styled as S } from './InfoPanel.styled';
import { Tooltip } from './Tooltip';

type InfoPanelProps = {
  title: JSX.Element | string;
  value: string | number;
};

export const InfoPanel: React.FC<InfoPanelProps> = ({ title, value, children }) => {
  return (
    <S.InfoPanel>
      <S.Icon>
        <Tooltip text={children} placement={'bottom-start'} offset={[12, 16]} />
      </S.Icon>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{value}</S.Subtitle>
    </S.InfoPanel>
  );
};
