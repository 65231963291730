import {
  AxiosRestApplicationClient,
  HardwareDto,
  IcdReadDto,
  ProjectUpdateDto,
  RestResponse,
  SvgDto,
} from './nggrace-back';
import axios, * as Axios from 'axios';
import FileDownload from 'js-file-download';
import { captureException } from '@sentry/react';

const instance = axios.create({ withCredentials: true });
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const setupInterceptor = (
  redirectToRootPath: () => void,
  resetContexts: () => void,
  showError: (message: string) => void
) => {
  const responseInterceptorId = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      captureException(error);

      const status = error.response?.status;
      const url = error.response?.config.url;
      if ((status === 401 || status === 403) && !url.endsWith('auth/login')) {
        resetContexts();
        redirectToRootPath();
      } else if (status === 404) {
        redirectToRootPath();
      } else if (!error.config.omitInterceptorErrorModal) {
        let message = error;
        if (axios.isAxiosError(error)) {
          message = error.response?.data || error.message;
        } else if (error instanceof Error) {
          message = error.message;
        }

        showError(message || 'Internal error');
      }

      return Promise.reject(error);
    }
  );

  const requestInterceptorId = instance.interceptors.request.use(
    (request) => {
      const devUserId = process.env.REACT_APP_USER_ID;
      if (devUserId !== undefined) {
        request.headers = { principalId: devUserId };
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return () => {
    instance.interceptors.response.eject(responseInterceptorId);
    instance.interceptors.response.eject(requestInterceptorId);
  };
};

class NgGraceApiClient extends AxiosRestApplicationClient {
  createProjectWithFormData(
    projectCreationDto: ProjectUpdateDto,
    options?: Axios.AxiosRequestConfig
  ): RestResponse<number> {
    const formData = new FormData();
    Object.entries(projectCreationDto).forEach((entry) => {
      if (entry[1] !== undefined) {
        formData.append(entry[0], entry[1]);
      }
    });
    return this.httpClient.request({
      method: 'POST',
      url: 'project',
      data: formData,
      options: options,
    });
  }

  createHardwareWithFormData(hardwareDto: HardwareDto, options?: Axios.AxiosRequestConfig): RestResponse<number> {
    const formData = new FormData();
    Object.entries(hardwareDto).forEach((entry) => {
      if (entry[1] !== undefined) {
        formData.append(
          entry[0],
          entry[1] instanceof File
            ? entry[1]
            : new Blob([JSON.stringify(entry[1])], {
                type: 'application/json',
              })
        );
      }
    });
    return this.httpClient.request({
      method: 'POST',
      url: 'hardware/create',
      data: formData,
      options: options,
    });
  }

  exportProjectWithFormData(
    projectId: number,
    projectName: string,
    files: Map<string, string>,
    options?: Axios.AxiosRequestConfig
  ) {
    const formData = new FormData();
    files.forEach((svg, name) => {
      formData.append('files', new Blob([svg], { type: 'plain/text' }), name);
    });

    return this.httpClient
      .request({
        method: 'POST',
        url: `project/${projectId}/export`,
        data: formData,
        options: { ...options, responseType: 'arraybuffer' },
      })
      .then((response) => FileDownload(new Blob([response.data as BufferSource]), `${projectName}-export.zip`));
  }

  downloadIcdFile(hardwareId: number, icdFileName: string, options?: Axios.AxiosRequestConfig) {
    return this.httpClient
      .request({
        method: 'GET',
        url: `hardware/${hardwareId}/icd-file`,
        options: { ...options, responseType: 'arraybuffer' },
      })
      .then((response) =>
        FileDownload(
          new Blob([response.data as BufferSource]),
          icdFileName.endsWith('.icd') || icdFileName.endsWith('.cid') ? icdFileName : `${icdFileName}.icd`
        )
      );
  }

  validateAndReadIcdFile(icdFile: File, options?: Axios.AxiosRequestConfig): RestResponse<IcdReadDto> {
    const formData = new FormData();
    formData.append('icdFile', icdFile);
    return this.httpClient.request({
      method: 'POST',
      url: 'hardware/validate-and-read-icd',
      data: formData,
      options: options,
    });
  }

  saveSvgFile(svgFile: File, options?: Axios.AxiosRequestConfig): RestResponse<SvgDto> {
    const formData = new FormData();
    formData.append('svgFile', svgFile);
    return this.httpClient.request({
      method: 'POST',
      url: 'hardware/save-svg',
      data: formData,
      options: options,
    });
  }
}

export const Api = new NgGraceApiClient(API_ENDPOINT || '/api', instance);
