import { PortModel } from '@projectstorm/react-diagrams';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export class SimplePortFactory extends AbstractModelFactory<PortModel, DiagramEngine> {
  cb: (initialConfig?: any) => PortModel;

  constructor(type: string, cb: (initialConfig?: any) => PortModel) {
    super(type);
    this.cb = cb;
  }

  generateModel(event: { initialConfig?: any }): PortModel {
    return this.cb(event.initialConfig);
  }
}
