import styled from 'styled-components';

const Content = styled.div`
  box-shadow: 0 0 20px 10px rgba(63, 63, 63, 0.1);
  border-radius: 16px;
  padding: 24px 0;
  background: ${(props) => props.theme.colors.white};
`;

const Grid = styled.div`
  display: grid;
  grid-auto-rows: 72px;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const Styled = {
  Content,
  Grid,
};
