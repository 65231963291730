import styled, { css } from 'styled-components';

import { List } from '../../../../../../../widgets/List.styled';
import { Icon } from '../../../../../../../widgets/Icon.styled';
import { Styled as Tree } from '../../../../../hardware/card/lncomposition/widget/Tree.styled';

const TreeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  ${Tree.Header} {
    border-bottom: unset;
    width: 100%;
    ${(props) =>
      // @ts-ignore
      !props.highlight &&
      css`
        :hover {
          background-color: unset;
        }
      `}
  }
`;

const TreeCell = styled(List.Cell)`
  line-height: 24px;
  font-size: 12px;
  height: 36px;
  border-bottom: unset;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${List.Button} {
    ${Icon} {
      width: 15px;
      height: 20px;
    }
  }
`;

export const Styled = {
  TreeRow,
  TreeCell,
};
