import React, { useCallback, useMemo } from 'react';
import { Styled as S } from './CheckboxGroup.styled';
import { theme } from '../../theme';

type CheckboxGroupProps<T extends string> = {
  selectedValues: T[];
  values: T[];
  disabledValues?: T[];
  onChange(values: T[]): void;
};

type CheckboxProps<T extends string> = {
  value: T;
  selectedValues: T[];
  disabled: boolean;
  onToggle(value: T): void;
};

export const CheckboxGroup = <T extends string>({
  values,
  selectedValues,
  disabledValues,
  onChange,
}: CheckboxGroupProps<T>) => {
  const handleToggle = useCallback(
    (value: T) => {
      onChange(selectedValues.includes(value) ? selectedValues.filter((f) => f !== value) : [...selectedValues, value]);
    },
    [onChange, selectedValues]
  );

  return (
    <S.CheckboxGroup>
      {values.map((value) => (
        <Checkbox
          key={value}
          value={value}
          disabled={disabledValues?.includes(value) || false}
          selectedValues={selectedValues}
          onToggle={handleToggle}
        />
      ))}
    </S.CheckboxGroup>
  );
};

const Checkbox = <T extends string>({ value, selectedValues, disabled, onToggle }: CheckboxProps<T>) => {
  const handleChange = useCallback(() => onToggle(value), [value, onToggle]);
  const id = useMemo(() => `${Date.now()}-${value}`, [value]);
  const checked = selectedValues.includes(value);

  return (
    <S.Checkbox>
      <S.Input
        type="checkbox"
        id={id}
        onChange={handleChange}
        disabled={disabled}
        checked={selectedValues.includes(value)}
      />
      <S.Label htmlFor={id} disabled={disabled} checked={checked}>
        <S.Check>
          {checked && (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.27273 1.27273H12.7273V12.7273H1.27273V1.27273ZM0 1.27273C0 0.569813 0.569825 0 1.27273 0H12.7273C13.4302 0 14 0.569825 14 1.27273V12.7273C14 13.4302 13.4302 14 12.7273 14H1.27273C0.569813 14 0 13.4302 0 12.7273V1.27273ZM10.627 4.46861C10.8258 4.17883 10.7521 3.7827 10.4623 3.58383C10.1726 3.38496 9.77645 3.45865 9.57759 3.74842L5.90607 9.09818L4.06933 6.88404C3.84493 6.61354 3.44375 6.57617 3.17325 6.80055C2.90275 7.02494 2.86538 7.42613 3.08976 7.69663L5.46433 10.5591C5.5917 10.7127 5.78374 10.7976 5.98305 10.7885C6.18236 10.7794 6.36589 10.6774 6.47878 10.5129L10.627 4.46861Z"
              fill={theme.colors.blue}
            />
          )}
          {!checked && (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.27273 1.27273H12.7273V12.7273H1.27273V1.27273ZM0 1.27273C0 0.569813 0.569825 0 1.27273 0H12.7273C13.4302 0 14 0.569825 14 1.27273V12.7273C14 13.4302 13.4302 14 12.7273 14H1.27273C0.569813 14 0 13.4302 0 12.7273V1.27273Z"
              fill={theme.colors.darkgrey}
            />
          )}
        </S.Check>
        {value}
      </S.Label>
    </S.Checkbox>
  );
};
