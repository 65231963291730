// @ts-ignore
import { LogicalDeviceStage } from '../../../../../api/nggrace-back';
import { CardType } from './LogicalDeviceCard';

export interface StageInfo {
  activeStage: LogicalDeviceStage;
  cardType: CardType;
  content: string;
  stageNumber: number;
}

export const STAGE_INFO_DATA: StageInfo[] = [
  {
    activeStage: 'FIRST_STAGE',
    cardType: 'create',
    content: 'Create an LD. Enter required parameters',
    stageNumber: 1,
  },
  {
    activeStage: 'SECOND_STAGE',
    cardType: 'create',
    content: 'Create an LD. Determine composition of nested LNs in LD composition',
    stageNumber: 2,
  },
  {
    activeStage: 'THIRD_STAGE',
    cardType: 'create',
    content: 'Create an LD. Create data packets (optional)',
    stageNumber: 3,
  },
  {
    activeStage: 'FIRST_STAGE',
    cardType: 'edit',
    content: 'Create an LD. Enter required parameters',
    stageNumber: 1,
  },
  {
    activeStage: 'SECOND_STAGE',
    cardType: 'edit',
    content: 'Create an LD. Determine composition of nested LNs in LD composition',
    stageNumber: 2,
  },
  {
    activeStage: 'THIRD_STAGE',
    cardType: 'edit',
    content: 'Create an LD. Create data packets (optional)',
    stageNumber: 3,
  },
  {
    activeStage: 'FIRST_STAGE',
    cardType: 'view',
    content: 'LD Name. Required parameters',
    stageNumber: 1,
  },
  {
    activeStage: 'SECOND_STAGE',
    cardType: 'view',
    content: 'LD Name. Composition of nested LNs in LD composition',
    stageNumber: 2,
  },
  {
    activeStage: 'THIRD_STAGE',
    cardType: 'view',
    content: 'LD Name. Data packets',
    stageNumber: 3,
  },
];
