import { ProjectStyleSelection } from '../../../../api/nggrace-back';
import { FrameRu } from './FrameRu';
import { FrameEu } from './FrameEu';

export interface FramePosition {
  x: number;
  y: number;
}

export interface FrameSize {
  width: number;
  height: number;
}

type Preset = FrameSize & { format?: string };

export interface Frame {
  svg: JSX.Element;
  size: Preset;
  maxSize: FrameSize;
  position: FramePosition;
}

const A0Size: FrameSize = {
  width: 1189,
  height: 841,
};

const presets: Preset[] = [
  {
    format: 'A4',
    width: 210,
    height: 297,
  },
  {
    format: 'A3',
    width: 420,
    height: 297,
  },
  {
    format: 'A2',
    width: 594,
    height: 420,
  },
  {
    format: 'A1',
    width: 841,
    height: 594,
  },
  {
    format: 'A0',
    ...A0Size,
  },
];

const PaddingRu = {
  width: 35,
  height: 75,
};

const PaddingEu = {
  width: 20,
  height: 85,
};

export const getFrame = (style: ProjectStyleSelection, width: number, height: number): Frame => {
  const frameSize = getFrameSize(style, width, height);
  switch (style) {
    case 'RU':
      return {
        svg: <FrameRu {...frameSize.size} />,
        ...frameSize,
        position: {
          x: 25,
          y: 10,
        },
      };
    case 'EU':
      return {
        svg: <FrameEu {...frameSize.size} />,
        ...frameSize,
        position: {
          x: 10,
          y: 10,
        },
      };
  }
};

const getFrameSize = (
  style: ProjectStyleSelection,
  width: number,
  height: number
): {
  size: Preset;
  maxSize: FrameSize;
} => {
  const padding = style === 'RU' ? PaddingRu : PaddingEu;
  const preset = presets.reduce<Preset>(
    (previousValue, currentValue) => {
      if (
        width + padding.width <= currentValue.width &&
        height + padding.height <= currentValue.height &&
        isFrameSmaller(previousValue, currentValue)
      ) {
        return currentValue;
      }
      return previousValue;
    },
    {
      width: Math.ceil((width + padding.width) / A0Size.width) * A0Size.width,
      height: Math.ceil((height + padding.height) / A0Size.height) * A0Size.height,
    }
  );
  return {
    size: preset,
    maxSize: {
      width: preset.width - padding.width,
      height: preset.height - padding.height,
    },
  };
};

const isFrameSmaller = (previousValue: FrameSize, currentValue: FrameSize) => {
  return currentValue.width <= previousValue.width && currentValue.height <= previousValue.height;
};
