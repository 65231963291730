import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Styled as S } from './InputField.styled';

type InputFieldProps = {
  label?: string;
  value?: string | number;
  autoComplete?: string;
  required?: boolean;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  step?: number;
  min?: number;
  max?: number;
  type?: InputType;
  title?: string;
  disabled?: boolean;
  fitContent?: boolean;
  dataTestid?: string;
  placeholder?: string;

  onKeyPress?(): void;
  onChange(value?: string | number): void;
};

export type InputType = 'text' | 'number' | 'date' | 'password';

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      value = '',
      autoComplete,
      required,
      pattern,
      minLength,
      maxLength,
      min,
      max,
      step,
      type,
      title,
      disabled,
      onChange,
      onKeyPress,
      fitContent,
      dataTestid,
      placeholder,
    },
    ref
  ) => {
    const id = useMemo(generateId, []);
    const [width, setWidth] = useState(0);

    useEffect(() => {
      fitContent &&
        setWidth(
          computeWidth(
            (value || '0'.repeat(placeholder ? placeholder.length : 1)).toString(),
            ref as MutableRefObject<HTMLInputElement>
          )
        );
    }, [fitContent, placeholder, ref, value]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      },
      [onChange]
    );

    const keyPressHandler = useCallback(
      (event) => {
        if (event.key === 'Enter') {
          if (ref) {
            const inputRef = ref as MutableRefObject<HTMLInputElement>;
            inputRef.current.blur();
          }
          if (onKeyPress) {
            onKeyPress();
          }
        }
      },
      [onKeyPress, ref]
    );

    return (
      <S.InputField empty={!value && value !== 0} disabled={disabled}>
        <S.Input
          width={fitContent ? width : undefined}
          ref={ref}
          id={id}
          autoComplete={autoComplete}
          type={type !== undefined ? type : 'text'}
          value={value}
          onChange={handleChange}
          required={required}
          pattern={pattern}
          min={min}
          max={max}
          step={step}
          minLength={minLength}
          maxLength={maxLength}
          title={title}
          disabled={disabled}
          onKeyPress={keyPressHandler}
          data-testid={dataTestid}
          placeholder={placeholder}
        />
        {label && <S.InputLabel htmlFor={id}>{label}</S.InputLabel>}
      </S.InputField>
    );
  }
);

const generateId = (): string => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const computeWidth = (text: string, ref: MutableRefObject<any>): number => {
  const span = document.createElement('span');
  span.style.font = getComputedStyle(ref.current).font;
  span.style.visibility = 'hidden';
  span.style.whiteSpace = 'pre';
  span.innerText = text;
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return width;
};
