import React, { useContext } from 'react';

import { Styled as S } from './FirstStageCardContent.styled';
import { useDirectory } from '../../../../../../hooks/useDirectory';
import { FirstStageStateDto, VendorViewDto } from '../../../../../../../api/nggrace-back';
import { InputField } from '../../../../hardware/card/cardcontent/InputField';
import { InputSelect } from '../../../../hardware/card/cardcontent/InputSelect';
import { DescriptionField } from '../../../../hardware/card/cardcontent/DescriptionField';
import { MultiSelect } from './multiselect/InputSelect';
import { CardTypeContext } from '../../LogicalDeviceList';

interface FirstStageCardContentProps {
  vendors: VendorViewDto[];
  firstStageStateDto: FirstStageStateDto;
  handleOnLogicalDeviceFirstStageChange: (logicalDevice: FirstStageStateDto) => void;
}

export const FirstStageCardContent: React.FC<FirstStageCardContentProps> = ({
  vendors,
  firstStageStateDto,
  handleOnLogicalDeviceFirstStageChange,
}) => {
  const cardType = useContext(CardTypeContext);
  const { voltageLevelDirectory, logicDeviceDirectory, getNodeDirectory } = useDirectory();
  const deviceDirectory = getNodeDirectory('EU');

  const handleNameChange = (name: string) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, name: name });
  };

  const handleVendorChange = (vendorId: number) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, vendorId: vendorId });
  };

  const handleCategoryChange = (categoryId: string) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, categoryId: categoryId });
  };

  const handleDescriptionChange = (description: string) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, description: description });
  };

  const handleVoltageChange = (voltageIds: any[]) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, voltageIds: voltageIds });
  };

  const handlePrimaryEquipmentChange = (primaryEquipmentIds: any[]) => {
    handleOnLogicalDeviceFirstStageChange({ ...firstStageStateDto, primaryEquipmentIds: primaryEquipmentIds });
  };

  return (
    <S.Container>
      <S.Column position={'left'}>
        <InputField onChange={handleNameChange} cardType={cardType} label={'LD name'} value={firstStageStateDto.name} />
        <InputSelect
          onChange={handleVendorChange}
          cardType={cardType}
          label={'Vendor'}
          selected={firstStageStateDto.vendorId}
          items={vendors}
        />
        <InputSelect
          onChange={handleCategoryChange}
          cardType={cardType}
          label={'PACS category'}
          selected={firstStageStateDto.categoryId}
          items={logicDeviceDirectory.getAll().map((it) => ({ id: it.id, name: it.name.en }))}
        />
        <DescriptionField
          onChange={handleDescriptionChange}
          cardType={cardType}
          label={'Description'}
          value={firstStageStateDto.description}
        />
      </S.Column>
      <S.Column position={'center'}>
        <MultiSelect
          cardType={cardType}
          onChange={handleVoltageChange}
          label={'Voltage class'}
          selected={firstStageStateDto?.voltageIds}
          options={voltageLevelDirectory.getAll().map((it) => ({ id: it.id, name: it.name.en }))}
          disabled={cardType === 'view'}
        />
      </S.Column>
      <S.Column position={'right'}>
        <MultiSelect
          cardType={cardType}
          onChange={handlePrimaryEquipmentChange}
          label={'Primary equipment type Relation (in accordance with IEC 61850)'}
          selected={firstStageStateDto?.primaryEquipmentIds}
          options={deviceDirectory.getAll().map((it) => ({ id: it.id, name: it.name.en }))}
          disabled={cardType === 'view'}
        />
      </S.Column>
    </S.Container>
  );
};
