import React, { PropsWithChildren, useCallback } from 'react';
import { Styled as S } from './Tree.styled';
import { Icon } from '../../../../../../widgets/Icon';
import { LogicalNodeTreeNodeModel } from '../LnComposition';
import { SelectableTreeNode } from '../../../../../../widgets/tree/state/TreeState';

export interface TreeNodeProps {
  model: LogicalNodeTreeNodeModel & SelectableTreeNode;
  highlight: boolean;
  canHighlight?: boolean;
  hasParent?: boolean;
  last: boolean;
  indentLevel: number;
  open: boolean;
  showInLd: boolean;

  toggleOpen(): void;
}

export const TreeNodeWidget: React.FC<PropsWithChildren<TreeNodeProps>> = ({
  model,
  highlight,
  canHighlight,
  children,
  hasParent = true,
  indentLevel,
  last,
  open,
  toggleOpen,
  showInLd,
}) => {
  const handleToggleClick = useCallback(
    (event: React.MouseEvent) => {
      toggleOpen();
      event.stopPropagation();
    },
    [toggleOpen]
  );

  return (
    <S.TreeNode indentLevel={indentLevel}>
      <S.Header
        highlight={highlight}
        canHighlight={canHighlight}
        hasParent={hasParent}
        last={last}
        indentLevel={indentLevel}
      >
        <S.Indent level={indentLevel} />
        {children ? (
          <S.Toggle
            open={open}
            onClick={handleToggleClick}
            hasParent={hasParent}
            indentLevel={indentLevel}
            highlight={highlight}
          >
            <Icon name={'toggle-arrow'} />
          </S.Toggle>
        ) : (
          <S.EmptyToggle hasParent={hasParent} indentLevel={indentLevel} highlight={highlight}>
            <Icon name={'leaf-node'} />
          </S.EmptyToggle>
        )}
        <S.Title>{model.getName()}</S.Title>
        {!showInLd && (
          <S.RowInfo>
            <S.Cell>{model.getClazz()}</S.Cell>
            <S.Cell>{model.getCommonDataClass()}</S.Cell>
            <S.Cell>{model.getDescription()}</S.Cell>
            <S.Cell>{model.getType()}</S.Cell>
            <S.Cell>{model.getBType()}</S.Cell>
            <S.Cell>{model.getFc()}</S.Cell>
          </S.RowInfo>
        )}
      </S.Header>
      <S.SubTree>{open && children}</S.SubTree>
    </S.TreeNode>
  );
};
