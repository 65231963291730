import { SmartLinkModel } from '../../SmartLinkModel';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { Point } from '@projectstorm/geometry';
import { DefaultPortDependantPointsCount, EditablePath } from '../Path';

export class UpdatingDependenciesEditablePath implements EditablePath {
  private readonly origin: EditablePath;
  private link: SmartLinkModel;

  constructor(path: EditablePath, link: SmartLinkModel) {
    this.origin = path;
    this.link = link;
  }

  moveSegment(startPoint: PointModel, displacement: Point): void {
    this.origin.moveSegment(startPoint, displacement);
    const result = this.link.getPoints();
    const afterEditPortDependentCount = DefaultPortDependantPointsCount;
    result.forEach((point) => {
      point.setSourceDependent(false);
      point.setTargetDependent(false);
    });

    result.slice(0, afterEditPortDependentCount).forEach((point) => point.setSourceDependent(true));

    result.slice(-afterEditPortDependentCount).forEach((point) => point.setTargetDependent(true));
  }
}
