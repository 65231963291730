import styled, { css } from 'styled-components';
import { Indicator } from '../../widgets/Indicator.styled';
import { Styled as StyledIcon } from '../../widgets/Icon.styled';

const ExportCard = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 28px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 25px 25px 25px 20px;
  background: ${(props) => (props.active ? props.theme.colors.solitude : props.theme.colors.white)};
  width: 100%;
  cursor: pointer;
  border: 1px solid ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.white)};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.blue};
    box-shadow: 0 0 20px 10px rgba(63, 63, 63, 0.1);
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const StageIndicator = styled(Indicator)`
  background-color: ${({ active, theme: { colors } }) => (active ? colors.blue : colors.white)};
  border-color: ${({ theme: { colors } }) => colors.blue};
  color: ${({ active, theme: { colors } }) => (active ? colors.white : colors.blue)};
  margin: 0;
`;

const ExportIndicator = styled(Indicator)`
  background-color: ${({ active, completed, theme: { colors } }) =>
    completed ? colors.turquoise : active ? colors.solitude : colors.white}66;
  border-color: ${({ completed, theme: { colors } }) => (completed ? colors.darkturquoise : colors.darkgrey)}66;
  margin: 0;

  ${StyledIcon.Icon} {
    color: ${(props) => (props.completed ? props.theme.colors.white : props.theme.colors.darkgrey)};
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

export const Styled = {
  ExportCard,
  StageIndicator,
  Content,
  Title,
  Subtitle,
  ExportIndicator,
};
