import React, { useEffect, useRef } from 'react';
import { InputField as InField, InputType } from '../../../../../widgets/InputField';
import { Styled as S } from './CardContent.styled';
import { CardType } from '../HardwareCard';

interface FieldProps {
  inputType?: InputType;
  min?: number;
  step?: number;
  integer?: boolean;
  cardType: CardType;
  label: string;
  value: string | number | undefined;
  onChange(value?: string | number): void;
}

export const InputField: React.FC<FieldProps> = ({
  inputType,
  min,
  step,
  integer,
  cardType,
  label,
  value,
  onChange,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (integer)
      ref.current?.addEventListener('keypress', (evt) => {
        if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((o) => `Digit${o}`).includes(evt.code)) evt.preventDefault();
      });
  }, [integer]);

  return (
    <S.Form cardType={cardType}>
      <InField
        ref={ref}
        type={inputType}
        disabled={cardType === 'view'}
        label={label}
        value={value}
        min={min ? min : 0}
        step={step ? step : 1}
        onChange={onChange}
      />
    </S.Form>
  );
};
