import * as React from 'react';
import { Point } from '@projectstorm/geometry';

export class ExportLayerBoundary {
  xMin = Number.MAX_SAFE_INTEGER;
  xMax = Number.MIN_SAFE_INTEGER;
  yMin = Number.MAX_SAFE_INTEGER;
  yMax = Number.MIN_SAFE_INTEGER;

  get width() {
    return Math.max(this.xMax - this.xMin, 0);
  }

  get height() {
    return Math.max(this.yMax - this.yMin, 0);
  }

  extendBoundary(point: Point) {
    if (point.x < this.xMin) {
      this.xMin = point.x;
    }
    if (point.y < this.yMin) {
      this.yMin = point.y;
    }
    if (point.x > this.xMax) {
      this.xMax = point.x;
    }
    if (point.y > this.yMax) {
      this.yMax = point.y;
    }
  }
}

export interface ExportLayer {
  readonly boundary: ExportLayerBoundary;
  readonly element: JSX.Element;

  build(): Promise<void>;
}

export class DefaultLayer implements ExportLayer {
  readonly boundary = new ExportLayerBoundary();

  get element(): JSX.Element {
    return <></>;
  }

  async build(): Promise<any> {}
}
