import React, { useCallback, useMemo } from 'react';
import { LibraryAccordion } from '../../widgets/library/Library';
import { useDirectory } from '../../hooks/useDirectory';
import {
  ControllerDirectoryEntry,
  PropertiesDirectory,
  PropertiesDirectoryEntry,
} from '../../editor/directory/PropertiesDirectory';
import { Brick } from '../../widgets/Brick.styled';
import { ProjectDto } from '../../../api/nggrace-back';
import { ScdPacsTree, ScdStructureTreeDndFormatDragId } from './tree/ScdPacsTree';
import { TreeDraggableType, TreeNodeModel, TreeNodeState } from '../../widgets/tree/state/TreeState';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Tippy from '@tippyjs/react';
import { TreeDraggable } from '../../widgets/tree/TreeDraggable';
import { theme } from '../../../theme';
import { Styled as S } from '../../widgets/library/Library.styled';
import { LibraryTreeLeafNode } from '../../widgets/library/LibraryTree';
import { TreeNode, TreeNodeChildFactory } from '../../widgets/tree/TreeNode';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
  TreeNodeModelComparator,
} from '../../widgets/tree/state/TreeBaseModel';
import { Tree } from '../../widgets/tree/Tree';

interface ScdLibraryProps {
  project: ProjectDto;
  treeLocked: boolean;
}

export const ScdLibrary: React.FC<ScdLibraryProps> = ({ project, treeLocked }) => {
  const { controllerVendorDirectory, controllerDirectory } = useDirectory();

  const vendorFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
    <TreeNode
      key={model.getKey()}
      model={model}
      childFactory={controllerFactory}
      indentLevel={indentLevel + 1}
      last={last}
    />
  );

  const controllerFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => {
    const entry = controllerDirectory.getEntry(model.getKey());
    return (
      <LibraryTreeLeafNode key={model.getKey()} indentLevel={indentLevel + 1} last={last}>
        {treeLocked ? <LockedScdLibraryItem entry={entry} /> : <ScdLibraryItem entry={entry} />}
      </LibraryTreeLeafNode>
    );
  };

  const models = useMemo(() => createLibraryModels(controllerVendorDirectory, controllerDirectory.getAll()), [
    controllerDirectory,
    controllerVendorDirectory,
  ]);

  return (
    <LibraryAccordion>
      <DndProvider backend={HTML5Backend}>
        <ScdPacsTree locked={treeLocked} />
        <S.Library>
          <S.Header>{'Library IED/MU'}</S.Header>
          <Tree>
            {models.map((model) => (
              <TreeNode
                key={model.getKey()}
                model={model}
                childFactory={vendorFactory}
                hasParent={false}
                indentLevel={0}
                last
                initialOpen
              />
            ))}
          </Tree>
        </S.Library>
      </DndProvider>
    </LibraryAccordion>
  );
};

type VendorGroup = { [key: string]: ControllerDirectoryEntry[] };

const createLibraryModels = (
  vendorDirectory: PropertiesDirectory<PropertiesDirectoryEntry>,
  entries: ControllerDirectoryEntry[]
): TreeNodeState[] => {
  const ied: VendorGroup = {};
  const mu: VendorGroup = {};

  entries.forEach((entry) => {
    if (entry.ied) {
      addToGroup(ied, entry);
    }
    if (entry.mu) {
      addToGroup(mu, entry);
    }
  });
  return [
    ControllerGroupTreeNodeState('IED', vendorDirectory, ied),
    ControllerGroupTreeNodeState('MU', vendorDirectory, mu),
  ];

  function addToGroup(group: VendorGroup, entry: ControllerDirectoryEntry) {
    if (!group[entry.vendorId]) {
      group[entry.vendorId] = [];
    }
    group[entry.vendorId].push(entry);
  }
};
const ControllerGroupTreeNodeState = (
  groupName: string,
  vendorDirectory: PropertiesDirectory<PropertiesDirectoryEntry>,
  vendorGroup: VendorGroup
): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => groupName,
    getKey: () => groupName,
    getChildren: () =>
      Object.entries(vendorGroup)
        .map((vendorEntry) => VendorTreeNodeState(vendorDirectory.getEntry(vendorEntry[0]), vendorEntry[1]))
        .sort(TreeNodeModelComparator),
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const VendorTreeNodeState = (
  vendorEntry: PropertiesDirectoryEntry,
  controllerEntries: ControllerDirectoryEntry[]
): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => vendorEntry.name.en,
    getKey: () => vendorEntry.id,
    getChildren: () => controllerEntries.map((entry) => ControllerTreeNodeState(entry)).sort(TreeNodeModelComparator),
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const ControllerTreeNodeState = (entry: ControllerDirectoryEntry): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => entry.name.en,
    getKey: () => entry.id,
    getChildren: () => [],
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const ScdLibraryItem = ({ entry }: { entry: ControllerDirectoryEntry }) => {
  const dragElementCallback = useCallback(() => undefined, []);
  const payload = useMemo(() => ({ type: TreeDraggableType.Controller, directoryId: entry.id }), [entry.id]);
  return (
    <TreeDraggable
      getDragElement={dragElementCallback}
      formatId={ScdStructureTreeDndFormatDragId[TreeDraggableType.Controller]!}
      payload={payload}
    >
      <Tippy content={entry.code} delay={[800, 0]}>
        <Brick color={theme.colors.blue}>{entry.code}</Brick>
      </Tippy>
      <S.ItemName>{entry.name.en}</S.ItemName>
    </TreeDraggable>
  );
};

const LockedScdLibraryItem = ({ entry }: { entry: ControllerDirectoryEntry }) => {
  return (
    <>
      <Tippy content={entry.code} delay={[800, 0]}>
        <Brick color={theme.colors.blue}>{entry.code}</Brick>
      </Tippy>
      <S.ItemName>{entry.name.en}</S.ItemName>
    </>
  );
};
