import { Styled as SecondStageCardContentStyled } from '../../second-stage-content/SecondStageCardContent.styled';
import { DatasetsStyled } from '../datasets/Datasets.styled';
import styled, { css } from 'styled-components';
import { List as L } from '../../../../../../../widgets/List.styled';
import { Styled as InputSelect } from '../../../../../../../widgets/InputSelect.styled';
import { Styled as InputField } from '../../../../../../../widgets/InputField.styled';

const Column = styled(DatasetsStyled.Column)`
  width: 100%;
  max-height: 538px;
`;

const GooseCBListGrid = styled(SecondStageCardContentStyled.LogicalDeviceGrid)`
  * {
    font-size: 12px;
  }

  height: 100%;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, 0.5fr)
    minmax(0, 0.1fr) minmax(0, 0.1fr);
`;

const ClickedRow = styled(L.Row)<{ editable: boolean; remove: boolean }>`
  ${(props) =>
    props.editable &&
    css`
            cursor: pointer;

            ${L.Cell} {
              border-top: 1px solid ${(props) => props.theme.colors.blue};
              border-bottom: 1px solid ${(props) => props.theme.colors.blue};
              color: ${(props) => props.theme.colors.blue};
            }

            ${DatasetsStyled.Cell}:nth-child(3) {
              grid-column: span 2;
            }

            ${DatasetsStyled.Cell}:nth-child(4) {
              display: none;
            }

            input {
              color: ${(props) => props.theme.colors.blue};
            }

            ${InputSelect.InputSelect} {
              ${InputSelect.InputSelected} {
                &:after {
                  content: '';
                }
              }
          `}
  ${(props) =>
    props.remove &&
    css`
      ${L.Cell} {
        border-top: 1px solid ${(props) => props.theme.colors.blue};
        border-bottom: 1px solid ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.blue};
      }

      input {
        color: ${(props) => props.theme.colors.blue};
      }

      ${DatasetsStyled.Cell}:nth-child(3) {
        display: none;
      }

      ${DatasetsStyled.Cell}:nth-child(4) {
        grid-column: span 2;
      }
    `}
  
  ${InputSelect.InputOptions} {
    ${InputSelect.InputOption} {
      opacity: 1 !important;
    }
  }

  ${InputField.InputField} {
    opacity: 1 !important;
  }
`;

export const GooseSBStyled = {
  Container: DatasetsStyled.Container,
  Column,
  HeaderRow: SecondStageCardContentStyled.HeaderRow,
  BtnIcon: SecondStageCardContentStyled.BtnIcon,
  GooseCBListGrid,
  Cell: DatasetsStyled.Cell,
  ClickedRow,
};
