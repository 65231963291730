import React from 'react';
import { InputField as InField } from '../../../../../widgets/InputField';
import { TextArea as TxtArea } from '../../../../../widgets/TextArea';
import { Styled as S } from './DescriptionField.styled';
import { CardType } from '../HardwareCard';
import Tippy from '@tippyjs/react/headless';
import styled from 'styled-components';

interface DescriptionFieldProps {
  cardType: CardType;
  label: string;
  value: string | undefined;

  onChange(value?: string): void;
}

const TippyContent = styled(Tippy)`
  background-color: transparent;
`;

export const DescriptionField: React.FC<DescriptionFieldProps> = ({ cardType, label, value, onChange }) => {
  const longDescriptionLength = 57;

  const isDescriptionLong = value && value.length > longDescriptionLength + 15;

  return (
    <S.DescriptionFieldForm cardType={cardType}>
      {cardType === 'view' && (
        <InField
          type={'text'}
          disabled={cardType === 'view'}
          label={label}
          value={isDescriptionLong ? `${value?.substring(0, longDescriptionLength)}...` : value}
          onChange={onChange}
        />
      )}
      {cardType === 'view' && isDescriptionLong && (
        <TippyContent
          render={(attrs) => (
            <S.Description {...attrs}>
              {value}
              <S.DescriptionArrow data-popper-arrow />
            </S.Description>
          )}
          offset={[150, 15]}
          trigger={'click'}
        >
          <p>Read more</p>
        </TippyContent>
      )}
      {cardType !== 'view' && <TxtArea value={value} label={label} onChange={onChange} maxLength={510} />}
    </S.DescriptionFieldForm>
  );
};
