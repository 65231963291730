import React, { useEffect, useState } from 'react';
import { LanStrokeWidth, SvgGroup } from '../node/LanNodeWidget';
import { Styled as S } from './RackWidget.styled';
import { LanLabel } from '../label/LanLabel';
import { SingleRackModel } from './SingleRackModel';

export interface RackWidgetProps {
  rack: SingleRackModel;
}

export const RackWidget: React.FC<RackWidgetProps> = React.memo(({ rack }) => {
  const [size, setSize] = useState(rack.getSize());
  const [position, setPosition] = useState(rack.getPosition());
  const [name, setName] = useState(rack.getFullName());

  useEffect(() => {
    setSize(rack.getSize());
    setPosition(rack.getPosition());
    setName(rack.getFullName());
    rack.registerListener({
      sizeChanged: () => setSize(rack.getSize()),
      positionChanged: () => setPosition(rack.getPosition()),
      relativeModelChanged: () => setName(rack.getFullName()),
    });
  }, [rack]);

  return (
    <S.Rack left={position.x} top={position.y}>
      <LanLabel size={'l'}>{name}</LanLabel>
      <svg width={size.x} height={size.y}>
        <SvgGroup>
          <rect
            x={LanStrokeWidth / 2}
            y={LanStrokeWidth / 2}
            width={size.x - LanStrokeWidth}
            height={size.y - LanStrokeWidth}
          />
        </SvgGroup>
      </svg>
    </S.Rack>
  );
});
