import styled, { css } from 'styled-components';
import { Icon } from './Icon.styled';
import { Styled as StyledInputSelect } from './InputSelect.styled';

const Cell = styled.div<{ center?: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  height: 72px;
  padding-left: 18px;

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}

}

  &:first-child {
    margin-left: 42px;
  }
  
  &:last-child {
    margin-right: 42px;
  }
`;

const CellWithLongContent = styled(Cell)`
  display: block;
  line-height: 72px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SimpleCell = styled(Cell)`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderCell = styled(SimpleCell)`
  border: none;
  font-weight: 700;
  margin-bottom: 32px;
  height: 48px;
`;

const FilterCell = styled(HeaderCell)`
  padding: 0;
  cursor: pointer;

  ${StyledInputSelect.InputSelect} {
    width: 100%;
    margin-top: 0;
  }

  ${StyledInputSelect.InputSelected} {
    padding: 4px 16px;

    ${Icon} {
      width: 24px;
      height: 24px;

      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  ${StyledInputSelect.InputOptions} {
    font-weight: 400;
  }

  &:hover {
    background: ${(props) => props.theme.colors.solitude};
  }
`;

const SortCell = styled(HeaderCell)<{ asc?: boolean; desc?: boolean }>`
  position: relative;
  cursor: pointer;

  ${Content} {
    margin-right: 32px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 14px;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: none;
    border-bottom: 5px solid ${(props) => (props.asc ? props.theme.colors.blue : props.theme.colors.grey)};
    height: 0;
    margin-left: 14px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 14px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5px solid ${(props) => (props.desc ? props.theme.colors.blue : props.theme.colors.grey)};
    border-bottom: none;
    transform: translateY(-50%);
    margin-left: 14px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.solitude};
  }
`;

const Button = styled.button<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
  &:hover:not([disabled]) {
    color: ${(props) => props.theme.colors.turquoise};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.darkgrey};
    cursor: default;
  }

  ${Icon} {
    width: 21px;
    height: 21px;
  }
`;

const Row = styled.div`
  display: contents;

  ${HeaderCell} {
    position: sticky;
    top: 0;
    background: ${(props) => props.theme.colors.white};
  }

  &:hover ${Cell} {
    background: ${(props) => props.theme.colors.solitude};
  }

  &:hover ${HeaderCell} {
    background: ${(props) => props.theme.colors.white};
  }
`;

const HeaderRow = styled.div`
  display: contents;

  ${HeaderCell} {
    position: sticky;
    top: 0;
  }
`;

export const List = {
  Row,
  HeaderRow,
  Cell: SimpleCell,
  HeaderCell,
  CellWithLongContent,
  SortCell,
  FilterCell,
  Content,
  Button,
};
