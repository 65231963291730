import { TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import { VoltageLevelDirectoryEntry } from '../../../editor/directory/PropertiesDirectory';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { groupBy } from 'lodash';
import { Directory } from '../../../editor/directory/Directory';
import { ScdStructureTreeVoltageLevelProps } from './ScdStructureTreeVoltageLevel';
import { ScdStructureTreeNodeModelType } from './ScdStructureTreeNode';
import { Factory } from '../../../../utils/factory';
import { NodeModel } from '@projectstorm/react-diagrams';
import { DirectoryNodeWithPlaceholdersModel } from '../../../editor/directory/DirectoryNodeWithPlaceholdersModel';
import { BusNodeWithPlaceholdersModel } from '../../../editor/bus/BusNodeWithPlaceholdersModel';
import { ScdModel } from '../../../editor/scd/ScdModel';

export const ScdStructureTreeStation = (
  station: string,
  model: ScdModel,
  voltageLevelDirectory: Directory<VoltageLevelDirectoryEntry>,
  treeVoltageLevelFactory: Factory<ScdStructureTreeVoltageLevelProps, TreeNodeState>
): TreeNodeState => ({
  ...ScdStructureTreeStationModel(station, model, voltageLevelDirectory, treeVoltageLevelFactory),
  ...NotExtendableModel(),
  ...HasNotExtendableParentModel(),
  ...NotSelectableModel(),
});

const ScdStructureTreeStationModel = (
  station: string,
  model: ScdModel,
  voltageLevelDirectory: Directory<VoltageLevelDirectoryEntry>,
  treeVoltageLevelFactory: Factory<ScdStructureTreeVoltageLevelProps, TreeNodeState>
): TreeNodeModel => ({
  getName: () => station,
  getKey: () => station,
  onChildrenChanged: () => () => {},
  getChildren: () => {
    const scdNodes = Object.values(model.getActiveNodeLayer().getNodes()).filter(canShowNodeInTree);
    const scdNodesByVoltageLevel = groupBy(scdNodes, (ssdNode) => ssdNode.getPayload().voltageLevel);

    return Object.entries(scdNodesByVoltageLevel)
      .sort(
        ([keyA], [keyB]) =>
          voltageLevelDirectory.getEntry(keyB).voltageInKilovolts -
          voltageLevelDirectory.getEntry(keyA).voltageInKilovolts
      )
      .map(([voltageLevel, nodes]: [string, ScdStructureTreeNodeModelType[]]) =>
        treeVoltageLevelFactory({ nodes, voltageLevel: voltageLevelDirectory.getEntry(voltageLevel) })
      );
  },
});

const canShowNodeInTree = (node: NodeModel): node is ScdStructureTreeNodeModelType => {
  return node instanceof DirectoryNodeWithPlaceholdersModel || node instanceof BusNodeWithPlaceholdersModel;
};
