import {
  LayerModelGenerics,
  LayerModel,
  FactoryBank,
  DeserializeEvent,
  Toolkit,
  BaseModelListener,
  BaseEvent,
} from '@projectstorm/react-canvas-core';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory';
import { ControllerModel } from '../../controller/ControllerModel';
import { ScdEngine } from '../../ScdEngine';
import { ScdModel } from '../../ScdModel';

export const ControllerLayerModelType = 'controller-node-layer';

export interface ControllerLayerListener extends BaseModelListener {
  controllerAdded: (event: BaseEvent) => any;
  controllerRemoved: (event: BaseEvent) => any;
}

export interface ControllerLayerModelGenerics extends LayerModelGenerics {
  PARENT: ScdModel;
  ENGINE: ScdEngine;
  CHILDREN: ControllerModel;
  LISTENER: ControllerLayerListener;
}

export class ControllerLayerModel extends LayerModel<ControllerLayerModelGenerics> {
  constructor(options: ControllerLayerModelGenerics['OPTIONS'] = {}) {
    super({ ...options, type: ControllerLayerModelType, transformed: true });
    this.models = {};
    this.repaintEnabled = true;
  }

  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }

  getChildModelFactoryBank(engine: ControllerLayerModelGenerics['ENGINE']) {
    return (engine.getControllerFactories() as unknown) as FactoryBank<AbstractModelFactory>; // typing shit again
  }

  addModel(model: ControllerLayerModelGenerics['CHILDREN']) {
    super.addModel(model);
    model.registerListener({
      entityRemoved: () => {
        this.removeModel(model);
        this.fireEvent({ controller: model }, 'controllerRemoved');
      },
    });

    this.fireEvent({ controller: model }, 'controllerAdded');
  }
}
