import styled from 'styled-components';
import { Icon } from './Icon.styled';

export const Btn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 36px;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightgrey};
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 100px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background: ${(props) => props.theme.colors.ngGradient};
    color: ${(props) => props.theme.colors.lightgrey};
  }

  ${Icon} {
    margin: 11px 12px 13px 6px;
  }
`;

export const BtnIcon = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 32px;
  min-width: 100px;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.lightgrey};
  background: ${(props) => props.theme.colors.blue};
  border-radius: 50px;
  border: none;
  cursor: pointer;

  ${Icon} {
    margin-right: 10px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.ngGradient};
  }
`;
