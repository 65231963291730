import { DirectoryLogicNodeModel } from '../../../editor/ssd/logic-device/LogicNodeModel';
import { TreeNodeState } from '../../../widgets/tree/state/TreeState';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';

export interface ScdStructureTreeLogicNodeProps {
  logicNode: DirectoryLogicNodeModel;
}

export const ScdStructureTreeLogicNodeFactory = (): Factory<ScdStructureTreeLogicNodeProps, TreeNodeState> => ({
  logicNode,
}) => ScdStructureTreeLogicNode(logicNode);

const ScdStructureTreeLogicNode = (logicNode: DirectoryLogicNodeModel): TreeNodeState => {
  const model = ScdStructureTreeLogicNodeModel(logicNode);
  return {
    ...model,
    ...HasNotExtendableParentModel(),
    ...NotExtendableModel(),
    ...NotSelectableModel(),
  };
};

const ScdStructureTreeLogicNodeModel = (logicNode: DirectoryLogicNodeModel) => ({
  getName: () => logicNode.getCodeName(),
  getKey: () => logicNode.getID(),
  onChildrenChanged: () => () => {},
  getChildren: () => [],
});
