import React, { useCallback } from 'react';
import { InputGroup } from '../../../widgets/InputGroup.styled';
import { InputField } from '../../../widgets/InputField';
import { ExportLang as $s } from '../Export.lang';
import { ExportFormDto, ProjectStyleSelection } from '../../../../api/nggrace-back';

type UpdateFormAction = (form: ExportFormDto) => ExportFormDto;

type ResponsibleSpecialistsGroupProps = {
  style: ProjectStyleSelection;
  form: ExportFormDto;

  onChange(formUpdater: UpdateFormAction): void;
};

export const ResponsibleSpecialistsGroup: React.FC<ResponsibleSpecialistsGroupProps> = ({ style, form, onChange }) => {
  const handleDevelopedByChange = useCallback(
    (developedBy: string) => {
      onChange((dto) => ({ ...dto, developedBy }));
    },
    [onChange]
  );

  const handleVerifiedByChange = useCallback(
    (verifiedBy: string) => {
      onChange((dto) => ({ ...dto, verifiedBy }));
    },
    [onChange]
  );

  const handleContractNumberChange = useCallback(
    (contractNumber: string) => {
      onChange((dto) => ({ ...dto, contractNumber }));
    },
    [onChange]
  );

  const handleCpeChange = useCallback(
    (chiefProjectEngineer: string) => {
      onChange((dto) => ({ ...dto, chiefProjectEngineer }));
    },
    [onChange]
  );

  const ru = style === 'RU';
  const maxLength = 255;

  return (
    <InputGroup>
      <InputField
        label="Prepared by"
        value={form.developedBy}
        onChange={handleDevelopedByChange}
        maxLength={maxLength}
      />
      <InputField label="Checked by" value={form.verifiedBy} onChange={handleVerifiedByChange} maxLength={maxLength} />
      <InputField
        label={$s.personGroup.approved[style]}
        value={form.contractNumber}
        onChange={handleContractNumberChange}
        maxLength={maxLength}
      />
      {ru && (
        <InputField
          label="Supervisor"
          value={form.chiefProjectEngineer}
          onChange={handleCpeChange}
          maxLength={maxLength}
        />
      )}
    </InputGroup>
  );
};
