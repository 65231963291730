import React from 'react';
import { Styled as S } from './Confirmation.styled';
import { Icon } from './Icon';
import { SecondaryButton } from './SecondaryButton';

interface ConfirmationProps {
  title: string;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const Confirmation: React.FC<ConfirmationProps> = ({ title, text, onClose, onConfirm }) => {
  return (
    <S.ConfirmationModal>
      <S.ConfirmationModalTitle>
        <Icon name={'info'} />
        {title}
      </S.ConfirmationModalTitle>
      <S.ConfirmationModelText>{text}</S.ConfirmationModelText>
      <S.Buttons>
        <SecondaryButton text={'Cancel'} onClick={onClose} />
        <S.PrimaryButton onClick={onConfirm}>Ok</S.PrimaryButton>
      </S.Buttons>
    </S.ConfirmationModal>
  );
};
