import { Node } from '../node/Node';
import { Graph } from '../graph/Graph';

export interface Mapping<From, To> {
  map(from: From): To;
}

export type PathMapping = Mapping<{ start: Node; end: Node }, Graph>;

export class DefaultMapping<From, To> implements Mapping<From, To> {
  private readonly mappingFn: (from: From) => To;

  constructor(mappingFn: (from: From) => To) {
    this.mappingFn = mappingFn;
  }

  map(from: From): To {
    return this.mappingFn(from);
  }
}
