import { Styled as StyledLibraries } from '../Libraries.styled';
import styled from 'styled-components';

const Header = styled(StyledLibraries.Header)`
  flex-direction: row;
`;

export const Styled = {
  Header,
  BtnIcon: StyledLibraries.BtnIcon,
};
