import styled from 'styled-components';

import { Styled as StyledSettings } from '../../../Settings.styled';
import { BtnIcon as ButtonIcon } from '../../../../widgets/Btn.styled';
import { Btn as Button } from '../../../../widgets/Btn.styled';
import { Styled as StyledFeedback } from '../../../../widgets/Feedback.styled';

const Settings = styled(StyledSettings.Settings)`
  padding: 48px 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderInput = styled.div`
  display: flex;
  flex-direction: column;
  height: 10%;
  justify-content: space-between;
`;

const StageHeader = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0em;
  }

  span:first-of-type {
    font-weight: 700;
  }
`;

const StageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #103c8b;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  color: #103c8b;
  margin-right: 22px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledFeedback.Feedback} {
    white-space: unset;
  }
`;

const Buttons = styled(StyledSettings.Buttons)`
  margin-top: 0;
`;

const Btn = styled(Button)`
  text-transform: none !important;
`;

export const Styled = {
  Settings,
  Title: StyledSettings.Title,
  Buttons,
  HeaderInput,
  StageHeader,
  StageIcon,
  Footer,
  Btn,
  BtnIcon: ButtonIcon,
};
