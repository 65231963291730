import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Factory } from '../../../../utils/factory';
import { AccordionWidget } from './AccordionWidget';

export interface MenuItem<T = any> {
  id?: string;
  title?: string;
  subtitle?: string | number;
  expand?: boolean;
  payload?: T;
  children?: MenuItem[];
}

export type AccordionChildFactory = Factory<{ model: MenuItem; indentLevel: number; last: boolean }, ReactNode>;

interface AccordionProps {
  model: MenuItem;
  childFactory?: AccordionChildFactory;
  expanded?: boolean;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;
  selected?: boolean;

  onClick(): void;
}

export const Accordion: React.FC<AccordionProps> = ({
  model,
  childFactory,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
  expanded,
  onClick,
}) => {
  const [children, setChildren] = useState(model.children);
  const [open, setOpen] = useState(initialOpen || false);
  const toggleOpen = useCallback(() => {
    model.expand = !open;
    setOpen((o) => !o);
  }, [model, open]);

  useEffect(() => {
    setChildren(model.children);
  }, [model]);

  const childrenComponents = useMemo(
    () =>
      childFactory &&
      children?.map((child, index) =>
        childFactory({
          model: child,
          indentLevel,
          last: children.length - 1 === index,
        })
      ),
    [children, childFactory, indentLevel]
  );
  return (
    <AccordionWidget
      key={model.id}
      title={model.title!}
      subtitle={model.subtitle}
      onClick={onClick}
      expanded={expanded}
      highlight={false}
      canHighlight={canHighlight}
      hasParent={hasParent}
      indentLevel={indentLevel}
      last={last}
      open={open}
      toggleOpen={toggleOpen}
    >
      {childrenComponents?.length ? childrenComponents : null}
    </AccordionWidget>
  );
};
