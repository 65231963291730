import { DirectoryNodeWidget } from './DirectoryNodeWidget';
import { DirectoryNodeModel, DirectoryNodeModelPayload } from './DirectoryNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { NodeDirectory } from './NodeDirectory';
import { RawDirectoryNodeModel } from './RawDirectoryNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import {PropertiesDirectory, VoltageLevelDirectoryEntry} from "./PropertiesDirectory";

export class DirectoryNodeFactory extends AbstractReactFactory<DirectoryNodeModel, DiagramEngine> {
  protected readonly directory: NodeDirectory;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;

  constructor(directory: NodeDirectory, voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>) {
    super('directory');
    this.voltageLevelDirectory = voltageLevelDirectory;
    this.directory = directory;
  }

  generateReactWidget(event: { model: DirectoryNodeModel }): JSX.Element {
    const model = event.model;
    const record = this.directory.getEntry(model.getDirectoryEntryId());
    return <DirectoryNodeWidget engine={this.engine} node={model} record={record} voltageLevelDirectory={this.voltageLevelDirectory} />;
  }

  generateModel(event: { initialConfig: { payload: DirectoryNodeModelPayload } }) {
    return new RawDirectoryNodeModel(this.directory.getEntry(event.initialConfig.payload.directoryEntryId));
  }
}
