import styled from 'styled-components';
import { Styled as StyledLibList } from '../../../../LibraryList.styled';
import { List } from '../../../../../../widgets/List.styled';
import { Styled as StyledLibraries } from '../../../../Libraries.styled';
import { Styled as LnComposition } from '../../../../hardware/card/lncomposition/LnComposition.styled';
import { ListWithoutMargin } from '../../../../ListWithoutMargin.styled';
import { Styled as InputField } from '../../../../../../widgets/InputField.styled';
import { Styled as FirstStageCardContentStyle } from '../first-stage-content/FirstStageCardContent.styled';
import SimpleBar from 'simplebar-react';

const Container = styled.div`
  margin-top: 23px;
  flex: 1 0 auto;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Column = styled(FirstStageCardContentStyle.Column)<{ position: 'left' | 'right' }>`
  width: ${(props) => (props.position === 'left' ? '70%' : '35%')};
  margin-right: ${(props) => (props.position === 'left' ? '57px' : '28px')};
  max-width: none;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  background: #ffffff;
  padding: 0 34px;
  margin-top: 32px;
  max-height: 580px;

  & header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 28px 0;
  }

  & h3 {
    font-size: 18px;
  }
`;

const LogicalDeviceGrid = styled(StyledLibList.DefaultGrid)`
  grid-template-columns:
    minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: 36px;

  * {
    font-size: 12px;
  }

  ${List.Cell} {
    &:first-child {
      padding-left: 0;
    }
  }
`;

const Cell = styled(ListWithoutMargin.Cell)`
  height: 36px;

  ${List.Button} {
    background: #103c8b;
    border-radius: 6px;
    padding: 0;
    width: 24px;
    height: 24px;
    color: white;
  }

  span {
    margin: 0 12px;
  }
`;

const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  //TODO: set me in %
  height: 479px;

  * {
    font-size: 12px;
  }
`;

const TreeHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${ListWithoutMargin.HeaderCell} {
    height: 36px;
    margin-bottom: 7px;
  }
`;

const TreeContent = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  height: 100%;
`;

const HeaderRow = styled(List.HeaderRow)`
  ${List.HeaderCell} {
    background: ${(props) => props.theme.colors.white};
  }

  &:hover ${Cell} {
    background: ${(props) => props.theme.colors.solitude};
  }

  &:hover ${List.HeaderCell} {
    background: ${(props) => props.theme.colors.white};
  }

  ${ListWithoutMargin.HeaderCell} {
    height: 36px;
    margin-bottom: 7px;
  }

  ${List.SortCell} {
    height: 36px;
    margin-bottom: 7px;
  }

  ${ListWithoutMargin.FilterCell} {
    height: 36px;
    margin-bottom: 7px;
  }

  ${InputField.InputContainer} {
    min-height: 36px;
  }
`;

export const Styled = {
  Container,
  ColumnContainer,
  Column,
  LogicalDeviceGrid,
  ClickedRow: StyledLibList.ClickedRow,
  BtnIcon: StyledLibraries.BtnIcon,
  TreeHeaderRow,
  ColumnName: LnComposition.ColumnName,
  TreeContainer,
  TreeContent,
  Cell,
  HeaderRow,
};
