import { TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import { SsdModel } from '../../../editor/ssd/SsdModel';
import { VoltageLevelDirectoryEntry } from '../../../editor/directory/PropertiesDirectory';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { groupBy } from 'lodash';
import { Directory } from '../../../editor/directory/Directory';
import { SsdStructureTreeVoltageLevelProps } from './SsdStructureTreeVoltageLevel';
import { SsdStructureTreeNodeModelType } from './SsdStructureTreeNode';
import { Factory } from '../../../../utils/factory';
import { NodeModel } from '@projectstorm/react-diagrams';
import { DirectoryNodeWithPlaceholdersModel } from '../../../editor/directory/DirectoryNodeWithPlaceholdersModel';
import { BusNodeWithPlaceholdersModel } from '../../../editor/bus/BusNodeWithPlaceholdersModel';

export const SsdStructureTreeStation = (
  station: string,
  model: SsdModel,
  voltageLevelDirectory: Directory<VoltageLevelDirectoryEntry>,
  treeVoltageLevelFactory: Factory<SsdStructureTreeVoltageLevelProps, TreeNodeState>
): TreeNodeState => ({
  ...SsdStructureTreeStationModel(station, model, voltageLevelDirectory, treeVoltageLevelFactory),
  ...NotExtendableModel(),
  ...HasNotExtendableParentModel(),
  ...NotSelectableModel(),
});

const SsdStructureTreeStationModel = (
  station: string,
  model: SsdModel,
  voltageLevelDirectory: Directory<VoltageLevelDirectoryEntry>,
  treeVoltageLevelFactory: Factory<SsdStructureTreeVoltageLevelProps, TreeNodeState>
): TreeNodeModel => ({
  getName: () => station,
  getKey: () => 'station',
  onChildrenChanged: () => () => {},
  getChildren: () => {
    const ssdNodes = Object.values(model.getActiveNodeLayer().getNodes()).filter(canShowNodeInTree);
    const ssdNodesByVoltageLevel = groupBy(ssdNodes, (ssdNode) => ssdNode.getPayload().voltageLevel);

    return Object.entries(ssdNodesByVoltageLevel)
      .sort(
        ([keyA], [keyB]) =>
          voltageLevelDirectory.getEntry(keyB).voltageInKilovolts -
          voltageLevelDirectory.getEntry(keyA).voltageInKilovolts
      )
      .map(([voltageLevel, nodes]: [string, SsdStructureTreeNodeModelType[]]) =>
        treeVoltageLevelFactory({ nodes, voltageLevel: voltageLevelDirectory.getEntry(voltageLevel) })
      );
  },
});

const canShowNodeInTree = (node: NodeModel): node is SsdStructureTreeNodeModelType => {
  return node instanceof DirectoryNodeWithPlaceholdersModel || node instanceof BusNodeWithPlaceholdersModel;
};
