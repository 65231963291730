import React, { useContext } from 'react';

import { LogicalNodeTreeNodeModel } from '../../../../../hardware/card/lncomposition/LnComposition';
import { SelectableTreeNode } from '../../../../../../../widgets/tree/state/TreeState';
import { TreeNode, TreeNodeChildFactory } from '../../../../../hardware/card/lncomposition/widget/TreeNode';
import { List } from '../../../../../../../widgets/List.styled';
import { Icon } from '../../../../../../../widgets/Icon';
import { Styled as S } from './LnTypeTree.styled';
import { CardTypeContext } from '../../../LogicalDeviceList';

interface LnTypeTreeProps {
  model: LogicalNodeTreeNodeModel & SelectableTreeNode;
  childFactory?: TreeNodeChildFactory;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;
  showInLd: boolean;
  handleDelClick: () => void;
}

const dataAttributeSubDataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
  <TreeNode
    key={model.getKey()}
    model={model}
    indentLevel={indentLevel + 1}
    childFactory={dataObjectFactory}
    last={last}
    showInLd={showInLd}
    initialOpen={false}
  />
);

const dataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
  <TreeNode
    key={model.getKey()}
    model={model}
    indentLevel={indentLevel + 1}
    childFactory={dataAttributeSubDataObjectFactory}
    last={last}
    showInLd={showInLd}
    initialOpen={false}
  />
);

export const lnTemplateFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
  <TreeNode
    key={model.getKey()}
    model={model}
    indentLevel={indentLevel + 1}
    childFactory={dataObjectFactory}
    last={last}
    showInLd={showInLd}
    initialOpen={false}
  />
);

export const LnTypeTree: React.FC<LnTypeTreeProps> = ({
  model,
  childFactory,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
  showInLd,
  handleDelClick,
}) => {
  const cardType = useContext(CardTypeContext);

  return (
    <S.TreeRow>
      <TreeNode
        model={model}
        childFactory={childFactory}
        canHighlight={canHighlight}
        hasParent={hasParent}
        indentLevel={indentLevel}
        last={last}
        initialOpen={initialOpen}
        showInLd={showInLd}
      />
      <S.TreeCell center onClick={(e) => e.stopPropagation()}>
        <List.Button disabled={cardType === 'view'} onClick={handleDelClick}>
          <Icon name={'trash'} />
        </List.Button>
      </S.TreeCell>
    </S.TreeRow>
  );
};
