import { DefaultNode, Node } from './node/Node';
import { NgGraceLinkModel } from '../link/NgGraceLinkModel';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { NormalizedNode } from './node/NormalizedNode';

export interface Link {
  getSourceNode(): Node;

  getTargetNode(): Node;
}

export class DefaultLink implements Link {
  private model: NgGraceLinkModel;

  constructor(model: NgGraceLinkModel) {
    this.model = model;
  }

  getSourceNode(): Node {
    return new NormalizedNode(new DefaultNode(this.model.getSourcePort().getParent() as RotatableNodeModel));
  }

  getTargetNode(): Node {
    return new NormalizedNode(new DefaultNode(this.model.getTargetPort().getParent() as RotatableNodeModel));
  }
}
