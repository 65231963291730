import { PortModelOptions } from '@projectstorm/react-diagrams';
import { PortRecord } from './NodeDirectory';
import { ConnectablePortModel, ConnectablePortModelGenerics } from '../generics/ConnectablePortModel';
import { DirectoryNodeModel } from './DirectoryNodeModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { BasePoint } from '../geometry/Point';

export const DirectoryPortSize = 15;
export const DirectoryPortVisibleRadius = 2;

export interface DirectoryPortModelGenerics extends ConnectablePortModelGenerics {
  PARENT: DirectoryNodeModel;
}

export class DirectoryPortModel<
  G extends DirectoryPortModelGenerics = DirectoryPortModelGenerics
> extends ConnectablePortModel<G> {
  relativePosition: BasePoint;

  constructor(); // deserialization
  constructor(record: PortRecord); // manual instantiation
  constructor(record?: PortRecord) {
    let options: PortModelOptions = {
      type: 'directory',
      name: '', //will be filled in deserialization
    };

    if (record) {
      options.name = record.name;
      options.alignment = record.alignment;
    }

    super(options);
    if (record) {
      const size = this.getSize();
      this.relativePosition = new BasePoint(record.x - size / 2, record.y - size / 2);
    } else {
      this.relativePosition = new BasePoint(0, 0);
    }
  }

  getSize(): number {
    return DirectoryPortSize;
  }

  getPosition(): BasePoint {
    const parentPosition = this.getParent().getPosition();
    return new BasePoint(parentPosition.x + this.relativePosition.x, parentPosition.y + this.relativePosition.y);
  }

  getRelativeCenter() {
    const size = this.getSize();
    return new BasePoint(this.relativePosition.x + size / 2, this.relativePosition.y + size / 2);
  }

  serialize() {
    return {
      ...super.serialize(),
      relativePosition: this.relativePosition,
      directoryEntryId: this.getParent().getDirectoryEntryId(),
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.relativePosition = new BasePoint(event.data.relativePosition);
  }
}
