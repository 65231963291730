import React, { useEffect, useState } from 'react';
import { Segment, SmartLinkModel } from './SmartLinkModel';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { ConnectingSmartLinkModel } from './ConnectingSmartLinkModel';
import { Toolkit } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '../../insides/engine/DiagramEngine';

export const SmartLinkSegmentClassName = 'smart-link-segment';
export const SmartLinkSegmentIdAttributeName = 'data-segment-id';

export interface SmartLinkWidgetProps {
  engine: DiagramEngine;
  link: SmartLinkModel | ConnectingSmartLinkModel;
}

export const Keyframes = keyframes`
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const selected = css`
  stroke-dasharray: 10, 2;
  animation: ${Keyframes} 1s linear infinite;
`;

export const Link = styled.path<{ selected: boolean }>`
  ${(p) => p.selected && selected};
  fill: none;
  pointer-events: all;
`;

export const HitBox = styled.path<{ selected: boolean }>`
  stroke-opacity: ${(p) => (p.selected ? 0.1 : 0)};
  pointer-events: all;
`;

const getSegmentCursor = (link: SmartLinkModel | ConnectingSmartLinkModel, segment: Segment) => {
  try {
    if (link.isLocked()) {
      return 'pointer';
    }

    return segment.isVertical() ? 'ew-resize' : 'ns-resize';
  } catch (e) {
    console.error(e);
    return 'move';
  }
};

export const SmartLinkWidget: React.FC<SmartLinkWidgetProps> = ({ link }) => {
  const hitBoxWidth = 16;
  const [selected, setSelected] = useState(link.isSelected());
  const [, setLastUpdated] = useState(() => Toolkit.UID());

  useEffect(
    () =>
      link.registerListener({
        selectionChanged: () => setSelected(link.isSelected()),
        pointsChanged: () => setLastUpdated(Toolkit.UID),
      }).deregister,
    [link]
  );

  const linkOptions = link.getOptions();

  const segments = link.getSegments().map((segment) => (
    <g
      className={SmartLinkSegmentClassName}
      key={segment.getId()}
      {...{ [SmartLinkSegmentIdAttributeName]: segment.getId() }}
      style={{ cursor: getSegmentCursor(link, segment) }}
    >
      {link instanceof ConnectingSmartLinkModel || (
        <HitBox
          selected={selected}
          strokeWidth={linkOptions.width! + hitBoxWidth}
          d={segment.getPath()}
          stroke={linkOptions.selectedColor}
        />
      )}
      <Link
        selected={selected}
        stroke={selected ? linkOptions.selectedColor : link.getColor()}
        strokeWidth={linkOptions.width!}
        d={segment.getPath()}
      />
    </g>
  ));
  return <>{segments}</>;
};
