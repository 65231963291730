import styled from 'styled-components';
import { List as L } from '../../widgets/List.styled';

const ClickedRow = styled(L.Row)`
  cursor: pointer;
`;

const DefaultGrid = styled.div`
  display: grid;
  grid-auto-rows: 72px;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  overflow-y: auto;
`;

const VendorsGrid = styled(DefaultGrid)<{ userHasLibraryEditAccess: boolean }>`
  height: calc(100vh - 355px);
  grid-template-columns: ${(props) => (props.userHasLibraryEditAccess ? 'auto 76px 118px' : 'auto')};
`;

const HardwareGrid = styled(DefaultGrid)<{ hasIcd: boolean; userHasLibraryEditAccess: boolean }>`
  height: calc(100vh - 341px);
  grid-template-columns: ${(props) =>
    `repeat(${props.hasIcd ? '7' : '6'}, auto) ${props.userHasLibraryEditAccess ? '118px 76px 118px' : ''}`};
`;

const LogicalDeviceGrid = styled(DefaultGrid)`
  height: calc(100vh - 434px);
  grid-template-columns: repeat(4, auto) 170px 120px repeat(3, auto) 76px 118px;
`;

const Content = styled.div`
  background: ${(props) => props.theme.colors.white};
  ${L.HeaderCell} {
    background: ${(props) => props.theme.colors.white};
  }
`;

export const Styled = {
  VendorsGrid,
  HardwareGrid,
  LogicalDeviceGrid,
  LibraryList: Content,
  ClickedRow,
  DefaultGrid,
};
