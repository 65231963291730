import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { NodeLayerModel } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '../../../insides/engine/DiagramEngine';
import { NgGraceNodeLayerWidget } from '../../../insides/layer/NgGraceNodeLayerWidget';
import * as React from 'react';
import { LanNodeLayerModel } from './LanNodeLayerModel';

export class LanNodeLayerFactory extends AbstractReactFactory<NodeLayerModel, DiagramEngine> {
  constructor() {
    super('lan-nodes');
  }

  generateModel(event: GenerateModelEvent): NodeLayerModel {
    return new LanNodeLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<LanNodeLayerModel>): JSX.Element {
    return <NgGraceNodeLayerWidget layer={event.model} engine={this.engine} />;
  }
}
