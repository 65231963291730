import { LogicNodeDirectoryEntry, PropertiesDirectory } from '../../directory/PropertiesDirectory';
import { BaseModel, BaseModelGenerics } from '@projectstorm/react-canvas-core';
import { DirectoryLogicDeviceModel } from './LogicDeviceModel';
import { DefaultHasRect, HasRect } from '../../placeholder/HasRect';
import { BasePoint } from '../../geometry/Point';
import { DefaultHasPosition, HasPositionListener } from '../../placeholder/HasPosition';
import { BrickHasSize } from '../../placeholder/size-computation/BrickHasSize';
import { Factory } from '../../../../utils/factory';
import { Listenable } from '../../placeholder/Listenable';
import { getBrickColor } from '../../../widgets/Brick.styled';

export interface LogicNodeModelGenerics extends BaseModelGenerics {
  PARENT: DirectoryLogicDeviceModel<any>;
}

export const BrickHasRectFactory: Factory<{ getCodeName(): string } & Listenable<HasPositionListener>, HasRect> = (
  named
) => new DefaultHasRect(new DefaultHasPosition(named), new BrickHasSize(named));

export class DirectoryLogicNodeModel extends BaseModel<LogicNodeModelGenerics> implements HasRect {
  private directoryEntry: LogicNodeDirectoryEntry;
  private hasRect: HasRect;
  private readonly brickColor: string;

  constructor(
    directory: PropertiesDirectory<LogicNodeDirectoryEntry>,
    entryId: string,
    hasRectFactory: Factory<{ getCodeName(): string } & Listenable<HasPositionListener>, HasRect> = BrickHasRectFactory
  ) {
    super({});
    this.directoryEntry = directory.getEntry(entryId);
    this.hasRect = hasRectFactory(this as any);

    const codes = directory.getAll().map((entry) => entry.code);
    this.brickColor = getBrickColor(codes, this.directoryEntry.code);
  }

  getDirectoryId(): string {
    return this.directoryEntry.id;
  }

  getCodeName(): string {
    return this.directoryEntry.code;
  }

  getBrickColor(): string {
    return this.brickColor;
  }

  getName(): string {
    return this.directoryEntry.name.en;
  }

  serialize() {
    return {
      ...super.serialize(),
      directoryId: this.getDirectoryId(),
    };
  }

  getRelativeToDeviceRect() {
    return this.getRect();
  }

  getRect() {
    return this.hasRect.getRect();
  }

  getPosition(): BasePoint {
    return this.hasRect.getPosition();
  }

  getSize(): BasePoint {
    return this.hasRect.getSize();
  }

  setPosition(newValue: BasePoint): void {
    return this.hasRect.setPosition(newValue);
  }
}
