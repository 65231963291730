import { isSmartLinkModel } from '../../../NgGraceModel';
import * as React from 'react';
import { LinkModel } from '@projectstorm/react-diagrams';

interface ExportLinkProps {
  link: LinkModel;
}

export const ExportLink: React.FC<ExportLinkProps> = ({ link }) => {
  if (isSmartLinkModel(link)) {
    return (
      <g>
        {link.getSegments().map((segment) => {
          return (
            <path
              fill="none"
              key={segment.getId()}
              stroke={link.getColor()}
              strokeWidth={link.getOptions().width!}
              d={segment.getPath()}
            />
          );
        })}
      </g>
    );
  }
  return <></>;
};
