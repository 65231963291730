import { Action, InputType } from '@projectstorm/react-canvas-core';
import { NgGraceEngine } from '../NgGraceEngine';
import { KeyboardEvent } from 'react';
import { queued } from '../FunctionDecorators';

export class UndoRedoAction extends Action {
  private readonly queuedUndo: () => void;
  private readonly queuedRedo: () => void;

  constructor() {
    super({
      type: InputType.KEY_DOWN,
      fire: async ({ event }) => {
        if (isUndoShortcut(event as KeyboardEvent)) {
          event.preventDefault();
          this.queuedUndo();
        }

        if (isRedoShortcut(event as KeyboardEvent)) {
          event.preventDefault();
          this.queuedRedo();
        }
      },
    });

    this.queuedUndo = queued(() => (this.engine as NgGraceEngine).undo());
    this.queuedRedo = queued(() => (this.engine as NgGraceEngine).redo());
  }
}

const undoCode = 'KeyZ';

const isUndoShortcut = ({ ctrlKey, shiftKey, metaKey, code, target }: KeyboardEvent): boolean => {
  const notInput = (target as HTMLElement).nodeName !== 'INPUT';
  return notInput && (ctrlKey || metaKey) && !shiftKey && code === undoCode;
};

const isRedoShortcut = ({ ctrlKey, shiftKey, metaKey, code, target }: KeyboardEvent): boolean => {
  const notInput = (target as HTMLElement).nodeName !== 'INPUT';
  return notInput && (ctrlKey || metaKey) && shiftKey && code === undoCode;
};
