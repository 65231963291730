import styled from 'styled-components';
import { Styled as StyledLibraries } from '../Libraries.styled';

const Content = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;
`;

export const Styled = {
  Content,
  Header: StyledLibraries.Header,
  BtnIcon: StyledLibraries.BtnIcon,
};
