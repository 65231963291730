import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { DirectoryLogicDeviceModel, LogicDeviceModelType } from './LogicDeviceModel';
import { SsdEngine } from '../SsdEngine';
import { PlaceholderWidget } from '../../placeholder/PlaceholderWidget';
import { DirectoryLogicNodeModel } from './LogicNodeModel';
import {
  LogicDeviceDirectoryEntry,
  LogicNodeDirectoryEntry,
  PropertiesDirectory,
} from '../../directory/PropertiesDirectory';
import { DefaultPlaceholder } from '../../placeholder/Placeholder';
import { HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { PlaceholderCachingHasSize } from '../../placeholder/size-computation/PlaceholderCachingHasSize';
import { PlaceholderHasSize } from '../../placeholder/size-computation/PlaceholderHasSize';
import { BasePoint } from '../../geometry/Point';
import { DefaultHasPosition } from '../../placeholder/HasPosition';

type GenerateModelEvent = { initialConfig: ReturnType<DirectoryLogicDeviceModel['serialize']> };

export class LogicDeviceFactory extends AbstractReactFactory<DirectoryLogicDeviceModel, SsdEngine> {
  protected deviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>;
  private nodeDirectory: PropertiesDirectory<LogicNodeDirectoryEntry>;

  constructor(
    deviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>,
    nodeDirectory: PropertiesDirectory<LogicNodeDirectoryEntry>
  ) {
    super(LogicDeviceModelType);
    this.deviceDirectory = deviceDirectory;
    this.nodeDirectory = nodeDirectory;
  }

  generateModel(event: GenerateModelEvent): DirectoryLogicDeviceModel {
    const data = event.initialConfig;
    const size = event.initialConfig.SSD ? new BasePoint(event.initialConfig.SSD.size) : undefined;
    const position = event.initialConfig.SSD ? new BasePoint(event.initialConfig.SSD.position) : undefined;
    return new DirectoryLogicDeviceModel(
      this.deviceDirectory.getEntry(data.directoryId),
      this.getNodes(data),
      (device) =>
        new DefaultPlaceholder(
          device,
          device as any,
          (origin) => new HasRelativeParent(origin),
          ({ hasChildren, hasRelativeModel, eventDelegate }) =>
            new PlaceholderCachingHasSize(new PlaceholderHasSize(hasChildren), eventDelegate, hasRelativeModel, size),
          (eventDelegate) => new DefaultHasPosition(eventDelegate as any, position)
        )
    );
  }

  generateReactWidget(event: GenerateWidgetEvent<DirectoryLogicDeviceModel>): JSX.Element {
    return <PlaceholderWidget placeholderModel={event.model} engine={this.engine} />;
  }

  getNodes(data: ReturnType<DirectoryLogicDeviceModel['serialize']>) {
    return data.nodes.map((node) => new DirectoryLogicNodeModel(this.nodeDirectory, node.directoryId));
  }
}
