import React, { useState } from 'react';
import { Styled as S } from './Libraries.styled';
import { VendorsLib } from './vendor/VendorsLib';
import { HardwareLib } from './hardware/HardwareLib';
import { TabButtonWrapper } from './TabButtonWrapper';
import { FunctionsLib } from './function/FunctionsLib';

export type TabType = 'vendor-lib' | 'hardware-lib' | 'function-lib';
export type TabPosition = 'left' | 'middle' | 'right';

export const Libraries: React.FC = () => {
  const [toggleState, setToggleState] = useState('hardware-lib' as TabType);

  return (
    <S.Container>
      <S.TabBlock>
        <TabButtonWrapper
          toggleState={toggleState}
          tabType={'hardware-lib'}
          position={'left'}
          name={'HARDWARE LIBRARY'}
          setToggleState={setToggleState}
        />
        <TabButtonWrapper
          toggleState={toggleState}
          tabType={'function-lib'}
          position={'middle'}
          name={'FUNCTION LIBRARY'}
          setToggleState={setToggleState}
        />
        <TabButtonWrapper
          toggleState={toggleState}
          tabType={'vendor-lib'}
          position={'right'}
          name={'VENDOR LIBRARY'}
          setToggleState={setToggleState}
        />
      </S.TabBlock>
      <S.TabContentContainer>
        <S.SpriteLong />
        {toggleState === 'hardware-lib' && (
          <S.TabContent>
            <HardwareLib />
          </S.TabContent>
        )}
        {toggleState === 'function-lib' && (
          <S.TabContent>
            <FunctionsLib />
          </S.TabContent>
        )}
        {toggleState === 'vendor-lib' && (
          <S.TabContent>
            <VendorsLib />
          </S.TabContent>
        )}
      </S.TabContentContainer>
    </S.Container>
  );
};
