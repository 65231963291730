import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Styled as S } from './OverlayContext.styled';

interface OverlayContextType {
  show(): void;

  hide(): void;
}

const defaultValue: OverlayContextType = {
  show: () => {},
  hide: () => {},
};

const OverlayContext = createContext<OverlayContextType>(defaultValue);

export const useOverlay = (): OverlayContextType => {
  return useContext(OverlayContext);
};

export const OverlayProvider: React.FC = ({ children }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleShow = useCallback(() => setShowOverlay(true), []);
  const handleHide = useCallback(() => setShowOverlay(false), []);

  const contextValue: OverlayContextType = useMemo(() => {
    return {
      show: handleShow,
      hide: handleHide,
    };
  }, [handleHide, handleShow]);

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      {showOverlay && <S.Overlay />}
    </OverlayContext.Provider>
  );
};
