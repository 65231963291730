import React from 'react';
import { Page } from '../../Page';
import { Styled as S } from '../TablePage.styled';
import { NotificationProvider } from '../../context/NotificationContext';
import { Libraries } from './Libraries';

export const LibrariesPage: React.FC = () => {
  return (
    <NotificationProvider>
      <Page active={'libraries'}>
        <S.Content>
          <Libraries />
        </S.Content>
      </Page>
    </NotificationProvider>
  );
};
