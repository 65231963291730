import React from 'react';
import { Styled as S } from './Feedback.styled';
import { Icon } from './Icon';

export const Feedback: React.FC<{ error?: boolean; success?: boolean; loading?: boolean; text: string }> = ({
  error,
  success,
  loading,
  text,
}) => {
  return (
    <S.Feedback error={error} success={success} loading={loading}>
      {error && <Icon name="error" />}
      {success && <Icon name="checkmark-filled" />}
      {loading && <Icon name="loading" />}
      {text}
    </S.Feedback>
  );
};
