import React, { useCallback, useMemo, useState } from 'react';
import { Styled as S } from '../../Modal.styled';
import { SsdCreationDto, StageDto } from '../../../api/nggrace-back';
import { InputSelect } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';
import { Feedback } from '../../widgets/Feedback';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Confirmation } from '../../widgets/Confirmation';
import { useNotifications } from '../../context/NotificationContext';
import { SecondaryButton } from '../../widgets/SecondaryButton';

type SsdSettingsProps = {
  stage: StageDto;
  onReloadStage?: () => void;
  onClose(): void;
};

export const SsdSettings: React.FC<SsdSettingsProps> = ({ stage, onClose, onReloadStage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<SsdCreationDto>({ ...stage.project });
  const { setOfRulesDirectory } = useDirectory();
  const { redirectToStagePage } = useRoutes();
  const update = useMemo(() => stage.baseInfo.type === 'SSD', [stage]);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const notifications = useNotifications();

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const options = useMemo(() => {
    return setOfRulesDirectory
      .getAll()
      .filter((entry) => entry.styleSelections.includes(stage.project.styleSelection))
      .map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [stage.project.styleSelection, setOfRulesDirectory]);

  const handleSetOfRulesChange = useCallback((setOfRules: string) => {
    setState((state) => ({ ...state, setOfRules }));
  }, []);

  const handleSynthesisSsd = useCallback(() => {
    setLoading(true);
    Api.createSsdStage(stage.project.id, state, { omitInterceptorErrorModal: true })
      .then(() => {
        if (update) {
          onReloadStage!!();
          setLoading(false);
          onClose();
          notifications.notifySuccess('SSD settings updated.');
        } else {
          redirectToStagePage(stage.project.id, 'SSD');
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  }, [stage.project.id, state, update, onReloadStage, onClose, notifications, redirectToStagePage]);

  const handleUpdateSsd = useCallback(() => {
    hideConfirmationModal();
    handleSynthesisSsd();
  }, [handleSynthesisSsd, hideConfirmationModal]);

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <>
      <ReactModal isOpen onRequestClose={handleClose} style={theme.modal}>
        <S.Modal>
          <S.Header>SSD Settings</S.Header>
          <S.Main>
            <S.LeftPanel>
              <S.Form>
                <InputSelect
                  label="Set of rules"
                  selected={state?.setOfRules}
                  options={options}
                  onChange={handleSetOfRulesChange}
                />
              </S.Form>
            </S.LeftPanel>
            <S.RightPanel>
              <S.Description>
                <p>You must select a parameter for SSD PACS system synthesis.</p>
                <p>The set of rules is chosen relative to the rules of the regulatory documentation.</p>
              </S.Description>
              {error && <Feedback error text={error} />}
            </S.RightPanel>
          </S.Main>
          <S.Footer>
            <SecondaryButton text={'Cancel'} onClick={handleClose} />
            <S.BtnPrimary onClick={update ? showConfirmationModal : handleSynthesisSsd} disabled={loading}>
              {!loading ? 'Synthesis' : 'Processing'}
            </S.BtnPrimary>
          </S.Footer>
        </S.Modal>
      </ReactModal>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Are you sure?'}
          text={`All changes made to diagram would be lost`}
          onClose={hideConfirmationModal}
          onConfirm={handleUpdateSsd}
        />
      </ReactModal>
    </>
  );
};
