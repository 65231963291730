import React from 'react';
import { Styled as S } from './Sidebar.styled';
import { Icon } from './widgets/Icon';
import { useRoutes } from '../routes/hooks/useRoutes';
import { useUser } from './pages/login/UserContext';
import { hasCompaniesAccess, hasLibrariesAccess, hasProjectsAccess, hasUsersAccess } from '../utils/role-utils';

export type NavigationSidebarType = 'projects' | 'companies' | 'users' | 'libraries';

interface NavigationSidebarProps {
  active?: NavigationSidebarType;
  hidden: boolean;
}

export const NavigationSidebar: React.FC<NavigationSidebarProps> = ({ active, hidden }) => {
  const user = useUser()!.user!;
  const { usersPath, projectsPath, companiesPath, librariesPath } = useRoutes();

  return (
    <S.Sidebar hidden={hidden}>
      {hasUsersAccess(user) && (
        <S.SidebarLink $active={active === 'users'} to={usersPath}>
          <Icon name="users" /> Users
        </S.SidebarLink>
      )}
      {hasProjectsAccess(user) && (
        <S.SidebarLink $active={active === 'projects'} to={projectsPath}>
          <Icon name="projects" /> Projects
        </S.SidebarLink>
      )}
      {hasCompaniesAccess(user) && (
        <S.SidebarLink $active={active === 'companies'} to={companiesPath}>
          <Icon name="companies" /> Companies
        </S.SidebarLink>
      )}
      {hasLibrariesAccess(user) && (
        <S.SidebarLink $active={active === 'libraries'} to={librariesPath}>
          <Icon name="libraries" /> Libraries
        </S.SidebarLink>
      )}
    </S.Sidebar>
  );
};
