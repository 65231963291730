import styled from 'styled-components';

import { Styled as Tabs } from './tabs/Tabs.styled';

const Container = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-top: 32px;
  margin-bottom: 36px;

  ${Tabs.Tabs} {
    background: #f8f8f8;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 16px 16px 0px 0px;
  }
`;

export const Styled = {
  Container,
};
