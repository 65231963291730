import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Styled as S } from './NotificationContext.styled';
import { Toast, ToastType } from '../widgets/Toast';
import { Toolkit } from '@projectstorm/react-canvas-core';

interface NotificationContext {
  notifyError(message: string, title?: string): void;

  notifyInfo(message: string, title?: string): void;

  notifyHint(message: string, title?: string): void;

  notifySuccess(message: string, title?: string): void;
}

interface Notification {
  id: string;
  message: string;
  title?: string;
  type: ToastType;
}

const notifyContextIsNotSet = () =>
  console.error('Attempt to use NotificationContext without Provider', new Error().stack);
const defaultValue: NotificationContext = {
  notifyError: notifyContextIsNotSet,
  notifyInfo: notifyContextIsNotSet,
  notifyHint: notifyContextIsNotSet,
  notifySuccess: notifyContextIsNotSet,
};

const Context = createContext<NotificationContext>(defaultValue);

export const useNotifications = (): NotificationContext => {
  return useContext(Context);
};

export const NotificationProvider: React.FC = ({ children }) => {
  const [queue, setQueue] = useState<Notification[]>([]);

  const push = useCallback((notification: Notification) => {
    setQueue((q) => [...q, notification]);
    setTimeout(() => setQueue((q) => q.slice(1)), 3000);
  }, []);

  const PushToast = useCallback(
    (type: ToastType) => (message: string, title: string) =>
      push({
        message,
        title,
        type: type,
        id: Toolkit.UID(),
      }),
    [push]
  );

  const contextValue: NotificationContext = useMemo(() => {
    return {
      notifyError: PushToast(ToastType.ERROR),
      notifyHint: PushToast(ToastType.HINT),
      notifyInfo: PushToast(ToastType.INFO),
      notifySuccess: PushToast(ToastType.SUCCESS),
    };
  }, [PushToast]);

  return (
    <Context.Provider value={contextValue}>
      {children}
      {
        <S.Notifications>
          {queue
            .slice(0, 3)
            .reverse()
            .map(({ title, message, type, id }) => (
              <S.Notification key={id}>
                <Toast title={title || type} text={message} type={type} />
              </S.Notification>
            ))}
        </S.Notifications>
      }
    </Context.Provider>
  );
};
