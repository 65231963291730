import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { ToolbarItem } from '../../toolbar/Toolbar';
import { ProjectUpdateDto, StageDto } from '../../../api/nggrace-back';
import { ProjectSettings } from '../project/ProjectSettings';
import { theme } from '../../../theme';

export const SldSettingsToolbarItem: React.FC<{ stage: StageDto; handleSetUserName: (name: string) => void }> = ({
  stage,
  handleSetUserName,
}) => {
  const [visible, setVisible] = useState(false);

  const handleOnClick = useCallback(() => setVisible(true), []);

  const handleClose = useCallback(
    (projectUpdate?: ProjectUpdateDto) => {
      if (projectUpdate) {
        handleSetUserName(projectUpdate.name!);
        stage.project.name = projectUpdate.name!;
        stage.project.stationName = projectUpdate.stationName;
        stage.project.customerCompany = projectUpdate.customerName;
      }
      setVisible(false);
    },
    [stage.project, handleSetUserName]
  );

  const handleCloseRequest = useCallback(() => handleClose(), [handleClose]);

  return (
    <ToolbarItem icon={'gear'} hint={'Settings'} onClick={handleOnClick}>
      <ReactModal isOpen={visible} onRequestClose={handleCloseRequest} style={theme.modal}>
        <ProjectSettings onClose={handleClose} project={stage.project} />
      </ReactModal>
    </ToolbarItem>
  );
};
