import { ComparisonResult } from '../../ssd/layer/node/SsdNodeLayerModel';
import { Sets } from './Sets';

export class PartiallyOrderedSets<T> implements Sets<T> {
  private readonly relation: (a: T, b: T) => ComparisonResult;
  private readonly sets: T[][];

  constructor(relation: (a: T, b: T) => ComparisonResult) {
    this.relation = relation;
    this.sets = [];
  }

  add(element: T) {
    const newSet = [element];
    if (this.sets.length === 0) {
      this.sets.push(newSet);
      return newSet;
    }

    const equivalentSet = this.sets.find((set) => this.relation(set[0], element) === 0);
    if (equivalentSet) {
      equivalentSet.push(element);
      return equivalentSet;
    }

    const greaterSetIndex = this.sets.findIndex((set) => this.relation(set[0], element) === 1);

    if (greaterSetIndex === -1) {
      this.sets.push(newSet);
      return newSet;
    }

    this.sets.splice(greaterSetIndex, 0, newSet);
    return newSet;
  }

  getSets(): T[][] {
    return this.sets;
  }
}
