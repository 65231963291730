import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { LanEngine } from '../../LanEngine';
import { GenerateModelEvent } from '../../../scd/controller/ControllerFactory';
import { LanControllerModel, LanControllerModelType } from './LanControllerModel';
import { RawLanControllerNodeModel } from './RawLanControllerNodeModel';
import { Directory } from '../../../directory/Directory';
import { ControllerDirectoryEntry } from '../../../directory/PropertiesDirectory';
import React from 'react';
import { LanControllerNodeWidget } from './LanControllerNodeWidget';

export class LanControllerNodeFactory extends AbstractReactFactory<LanControllerModel, LanEngine> {
  private controllerDirectory: Directory<ControllerDirectoryEntry>;

  constructor(controllerDirectory: Directory<ControllerDirectoryEntry>) {
    super(LanControllerModelType);
    this.controllerDirectory = controllerDirectory;
  }

  generateReactWidget(event: { model: LanControllerModel }): JSX.Element {
    return <LanControllerNodeWidget node={event.model} engine={this.engine} />;
  }

  generateModel(event: GenerateModelEvent<LanControllerModel>) {
    const entry = this.controllerDirectory.getEntry(event.initialConfig.directoryId);
    if (!entry) {
      console.log(event.initialConfig.directoryId);
    }
    return new RawLanControllerNodeModel(this.controllerDirectory.getEntry(event.initialConfig.directoryId));
  }
}
