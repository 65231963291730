import React, { useContext } from 'react';
import { Canvas, CanvasError } from './Canvas';
import { EngineContext } from './EngineContext';
import { DndCanvas } from './dnd/DndCanvas';
import { Styled as S } from './DiagramCanvas.styled';
import { NodeDirectory } from './directory/NodeDirectory';
import { NgGraceCanvasWidget } from './insides/canvas/NgGraceCanvasWidget';
import { BlockPageScroll } from './BlockPageScroll';
import { ErrorBoundary } from '@sentry/react';

interface DiagramEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  directory: NodeDirectory;
  header: string;
}

export const DiagramCanvas: React.FC<DiagramEditorProps> = ({ directory, header, ...props }) => {
  const engine = useContext(EngineContext);
  return (
    <ErrorBoundary fallback={DiagramCanvasFallback}>
      <S.DiagramCanvas {...props}>
        <S.Header>{header}</S.Header>
        <BlockPageScroll>
          <S.Content>
            <DndCanvas directory={directory}>
              <Canvas>
                <NgGraceCanvasWidget engine={engine} />
              </Canvas>
            </DndCanvas>
          </S.Content>
        </BlockPageScroll>
      </S.DiagramCanvas>
    </ErrorBoundary>
  );
};

export const DiagramCanvasFallback = ({ error, resetError }: { error: Error; resetError: () => void }) => {
  return (
    <div>
      <CanvasError>
        <p>Something went wrong:</p>
        <pre>{error.stack}</pre>
        <button onClick={resetError}>Try again</button>
      </CanvasError>
    </div>
  );
};
