import * as React from 'react';
import { ConnectivityNodeModel, ConnectivityNodeSize } from '../../../connectivity/ConnectivityNodeModel';
import {PropertiesDirectory, VoltageLevelDirectoryEntry} from "../../../directory/PropertiesDirectory";

interface ExportConnectivityNodeProps {
  node: ConnectivityNodeModel;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
}

export const ExportConnectivityNode: React.FC<ExportConnectivityNodeProps> = ({ node, voltageLevelDirectory }) => {
  const radius = ConnectivityNodeSize / 2;
  const position = node.getPositionToRender();
  return (
    <>
      <circle cx={position.x + radius} cy={position.y + radius} r={radius}
              fill={node.getPayload().colorIds
                  ? voltageLevelDirectory.getEntry(node.getPayload().colorIds![0]).color
                  : 'black'
              }
      />
    </>
  );
};
