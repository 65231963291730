import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Styled as S } from './SvgInputSelect.styled';
import { useComponentVisible } from '../../../../../../hooks/useComponentVisible';
import { Icon } from '../../../../../../widgets/Icon';

export type OptionType = {
  id: number;
  name: string;
  content: string;
  basic: boolean;
  disabled?: boolean;
};

type InputSelectProps = {
  label?: string;
  selected?: string | number;
  options: OptionType[];
  hideLabel?: boolean;
  hideBorder?: boolean;
  filter?: boolean;
  disabled?: boolean;

  onChange(selected?: OptionType): void;
  onSvgFileUpload(file: File): void;
  onSvgDelete: (svgId: number) => void;
  scrollToBottom: () => void;
};

export const SvgInputSelect: React.FC<InputSelectProps> = ({
  label,
  selected,
  options,
  hideLabel,
  hideBorder,
  filter,
  disabled,
  onChange,
  onSvgFileUpload,
  scrollToBottom,
  onSvgDelete,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { ref, visible, setVisible } = useComponentVisible<HTMLDivElement>(false);
  const [filterValue, setFilterValue] = useState('');

  const handleOptionChanged = useCallback(
    (newOption: OptionType) => {
      setVisible(false);
      newOption.id !== selected && onChange(newOption);
    },
    [onChange, selected, setVisible]
  );

  const handleSelectionClick = useCallback(() => {
    !disabled && setVisible((visible) => !visible);
    setTimeout(scrollToBottom, 10); // scroll down with a delay because we have to wait until the scroll height is recalculated
  }, [disabled, scrollToBottom, setVisible]);

  const handleFilterClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  const resetFilter = useCallback(
    (event) => {
      event.stopPropagation();
      onChange(undefined);
    },
    [onChange]
  );

  const fileChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const files = event.target.files;
      if (files && files.length > 0) {
        onSvgFileUpload(files[0]);
        setVisible(false);
      }
    },
    [onSvgFileUpload, setVisible]
  );

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // allow uploading same file again
      fileInputRef.current.click();
    }
  }, []);

  useEffect(() => {
    setFilterValue('');
  }, [visible]);

  return (
    <S.InputSelect
      disabled={disabled}
      active={visible}
      empty={!selected}
      hideBorder={hideBorder}
      ref={ref}
      onClick={handleSelectionClick}
    >
      <S.InputSelected icon={filter}>
        {filter && (selected ? <Icon name={'filter-on'} onClick={resetFilter} /> : <Icon name={'filter-off'} />)}
        {selected && <S.InputSelectedValue>{selected}</S.InputSelectedValue>}
        {!selected && hideLabel && label && <S.InputSelectedValue>{label}</S.InputSelectedValue>}
      </S.InputSelected>
      {!hideLabel && label && <S.InputLabel>{label}</S.InputLabel>}
      <S.InputOptions>
        {filter && (
          <S.InputFilter
            value={filterValue}
            placeholder={'Quick search'}
            autoFocus
            onClick={handleFilterClick}
            onChange={handleFilterChange}
          />
        )}
        <S.UploadFileSection>
          <S.Btn onClick={handleUploadClick}>
            <Icon name={'share'} />
            <span>Upload file</span>
          </S.Btn>
          <input type="file" accept={'.svg'} ref={fileInputRef} onChange={fileChangeCallback} />
          <span>File supported: SVG</span>
          <span>Maximum file size: 150 KB</span>
        </S.UploadFileSection>
        {options
          .filter((o) => !filterValue || o.name.toLowerCase().includes(filterValue.toLowerCase()))
          .map((o) => (
            <SelectOption
              onSelect={handleOptionChanged}
              onSvgDelete={onSvgDelete}
              option={o}
              basicSvg={o.basic}
              current={selected === o.id}
              key={o.id}
            />
          ))}
      </S.InputOptions>
    </S.InputSelect>
  );
};

interface SelectOptionProps {
  option: OptionType;
  current: boolean;
  basicSvg: boolean;

  onSelect(option: OptionType): void;
  onSvgDelete: (svgId: number) => void;
}

export const SelectOption: React.FC<SelectOptionProps> = ({ option, current, basicSvg, onSelect, onSvgDelete }) => {
  const [showDialogue, setShowDialogue] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onSelect(option);
    },
    [onSelect, option]
  );

  const onTrashClick = (event: Event) => {
    event.stopPropagation();
    setShowDialogue(true);
  };

  const onNoClick = () => {
    setShowDialogue(false);
  };

  const onYesClick = () => {
    onSvgDelete(option.id);
  };

  const formatString = (str: string) => {
    const maxLength = 23;
    return str.length > maxLength ? `${str?.substring(0, maxLength - 2)}...` : str;
  };

  return (
    <>
      <S.InputOption onClick={handleClick} current={current} disabled={option.disabled}>
        <img
          src={URL.createObjectURL(new Blob([option.content], { type: 'image/svg+xml' }))}
          aria-hidden
          alt={'Picture can not be visualized'}
        />
        <span>{formatString(option.name)}</span>
        <S.Label basic={basicSvg}>{basicSvg ? 'basic' : 'custom'}</S.Label>
        {!basicSvg && !showDialogue && <Icon name={'trash'} onClick={onTrashClick} />}
        {!basicSvg && showDialogue && (
          <S.Dialogue onClick={(event) => event.stopPropagation()}>
            Sure?
            <span onClick={onYesClick}>Yes</span>
            <span onClick={onNoClick}>No</span>
          </S.Dialogue>
        )}
      </S.InputOption>
    </>
  );
};
