import * as React from 'react';
import { useCallback, useContext } from 'react';
import { EngineContext } from '../EngineContext';
import { DirectoryNodeModel } from '../directory/DirectoryNodeModel';
import { NodeDirectory, NodeEntryType } from '../directory/NodeDirectory';
import styled from '@emotion/styled';
import { BusNodeModel } from '../bus/BusNodeModel';
import { DirectoryNodeDraggableEventData } from './DirectoryNodeDraggable';

export const DirectoryNodeDndFormatId = 'directory-node-dnd';

interface DndCanvasProps {
  directory: NodeDirectory;
  color?: string;
  background?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const DndCanvas: React.FC<DndCanvasProps> = ({ directory, children }) => {
  const engine = useContext(EngineContext);

  const dropHandler = useCallback(
    (event: React.DragEvent) => {
      if (engine.getModel().isLocked()) {
        return;
      }

      const data: DirectoryNodeDraggableEventData = JSON.parse(event.dataTransfer.getData(DirectoryNodeDndFormatId));
      const node =
        data.payload.type === NodeEntryType.BUS
          ? new BusNodeModel()
          : new DirectoryNodeModel(directory.getEntry(data.payload.directoryId));

      engine
        .getModel()
        .addNode(node)
        .setPosition(
          engine.getRelativeMousePoint({
            clientX: event.clientX - (data.relativeX * engine.getModel().getZoomLevel()) / 100,
            clientY: event.clientY - (data.relativeY * engine.getModel().getZoomLevel()) / 100,
          })
        );
      engine.getModel().clearSelection();
      node.setSelected(true);
      engine.repaintCanvas();
    },
    [engine, directory]
  );

  return (
    <Container onDrop={dropHandler} onDragOver={(event) => event.preventDefault()}>
      {children}
    </Container>
  );
};
