import { Rectangle } from '@projectstorm/geometry';
import { BasePoint, ClockwiseRotatedPoint } from './Point';
import { SmartRotationHour } from './RotationHour';

export class RotatedRectangle extends Rectangle {
  constructor(origin: Rectangle, hour: SmartRotationHour, rotationCenter: BasePoint) {
    const center = new ClockwiseRotatedPoint(origin.getOrigin(), hour, rotationCenter);
    const width = origin.getWidth();
    const height = origin.getHeight();
    const swapped = hour.isAxisSwapped();
    const newWidth = swapped ? height : width;
    const newHeight = swapped ? width : height;
    super(new BasePoint(center.x - newWidth / 2, center.y - newHeight / 2), newWidth, newHeight);
  }
}
