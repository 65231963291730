import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { HasNotExtendableParentModel, SelectableTreeBaseModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { BuildingLayerModel } from '../../../editor/lan/building/layer/BuildingLayerModel';
import { BuildingModel } from '../../../editor/lan/building/BuildingModel';
import { RoomModel } from '../../../editor/lan/building/RoomModel';
import { DefaultHasName } from '../../../editor/lan/building/HasName';
import { LanModel } from '../../../editor/lan/LanModel';
import { LanStructureTreeRoomProps } from './LanStructureTreeRoom';
import { NameConfiguration } from './LanStructureTree';
import { LanEngine } from '../../../editor/lan/LanEngine';

export const LanStructureTreeBuildingFactory = (
  engine: LanEngine,
  lanModel: LanModel,
  roomConfigurator: () => Promise<NameConfiguration>,
  roomFactory: Factory<LanStructureTreeRoomProps, TreeNodeState>
): Factory<LanStructureTreeBuildingProps, TreeNodeState> => ({ model, parent }) =>
  LanStructureTreeBuilding(engine, parent, model, lanModel.getBuildingLayer(), roomConfigurator, roomFactory);

export interface LanStructureTreeBuildingProps {
  model: BuildingModel;
  parent: ExtendableTreeNode;
}

export const LanStructureTreeBuilding = (
  engine: LanEngine,
  parent: ExtendableTreeNode,
  building: BuildingModel,
  buildingLayer: BuildingLayerModel,
  roomConfigurator: () => Promise<NameConfiguration>,
  roomFactory: Factory<LanStructureTreeRoomProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableLanStructureTreeBuildingNode(buildingLayer, building, roomConfigurator);

  const model = LanStructureTreeBuildingModel(extendable, building, roomFactory);

  return {
    ...model,
    ...extendable,
    ...HasNotExtendableParentModel(),
    ...SelectableTreeBaseModel(building, engine),
  };
};

const LanStructureTreeBuildingModel = (
  parent: ExtendableTreeNode,
  building: BuildingModel,
  roomFactory: Factory<LanStructureTreeRoomProps, TreeNodeState>
): TreeNodeModel => {
  return {
    getName: () => building.getName(),
    getKey: () => building.getID(),
    onChildrenChanged: (callback) => {
      return building.registerListener({
        childrenChanged: (event: { child: RoomModel; created: boolean }) =>
          callback(roomFactory({ model: event.child, parent }), event.created),
      } as any).deregister;
    },
    getChildren: () => {
      return building.getChildren().map((model) => roomFactory({ parent, model }));
    },
  };
};

const ExtendableLanStructureTreeBuildingNode = (
  buildingLayer: BuildingLayerModel,
  building: BuildingModel,
  roomConfigurator: () => Promise<NameConfiguration>
): ExtendableTreeNode => ({
  canAddChild: (childPayload) => {
    return (
      childPayload.type === TreeDraggableType.Room &&
      (!childPayload.modelId || !!building.getChildren().find((child) => child.getID() === childPayload.modelId))
    );
  },
  addChild: (childPayload: TreeDraggablePayload, childToAddAfter?: TreeNodeModel) => {
    if (!childPayload.modelId) {
      createRoom()
        .then(addRoom)
        .catch(() => {});
    } else {
      const room = building.getChildren().find((room) => room.getID() === childPayload.modelId);
      room!.notCascadeRemove();

      addRoom(room!);
    }

    function addRoom(room: RoomModel) {
      const indexToAdd = childToAddAfter
        ? building.getChildren().findIndex((child) => child.getID() === childToAddAfter.getKey()) + 1
        : 0;

      room.setRelativeModel(building, indexToAdd);
    }

    async function createRoom(): Promise<RoomModel> {
      const configuration = await roomConfigurator();
      return new RoomModel(new DefaultHasName(configuration.name));
    }
  },
});
