import { BusNodeFactory } from '../../../bus/BusNodeFactory';
import { BusNodeWithPlaceholdersModel } from '../../../bus/BusNodeWithPlaceholdersModel';
import { DirectoryLogicDeviceModel } from '../../logic-device/LogicDeviceModel';
import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';
import { StageType } from '../../../../../api/nggrace-back';

export class SsdBusNodeFactory extends BusNodeFactory {
  private stage: StageType = 'SSD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new BusNodeWithPlaceholdersModel<DirectoryLogicDeviceModel>(
      (node) =>
        new DefaultNodeWithPlaceholders<DirectoryLogicDeviceModel>(node, node, this.stage, event.initialConfig.SSD)
    );
  }
}
