import styled from 'styled-components';
import { Styled as StyledForm } from '../CardContent.styled';
import { Styled as StyledInputField } from '../../../../../../widgets/InputField.styled';
import { CardType } from '../../HardwareCard';

const SvgContainer = styled.div<{ cardType?: CardType }>`
  display: flex;
  flex-direction: ${(props) => (props.cardType === 'view' ? 'row-reverse' : 'row')};
  height: 107px;
  width: 507px;
  margin-top: ${(props) => (props.cardType === 'view' ? '-80px' : '0')};

  & img {
    height: 100px;
    width: 100px;
    border: 1px solid ${(props) => props.theme.colors.sky};
    border-radius: 5%;
    padding: 12px;
  }
`;

const InputAndSvgImg = styled.div`
  ${StyledForm.Form} {
    ${StyledInputField.InputField} {
      width: 400px;
      ${StyledInputField.Input} {
        border-bottom: none;
      }
    }
  }
`;

const SvgFileSelect = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 16px;

  & p {
    font-style: bold;
    margin-bottom: 8px;
    font-weight: 700;
  }

  & h1 {
    width: 244px;
    height: 44px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  ${StyledForm.Form} {
    ${StyledInputField.InputField} {
      margin-bottom: 7px;
    }
  }
`;

const NoImage = styled.div`
  height: 100px;
  width: 100px;

  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 21px;
  color: ${(props) => props.theme.colors.darkgrey};
  text-align: center;

  border: 1px solid ${(props) => props.theme.colors.black};
  padding: 22px 12px 24px 12px;
`;

export const Styled = {
  InputAndSvgImg,
  NoImage,
  SvgContainer,
  SvgFileSelect,
  Form: StyledForm.Form,
};
