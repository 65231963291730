import React, { useCallback, useState } from 'react';
import { Styled as S } from './HardwareLib.styled';
import { HardwareList } from './HardwareList';
import { Icon } from '../../../widgets/Icon';
import { HardwareType } from '../../../../api/nggrace-back';
import { Tab, Tabs } from '../../../widgets/Tabs';
import { useUser } from '../../login/UserContext';
import { hasLibraryEditAccess } from '../../../../utils/role-utils';

export const getHardwareTabName = (tab: HardwareType): string => {
  switch (tab) {
    case 'IED':
      return 'IED';
    case 'MU':
      return 'MU';
    case 'IED_MU':
      return 'IED/MU';
    case 'NETWORK':
      return 'Network devices';
    case 'PLC':
      return 'PLC';
    case 'SERVERS':
      return 'Servers';
    case 'TIME_SERVERS':
      return 'Time Servers';
    case 'AWS':
      return 'Workstations';
    case 'PRINTERS':
      return 'Printers';
  }
  throw Error(`Unexpected tab type: ${tab}`);
};

export const HardwareLib: React.FC = () => {
  const user = useUser()!.user!;

  const [activeTab, setActiveTab] = useState('IED' as HardwareType);

  const [hardwareCreationVisibility, setHardwareCreationVisibility] = useState(false);

  const showHardwareCreation = useCallback(() => {
    setHardwareCreationVisibility(true);
  }, []);

  const hideHardwareCreation = useCallback(() => {
    setHardwareCreationVisibility(false);
  }, []);

  const tabs: Tab<HardwareType>[] = ([
    'IED',
    'MU',
    'IED_MU',
    'NETWORK',
    'PLC',
    'SERVERS',
    'TIME_SERVERS',
    'AWS',
    'PRINTERS',
  ] as HardwareType[]).map((type) => {
    return { id: type as string, name: getHardwareTabName(type) } as Tab<HardwareType>;
  });

  return (
    <>
      <S.Header>
        <Tabs<HardwareType> activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
        {hasLibraryEditAccess(user) && (
          <S.BtnIcon onClick={showHardwareCreation}>
            <Icon name={'plus'} />
            <span>Add new</span>
          </S.BtnIcon>
        )}
      </S.Header>
      <HardwareList
        hardwareType={activeTab}
        hardwareCreationVisibility={hardwareCreationVisibility}
        hideHardwareCreation={hideHardwareCreation}
      />
    </>
  );
};
