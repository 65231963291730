import styled from 'styled-components';
import logo from '../../assets/logo_for_empty_list.svg';

const Container = styled.div<{ height: string; keyframes: any }>`
  display: flex;
  justify-content: center;
  height: ${(props) => props.height};
  overflow: auto;
  background: url(${logo}) no-repeat;
  background-position: center;
  opacity: 0;
  animation: ${(props) => props.keyframes} 0.4s forwards;
`;

const Text = styled.div`
  text-align: center;
  position: absolute;
  font-family: ${(props) => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
  display: block;
  margin-left: -170px;
  margin-top: -25px;
`;

const Logo = styled.div`
  display: block;
  margin: auto;
`;

export const Styled = {
  Container,
  Logo,
  Text,
};
