import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyBaseDto, UserDto, UserRole } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { InputField } from '../../widgets/InputField';
import { Styled as S } from '../Settings.styled';
import { Btn } from '../../widgets/Btn.styled';
import { Feedback } from '../../widgets/Feedback';
import { InputRadio } from '../../widgets/InputRadio';
import { useUser } from '../login/UserContext';
import { convertRole, hasCompaniesAccess } from '../../../utils/role-utils';
import { useOverlay } from '../../context/OverlayContext';
import { InputSelect } from '../../widgets/InputSelect';
import { useNotifications } from '../../context/NotificationContext';
import { SecondaryButton } from '../../widgets/SecondaryButton';

interface UserControlProps {
  onHide: () => void;
  user?: UserDto;
  loadPageHandler: () => void;
}

interface User {
  name?: string;
  email: string;
  role: UserRole;
  disabled?: boolean;
  company?: CompanyBaseDto;
}

export const UserSettings: React.FC<UserControlProps> = ({ onHide, user: initUser, loadPageHandler }) => {
  const [user, setUser] = useState<User>({
    role: 'ROLE_COMPANY_USER',
    email: '',
    ...initUser,
  });
  const [companies, setCompanies] = useState<CompanyBaseDto[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);
  const update = useMemo(() => !!initUser, [initUser]);
  const { user: authUser, setUser: setAuthUser } = useUser()!;
  const updateAuthUser = useMemo((): boolean => authUser!.id === initUser?.id, [authUser, initUser?.id]);
  const authUserAccessCompanies = useMemo(() => hasCompaniesAccess(authUser!), [authUser]);
  const maxLengthInput = 255;

  useEffect(() => {
    if (authUserAccessCompanies) {
      Api.getAllCompanies().then((response) => setCompanies(response.data));
    }
  }, [authUserAccessCompanies]);

  const handleNameChange = useCallback(
    (value: string) => {
      setError(undefined);
      setUser({ ...user, name: value });
      setUpdated(true);
    },
    [user]
  );

  const handleEmailChange = useCallback(
    (value: string) => {
      setError(undefined);
      setUser({ ...user, email: value });
      setUpdated(true);
    },
    [user]
  );

  const handleRoleChange = useCallback(
    (value: UserRole) => {
      setError(undefined);
      setUser({
        ...user,
        role: value,
      });
      setUpdated(true);
    },
    [user]
  );

  const handleCompanyChange = useCallback(
    (companyId: number) => {
      setError(undefined);
      setUser({ ...user, company: companies.find((company) => company.id === companyId)! });
      setUpdated(true);
    },
    [companies, user]
  );

  const handleClose = useCallback(() => {
    setUpdated(false);
    setUser({
      role: 'ROLE_COMPANY_USER',
      email: '',
      ...initUser,
    });
    onHide();
  }, [initUser, onHide]);

  const handleUpdate = useCallback(() => {
    if (updated) {
      setLoading(true);
      overlay.show();
      Api.updateUser(
        initUser!.id,
        {
          ...user,
          company: user.company!,
          name: user.name!,
        },
        { omitInterceptorErrorModal: true }
      )
        .then((_) => {
          if (updateAuthUser) {
            setAuthUser({ ...initUser!, role: user.role, name: user.name!, email: user.email });
          }
          loadPageHandler();
          handleClose();
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    } else {
      handleClose();
    }
  }, [updated, overlay, initUser, user, updateAuthUser, loadPageHandler, handleClose, setAuthUser]);

  const handleInvite = useCallback(() => {
    setLoading(true);
    overlay.show();
    Api.inviteUser(user, { omitInterceptorErrorModal: true })
      .then((_) => {
        notifications.notifyInfo("We've sent an invite to provided email address.", 'User invite');
        handleClose();
        overlay.hide();
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [handleClose, notifications, overlay, user]);

  return (
    <S.Settings>
      <S.Title>{update ? 'Edit' : 'New'} user</S.Title>
      <S.Form>
        {update && (
          <InputField label={'Name'} value={user.name} onChange={handleNameChange} maxLength={maxLengthInput} />
        )}
        <InputField label={'Email'} value={user.email} onChange={handleEmailChange} maxLength={maxLengthInput} />
        {!update && authUserAccessCompanies && (
          <InputSelect
            filter
            label={'Company'}
            selected={user.company?.id}
            options={companies}
            onChange={handleCompanyChange}
          />
        )}
        {!updateAuthUser && (
          <S.RadioGroup>
            {authUser!.role === 'ROLE_SYSTEM_ADMIN' && (
              <InputRadio<UserRole>
                value={'ROLE_SYSTEM_ADMIN'}
                name={'userRole'}
                selected={user.role}
                label={convertRole('ROLE_SYSTEM_ADMIN')}
                onChange={handleRoleChange}
              />
            )}
            <InputRadio<UserRole>
              value={'ROLE_COMPANY_ADMIN'}
              name={'userRole'}
              selected={user.role}
              label={convertRole('ROLE_COMPANY_ADMIN')}
              onChange={handleRoleChange}
            />
            <InputRadio<UserRole>
              value={'ROLE_COMPANY_USER'}
              name={'userRole'}
              selected={user.role}
              label={convertRole('ROLE_COMPANY_USER')}
              onChange={handleRoleChange}
            />
          </S.RadioGroup>
        )}
      </S.Form>
      <Feedback error={!!error} text={error ? error : ''} />
      <S.Buttons>
        <SecondaryButton text={'Cancel'} onClick={handleClose} />
        <Btn onClick={update ? handleUpdate : handleInvite} disabled={loading}>
          {!loading ? 'Ok' : 'Processing'}
        </Btn>
      </S.Buttons>
    </S.Settings>
  );
};
