import React from 'react';
import { Styled as S } from './LanLabel.styled';
import { Point } from '@projectstorm/geometry';

export type Size = 's' | 'm' | 'l';
export const sizeFontMapping: Record<Size, number> = { s: 8, m: 10, l: 12 };

export interface LanLabelProps {
  children: string;
  size?: Size;
  offset?: Point;
  alignment?: 'horizontal' | 'vertical';
}

export const LanLabel: React.FC<LanLabelProps> = ({
  children,
  size = 'm',
  offset = new Point(0, -15),
  alignment = 'horizontal',
}) => {
  return (
    <S.Label fontSize={sizeFontMapping[size]} left={offset.x} top={offset.y} vertical={alignment !== 'horizontal'}>
      {children}
    </S.Label>
  );
};
