export const FrameLeftEu = () => {
  return (
    <g style={{ fontSize: '3px', fontFamily: 'GOSTRUS', fill: '#000000' }}>
      <text x="4.5" y="-1" transform="rotate(270 4.5 -1)" dominantBaseline="text-after-edge">
        Format UNI A4
      </text>
      <text x="6" y="0.5" dominantBaseline="text-before-edge">
        This document is generated in ng.Grace.
      </text>
    </g>
  );
};
