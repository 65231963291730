import React, { FormEvent, useCallback, useState } from 'react';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { UserRegisterDto } from '../../../api/nggrace-back';
import { useOverlay } from '../../context/OverlayContext';
import { Api } from '../../../api/Api';
import { Styled as S } from '../AuthForm.styled';
import Logo from '../../../assets/logo.svg';
import { InputField } from '../../widgets/InputField';
import { InputFieldPassword } from '../../widgets/InputFieldPassword';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';
import { SecondaryButton } from '../../widgets/SecondaryButton';

interface UserRegisterSettingsProps {
  userInviteToken: string;
}

export const UserRegisterSettings: React.FC<UserRegisterSettingsProps> = ({ userInviteToken }) => {
  const { redirectToLoginPage, loginPath } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState<UserRegisterDto>({ name: '', password: '' });
  const [error, setError] = useState<string>();
  const overlay = useOverlay();

  const handleNameChange = useCallback((name: string) => {
    setError(undefined);
    setRegisterInfo((registerInfo) => ({ ...registerInfo, name }));
  }, []);

  const handlePasswordChange = useCallback((password: string) => {
    setError(undefined);
    setRegisterInfo((registerInfo) => ({ ...registerInfo, password }));
  }, []);

  const handleRegister = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      overlay.show();
      Api.registerUserByInvite(userInviteToken, registerInfo, { omitInterceptorErrorModal: true })
        .then(() => {
          redirectToLoginPage();
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    },
    [overlay, redirectToLoginPage, registerInfo, userInviteToken]
  );

  return (
    <S.AuthForm>
      <S.Main onSubmit={handleRegister}>
        <S.LeftPanel>
          <S.Title>Sign up</S.Title>
          <S.Description>
            Please use the registration form.
            <br />
            If you are the ng.Grace user, please&nbsp;<S.Link to={loginPath}>log in</S.Link>.
          </S.Description>
        </S.LeftPanel>
        <S.RightPanel>
          <S.Logo src={Logo} alt="ngGrace Logo" />
          <div>Use your contact details to register</div>
          <S.Form>
            <InputField label={'Name'} value={registerInfo?.name} onChange={handleNameChange} />
            <InputFieldPassword
              label={'Password'}
              value={registerInfo?.password}
              onChange={handlePasswordChange}
              checkPasswordFormat
            />
          </S.Form>
          {error && <Feedback error text={error} />}
          <S.Footer>
            <S.ExtraInfo>By creating an account, you agree to the Terms of Service.</S.ExtraInfo>
            <S.Buttons>
              <SecondaryButton text={'Cancel'} onClick={redirectToLoginPage} />
              <Btn type="submit" disabled={loading}>
                {!loading ? 'Sign up' : 'Processing'}
              </Btn>
            </S.Buttons>
          </S.Footer>
        </S.RightPanel>
      </S.Main>
    </S.AuthForm>
  );
};
