import React, { FormEvent, useCallback, useState } from 'react';
import { Styled as S } from '../AuthForm.styled';
import Logo from '../../../assets/logo.svg';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';
import { useOverlay } from '../../context/OverlayContext';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { InputFieldPassword } from '../../widgets/InputFieldPassword';
import { Api } from '../../../api/Api';
import { SecondaryButton } from '../../widgets/SecondaryButton';

interface ResetPasswordSettingsProps {
  passwordRecoveryToken: string;
}

export const PasswordResetSettings: React.FC<ResetPasswordSettingsProps> = ({ passwordRecoveryToken }) => {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  const handleReset = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (password !== passwordConfirmation) {
        setError('Password confirmation does not match password');
        return;
      }
      setLoading(true);
      overlay.show();
      Api.resetPassword(passwordRecoveryToken, { password }, { omitInterceptorErrorModal: true })
        .then(() => {
          redirectToLoginPage();
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    },
    [overlay, password, passwordConfirmation, passwordRecoveryToken, redirectToLoginPage]
  );

  const handlePasswordChange = useCallback((password: string) => {
    setError(undefined);
    setPassword(password);
  }, []);

  const handlePasswordConfirmationChange = useCallback((passwordConfirmation: string) => {
    setError(undefined);
    setPasswordConfirmation(passwordConfirmation);
  }, []);

  return (
    <S.AuthForm>
      <S.Main onSubmit={handleReset}>
        <S.LeftPanel>
          <S.Title>Reset password</S.Title>
          <S.Description>Enter new password for your account.</S.Description>
        </S.LeftPanel>
        <S.RightPanel>
          <S.Logo src={Logo} alt="ngGrace Logo" />
          <div>Enter new password for your account</div>
          <S.Form>
            <InputFieldPassword
              label={'Password'}
              value={password}
              onChange={handlePasswordChange}
              checkPasswordFormat
            />
            <InputFieldPassword
              label={'Password confirmation'}
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
            />
          </S.Form>
          {error && <Feedback error text={error} />}
          <S.FooterOnlyButtons>
            <S.Buttons>
              <SecondaryButton text={'Cancel'} onClick={redirectToLoginPage} />
              <Btn type="submit" disabled={loading}>
                {!loading ? 'Reset password' : 'Processing'}
              </Btn>
            </S.Buttons>
          </S.FooterOnlyButtons>
        </S.RightPanel>
      </S.Main>
    </S.AuthForm>
  );
};
