import { Path } from '../Path';
import { BasePoint, RestrictedDirectionPoint } from '../../../../geometry/Point';

export type PathPort = { getCenter(): BasePoint; getConnector(): RestrictedDirectionPoint };

export class PortEndedPath implements Path {
  private origin: Path;
  private port: PathPort;

  constructor(origin: Path, port: PathPort) {
    this.origin = origin;
    this.port = port;
  }

  getPoints(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint) {
    const portCenter = this.port.getCenter();
    const starting = start.equals(portCenter);
    const ending = end.equals(portCenter);

    if (!starting && !ending) {
      throw new Error('start or end has to be encapsulated port center');
    }
    const originBoundaries: [RestrictedDirectionPoint, RestrictedDirectionPoint] = ending
      ? [start, this.port.getConnector()]
      : [this.port.getConnector(), end];

    const points = this.origin.getPoints(...originBoundaries);
    return ending ? [...points, portCenter] : [portCenter, ...points];
  }
}
