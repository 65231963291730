import * as React from 'react';
import styled from '@emotion/styled';

interface CanvasProps {
  color?: string;
  background?: string;
}

const Container = styled.div<{ color: string; background: string }>`
  display: flex;
  flex: 1;
  background-color: ${(p) => p.background};
  background-size: 50px 50px;

  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }

  background-image: linear-gradient(
      0deg,
      transparent 24%,
      ${(p) => p.color} 25%,
      ${(p) => p.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(p) => p.color} 75%,
      ${(p) => p.color} 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      ${(p) => p.color} 25%,
      ${(p) => p.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(p) => p.color} 75%,
      ${(p) => p.color} 76%,
      transparent 77%,
      transparent
    );
`;

export const CanvasError = styled.div`
  width: 70vw;
`;

export const Canvas: React.FC<CanvasProps> = ({ color, background, children }) => {
  return (
    <Container
      background={background || 'rgb(255, 255, 255)'}
      color={color || 'rgba(0,0,0, 0.05)'}
      data-testid={'canvas'}
    >
      {/*<Container background={background || 'rgb(60, 60, 60)'} color={color || 'rgba(255,255,255, 0.05)'}>*/}
      {children}
    </Container>
  );
};
