import React from 'react';
import { InputUpload as InUpload } from '../../../../../widgets/InputUpload';
import { Styled as S } from './CardContent.styled';
import { CardType } from '../HardwareCard';

interface FieldProps {
  cardType: CardType;
  accept: string;
  file: string;
  disabled: boolean;
  onChange(file: File): void;
}

export const InputUpload: React.FC<FieldProps> = ({ cardType, accept, file, disabled, onChange }) => {
  return (
    <S.Form cardType={cardType}>
      <InUpload disabled={disabled} file={file} onChange={onChange} accept={accept} />
    </S.Form>
  );
};
