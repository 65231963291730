import styled from 'styled-components';
import { Styled as StyledLibList } from '../../LibraryList.styled';
import { Styled as S } from '../../../Table.styled';

const LogicalDeviceGrid = styled(S.Grid)<{ showCompany: boolean }>`
  grid-template-columns: ${(props) => `
      minmax(0, 2fr) repeat(2, minmax(0, 1fr)) ${props.showCompany ? 'minmax(0, 1.5fr)' : ''} 
      repeat(2, minmax(0, 1fr)) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1.25fr) 76px 118px
   `};
`;

export const Styled = {
  LogicalDeviceGrid,
  LibraryList: StyledLibList.LibraryList,
  ClickedRow: StyledLibList.ClickedRow,
};
