import { Draggable, DraggableEventData, DraggableProps } from './Draggable';
import { NodeEntryType } from '../directory/NodeDirectory';
import React from 'react';

export interface DirectoryNodeDraggablePayload {
  type: NodeEntryType;
  directoryId: string;
}

export interface DirectoryNodeDraggableProps extends DraggableProps<DirectoryNodeDraggablePayload> {}

export interface DirectoryNodeDraggableEventData extends DraggableEventData<DirectoryNodeDraggablePayload> {}

export const DirectoryNodeDraggable: React.FC<DirectoryNodeDraggableProps> = (props) => <Draggable {...props} />;
