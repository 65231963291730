import { LanPortHighlight, LanPortModel } from './LanPortModel';
import { LanEngine } from '../../LanEngine';
import React, { useEffect, useMemo, useState } from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import { LanStrokeWidth, SvgGroup } from '../LanNodeWidget';
import { LanLabel } from '../../label/LanLabel';
import { Point } from '@projectstorm/geometry';
import { AddedPoint } from '../../../geometry/Point';
import { LanLinkModel } from '../../link/LanLinkModel';

interface LanPortProps {
  port: LanPortModel;
  engine: LanEngine;
  index: number;
  selected: boolean;
}

export const LanPortWidget: React.FC<LanPortProps> = React.memo(({ port, index, engine, selected }) => {
  const size = port.getSize();
  const position = port.getRelativePosition();
  const [highlight, setHighlight] = useState(port.getHighLight());
  const [link, setLink] = useState(port.getLanLink());

  useEffect(() => {
    setHighlight(port.getHighLight());
    setLink(port.getLanLink());

    return port.registerListener({
      highlightChanged: () => setHighlight(port.getHighLight()),
      linksChanged: () => setLink(port.getLanLink()),
    } as any).deregister;
  }, [port]);

  const cursorHighlightMapping: Record<LanPortHighlight, string> = {
    [LanPortHighlight.NONE]: 'auto',
    [LanPortHighlight.ALLOW]: 'pointer',
    [LanPortHighlight.FORBID]: 'not-allowed',
  };

  const indexLabel = (index + 1).toString();
  const labelOffset = useMemo(() => {
    const offset = port.getLabelOffset();
    return new Point(offset.x < 0 ? indexLabel.length * offset.x : offset.x, offset.y);
  }, [port, indexLabel]);

  const linkLabelOffset = useMemo(() => {
    const xOffset = labelOffset.x > 0 ? labelOffset.x - 5 : -10;
    return new AddedPoint(new Point(xOffset, 12), position);
  }, [labelOffset, position]);

  return (
    <>
      {' '}
      <PortWidget
        port={port}
        engine={engine as any}
        style={{
          position: 'absolute',
          display: 'flex',
          height: size,
          width: size,
          left: position.x,
          top: position.y,
          cursor: cursorHighlightMapping[highlight],
        }}
      >
        <LanLabel size={'s'} offset={labelOffset}>
          {indexLabel}
        </LanLabel>
        <svg height={size} width={size}>
          <SvgGroup>
            <rect
              x={LanStrokeWidth / 2}
              y={LanStrokeWidth / 2}
              width={size - LanStrokeWidth}
              height={size - LanStrokeWidth}
              stroke={selected ? 'deepskyblue' : port.getPortColor()}
              fill={selected ? 'deepskyblue' : port.getPortColor()}
            />
          </SvgGroup>
        </svg>
      </PortWidget>
      {link && link instanceof LanLinkModel && (
        <LanLabel size={'s'} offset={linkLabelOffset} alignment={'vertical'}>
          {link.getLabel()}
        </LanLabel>
      )}
    </>
  );
});
