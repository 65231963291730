import React, { useCallback, useEffect, useState } from 'react';
import { UserDto } from '../../../api/nggrace-back';
import { List } from '../../widgets/List.styled';
import { Icon } from '../../widgets/Icon';
import { StatusTag } from '../../widgets/StatusTag';
import { formatMomentShort } from '../../../utils/date-utils';
import { useUser } from '../login/UserContext';
import { Api } from '../../../api/Api';

interface UserProps {
  user: UserDto;
  handleUpdate: (user: UserDto) => void;
  handleDelete: (user: UserDto) => void;
  companyShown: boolean;
}

export const User: React.FC<UserProps> = ({ user, handleUpdate, handleDelete, companyShown }) => {
  const userSelf = user.id === useUser()!.user!.id;

  const [userDisabled, setUserDisabled] = useState(user.disabled);

  useEffect(() => {
    setUserDisabled(user.disabled);
  }, [user.disabled]);

  const handleChangeStatus = useCallback(
    (status: boolean) => {
      Api.updateUser(user.id, {
        disabled: status,
        name: user.name,
        email: user.email,
        role: user.role,
        company: user.company,
      }).then(() => {
        setUserDisabled(status);
      });
    },
    [user]
  );

  return (
    <>
      <List.Row key={user.id}>
        <List.Cell data-testid={'userNameCell'}>{user.name}</List.Cell>
        <List.Cell data-testid={'userEmailCell'}>{user.email}</List.Cell>
        {companyShown && <List.Cell data-testid={'userCompanyCell'}>{user.company.name}</List.Cell>}
        <List.Cell data-testid={'userCreatedCell'}>{formatMomentShort(user.createdAt)}</List.Cell>
        <List.Cell>
          <StatusTag active={!userDisabled} onClick={() => handleChangeStatus(!userDisabled)} disabled={userSelf} />
        </List.Cell>
        <List.Cell center>
          <List.Button onClick={() => handleUpdate(user)}>
            <Icon name={'edit'} />
          </List.Button>
        </List.Cell>
        <List.Cell center>
          <List.Button disabled={userSelf} onClick={() => handleDelete(user)}>
            <Icon name={'trash'} />
          </List.Button>
        </List.Cell>
      </List.Row>
    </>
  );
};
