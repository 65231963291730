import React from 'react';
import { Styled as S } from './Ieds.styled';

interface IedsProps {
  ieds: string[];
}

export const Ieds: React.FC<IedsProps> = ({ ieds }) => {
  return (
    <S.Ieds>
      <span>IEDs</span>
      {ieds.map((ied) => (
        <S.Ied>{ied}</S.Ied>
      ))}
    </S.Ieds>
  );
};
