import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { LanEngine } from '../../LanEngine';
import * as React from 'react';
import { ZoneLayerModel, ZoneLayerModelType } from './ZoneLayerModel';

export class ZoneLayerFactory extends AbstractReactFactory<ZoneLayerModel, LanEngine> {
  constructor() {
    super(ZoneLayerModelType);
  }

  generateModel(event: GenerateModelEvent): ZoneLayerModel {
    return new ZoneLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<ZoneLayerModel>): JSX.Element {
    return <></>;
    // return <ZoneLayerWidget model={event.model} />;
  }
}

// const ZoneLayerWidget: React.FC<{ model: ZoneLayerModel }> = ({ model }) => {
//   const [graph, setGraph] = useState(model.getLinkWaypointGraph());
//
//   useEffect(() => {
//     setGraph(model.getLinkWaypointGraph());
//     model.registerListener({
//       waypointsChanged: () => setGraph(model.getLinkWaypointGraph()),
//     } as any);
//   }, [model]);
//
//   return (
//     <>
//       {Object.values(model.getModels()).map((zone) => (
//         <ZoneWidget zone={zone} key={zone.getID()} />
//       ))}
//       {graph?.getNodes().map((node) => {
//         const point = node.getRect().getTopLeft();
//         return (
//           <div
//             style={{
//               position: 'absolute',
//               height: '10px',
//               width: '10px',
//               backgroundColor: 'green',
//               left: point.x,
//               top: point.y,
//             }}
//             key={node.getID()}
//           >
//             {point.y}
//           </div>
//         );
//       })}
//     </>
//   );
// };
