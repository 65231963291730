import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../../../utils/factory';
import { DataAttributeTemplateDto } from '../../../../../../api/nggrace-back';
import { LogicalNodeTreeModel, stringValueFormatter } from './LnComposition';

export interface LnCompositionTreeDataAttributeProps {
  dataAttribute: DataAttributeTemplateDto;
}

export const LnCompositionTreeDataAttributeFactory = (): Factory<
  LnCompositionTreeDataAttributeProps,
  LogicalNodeTreeModel
> => ({ dataAttribute }) => LnCompositionTreeDataAttribute(dataAttribute);

const LnCompositionTreeDataAttribute = (dataAttribute: DataAttributeTemplateDto): LogicalNodeTreeModel => {
  return {
    ...LnCompositionTreeDoTemplateModel(dataAttribute),
    ...HasNotExtendableParentModel(),
    ...NotExtendableModel(),
    ...NotSelectableModel(),
  };
};

const LnCompositionTreeDoTemplateModel = (dataAttribute: DataAttributeTemplateDto) => {
  return {
    getName: () => `DA - ${stringValueFormatter(dataAttribute.name)}`,
    getKey: () => dataAttribute.name + dataAttribute.type,
    onChildrenChanged: () => () => {},
    getChildren: () => [],

    getClazz: () => '',
    getCommonDataClass: () => '',
    getDescription: () => stringValueFormatter(dataAttribute.desc),
    getType: () => (dataAttribute.type ? stringValueFormatter(dataAttribute.type) : ''),
    getBType: () => (dataAttribute.bType ? stringValueFormatter(dataAttribute.bType) : ''),
    getFc: () => stringValueFormatter(dataAttribute.fc),
  };
};
