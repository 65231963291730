import React, { useCallback } from 'react';
import { Styled as S } from './Accordion.styled';
import { Icon } from '../../../widgets/Icon';

type AccordionProps = {
  title: string;
  subtitle?: string | number;
  expanded?: boolean;
  highlight: boolean;
  canHighlight?: boolean;
  hasParent?: boolean;
  last: boolean;
  indentLevel: number;
  open: boolean;
  onClick(): void;
  toggleOpen(): void;
};

export const AccordionWidget: React.FC<AccordionProps> = ({
  title,
  subtitle,
  expanded,
  children,
  highlight,
  canHighlight,
  hasParent = true,
  indentLevel,
  last,
  open,
  onClick,
  toggleOpen,
}) => {
  const handleToggleClick = useCallback(
    (event: React.MouseEvent) => {
      toggleOpen();
      event.stopPropagation();
    },
    [toggleOpen]
  );

  return (
    <S.Accordion>
      {hasParent && (
        <>
          <S.Header
            highlight={highlight}
            canHighlight={canHighlight}
            hasParent={hasParent}
            last={last}
            indentLevel={indentLevel}
          >
            <S.Indent level={indentLevel} />
            {children ? (
              <S.Toggle
                open={open}
                onClick={handleToggleClick}
                hasParent={hasParent}
                indentLevel={indentLevel}
                highlight={highlight}
              >
                <Icon name={'toggle'} />
              </S.Toggle>
            ) : (
              <S.EmptyToggle hasParent={hasParent} indentLevel={indentLevel} highlight={highlight} />
            )}
            <S.Text>
              <S.Title>{title}</S.Title>
              <S.Subtitle>{subtitle}</S.Subtitle>
            </S.Text>
          </S.Header>
          {children && <S.SubTree>{open && children}</S.SubTree>}
        </>
      )}
      {!hasParent && (
        <S.Button expanded={expanded} onClick={onClick}>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.Button>
      )}
      {expanded && children && <S.Content>{children}</S.Content>}
    </S.Accordion>
  );
};
