import { NgGraceEngine } from '../NgGraceEngine';
import { LogicDeviceFactory } from './logic-device/LogicDeviceFactory';
import { FactoryBank } from '@projectstorm/react-canvas-core';
import { NodeDirectory } from '../directory/NodeDirectory';
import {
  LogicDeviceDirectoryEntry,
  LogicNodeDirectoryEntry,
  PropertiesDirectory, VoltageLevelDirectoryEntry,
} from '../directory/PropertiesDirectory';
import { LogicDeviceLayerFactory } from './layer/logic-device/LogicDeviceLayerFactory';
import { SsdModel } from './SsdModel';
import { SsdDirectoryNodeFactory } from './node/directory/SsdDirectoryNodeFactory';
import { SsdNodeLayerFactory } from './layer/node/SsdNodeLayerFactory';
import { SsdBusNodeFactory } from './node/bus/SsdBusNodeFactory';
import { SsdConnectivityNodeFactory } from './node/connectivity/SsdConnectivityNodeFactory';
import { SsdSmartLinkFactory } from './link/SsdSmartLinkFactory';
import { SsdDefaultState } from './state/SsdDefaultState';
import { SsdModelObserver } from './SsdModelObserver';
import { ModelObserver } from '../NgGraceModelObserver';
import { StageType } from '../../../api/nggrace-back';

export class SsdEngine extends NgGraceEngine {
  protected readonly logicDevicesFactories: FactoryBank<LogicDeviceFactory> = new FactoryBank();

  constructor(
    directory: NodeDirectory,
    voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>,
    logicDeviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>,
    logicNodeDirectory: PropertiesDirectory<LogicNodeDirectoryEntry>,
    observer: ModelObserver = new SsdModelObserver(),
    stageType: StageType = 'SSD'
  ) {
    super(directory, observer);

    this.logicDevicesFactories.registerListener({
      factoryAdded: (event: any) => {
        event.factory.setDiagramEngine(this);
      },
      factoryRemoved: (event: any) => {
        event.factory.setDiagramEngine(null);
      },
    });

    this.logicDevicesFactories.registerFactory(new LogicDeviceFactory(logicDeviceDirectory, logicNodeDirectory));
    this.layerFactories.registerFactory(new LogicDeviceLayerFactory());
    this.layerFactories.registerFactory(new SsdNodeLayerFactory());

    this.linkFactories.registerFactory(new SsdSmartLinkFactory(stageType));
    [
      new SsdDirectoryNodeFactory(directory, voltageLevelDirectory),
      new SsdBusNodeFactory(voltageLevelDirectory),
      new SsdConnectivityNodeFactory(voltageLevelDirectory),
    ].forEach((factory) => this.nodeFactories.registerFactory(factory));

    this.stateMachine.pushState(new SsdDefaultState());
  }

  getLogicDeviceFactories() {
    return this.logicDevicesFactories;
  }

  getModel(): SsdModel {
    return super.getModel() as SsdModel;
  }

  buildNewModel() {
    return new SsdModel();
  }
}
