import { DefaultDisposed, EnvironmentalLinkSet, Graph } from './Graph';
import { Link } from '../Link';
import { Node } from '../node/Node';
import { Rectangle } from '@projectstorm/geometry';

export class CompositeGraph implements Graph {
  private graphsToCompose: Graph[];
  private environment: Graph;
  private disposed: DefaultDisposed;
  private linkSet: EnvironmentalLinkSet;
  constructor(graphsToCompose: Graph[], environment: Graph) {
    this.graphsToCompose = graphsToCompose;
    this.environment = environment;
    this.disposed = new DefaultDisposed(this);
    this.linkSet = new EnvironmentalLinkSet(this, environment);
  }
  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    if (
      this.graphsToCompose
        .flatMap((graph) => graph.getNodes())
        .filter((node, index, array) => array.indexOf(node) === index).length < 5
    ) {
    }
    return this.graphsToCompose
      .flatMap((graph) => graph.getNodes())
      .filter((node, index, array) => array.indexOf(node) === index);
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}
