import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { ConnectingPath, DefaultConnectedPathLength } from '../Path';
import { BasePoint, RestrictedDirectionPoint } from '../../../../geometry/Point';
import { DefaultRightAngledVector } from '../../../../geometry/Vector';

export class RemovingConnectingPath implements ConnectingPath {
  private readonly origin: ConnectingPath;

  constructor(path: ConnectingPath) {
    this.origin = path;
  }

  connect(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): SmartLinkPointModel[] {
    const result = this.origin.connect(start, end);
    let mayContainSame = true;

    while (mayContainSame) {
      mayContainSame = false;
      for (let i = 0; i < result.length - 1; i++) {
        if (
          result.length > DefaultConnectedPathLength &&
          new BasePoint(result[i].getPosition()).equals(result[i + 1].getPosition())
        ) {
          result.splice(i, 1);
          mayContainSame = true;
        }
      }
    }

    //2 for algorithm lookahead (i+2)
    //1 for last segment protection (same as first segment protection: i = 1)
    let i = 1;
    while (i < result.length - 3 && result.length > DefaultConnectedPathLength) {
      if (
        new DefaultRightAngledVector(result[i].getPosition(), result[i + 1].getPosition())
          .getDirection()
          .isHorizontal() ===
        new DefaultRightAngledVector(result[i + 1].getPosition(), result[i + 2].getPosition())
          .getDirection()
          .isHorizontal()
      ) {
        result.splice(i + 1, 1);
      } else {
        i++;
      }
    }

    return result;
  }
}
