import styled from 'styled-components';

const OpexCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const GraphContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  //TODO: adding custom size
  height: 402px;
`;

const Graph = styled.svg`
  height: 90px;
`;

const GraphNameText = styled.text`
  font: 900 16px 'Mulish', 'Open Sans', sans-serif;
  fill: ${(props) => props.theme.colors.white};
`;

const GraphValueText = styled.text`
  font: 700 32px 'Mulish', 'Open Sans', sans-serif;
  fill: ${(props) => props.theme.colors.white};
`;

const Content = styled.div`
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 32px;
  width: 100%;
`;

export const Styled = {
  OpexCard,
  GraphContainer,
  Graph,
  GraphNameText,
  GraphValueText,
  Content,
  Footer,
};
