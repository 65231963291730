import { Action, ActionEvent, BaseModel, InputType, SelectingState, State } from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { DragDiagramItemsState } from '@projectstorm/react-diagrams-core';
import { CreateLinkState } from './CreateLinkState';
import { ResizeNodeState } from './ResizeNodeState';
import { NodeResizerClassName } from '../NodeResizer';
import { SmartLinkDragState } from './SmartLinkDragState';
import { isBusNodeModel, isBusPortModel, isPortModel, isSmartLinkModel } from '../NgGraceModel';
import { DragCanvasState } from './DragCanvasState';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import { MoveBusPortState } from './MoveBusPortState';

export class DefaultState extends State<DiagramEngine> {
  static readonly Name = 'starting-state';
  private readonly dragCanvas: DragCanvasState;
  private readonly createLink: CreateLinkState;
  private readonly dragItems: DragDiagramItemsState;
  private readonly resizeNodeState: ResizeNodeState;
  private readonly smartLinkDragState: SmartLinkDragState;
  private readonly moveBusPortState: MoveBusPortState;

  constructor() {
    super({ name: DefaultState.Name });
    this.childStates = [new SelectingState()];
    this.dragCanvas = new DragCanvasState();
    this.createLink = new CreateLinkState({ allowLooseLinks: false });
    this.dragItems = new DragDiagramItemsState();
    this.resizeNodeState = new ResizeNodeState();
    this.smartLinkDragState = new SmartLinkDragState();
    this.moveBusPortState = new MoveBusPortState();
    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const model: BaseModel | null = this.engine
            .getActionEventBus()
            .getModelForEvent(event as ActionEvent<MouseEvent>);

          // the canvas was clicked on, transition to the dragging canvas state
          if (!model) {
            this.transitionWithEvent(this.dragCanvas, event);
            return;
          }

          if (isBusPortModel(model)) {
            return this.transitionWithEvent(this.moveBusPortState, event);
          }
          // initiate dragging a new link
          if (isPortModel(model)) {
            this.transitionWithEvent(this.createLink, event);
            return;
          }

          // move the items (and potentially link points)
          if (isBusNodeModel(model) && this.isResizeElement(event.event.target as Element)) {
            this.transitionWithEvent(this.resizeNodeState, event);
            return;
          }

          if (isSmartLinkModel(model)) {
            this.transitionWithEvent(this.smartLinkDragState, event);
            return;
          }

          this.transitionWithEvent(this.dragItems, event);
        },
      })
    );
  }

  isResizeElement(element: Element) {
    return !!element.closest(`.${NodeResizerClassName}`);
  }
}
