import React, { useEffect, useRef } from 'react';
import { InputField as InField } from '../../../../../widgets/InputField';
import { InputSelect as InSelect, OptionType } from '../../../../../widgets/InputSelect';
import { Styled as S } from './PhysicalInterface.styled';
import { CardType } from '../HardwareCard';
import { HardwareInterfaceDto } from '../../../../../../api/nggrace-back';
import { Icon } from '../../../../../widgets/Icon';

interface PhysicalInterfaceProps {
  index: number;
  interfaces: HardwareInterfaceDto[];
  cardType: CardType;
  selected?: string;
  number?: number;
  items: OptionType[];

  handleInterfacesChange(interfaces: HardwareInterfaceDto[]): void;
}

export const PhysicalInterface: React.FC<PhysicalInterfaceProps> = ({
  index,
  interfaces,
  cardType,
  selected,
  number,
  items,
  handleInterfacesChange,
}) => {
  const onInterfaceTypeChange = (id: string) => {
    handleInterfacesChange(
      interfaces.map((o, i) => {
        if (i === index) {
          o.directoryId = id;
        }
        return o;
      })
    );
  };

  const onInterfaceNumberChange = (number: number) => {
    handleInterfacesChange(
      interfaces.map((o, i) => {
        if (i === index) {
          o.number = number;
        }
        return o;
      })
    );
  };

  const onDelete = () => {
    handleInterfacesChange(interfaces.filter((o, i) => i !== index));
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.addEventListener('keypress', (evt) => {
      if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((o) => `Digit${o}`).includes(evt.code)) evt.preventDefault();
    });
  }, []);

  return (
    <S.PhysicalInterfaceForm cardType={cardType}>
      <InSelect
        disabled={cardType === 'view'}
        label={'Type'}
        selected={selected}
        options={items}
        onChange={onInterfaceTypeChange}
      />
      <InField
        ref={ref}
        type={'number'}
        disabled={cardType === 'view'}
        label={'Amount'}
        value={number}
        min={0}
        max={100}
        step={1}
        onChange={onInterfaceNumberChange}
      />
      {cardType !== 'view' && <Icon name={'trash'} onClick={onDelete} />}
    </S.PhysicalInterfaceForm>
  );
};
