import { LogicalNodeTreeModel } from './LnComposition';
import { LnCompositionTreeDataAttributeProps } from './LnCompositionTreeDataAttribute';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../../../../../widgets/tree/state/TreeBaseModel';
import { DataObjectDto, DataObjectTemplateDto } from '../../../../../../../../api/nggrace-back';
import { Factory } from '../../../../../../../../utils/factory';
import { stringValueFormatter } from '../../../../../hardware/card/lncomposition/LnComposition';

export interface LnCompositionTreeDataObjectProps {
  dataObject: DataObjectDto;
}

export const LnCompositionTreeDataObjectFactory =
  (
    dataObjectTemplates: { [index: string]: DataObjectTemplateDto },
    lnCompositionTreeDataAttributeFactory: Factory<LnCompositionTreeDataAttributeProps, LogicalNodeTreeModel>
  ): Factory<LnCompositionTreeDataObjectProps, LogicalNodeTreeModel> =>
  ({ dataObject }) =>
    LnCompositionTreeDataObject(dataObject, dataObjectTemplates, lnCompositionTreeDataAttributeFactory);

const LnCompositionTreeDataObject = (
  dataObject: DataObjectDto,
  dataObjectTemplates: { [index: string]: DataObjectTemplateDto },
  lnCompositionTreeDataAttributeFactory: Factory<LnCompositionTreeDataAttributeProps, LogicalNodeTreeModel>
): LogicalNodeTreeModel => {
  return {
    ...LnCompositionTreeDoTemplateModel(dataObject, dataObjectTemplates, lnCompositionTreeDataAttributeFactory),
    ...HasNotExtendableParentModel(),
    ...NotExtendableModel(),
    ...NotSelectableModel(),
  };
};

const LnCompositionTreeDoTemplateModel = (
  dataObject: DataObjectDto,
  dataObjectTemplates: { [index: string]: DataObjectTemplateDto },
  lnCompositionTreeDataAttributeFactory: Factory<LnCompositionTreeDataAttributeProps, LogicalNodeTreeModel>
) => {
  const dataObjectTemplate = dataObjectTemplates[dataObject.type];
  return {
    getName: () => `DO - ${stringValueFormatter(dataObject.name)}`,
    getKey: () => dataObject.name + dataObject.type,
    onChildrenChanged: () => () => {},
    getChildren: () => {
      if (!dataObjectTemplate) return [];
      const dataAttributes = dataObjectTemplate.dataAttributes.map((dataAttribute) =>
        lnCompositionTreeDataAttributeFactory({ dataAttribute })
      );
      const subDataObjects = dataObjectTemplate.subDataObjects.map((subDataObject) =>
        LnCompositionTreeDataObject(subDataObject, dataObjectTemplates, lnCompositionTreeDataAttributeFactory)
      );
      return [...dataAttributes, ...subDataObjects];
    },

    getClazz: () => '',
    getCommonDataClass: () => (dataObjectTemplate ? stringValueFormatter(dataObjectTemplate.cdc) : ''),
    getDescription: () => stringValueFormatter(dataObject.desc),
    getType: () => stringValueFormatter(dataObject.type),
    getBType: () => '',
    getFc: () => '',
    getInstance: () => 0,
    getLogicalNodeTypeId: () => 0,
  };
};
