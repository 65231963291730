import { Link } from '../../Link';
import { Node, NodeType } from '../../node/Node';
import { Graph } from '../Graph';
import { Polygon, Rectangle } from '@projectstorm/geometry';

export class Transformers implements Graph {
  private environment: Graph;

  constructor(environment: Graph) {
    this.environment = environment;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    return this.environment.getNodes().filter((node) => node.getType() === NodeType.TR);
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}
