import styled, { css } from 'styled-components';
import { Icon } from './Icon.styled';

export const Indicator = styled.div<{ completed?: boolean; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${(props) => props.theme.colors.lightgrey};
  background-color: ${(props) => props.theme.colors.darkgrey};
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin-bottom: 8px;

  ${(props) =>
    props.completed &&
    css`
      background: ${(props) => props.theme.colors.turquoise}66;
      border: 1px solid ${(props) => props.theme.colors.green}66;
    `};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.blue}80;
      border: 1px solid ${(props) => props.theme.colors.blue};
    `};

  ${Icon} {
    color: ${(props) => props.theme.colors.white};
    width: 15px;
    height: 14px;
    margin: 0;
  }
`;
