export interface HasName {
  getName(): string;
}

export class DefaultHasName implements HasName {
  private name: string;

  constructor(name: string) {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }
}
