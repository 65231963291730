import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectableTreeNode } from '../../../../../../widgets/tree/state/TreeState';
import { Factory } from '../../../../../../../utils/factory';
import { LogicalNodeTreeModel, LogicalNodeTreeNodeModel } from '../LnComposition';
import { TreeNodeWidget } from './TreeNodeWidget';

export type TreeNodeChildFactory = Factory<
  { model: LogicalNodeTreeModel; indentLevel: number; last: boolean; showInLd: boolean },
  ReactNode
>;

interface TreeNodeProps {
  model: LogicalNodeTreeNodeModel & SelectableTreeNode;
  childFactory?: TreeNodeChildFactory;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;
  showInLd: boolean;
}

export const TreeNode: React.FC<TreeNodeProps> = ({
  model,
  childFactory,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
  showInLd,
}: TreeNodeProps) => {
  const [children, setChildren] = useState<LogicalNodeTreeModel[]>(model.getChildren() as LogicalNodeTreeModel[]);
  const [open, setOpen] = useState(initialOpen || false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  useEffect(() => {
    setChildren(model.getChildren() as LogicalNodeTreeModel[]);
    return model.onChildrenChanged((child, added) => {
      setChildren(model.getChildren() as LogicalNodeTreeModel[]);

      if (added) {
        setOpen(true);
      }

      if (added && child.canSelect()) {
        child.setSelected(true);
      }
    });
  }, [model]);

  const [selected, setSelected] = useState(model.isSelected());
  useEffect(() => {
    setSelected(model.isSelected());
    return model.onSelectionChanged(() => setSelected(model.isSelected()));
  }, [model]);

  const childrenComponents = useMemo(
    () =>
      childFactory &&
      children.map((child, index) =>
        childFactory({
          model: child,
          indentLevel,
          last: children.length - 1 === index,
          showInLd: showInLd,
        })
      ),
    [children, childFactory, indentLevel]
  );

  return (
    <TreeNodeWidget
      model={model}
      highlight={selected}
      key={model.getKey()}
      canHighlight={canHighlight}
      hasParent={hasParent}
      indentLevel={indentLevel}
      last={last}
      open={open}
      toggleOpen={toggleOpen}
      showInLd={showInLd}
    >
      {childrenComponents?.length ? childrenComponents : null}
    </TreeNodeWidget>
  );
};
