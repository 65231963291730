import React, { useContext, useEffect, useState } from 'react';
import { NgGraceModel } from './NgGraceModel';
import { EngineContext } from './EngineContext';

export const DiagramModelContext = React.createContext(new NgGraceModel());

export const DiagramModelProvider: React.FC = ({ children }) => {
  const engine = useContext(EngineContext);
  const [model, setModel] = useState(engine.getModel());

  useEffect(() => {
    setModel(engine.getModel());
    return engine.registerListener({
      modelChanged: () => setModel(engine.getModel()),
    }).deregister;
  }, [engine]);

  return <DiagramModelContext.Provider value={model}>{children}</DiagramModelContext.Provider>;
};
