import React from 'react';
import { Styled } from './hardware/card/HardwareCard.styled';
import { formatMomentShort } from '../../../utils/date-utils';
import { CardType } from './hardware/card/HardwareCard';
import { useUser } from '../login/UserContext';

interface EditInfoProps {
  cardType: CardType;
  editedAt?: number;
  editorName?: string;
}

export const EditInfo: React.FC<EditInfoProps> = ({ cardType, editedAt, editorName }) => {
  const user = useUser()!.user!;

  return (
    <>
      <Styled.EditInfo>
        {cardType !== 'create' && editedAt !== undefined && (
          <div>
            Edit date:<span>{formatMomentShort(editedAt)}</span>
          </div>
        )}
        <div>
          Author:<span>{cardType !== 'create' ? editorName : user.name}</span>
        </div>
      </Styled.EditInfo>
    </>
  );
};
