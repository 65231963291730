import styled from 'styled-components';
import { Styled as StyledFeedback } from '../../../../../../widgets/Feedback.styled';
import { Styled as StyledLibList } from '../../../../LibraryList.styled';
import { Styled as InputSelect } from '../../../../../../widgets/InputSelect.styled';
import { Styled as DescriptionField } from '../../../../hardware/card/cardcontent/DescriptionField.styled';
import { Styled as StyledTextArea } from '../../../../../../widgets/TextArea.styled';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 36px;
  flex: 1 0 auto;
`;

const Column = styled.div<{ position: 'left' | 'center' | 'right' }>`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.position !== 'center' ? '508px' : '294px')};
  width: 100%;

  ${StyledFeedback.Feedback} {
    margin-top: -24px;
  }

  ${InputSelect.InputOptions} {
    max-height: 206px;
  }

  ${DescriptionField.DescriptionFieldForm} {
    padding: 0;
    max-height: 144px;

    ${StyledTextArea.TextArea} {
      height: 144px;
    }
  }

  & p {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 15px;

    & span {
      margin-left: 19px;
      color: ${(props) => props.theme.colors.blue};
      cursor: pointer;
    }
  }
`;

const LogicalNodeGrid = styled(StyledLibList.DefaultGrid)`
  grid-template-columns:
    minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
`;

export const Styled = {
  Container,
  Column,
  LogicalNodeGrid,
  ClickedRow: StyledLibList.ClickedRow,
};
