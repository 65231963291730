import styled from 'styled-components';
import { Styled as ModalStyled } from '../../Modal.styled';

const TcoMain = styled.div`
  padding: 0 72px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ModalStyled.Header)`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const Description = styled(ModalStyled.Description)`
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Styled = {
  Header,
  Description,
  Footer: ModalStyled.Footer,
  BtnPrimary: ModalStyled.BtnPrimary,
  Modal: ModalStyled.Modal,
  TcoMain,
};
