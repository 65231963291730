import * as React from 'react';
import { LabelFontSize } from '../../../label/LabelWidget';
import { RotatedDirection } from '../../../geometry/Direction';
import { BusNodeModel } from '../../../bus/BusNodeModel';

interface ExportBusNodeLabelProps {
  node: BusNodeModel;
}

export const ExportBusNodeLabel: React.FC<ExportBusNodeLabelProps> = ({ node }) => {
  const label = node.getLabel();
  const direction = new RotatedDirection(label.getDirection(), node.getRotation());
  const textRotation = direction.isHorizontal() ? 270 : 0;
  const dominantBaseline = direction.isIncreasing() ? 'text-before-edge' : 'text-after-edge';
  const textAnchor = direction.isIncreasing() ? 'end' : 'start';

  return (
    <text
      x={0}
      y={direction.isHorizontal() ? node.getSize().y : 0}
      fontFamily="GOSTRUS"
      fontSize={`${LabelFontSize}px`}
      textAnchor={textAnchor}
      dominantBaseline={dominantBaseline}
      transform={`rotate(${textRotation})`}
    >
      {node.getLabel().getContent().text}
    </text>
  );
};
