import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Styled as S } from './Properties.styled';
import { DirectoryNodeDirectoryField, DirectoryNodeModelPayload } from '../directory/DirectoryNodeModel';
import { DirectoryFieldRecord, PortRecord } from '../directory/NodeDirectory';
import { InputSelect } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';

type PropertiesDirectoryFieldProps = {
  field: DirectoryFieldRecord;
  payload: DirectoryNodeModelPayload;
  port?: PortRecord;
  disabled?: boolean;

  onChange(payload: DirectoryNodeModelPayload): void;
};

export const PropertiesDirectoryField: React.FC<PropertiesDirectoryFieldProps> = ({
  field,
  payload,
  disabled,
  onChange,
  port,
}) => {
  const { getDirectory } = useDirectory();

  const selectedPortId = port
      ? (payload.portFields[port.name]?.[field.name] as DirectoryNodeDirectoryField | undefined)?.directoryId
      : (payload.fields[field.name] as DirectoryNodeDirectoryField)?.directoryId;

  const items = useMemo(() => {
    return getDirectory(field.directoryName)
      .getAll()
      .filter(record => !(record.ignored && (record.id !== selectedPortId)))
      .map(record => ({
        id: record.id,
        name: record.name.en,
      }));
  }, [field.directoryName, getDirectory]);

  const handleChange = useCallback(
    (directoryId: string) => {
      if (port) {
        const updatedPayload: DirectoryNodeModelPayload = {
          ...payload,
          portFields: {
            ...payload.portFields,
            [port.name]: {
              ...payload.portFields[port.name],
              [field.name]: { directoryId },
            },
          },
        };
        onChange(updatedPayload);
      } else {
        const updatedPayload: DirectoryNodeModelPayload = {
          ...payload,
          fields: {
            ...payload.fields,
            [field.name]: { directoryId },
          },
        };
        onChange(updatedPayload);
      }
    },
    [field.name, onChange, payload, port]
  );

  return (
    <S.ContentItem>
      <S.ContentItemLabel>{field.displayName.en}</S.ContentItemLabel>
      <InputSelect selected={selectedPortId} options={items} onChange={handleChange} disabled={disabled} />
    </S.ContentItem>
  );
};
