import { DefaultDisposed, EnvironmentalLinkSet, Graph } from './Graph';
import { Node, NodeType } from '../node/Node';
import { Link } from '../Link';
import { Rectangle } from '@projectstorm/geometry';

export class SimpleNodesSubGraph implements Graph {
  private parentGraph: Graph;
  private disposed: DefaultDisposed;
  private linkSet: EnvironmentalLinkSet;

  constructor(parentGraph: Graph) {
    this.parentGraph = parentGraph;
    this.linkSet = new EnvironmentalLinkSet(this, parentGraph);
    this.disposed = new DefaultDisposed(this);
  }

  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    return this.parentGraph.getNodes().filter((node) => node.getType() === NodeType.OTHER);
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}
