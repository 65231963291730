import styled from 'styled-components';
import { Styled as StyledForm } from './CardContent.styled';
import { Styled as StyledInputField } from '../../../../../widgets/InputField.styled';
import { Styled as StyledTextArea } from '../../../../../widgets/TextArea.styled';

const DescriptionFieldForm = styled(StyledForm.Form)`
  display: flex;

  ${StyledTextArea.TextArea} {
    width: 507px;
    height: 48px;
  }

  & p {
    font-weight: 700;
    cursor: pointer;
    background: ${(props) => props.theme.colors.white};
    margin-left: -80px;
    margin-top: 25px;
    height: 20px;
    z-index: 2000;
  }
`;

const DescriptionArrow = styled.div`
  visibility: hidden;

  &::before {
    position: absolute;
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.colors.white};

    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const Description = styled.div`
  width: 546px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  box-shadow: 0 0 20px 10px rgba(63, 63, 63, 0.1);
  border-radius: 16px;
  padding: 20px;
  text-align: justify;

  &[data-placement^='top'] {
    ${DescriptionArrow} {
      bottom: 10px;
    }
  }

  &[data-placement^='bottom'] {
    ${DescriptionArrow} {
      top: -10px;
    }
  }
`;

export const Styled = {
  Description,
  DescriptionArrow,
  DescriptionFieldForm,
  InputLabel: StyledInputField.InputLabel,
};
