import styled from 'styled-components';

const Image = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
`;

const Label = styled.div<{ height: number; width: number; yCenter: boolean }>`
  position: absolute;
  left: 0;
  top: 0;

  font-family: ${(p) => p.theme.fonts.gost};
  font-size: 10px;
  line-height: 12px;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  display: flex;
  flex-direction: column;
  justify-content: ${({ yCenter }) => (yCenter ? 'center' : 'start')};
  align-items: center;
`;

export const Styled = {
  Image,
  Label,
};
