import { DefaultDisposed, EnvironmentalLinkSet, Graph } from '../Graph';
import { Link } from '../../Link';
import { Node } from '../../node/Node';
import { Rectangle } from '@projectstorm/geometry';
import { Busses } from './Busses';

export class BusLocalsOrderGraph implements Graph {
  private bus: Node;
  private busLocalsMap: Map<Node, Graph>;
  private busses: Busses;
  private disposed: DefaultDisposed;
  private linkSet: EnvironmentalLinkSet;

  constructor(bus: Node, busLocalsMap: Map<Node, Graph>, busses: Busses) {
    this.bus = bus;
    this.busLocalsMap = busLocalsMap;
    this.busses = busses;
    this.disposed = new DefaultDisposed(this);
    this.linkSet = new EnvironmentalLinkSet(this, busLocalsMap.get(bus)!);
  }

  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    const busLocals = this.busLocalsMap.get(this.bus)!;
    const busLocalsLinks = busLocals.getLinks();
    const nodeId = this.bus.getID();

    let defaultIndex = -1;

    const busRows = this.busses.getBusRows();
    for (let i = 0, n = busRows.length; i < n; i++) {
      const busRow = busRows[i];
      for (let j = 0, m = busRow.length; j < m; j++) {
        if (busRow[j].getID() === this.bus.getID()) {
          defaultIndex = i > 0 ? m - j : j;
        }
      }
    }

    return busLocalsLinks
      .filter((link) => link.getSourceNode().getID() === nodeId || link.getTargetNode().getID() === nodeId)
      .map((link) => (link.getTargetNode().getID() === nodeId ? link.getSourceNode() : link.getTargetNode()))
      .sort((n1, n2) => this.getBusIndexForNode(n2, defaultIndex) - this.getBusIndexForNode(n1, defaultIndex));
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }

  private getBusIndexForNode(node: Node, defaultIndex: number) {
    const busRows = this.busses.getBusRows();
    for (let i = 0, n = busRows.length; i < n; i++) {
      const busRow = busRows[i];
      for (let j = 0, m = busRow.length; j < m; j++) {
        const bus = busRow[j];
        if (bus.getID() === this.bus.getID()) {
          continue;
        }

        const busLocals = this.busLocalsMap.get(bus)!;
        const includes = busLocals.getNodes().some((busNode) => busNode.getID() === node.getID());
        if (includes) {
          console.debug('getBusIndexForNode', (node as any).origin.model.payload.operationName, defaultIndex, j, n);
          return i > 0 ? m - j : j;
        }
      }
    }

    return defaultIndex;
  }
}
