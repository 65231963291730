import React, { useMemo, useRef } from 'react';
import { Styled as S } from './LnComposition.styled';
import { IcdReadDto } from '../../../../../../api/nggrace-back';
import { LnCompositionTree } from './LnCompositionTreeLnTemplate';
import { LnCompositionTreeDataObjectFactory } from './LnCompositionTreeDataObject';
import { TreeNodeModel, TreeNodeState } from '../../../../../widgets/tree/state/TreeState';
import { TreeNode, TreeNodeChildFactory } from './widget/TreeNode';
import { LnCompositionTreeDataAttributeFactory } from './LnCompositionTreeDataAttribute';

export interface LogicalNodeTreeModel extends TreeNodeState {
  getClazz(): string;
  getCommonDataClass(): string;
  getDescription(): string;
  getType(): string;
  getBType(): string;
  getFc(): string;
}

export interface LogicalNodeTreeNodeModel extends TreeNodeModel {
  getClazz(): string;
  getCommonDataClass(): string;
  getDescription(): string;
  getType(): string;
  getBType(): string;
  getFc(): string;
}

export const stringValueFormatter = (stringValue: string) => stringValue.substring(0, 10);

interface LnCompositionProps {
  icdFileContent: IcdReadDto;
  onlyLogicalNodeType?: string;
  handleScrollChange: (scrollTop: number, scrollHeight: number) => void;
}

export const LnComposition: React.FC<LnCompositionProps> = ({
  icdFileContent,
  handleScrollChange,
  onlyLogicalNodeType,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    const scrollTop = containerRef.current!.scrollTop;
    const scrollHeight = containerRef.current!.scrollHeight - containerRef.current!.clientHeight;
    handleScrollChange(scrollTop, scrollHeight);
  };

  const logicalNodeComposition = useMemo(() => {
    return icdFileContent.logicalNodeComposition!.logicalNodeTemplates.map((lnTemplate) =>
      LnCompositionTree(
        lnTemplate,
        LnCompositionTreeDataObjectFactory(
          icdFileContent.logicalNodeComposition!.dataObjectTemplates,
          LnCompositionTreeDataAttributeFactory()
        )
      )
    );
  }, [icdFileContent]);

  const dataAttributeSubDataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
    <TreeNode
      model={model}
      indentLevel={indentLevel + 1}
      childFactory={dataObjectFactory}
      last={last}
      showInLd={showInLd}
    />
  );

  const dataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
    <TreeNode
      model={model}
      indentLevel={indentLevel + 1}
      childFactory={dataAttributeSubDataObjectFactory}
      last={last}
      showInLd={showInLd}
    />
  );

  const lnTemplateFactory: TreeNodeChildFactory = ({ model, indentLevel, last, showInLd }) => (
    <TreeNode
      model={model}
      indentLevel={indentLevel + 1}
      childFactory={dataObjectFactory}
      last={last}
      showInLd={showInLd}
    />
  );

  return (
    <S.Container ref={containerRef} onScroll={onScroll}>
      <S.Header>
        <S.ColumnName>Name</S.ColumnName>
        <S.ColumnName>Class</S.ColumnName>
        <S.ColumnName>CDC</S.ColumnName>
        <S.ColumnName>Description</S.ColumnName>
        <S.ColumnName>Type</S.ColumnName>
        <S.ColumnName>bType</S.ColumnName>
        <S.ColumnName>Value</S.ColumnName>
      </S.Header>
      {logicalNodeComposition
        .filter((model) => (onlyLogicalNodeType ? model.getKey() === onlyLogicalNodeType : true))
        .map((model) => (
          <TreeNode
            model={model}
            childFactory={lnTemplateFactory}
            hasParent={false}
            indentLevel={0}
            last
            initialOpen
            showInLd={false}
          />
        ))}
    </S.Container>
  );
};
