import { BaseModel, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { HasName } from '../building/HasName';
import { BaseRackModel, Rack, RackHasRelativeRoomFactory } from './Rack';
import { RackHasChildrenFactory, SingleRackModel } from './SingleRackModel';
import { HasRelativeModel } from '../../placeholder/HasRelativeModel';
import { RoomModel } from '../building/RoomModel';
import { ZoneModel } from '../zone/ZoneModel';
import { HasChildren } from '../../placeholder/HasChildren';
import { LanNodeModel } from '../node/LanNodeModel';
import { LanEngine } from '../LanEngine';
import { Removable } from '../Removable';

export const CompositeRackModelType = 'composite-rack';

export class CompositeRackModel extends BaseModel implements Rack, HasChildren<LanNodeModel>, Removable {
  private readonly origin: BaseRackModel;
  private readonly hasName: HasName;
  private readonly hasRelativeRoom: HasRelativeModel<RoomModel>;
  private racks: { [key: string]: SingleRackModel } = {};
  private deserializedNodeIds: string[] | undefined;

  constructor(
    hasChildrenFactory: RackHasChildrenFactory,
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory
  ); // deserialization
  constructor(
    hasChildrenFactory: RackHasChildrenFactory,
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory,
    zones: ZoneModel[],
    engine: LanEngine
  );
  constructor(
    hasChildrenFactory: RackHasChildrenFactory,
    hasName: HasName,
    hasRelativeRoomFactory: RackHasRelativeRoomFactory,
    zones?: ZoneModel[],
    engine?: LanEngine
  ) {
    super({ type: CompositeRackModelType });
    const eventDelegate = this as any;
    this.hasName = hasName;
    this.hasRelativeRoom = hasRelativeRoomFactory(this);
    this.origin = new BaseRackModel(hasChildrenFactory(eventDelegate as any), hasName, this.hasRelativeRoom);

    if (zones && engine) {
      this.initRacks(zones || [], engine);
    }
  }

  getChildren(): LanNodeModel[] {
    return Object.values(this.racks).flatMap((rack) => rack.getChildren());
  }

  addChild(childToAdd: LanNodeModel, index?: number): void {
    const childZone = childToAdd.getRelativeZone();
    if (childZone === undefined) {
      throw new Error(
        'to add node into composite rack use compositeRack.getRacks().find(...).addChild(node),' +
          ' otherwise at least add relativeZone to node'
      );
    }

    this.racks[childZone.getID()].addChild(childToAdd, index);
  }

  asComposite(): CompositeRackModel | undefined {
    return this;
  }

  asSingle(): SingleRackModel | undefined {
    return undefined;
  }

  getFullName(): string {
    return this.origin.getFullName();
  }

  getName(): string {
    return this.origin.getName();
  }

  serialize() {
    return {
      ...super.serialize(),
      name: this.getName(),
      room: this.hasRelativeRoom.getRelativeModel()?.getID(),
      nodes:
        this.deserializedNodeIds ||
        Object.values(this.racks)
          .flatMap((rack) => rack.getChildren())
          .map((child) => child.getID()),
      zones: Object.keys(this.racks),
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);

    //we need to save child nodes here, because immediately after deserialization will be serialization for undo/redo
    //and we have to save nodes in serialized undo/redo model
    this.deserializedNodeIds = event.data.nodes;

    Promise.all(event.data.zones.map((zoneId) => event.getModel<ZoneModel>(zoneId)))
      .then((zones) => {
        this.initRacks(zones, event.engine as LanEngine);
      })
      .then(() => {
        return Promise.all(event.data.nodes.map((node) => event.getModel<LanNodeModel>(node)));
      })
      .then((nodes) => {
        nodes.forEach((node) => this.racks[node.getRelativeZone()!.getID()].addChild(node));
        this.deserializedNodeIds = undefined;
      });
    event.registerModel(this);
  }

  setRoom(room: RoomModel, index?: number): void {
    this.origin.setRoom(room);
  }

  getRacks() {
    return Object.values(this.racks);
  }

  remove() {
    this.getRacks().map((rack) => rack.remove());
    super.remove();
  }

  notCascadeRemove() {
    super.remove();
  }

  canRemove() {
    return !this.isLocked() && !this.getChildren().some((child) => !child.canRemove());
  }

  private initRacks(zones: ZoneModel[], engine: LanEngine) {
    this.racks = zones.reduce((result: { [key: string]: SingleRackModel }, zone) => {
      result[zone.getID()] = engine
        .getSingleRackFactory()
        .createNewModel(this.hasName, zone, () => this.hasRelativeRoom);
      return result;
    }, {});
  }

  getSelectionEntities(): Array<BaseModel> {
    return [this, ...this.getRacks()];
  }
}
