import React, { PropsWithChildren, useCallback } from 'react';

import { Icon } from '../../../../../../../../widgets/Icon';
import { Styled as S } from './Tree.styled';

export interface TreeNodeProps {
  title?: string;
  highlight?: boolean;
  canHighlight?: boolean;
  hasParent?: boolean;
  last: boolean;
  indentLevel: number;
  open: boolean;
  handleSelected: (event: React.MouseEvent) => void;

  toggleOpen(): void;
}

export const TreeNodeWidget: React.FC<PropsWithChildren<TreeNodeProps>> = ({
  highlight,
  title,
  canHighlight,
  children,
  hasParent = true,
  indentLevel,
  last,
  open,
  handleSelected,
  toggleOpen,
}) => {
  const handleToggleClick = useCallback(
    (event: React.MouseEvent) => {
      toggleOpen();
      event.stopPropagation();
    },
    [toggleOpen]
  );

  return (
    <S.TreeNode indentLevel={indentLevel}>
      <S.Header
        highlight={highlight}
        canHighlight={canHighlight}
        hasParent={hasParent}
        last={last}
        indentLevel={indentLevel}
      >
        <S.Indent level={indentLevel} />
        {children ? (
          <S.Toggle
            open={open}
            onClick={handleToggleClick}
            hasParent={hasParent}
            indentLevel={indentLevel}
            highlight={highlight}
          >
            <Icon name={'toggle-arrow'} />
          </S.Toggle>
        ) : (
          <S.EmptyToggle hasParent={hasParent} indentLevel={indentLevel} highlight={highlight} />
        )}
        <S.Title onClick={canHighlight ? handleSelected : () => {}}>{title}</S.Title>
      </S.Header>
      <S.SubTree>{open && children}</S.SubTree>
    </S.TreeNode>
  );
};
