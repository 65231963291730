import React, { PropsWithChildren, useCallback } from 'react';
import { Direction } from '../../../api/nggrace-back';
import { List } from '../../widgets/List.styled';
import { ListWithoutMargin } from './ListWithoutMargin.styled';

interface SortCellProps<T extends any> {
  type: T;
  direction?: Direction;

  onSort(property: T, direction: Direction): void;
}

export const SortCellWithoutMargin = <T extends any>({
  type,
  direction,
  onSort,
  children,
}: PropsWithChildren<SortCellProps<T>>) => {
  const handleSort = useCallback(() => {
    onSort(type, direction === 'ASC' ? 'DESC' : 'ASC');
  }, [onSort, type, direction]);

  return (
    <ListWithoutMargin.SortCell asc={direction === 'ASC'} desc={direction === 'DESC'} onClick={handleSort}>
      <List.Content>{children}</List.Content>
    </ListWithoutMargin.SortCell>
  );
};
