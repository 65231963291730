import { DefaultDisposed, EnvironmentalLinkSet, Graph, LinkSet } from '../Graph';
import { Link } from '../../Link';
import { Node, NodeType } from '../../node/Node';
import { Rectangle } from '@projectstorm/geometry';
import { Mapping } from '../../mapping/Mapping';

export class BusLocals implements Graph {
  private bus: Node;
  private environment: Graph;
  private busWithTrAndNeighbourhood: Graph;
  private disposed: DefaultDisposed;
  private linkSet: LinkSet;
  private pathMapping: Mapping<{ start: Node; end: Node }, Graph>;

  constructor(
    bus: Node,
    busWithTrAndNeighbourhood: Graph,
    environment: Graph,
    pathMapping: Mapping<{ start: Node; end: Node }, Graph>
  ) {
    this.bus = bus;
    this.busWithTrAndNeighbourhood = busWithTrAndNeighbourhood;
    this.environment = environment;
    this.pathMapping = pathMapping;
    this.disposed = new DefaultDisposed(this);
    this.linkSet = new EnvironmentalLinkSet(this, environment);
  }

  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    const disposedNodes = this.busWithTrAndNeighbourhood.getNodes();
    return this.environment
      .getNodes()
      .filter((node) => !disposedNodes.find((disposedNode) => disposedNode.getID() === node.getID()))
      .filter((node) => node.getType() !== NodeType.BUS && node.getType() !== NodeType.TR)
      .filter((node) => {
        const path = this.pathMapping.map({ start: this.bus, end: node }).getNodes();
        return (
          path.length &&
          (path.length === 2 ||
            !path
              .slice(1, -1)
              .some((pathElement) => pathElement.getType() === NodeType.BUS || pathElement.getType() === NodeType.TR))
        );
      });
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}
