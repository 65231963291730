import { Graph } from '../Graph';
import { Busses } from './Busses';
import { Node } from '../../node/Node';
import { Link } from '../../Link';
import { TranslatedNode } from '../../node/TranslatedNode';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { RotatedNode } from '../../node/RotatedNode';
import { BasePoint } from '../../../geometry/Point';
import { DefaultSmartRotationHour, RotationHour } from '../../../geometry/RotationHour';
import { Mapping } from '../../mapping/Mapping';

export class WholeSchema implements Graph {
  private busses: Busses;
  private busMapping: Mapping<Node, Graph>;

  constructor(busses: Busses, busMapping: Mapping<Node, Graph>) {
    this.busses = busses;
    this.busMapping = busMapping;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const busOffset = 200;
    const rowsOffset = 70;
    const busRows = this.busses.getBusRows();

    return busRows.flatMap((row, rowIndex) => {
      let xCumulativeOffset = 0;
      if (rowIndex === 0) {
        return row.flatMap((bus) => {
          const prevOffset = xCumulativeOffset;
          const mappedGraph = this.busMapping.map(bus);
          const mappedGraphRect = Polygon.boundingBoxFromPolygons(mappedGraph.getNodes().map((node) => node.getRect()));
          const graphWidth = mappedGraphRect.getWidth();
          xCumulativeOffset += graphWidth + busOffset;

          return mappedGraph
            .getNodes()
            .map((node) => new TranslatedNode(node, new BasePoint(prevOffset + graphWidth / 2, 0)));
        });
      }

      //FIXME added 0 in case of zero busses at row
      const firstRowHeight = Math.max(...busRows[0].map((bus) => this.busMapping.map(bus).getRect().getHeight()), 0);
      const secondRowHeight = Math.max(...busRows[1].map((bus) => this.busMapping.map(bus).getRect().getHeight()), 0);
      const totalHeight = firstRowHeight + secondRowHeight;
      return row.flatMap((bus) => {
        const busWithTrAndBranches = this.busMapping.map(bus);
        const rect = busWithTrAndBranches.getRect();
        const prevOffset = xCumulativeOffset;
        const nodesWidth = Polygon.boundingBoxFromPolygons(
          busWithTrAndBranches.getNodes().map((node) => node.getRect())
        ).getWidth();
        xCumulativeOffset += nodesWidth + busOffset;
        return busWithTrAndBranches
          .getNodes()
          .map(
            (node) =>
              new TranslatedNode(
                new RotatedNode(node, new DefaultSmartRotationHour(RotationHour.SIX), new BasePoint(rect.getOrigin())),
                new BasePoint(prevOffset + nodesWidth / 2, rowsOffset + totalHeight - rect.getHeight())
              )
          );
      });
    });
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}
