import styled from 'styled-components';
import { List as L } from '../../widgets/List.styled';

const Cell = styled(L.Cell)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const HeaderCell = styled(L.HeaderCell)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const FilterCell = styled(L.FilterCell)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const SortCell = styled(L.SortCell)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const CellWithLongContent = styled(L.CellWithLongContent)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const ListWithoutMargin = {
  Cell,
  HeaderCell,
  SortCell,
  FilterCell,
  CellWithLongContent,
};
