import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { theme } from '../../theme';
import { ToolbarItem } from './Toolbar';
import ReactModal from 'react-modal';
import { IconName } from '../widgets/Icon';

interface ToolbarModalContextType {
  show(): void;

  hide(): void;
}

const defaultValue: ToolbarModalContextType = {
  show: () => {},
  hide: () => {},
};

const ToolbarModalContext = createContext<ToolbarModalContextType>(defaultValue);

export const useToolbarModal = (): ToolbarModalContextType => {
  return useContext(ToolbarModalContext);
};

type ToolbarModalItemProviderProps = {
  icon: IconName;
  disabled?: boolean;
  active?: boolean;
  hint: string;
};

export const ToolbarModalProvider: React.FC<ToolbarModalItemProviderProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = useCallback(() => setShowModal(true), []);
  const handleHide = useCallback(() => setShowModal(false), []);

  const contextValue: ToolbarModalContextType = useMemo(() => {
    return {
      show: handleShow,
      hide: handleHide,
    };
  }, [handleHide, handleShow]);

  return (
    <ToolbarModalContext.Provider value={contextValue}>
      <ToolbarItem {...props} onClick={handleShow}>
        <ReactModal isOpen={showModal} onRequestClose={handleHide} style={theme.modal}>
          {props.children}
        </ReactModal>
      </ToolbarItem>
    </ToolbarModalContext.Provider>
  );
};
