import React, { useCallback, useMemo } from 'react';
import { LibraryAccordion } from '../../widgets/library/Library';
import { useDirectory } from '../../hooks/useDirectory';
import {
  LanNodeDirectoryEntry,
  NetworkDeviceDirectoryEntry,
  PropertiesDirectoryEntry,
  PropertiesDirectoryName,
} from '../../editor/directory/PropertiesDirectory';
import { ProjectDto } from '../../../api/nggrace-back';
import {
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../widgets/tree/state/TreeState';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { TreeDraggable } from '../../widgets/tree/TreeDraggable';
import { Styled as S } from '../../widgets/library/Library.styled';
import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../widgets/tree/state/TreeBaseModel';
import { Tree } from '../../widgets/tree/Tree';
import { LanStructureTree, LanTreeDndFormatDragId } from './tree/LanStructureTree';
import { LanConfigurationProvider } from './tree/LanConfigurationContext';
import { Directory } from '../../editor/directory/Directory';
import { TreeNode, TreeNodeChildFactory } from '../../widgets/tree/TreeNode';

interface LanLibraryProps {
  project: ProjectDto;
  locked: boolean;
}

export const LanLibrary: React.FC<LanLibraryProps> = ({ project, locked }) => {
  const {
    switchVendorDirectory,
    switchDirectory,
    networkDeviceTypeDirectory,
    networkDeviceVendorDirectory,
    networkDeviceDirectory,
  } = useDirectory();

  const switchGroup = useMemo(() => SwitchGroupTreeNodeState(switchVendorDirectory, switchDirectory), [
    switchDirectory,
    switchVendorDirectory,
  ]);

  const networkDeviceGroups = useMemo(
    () =>
      NetworkDeviceGroupsTreeNodeState(
        networkDeviceTypeDirectory,
        networkDeviceVendorDirectory,
        networkDeviceDirectory
      ),
    [networkDeviceDirectory, networkDeviceTypeDirectory, networkDeviceVendorDirectory]
  );

  const switchVendorFactory = useMemo(() => DirectoryVendorFactory('SwitchDirectory', locked), [locked]);
  const networkDeviceVendorFactory = useMemo(() => DirectoryVendorFactory('NetworkDeviceDirectory', locked), [locked]);

  return (
    <LibraryAccordion>
      <DndProvider backend={HTML5Backend}>
        <LanConfigurationProvider>
          <LanStructureTree station={project.stationName} />
        </LanConfigurationProvider>
        <S.Library>
          <S.Header>{'Library IED/MU'}</S.Header>
          <Tree>
            <BaseLibraryItemWidget locked={locked} type={TreeDraggableType.Building} name="New building" />
            <BaseLibraryItemWidget locked={locked} type={TreeDraggableType.Room} name="New room" />
            <BaseLibraryItemWidget locked={locked} type={TreeDraggableType.Rack} name="New rack" />
            <TreeNode model={switchGroup} hasParent={false} childFactory={switchVendorFactory} indentLevel={0} last />
            {networkDeviceGroups.map((networkDeviceGroup) => (
              <TreeNode
                key={networkDeviceGroup.getKey()}
                model={networkDeviceGroup}
                hasParent={false}
                childFactory={networkDeviceVendorFactory}
                indentLevel={0}
                last
              />
            ))}
          </Tree>
        </S.Library>
      </DndProvider>
    </LibraryAccordion>
  );
};

const SwitchGroupTreeNodeState = (
  vendorDirectory: Directory<PropertiesDirectoryEntry>,
  directory: Directory<LanNodeDirectoryEntry>
): TreeNodeState => {
  return DirectoryGroupTreeNodeState('Switch', vendorDirectory, directory.getAll());
};

const NetworkDeviceGroupsTreeNodeState = (
  typeDirectory: Directory<PropertiesDirectoryEntry>,
  vendorDirectory: Directory<PropertiesDirectoryEntry>,
  directory: Directory<NetworkDeviceDirectoryEntry>
): TreeNodeState[] => {
  return Object.entries(
    directory.getAll().reduce((map, entry) => {
      if (!map[entry.deviceType]) {
        map[entry.deviceType] = [];
      }
      map[entry.deviceType].push(entry);
      return map;
    }, {} as { [deviceType: string]: LanNodeDirectoryEntry[] })
  ).map((entry) => DirectoryGroupTreeNodeState(typeDirectory.getEntry(entry[0]).name.en, vendorDirectory, entry[1]));
};

const DirectoryGroupTreeNodeState = (
  name: string,
  vendorDirectory: Directory<PropertiesDirectoryEntry>,
  entries: LanNodeDirectoryEntry[]
): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => name,
    getKey: () => name,
    getChildren: () =>
      Object.entries(
        entries.reduce((map, entry) => {
          if (!map[entry.vendorId]) {
            map[entry.vendorId] = [];
          }
          map[entry.vendorId].push(entry);
          return map;
        }, {} as { [vendorId: string]: LanNodeDirectoryEntry[] })
      ).map((entry) => DirectoryVendorTreeNodeState(vendorDirectory.getEntry(entry[0]), entry[1])),
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const DirectoryVendorTreeNodeState = (
  vendorEntry: PropertiesDirectoryEntry,
  entries: LanNodeDirectoryEntry[]
): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => vendorEntry.name.en,
    getKey: () => vendorEntry.id,
    getChildren: () => entries.map(DirectoryTreeNodeState),
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const DirectoryTreeNodeState = (entry: LanNodeDirectoryEntry): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => entry.name.en,
    getKey: () => entry.id,
    getChildren: () => [],
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};

const DirectoryVendorFactory = (directory: PropertiesDirectoryName, locked: boolean): TreeNodeChildFactory => ({
  model,
  indentLevel,
  last,
}) => {
  return (
    <TreeNode
      key={model.getKey()}
      model={model}
      childFactory={DirectoryFactory(directory, locked)}
      indentLevel={indentLevel + 1}
      last={last}
    />
  );
};

const DirectoryFactory = (directory: PropertiesDirectoryName, locked: boolean): TreeNodeChildFactory => ({
  model,
  indentLevel,
  last,
}) => {
  if (locked) {
    return <TreeNode key={model.getKey()} model={model} indentLevel={indentLevel + 1} last={last} />;
  }

  const dragElementCallback = () => undefined;
  const payload: TreeDraggablePayload = {
    type: TreeDraggableType.LanNode,
    directoryId: model.getKey(),
    directory: directory,
  };

  return (
    <TreeDraggable
      key={model.getKey()}
      getDragElement={dragElementCallback}
      formatId={LanTreeDndFormatDragId[TreeDraggableType.LanNode]!}
      payload={payload}
    >
      <TreeNode model={model} indentLevel={indentLevel + 1} last={last} />
    </TreeDraggable>
  );
};

interface NameLibraryItemWidgetProps {
  type: TreeDraggableType;
  name: string;
  locked: boolean;
}

const BaseLibraryItemWidget = ({ type, name, locked }: NameLibraryItemWidgetProps) => {
  const dragElementCallback = useCallback(() => undefined, []);
  const payload = useMemo(() => ({ type }), [type]);
  const model = BaseLibraryItemTreeNodeState(name);

  if (locked) {
    return <TreeNode model={model} hasParent={false} indentLevel={0} last />;
  }

  return (
    <TreeDraggable getDragElement={dragElementCallback} formatId={LanTreeDndFormatDragId[type]!} payload={payload}>
      <TreeNode model={model} hasParent={false} indentLevel={0} last />
    </TreeDraggable>
  );
};

const BaseLibraryItemTreeNodeState = (name: string): TreeNodeState => {
  const model: TreeNodeModel = {
    getName: () => name,
    getKey: () => name,
    getChildren: () => [],
    onChildrenChanged: () => () => {},
  };
  return {
    ...model,
    ...NotSelectableModel(),
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
  };
};
