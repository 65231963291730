import { BaseModel, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { DefaultHasChildren, HasChildren } from '../../placeholder/HasChildren';
import { DefaultHasName, HasName } from './HasName';
import { RoomModel } from './RoomModel';

export const BuildingModelType = 'building';

export class BuildingModel extends BaseModel implements HasChildren<RoomModel>, HasName {
  private hasChildren: HasChildren<RoomModel>;
  private hasName: HasName;

  constructor(hasName: HasName) {
    super({ type: BuildingModelType });
    this.hasName = hasName;
    this.hasChildren = new DefaultHasChildren<RoomModel>(this as any);
  }

  addChild(childToAdd: RoomModel, index?: number): void {
    this.hasChildren.addChild(childToAdd, index);
  }

  getChildren(): RoomModel[] {
    return this.hasChildren.getChildren();
  }

  getName(): string {
    return this.hasName.getName();
  }

  serialize() {
    return {
      ...super.serialize(),
      name: this.getName(),
      rooms: this.getChildren().map((child) => child.serialize()),
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.data.rooms.forEach((room) => {
      const model = new RoomModel(new DefaultHasName(room.name));
      model.deserialize({ ...event, data: room });
      model.setRelativeModel(this);
    });
  }

  getSelectionEntities(): Array<BaseModel> {
    return [...super.getSelectionEntities(), ...this.getChildren()];
  }

  remove() {
    super.remove();
    this.getChildren().forEach((child) => child.remove());
  }

  canRemove() {
    return !this.isLocked() && !this.getChildren().some((child) => !child.canRemove());
  }
}
