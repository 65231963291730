import { DeserializeEvent, Toolkit } from '@projectstorm/react-canvas-core';
import { NodeLayerModel, NodeLayerModelGenerics } from '@projectstorm/react-diagrams';

export class DefaultNodeLayerModel<
  G extends NodeLayerModelGenerics = NodeLayerModelGenerics
> extends NodeLayerModel<G> {
  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }
}
