import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useRoutes } from './hooks/useRoutes';
import { useUser } from '../components/pages/login/UserContext';

interface PrivateRouteProps {
  component: React.FC;
  path: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, path }) => {
  const user = useUser()!.user;
  const { loginPath } = useRoutes();

  return user ? <Route path={path} component={component} /> : <Redirect to={loginPath} />;
};
