import React, { useEffect, useState } from 'react';
import { LanPortModel } from './port/LanPortModel';
import { theme } from '../../../../theme';
import { LanPortWidget } from './port/LanPortWidget';
import { LanEngine } from '../LanEngine';
import { LanNodeModel } from './LanNodeModel';

const borderWidth = 1;
export const LanStrokeWidth = borderWidth;

export interface LanNodeWidgetProps {
  node: LanNodeModel;
  engine: LanEngine;
}

export const LanNodeWidget: React.FC<LanNodeWidgetProps> = React.memo(({ node, engine }) => {
  const size = node.getSize();
  const [selected, setSelected] = useState(node.isSelected());
  useEffect(() => {
    setSelected(node.isSelected());
    return node.registerListener({
      selectionChanged: () => setSelected(node.isSelected()),
    } as any).deregister;
  }, [setSelected, node]);
  return (
    <>
      <svg height={size.y} width={size.x}>
        <SvgGroup>
          <rect
            x={borderWidth / 2}
            y={borderWidth / 2}
            width={size.x - borderWidth}
            height={size.y - borderWidth}
            stroke={selected ? 'deepskyblue' : 'black'}
          />
        </SvgGroup>
      </svg>
      {Object.values(node.getPorts()).map((port, index) => (
        <LanPortWidget
          port={port as LanPortModel}
          engine={engine}
          key={port.getID()}
          index={index}
          selected={selected}
        />
      ))}
    </>
  );
});

export const SvgGroup: React.FC = ({ children }) => {
  return (
    <g stroke={`${theme.colors.black}`} strokeWidth={borderWidth} fill="white">
      {children}
    </g>
  );
};
