import styled from 'styled-components';

const EditInfo = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fonts.onlyPrimary};
  color: black;
  font-weight: 700;
  font-size: 14px;
  line-height: 110%;

  & div {
    margin-right: 52px;
    & span {
      font-weight: 400;
      margin-left: 19px;
    }
  }
`;

export const Styled = {
  EditInfo,
};
