import * as React from 'react';
import styled from '@emotion/styled';
import { ConnectivityNodeModel, ConnectivityNodeSize } from './ConnectivityNodeModel';
import { ConnectivityPortWidget } from './ConnectivityPortWidget';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import { PropertiesDirectory, VoltageLevelDirectoryEntry } from '../directory/PropertiesDirectory';

export interface ConnectivityNodeWidgetProps {
  node: ConnectivityNodeModel;
  engine: DiagramEngine;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
}

const Node = styled.div<{ selected: boolean; size: number }>`
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;

  position: relative;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
`;

export const ConnectivityNodeWidget: React.FC<ConnectivityNodeWidgetProps> = ({
  node,
  engine,
  voltageLevelDirectory,
}) => {
  const color =
    node.getPayload().colorIds && node.getPayload().colorIds!.length !== 0
      ? voltageLevelDirectory.getEntry(node.getPayload().colorIds![0]).color
      : 'black';
  return (
    <Node
      selected={node.isSelected()}
      size={ConnectivityNodeSize}
      style={{ backgroundColor: `${node.isSelected() ? 'deepskyblue' : color}` }}
    >
      {Object.values(node.getPorts()).map((port) => (
        <ConnectivityPortWidget port={port} engine={engine} key={port.getID()} />
      ))}
    </Node>
  );
};
