import { DefaultDisposed, Graph } from './Graph';
import { Node } from '../node/Node';
import { Link } from '../Link';
import { Rectangle } from '@projectstorm/geometry';

export class ExcludingGraph implements Graph {
  private decreasing: Graph;
  private subtraction: Graph;
  private disposed: DefaultDisposed;

  constructor(decreasing: Graph, subtraction: Graph) {
    this.decreasing = decreasing;
    this.subtraction = subtraction;
    this.disposed = new DefaultDisposed(this);
  }

  getLinks(): Link[] {
    return this.decreasing
      .getLinks()
      .filter((link) => !this.nodeInSubtraction(link.getSourceNode()) && !this.nodeInSubtraction(link.getTargetNode()));
  }

  getNodes(): Node[] {
    return this.decreasing.getNodes().filter((node) => !this.nodeInSubtraction(node));
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }

  private nodeInSubtraction(nodeToCheck: Node): boolean {
    return !!this.subtraction.getNodes().find((node) => node.getID() === nodeToCheck.getID());
  }
}
