import styled, { css } from 'styled-components';
import { Styled as S } from '../../Sidebar.styled';

const ProjectSidebar = styled(S.Sidebar)`
  z-index: 98;
`;

const StageLink = styled(S.SidebarLink)<{ $active: boolean; disabled?: boolean; $completed?: boolean }>`
  ${(props) =>
    props.$active &&
    css`
      &::before {
        background: ${(props) =>
          `linear-gradient(180deg, ${props.theme.colors.turquoise} 0%, ${props.theme.colors.blue} 100%)`};
      }
    `};

  ${(props) =>
    props.$completed &&
    css`
      color: ${(props) => props.theme.colors.turquoise};
    `};

  &:hover {
    opacity: 1;
  }
`;

export const Styled = {
  ProjectSidebar,
  StageLink,
};
