import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Styled as S } from './TcoSettings.styled';
import { Feedback } from '../../widgets/Feedback';
import { TcoSettingsForm } from './TcoSettingsForm';
import { Api } from '../../../api/Api';
import { StageDto, TcoSettingsDto } from '../../../api/nggrace-back';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { getCurrency } from '../../../utils/project-utils';
import { SecondaryButton } from '../../widgets/SecondaryButton';

type TcoSettingsProps = {
  stage: StageDto;
  onClose(): void;
  onReloadStage?(): void;
};

export const TcoSettings: React.FC<TcoSettingsProps> = ({ stage, onClose, onReloadStage }) => {
  const { redirectToStagePage } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const update = useMemo(() => stage.baseInfo.type === 'TCO', [stage]);

  const project = stage.project;
  const [form, setForm] = useState<TcoSettingsDto>(() =>
    update
      ? stage.tcoSettings!!
      : {
          autoCurrencyExchangeEnabled: true,
          personPerHourCost: project.styleSelection === 'RU' ? 433 : 100,
          personPerHourCostFactor: 1,
          serviceFactor: 0.25,
          discountFactor: 0.08,
          inflationFactor: 0.05,
          numberOfTechnicalService: 6,
          billingPeriodInYears: 20,
        }
  );

  useEffect(() => {
    Api.getDefaultTcoSettings().then((response) => {
      setForm(response.data);
    });
  }, []);

  const handleSynthesisTco = useCallback(() => {
    setLoading(true);
    Api.createTcoStage(project.id, form, { omitInterceptorErrorModal: true })
      .then(() => {
        if (update) {
          onReloadStage!!();
          setLoading(false);
          onClose();
        } else {
          redirectToStagePage(project.id, 'TCO');
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  }, [form, onClose, onReloadStage, project.id, redirectToStagePage, update]);

  return (
    <ReactModal isOpen onRequestClose={onClose} style={theme.modal}>
      <S.Modal>
        <S.Header>TCO Settings</S.Header>
        <S.TcoMain>
          <S.Description>
            <p>You must select the parameters for TCO synthesis.</p>
          </S.Description>
          {error && <Feedback error text={error} />}
          <TcoSettingsForm currency={getCurrency(project.styleSelection)} form={form} onChange={setForm} />
        </S.TcoMain>
        <S.Footer>
          <SecondaryButton text={'Cancel'} onClick={onClose} />
          <S.BtnPrimary onClick={handleSynthesisTco} disabled={loading}>
            {!loading ? 'Synthesis' : 'Processing'}
          </S.BtnPrimary>
        </S.Footer>
      </S.Modal>
    </ReactModal>
  );
};
