import styled from 'styled-components';
import { Btn } from '../../../widgets/Btn.styled';
import { Styled as InputRadioGroupS } from '../../../widgets/InputRadioGroup.styled';

const ConfigurationModal = styled.form`
  padding: 32px 72px 72px 72px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${InputRadioGroupS.InputContainer} {
    flex-direction: column;
    align-items: start;
  }
`;

const Error = styled.div`
  display: flex;
  padding: 32px 0 22px 0;
`;

const Group = styled.div`
  display: flex;
  padding: 32px 0 22px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

const GroupTitle = styled.div`
  padding: 14px;
  flex-basis: 350px;
  min-width: 350px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 48px 0 0 0;
`;

const CreateBtn = styled(Btn)`
  height: 40px;
  width: 131px;
`;

export const Styled = {
  ConfigurationModal,
  Header,
  Content,
  Footer,
  Error,
  Group,
  GroupTitle,
  CreateBtn,
};
