import * as React from 'react';
import { Point } from '@projectstorm/geometry';
import { Size, sizeFontMapping } from '../../../lan/label/LanLabel';
import { LanLinkModel } from '../../../lan/link/LanLinkModel';

interface ExportLanLinkLabelProps {
  link: LanLinkModel;
  position: Point;
  size: Size;
}

export const ExportLanLinkLabel: React.FC<ExportLanLinkLabelProps> = ({ link, position, size }) => {
  const labelSize = sizeFontMapping[size];
  const label = link.getLabel();

  return (
    <text
      x={-position.x}
      y={-position.y}
      fontFamily="GOSTRUS"
      fontSize={`${labelSize}px`}
      writingMode={'vertical-lr'}
      transform={'rotate(180)'}
      textAnchor={'end'}
      dominantBaseline={'auto'}
      letterSpacing={'-0.5px'}
    >
      {label}
    </text>
  );
};
