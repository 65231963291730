import styled from 'styled-components';
import { Styled as StyledInputField } from '../../../../../widgets/InputField.styled';
import { Styled as StyledInputSelect } from '../../../../../widgets/InputSelect.styled';
import { Icon } from '../../../../../widgets/Icon.styled';
import { Styled as StyledForm } from './CardContent.styled';

const PhysicalInterfaceForm = styled(StyledForm.Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 507px;

  ${StyledInputField.InputField} {
    margin-bottom: 24px;
    opacity: ${(props) => (props.cardType === 'view' ? '1 !important' : 'inherit')};
  }

  ${StyledInputSelect.InputSelect}:first-of-type {
    width: 289px;
    margin-right: 11px;
  }

  ${StyledInputSelect.InputSelect}:first-of-type + ${StyledInputField.InputField} {
    width: ${(props) => (props.cardType === 'view' ? '207px' : '171px')};
  }

  ${Icon} {
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const Styled = {
  PhysicalInterfaceForm,
};
