import * as React from 'react';
import { ExportFormDto } from '../../../../../api/nggrace-back';
import { Frame } from '../Frame';
import { FrameTableRu } from './FrameTableRu';
import { FrameTableEu } from './FrameTableEu';
import { IProjectExport } from '../../types';

interface ExportFrameProps {
  dto: ExportFormDto;
  frame: Frame;
  project: IProjectExport;
}

export const FrameTable: React.VFC<ExportFrameProps> = ({ dto, frame, project }) => {
  return project.styleSelection === 'RU' ? (
    <g transform={`translate(${frame.size.width - 190},${frame.size.height - 60})`}>
      <FrameTableRu dto={dto} project={project} />
    </g>
  ) : (
    <g transform={`translate(${frame.size.width - 185},${frame.size.height - 70})`}>
      <FrameTableEu dto={dto} project={project} format={frame.size.format} />
    </g>
  );
};
