import React, { useCallback, useMemo, useState } from 'react';
import { InputField } from './InputField';
import { Styled as S } from './InputField.styled';
import { Icon, IconName } from './Icon';

type InputPasswordProps = {
  label?: string;
  value?: string | number;
  autoComplete?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  fitContent?: boolean;
  dataTestid?: string;
  checkPasswordFormat?: boolean;

  onChange(value?: string | number): void;
};

export const InputFieldPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  (
    {
      label,
      value,
      autoComplete,
      required,
      minLength,
      maxLength,
      fitContent,
      dataTestid,
      checkPasswordFormat,
      onChange,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const iconName = useMemo((): IconName => {
      return visible ? 'eye' : 'closed-eye';
    }, [visible]);
    const passwordPattern = '(^[A-Za-z\\d#^@$!%*?&]{15,}$)|(^(?=.*[a-z])(?=.*\\d)[A-Za-z\\d#^@$!%*?&]{8,}$)';
    const passwordPatternInfo =
      "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter.";
    const pattern = useMemo(() => {
      return checkPasswordFormat ? passwordPattern : undefined;
    }, [checkPasswordFormat]);
    const title = useMemo(() => {
      return checkPasswordFormat ? passwordPatternInfo : undefined;
    }, [checkPasswordFormat]);

    const handleVisibleClick = useCallback(() => {
      setVisible((visible) => !visible);
    }, []);

    return (
      <S.InputFieldPassword empty={!value}>
        <InputField
          ref={ref}
          autoComplete={autoComplete}
          type={visible ? 'text' : 'password'}
          pattern={pattern}
          title={title}
          value={value}
          label={label}
          onChange={onChange}
          minLength={minLength || 4}
          maxLength={maxLength || 64}
          required={required}
          fitContent={fitContent}
          dataTestid={dataTestid}
        />
        <Icon name={iconName} onClick={handleVisibleClick} />
      </S.InputFieldPassword>
    );
  }
);
