import * as React from 'react';
import { RackLayerModel } from './RackLayerModel';
import { LanEngine } from '../../LanEngine';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface RackLayerWidgetProps {
  layer: RackLayerModel;
  engine: LanEngine;
}

export const RackLayerWidget: React.FC<RackLayerWidgetProps> = ({ layer, engine }) => {
  const getRacks = useCallback(() => Object.values(layer.getModels()), [layer]);
  const [racks, setRacks] = useState(getRacks);

  useEffect(() => {
    setRacks(getRacks());
    return layer.registerListener({
      modelAdded: () => setRacks(getRacks()),
      modelRemoved: () => setRacks(getRacks()),
    }).deregister;
  }, [layer, getRacks]);

  const rackWidgets = useMemo(
    () =>
      racks.map((rack) =>
        engine
          .getRackFactories()
          .getFactory(rack.getType())
          .generateReactWidget({ model: rack } as any)
      ),
    [engine, racks]
  );
  return <>{rackWidgets}</>;
};
