import * as React from 'react';
import { useCallback, useState } from 'react';
import { Btn } from '../../widgets/Btn.styled';
import { Toolbar, ToolbarItem } from '../../toolbar/Toolbar';
import { StageDto } from '../../../api/nggrace-back';
import { TcoSettings } from './TcoSettings';
import { ToolbarModalProvider } from '../../toolbar/ToolbarModalContext';
import { TcoHelp } from './TcoHelp';

type TcoToolbarProps = {
  stage: StageDto;

  onRollbackStage(): void;
  onFinishStage(): void;
  onReloadStage(): void;
  onNext(): void;
};

export const TcoToolbar: React.FC<TcoToolbarProps> = ({
  stage,
  onRollbackStage,
  onFinishStage,
  onReloadStage,
  onNext,
}) => {
  const { finished } = stage.baseInfo;

  const [showSettings, setShowSettings] = useState(false);
  const handleSettingsClick = useCallback(() => setShowSettings(true), []);
  const handleSettingsClose = useCallback(() => setShowSettings(false), []);

  const nextBtn = (
    <Btn onClick={onNext} disabled={!finished}>
      Next
    </Btn>
  );

  return (
    <Toolbar title={stage.project.name} btn={nextBtn}>
      <ToolbarItem
        icon={finished ? 'lock-fill' : 'unlock'}
        active={finished}
        hint={'Ready flag'}
        onClick={finished ? onRollbackStage : onFinishStage}
      />
      <ToolbarItem icon={'gear'} hint={'Settings'} disabled={finished} onClick={handleSettingsClick}>
        {showSettings && <TcoSettings stage={stage} onClose={handleSettingsClose} onReloadStage={onReloadStage} />}
      </ToolbarItem>
      <ToolbarModalProvider icon={'question'} hint={'Help'}>
        <TcoHelp />
      </ToolbarModalProvider>
    </Toolbar>
  );
};
