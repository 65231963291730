import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserDto } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';

interface UserContextInterface {
  user: UserDto | undefined;
  setUser: (value: UserDto | undefined) => void;
  resetUser: () => void;
  loading: boolean;
}

const UserContext = React.createContext<UserContextInterface | undefined>(undefined);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserDto>();
  const [loading, setLoading] = useState(true);

  const loadCurrentUser = useCallback(() => {
    Api.getCurrentUser()
      .then((res) => setUser(res.data))
      .catch(() => setUser(undefined))
      .finally(() => setLoading(false));
  }, []);

  const resetUser = useCallback(() => {
    setUser(undefined);
    setLoading(true);
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        setUser,
        resetUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
