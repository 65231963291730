import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { BusPortModel } from './BusPortModel';
import { Point } from '@projectstorm/geometry';

export class RawBusPortModel extends BusPortModel {
  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    if (event.data.raw) {
      //TODO added due to incorrect ports naming on ngGrace side
      this.options.name = this.options.id!;
      this.position = new Point(0, 0);
      this.busOffset = 0;
    }
  }
}
