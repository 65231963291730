import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Direction,
  LogicalDeviceSortDto,
  LogicalDeviceSortProperty,
  LogicalDeviceViewDto,
  LogicalDeviceViewForListDto,
  UserBaseDto,
  VendorViewDto,
} from '../../../../../api/nggrace-back';
import { useNotifications } from '../../../../context/NotificationContext';
import { useDirectory } from '../../../../hooks/useDirectory';
import { Api } from '../../../../../api/Api';
import { hasCompaniesAccess, hasLibraryEditAccess } from '../../../../../utils/role-utils';
import { useUser } from '../../../login/UserContext';
import { formatMomentShort } from '../../../../../utils/date-utils';
import { Confirmation } from '../../../../widgets/Confirmation';
import { theme } from '../../../../../theme';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Styled as S } from './LogicalDeviceList.styled';
import { SortCell } from '../../../../widgets/SortCell';
import { EmptyList } from '../../../../widgets/EmptyList';
import { Icon } from '../../../../widgets/Icon';
import { ListWithoutMargin } from '../../ListWithoutMargin.styled';
import { List } from '../../../../widgets/List.styled';
import ReactModal from 'react-modal';
import { InputSelect } from '../../../../widgets/InputSelect';
import { CardType } from '../../hardware/card/HardwareCard';
import {
  LogicDeviceDirectoryEntry,
  PropertiesDirectory,
  VoltageLevelDirectoryEntry,
} from '../../../../editor/directory/PropertiesDirectory';
import { NodeDirectory } from '../../../../editor/directory/NodeDirectory';
import { LogicalDeviceCard } from './LogicalDeviceCard';

interface LogicalDeviceRowProps {
  logicalDevice: LogicalDeviceViewForListDto;
  userHasLibraryEditAccess: boolean;
  cardType: CardType;
  handleCardTypeChange: (type: CardType) => void;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;
  logicalDeviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>;
  deviceDirectory: NodeDirectory;
  remove: (ldId: number) => void;
  reload: () => void;
}

interface LogicalDeviceListState {
  logicalDevices: LogicalDeviceViewForListDto[];
  hasMore: boolean;
  page: number;
  size: number;
  vendorId?: number;
  editorId?: number;
  categoryId?: string;
  sortProperty: LogicalDeviceSortProperty;
  sortDirection: Direction;
  vendors: VendorViewDto[];
  editors: UserBaseDto[];
  categories: LogicDeviceDirectoryEntry[];
}

interface LogicalDeviceListProps {
  logicalDeviceCreationVisibility: boolean;
  hideLogicalDeviceCreation: () => void;
}

const formatVoltages = (ids: string[], voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>) => {
  return `${ids.map((id) => voltageLevelDirectory.getEntry(id).voltageInKilovolts).join('/')} kV`;
};

const formatPrimaryEquipmentNames = (ids: string[], deviceDirectory: NodeDirectory) => {
  return ids.map((id) => deviceDirectory.getEntry(id).name.en).join('/');
};

const formatCategoryNames = (id: string, logicalDeviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>) => {
  return logicalDeviceDirectory.getEntry(id).name.en;
};

export const CardTypeContext = React.createContext<CardType>('view');

export const LogicalDeviceList: React.FC<LogicalDeviceListProps> = ({
  logicalDeviceCreationVisibility,
  hideLogicalDeviceCreation,
}) => {
  const [logicalDevicesState, setLogicalDevicesState] = useState<LogicalDeviceListState>({
    logicalDevices: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'NAME',
    sortDirection: 'ASC',
    vendors: [],
    editors: [],
    categories: [],
  });
  const notifications = useNotifications();
  const { voltageLevelDirectory, logicDeviceDirectory, getNodeDirectory } = useDirectory();
  const deviceDirectory = getNodeDirectory('EU');
  const [isListEmpty, setListEmpty] = useState(true);
  const user = useUser()!.user!;
  const userAccessCompanies = useMemo(() => hasCompaniesAccess(user), [user]);
  const userHasLibraryEditAccess = useMemo(() => hasLibraryEditAccess(user), [user]);
  const [cardType, setCardType] = useState<CardType>('view');

  useEffect(() => {
    let cleanupFunction = false;
    Api.getAllVendors().then((response) => {
      if (!cleanupFunction) setLogicalDevicesState((state) => ({ ...state, vendors: response.data }));
    });
    Api.getAllUsers().then((response) => {
      if (!cleanupFunction) setLogicalDevicesState((state) => ({ ...state, editors: response.data }));
    });
    if (!cleanupFunction)
      setLogicalDevicesState((state) => ({
        ...state,
        categories: logicDeviceDirectory.getAll(),
      }));
    return () => {
      cleanupFunction = true;
    };
  }, [logicDeviceDirectory]);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: LogicalDeviceSortDto = {
        property: logicalDevicesState.sortProperty,
        direction: logicalDevicesState.sortDirection,
        vendorId: logicalDevicesState.vendorId,
        editorId: logicalDevicesState.editorId,
        categoryId: logicalDevicesState.categoryId,
      };
      Api.getLogicalDevices(sort, { page, size }).then((response) => {
        setLogicalDevicesState((state) => ({
          ...state,
          logicalDevices: reload
            ? response.data.content || []
            : state.logicalDevices.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
        setListEmpty(response.data.totalElements === 0);
      });
    },
    [
      logicalDevicesState.sortDirection,
      logicalDevicesState.sortProperty,
      logicalDevicesState.vendorId,
      logicalDevicesState.editorId,
      logicalDevicesState.categoryId,
    ]
  );

  const reload = useCallback(() => {
    setLogicalDevicesState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, logicalDevicesState.size, true);
  }, [fetch, logicalDevicesState.size]);

  useEffect(reload, [reload]);

  const direction = (property: LogicalDeviceSortProperty) => {
    return logicalDevicesState.sortProperty === property ? logicalDevicesState.sortDirection : undefined;
  };

  const handleSort = useCallback((property: LogicalDeviceSortProperty, direction: Direction) => {
    setLogicalDevicesState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const handleNext = useCallback(() => {
    fetch(logicalDevicesState.page + 1, logicalDevicesState.size);
    setLogicalDevicesState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, logicalDevicesState.page, logicalDevicesState.size]);

  const handleDelete = useCallback(
    (logicalDeviceId) => {
      Api.deleteLogicalDevice(logicalDeviceId)
        .then(() => {
          fetch(logicalDevicesState.logicalDevices.length - 1, 1);
          notifications.notifySuccess('logicalDeviceLib deleted.');
        })
        .then(() =>
          setLogicalDevicesState((state) => ({
            ...state,
            logicalDevices: state.logicalDevices.filter((it) => it.id !== logicalDeviceId),
          }))
        );
    },
    [fetch, logicalDevicesState.logicalDevices.length, notifications]
  );

  const handleVendorChange = useCallback((vendorId: number) => {
    setLogicalDevicesState((state) => {
      return { ...state, vendorId };
    });
  }, []);

  const handleCategoryChange = useCallback((categoryId: string) => {
    setLogicalDevicesState((state) => {
      return { ...state, categoryId };
    });
  }, []);

  const handleEditorChange = useCallback((editorId: number) => {
    setLogicalDevicesState((state) => {
      return { ...state, editorId };
    });
  }, []);

  const handleModalClose = useCallback(() => {
    reload();
    hideLogicalDeviceCreation();
  }, [hideLogicalDeviceCreation, reload]);

  return (
    <>
      {!isListEmpty && (
        <S.LibraryList>
          <InfiniteScroll
            dataLength={logicalDevicesState.logicalDevices.length}
            next={handleNext}
            hasMore={logicalDevicesState.hasMore}
            scrollableTarget="scrollableTarget"
            loader={null}
          >
            <S.LogicalDeviceGrid id="scrollableTarget" showCompany={userAccessCompanies}>
              <List.HeaderRow>
                <SortCell<LogicalDeviceSortProperty> type={'NAME'} direction={direction('NAME')} onSort={handleSort}>
                  LD Name
                </SortCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Vendor'}
                    hideLabel
                    hideBorder
                    selected={logicalDevicesState.vendorId}
                    options={logicalDevicesState.vendors.map((it) => ({ id: it.id, name: it.name }))}
                    onChange={handleVendorChange}
                  />
                </ListWithoutMargin.FilterCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'PACS category'}
                    hideLabel
                    hideBorder
                    selected={logicalDevicesState.categoryId}
                    options={logicDeviceDirectory.getAll().map((it) => ({
                      id: it.id,
                      name: it.name.en,
                    }))}
                    onChange={handleCategoryChange}
                  />
                </ListWithoutMargin.FilterCell>
                <ListWithoutMargin.HeaderCell>Description</ListWithoutMargin.HeaderCell>
                <SortCell<LogicalDeviceSortProperty>
                  type={'PERFORMANCE_POINTS'}
                  direction={direction('PERFORMANCE_POINTS')}
                  onSort={handleSort}
                >
                  Performance points
                </SortCell>
                <ListWithoutMargin.HeaderCell center>Voltage classes</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell center>Primary equipments</ListWithoutMargin.HeaderCell>
                <SortCell<LogicalDeviceSortProperty>
                  type={'EDITED_AT'}
                  direction={direction('EDITED_AT')}
                  onSort={handleSort}
                >
                  Edited
                </SortCell>
                <ListWithoutMargin.FilterCell>
                  <InputSelect
                    filter
                    label={'Editor'}
                    hideLabel
                    hideBorder
                    selected={logicalDevicesState.editorId}
                    options={logicalDevicesState.editors}
                    onChange={handleEditorChange}
                  />
                </ListWithoutMargin.FilterCell>
                <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>
              </List.HeaderRow>
              {logicalDevicesState.logicalDevices.map((logicalDevice) => (
                <LogicalDeviceRow
                  key={logicalDevice.id}
                  logicalDevice={logicalDevice}
                  cardType={cardType}
                  userHasLibraryEditAccess={userHasLibraryEditAccess}
                  remove={handleDelete}
                  reload={reload}
                  handleCardTypeChange={setCardType}
                  voltageLevelDirectory={voltageLevelDirectory}
                  deviceDirectory={deviceDirectory}
                  logicalDeviceDirectory={logicDeviceDirectory}
                />
              ))}
            </S.LogicalDeviceGrid>
          </InfiniteScroll>
        </S.LibraryList>
      )}
      {isListEmpty && <EmptyList height={'calc(100vh - 341px)'} />}
      <ReactModal
        isOpen={logicalDeviceCreationVisibility}
        onRequestClose={() => handleModalClose()}
        style={theme.modalCreateLdInfo}
      >
        <CardTypeContext.Provider value={'create'}>
          <LogicalDeviceCard onClose={handleModalClose} logicalDeviceViewDto={undefined} />
        </CardTypeContext.Provider>
      </ReactModal>
    </>
  );
};
const LogicalDeviceRow: React.FC<LogicalDeviceRowProps> = ({
  logicalDevice,
  cardType,
  userHasLibraryEditAccess,
  handleCardTypeChange,
  voltageLevelDirectory,
  deviceDirectory,
  logicalDeviceDirectory,
  remove,
  reload,
}) => {
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [logicalDeviceForCard, setLogicalDeviceForCard] = useState<LogicalDeviceViewDto>();

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const handleCloseCard = () => {
    reload();
    setLogicalDeviceForCard(undefined);
  };

  const handleOpenClick = useCallback(
    (ldId: number, type: CardType) => {
      Api.getLogicalDevice(ldId).then((response) => {
        setLogicalDeviceForCard(response.data);
        handleCardTypeChange(type);
      });
    },
    [handleCardTypeChange]
  );

  const handleConfirmDelete = useCallback(() => {
    remove(logicalDevice.id);
    hideConfirmationModal();
  }, [logicalDevice.id, hideConfirmationModal, remove]);

  return (
    <>
      <S.ClickedRow key={logicalDevice.id} onClick={() => handleOpenClick(logicalDevice.id, 'view')}>
        <ListWithoutMargin.Cell>{logicalDevice.name}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalDevice.vendor.name}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell center>
          {formatCategoryNames(logicalDevice.categoryId!, logicalDeviceDirectory)}
        </ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalDevice.description}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{logicalDevice.performancePoints}</ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell center>
          {formatVoltages(logicalDevice.voltageIds!, voltageLevelDirectory)}
        </ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell center>
          {formatPrimaryEquipmentNames(logicalDevice.primaryEquipmentIds!, deviceDirectory)}
        </ListWithoutMargin.Cell>
        <ListWithoutMargin.Cell>{formatMomentShort(logicalDevice.editedAt!)}</ListWithoutMargin.Cell>
        <ListWithoutMargin.CellWithLongContent title={logicalDevice.editor!.name}>
          {logicalDevice.editor!.name}
        </ListWithoutMargin.CellWithLongContent>
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={() => handleOpenClick(logicalDevice.id, 'edit')}>
              <Icon name={'edit'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
        {userHasLibraryEditAccess && (
          <ListWithoutMargin.Cell center onClick={(e) => e.stopPropagation()}>
            <List.Button onClick={showConfirmationModal}>
              <Icon name={'trash'} />
            </List.Button>
          </ListWithoutMargin.Cell>
        )}
      </S.ClickedRow>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Remove logicalDevice'}
          text={`Are you sure you want to remove ${logicalDevice.name}?`}
          onClose={hideConfirmationModal}
          onConfirm={handleConfirmDelete}
        />
      </ReactModal>
      <ReactModal
        isOpen={logicalDeviceForCard !== undefined}
        onRequestClose={() => handleCloseCard()}
        style={theme.modalCreateLdInfo}
      >
        <CardTypeContext.Provider value={cardType}>
          <LogicalDeviceCard onClose={handleCloseCard} logicalDeviceViewDto={logicalDeviceForCard} />
        </CardTypeContext.Provider>
      </ReactModal>
    </>
  );
};
