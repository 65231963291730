import * as React from 'react';
import { useCallback, useContext } from 'react';
import { EngineContext } from '../../editor/EngineContext';
import { Btn } from '../../widgets/Btn.styled';
import { StageDto } from '../../../api/nggrace-back';
import { Toolbar, ToolbarItem } from '../../toolbar/Toolbar';
import { useStageState } from '../../hooks/useStageState';
import { ExportToolbarItem } from '../../toolbar/ExportToolbarItem';
import { LanSettingsToolbarItem } from './LanSettingsToolbarItem';
import { ToolbarModalProvider } from '../../toolbar/ToolbarModalContext';
import { LanHelp } from './LanHelp';
import { LockToolbarItem } from '../../toolbar/LockToolbarItem';

type LanToolbarProps = {
  stage: StageDto;

  onNextStage(): void;
  onValidateStage(): void;
  onFinishStage(): void;
  onRollbackStage(): void;
  onReloadStage(): void;
};

export const LanToolbar: React.FC<LanToolbarProps> = ({
  stage,
  onNextStage,
  onValidateStage,
  onFinishStage,
  onRollbackStage,
  onReloadStage,
}) => {
  const engine = useContext(EngineContext);

  const { finished } = stage.baseInfo;

  const computeStateCallback = useCallback(() => {
    const finished = stage.baseInfo.finished;

    const canDelete = !finished && engine.getModel().getSelectedEntities().length > 0;

    const canUndo = !finished && engine.canUndo();
    const canRedo = !finished && engine.canRedo();
    return { canUndo, canRedo, canDelete };
  }, [stage.baseInfo.finished, engine]);

  const state = useStageState(engine, stage, computeStateCallback);

  const handleUndo = useCallback(async () => {
    await engine.undo();
  }, [engine]);

  const handleRedo = useCallback(async () => {
    await engine.redo();
  }, [engine]);

  const handleFitToSelect = useCallback(async () => {
    await engine.zoomToFitNodes(50);
  }, [engine]);

  const handleDelete = useCallback(async () => {
    await engine
      .getModel()
      .getSelectedEntities()
      .forEach((entity) => entity.remove());
  }, [engine]);

  const nextBtn = (
    <Btn onClick={onNextStage} disabled={!finished}>
      Next
    </Btn>
  );

  return (
    <Toolbar title={stage.project.name} btn={nextBtn}>
      <ToolbarItem icon={'undo'} hint={'Undo'} disabled={!state.canUndo} onClick={handleUndo} />
      <ToolbarItem icon={'redo'} hint={'Redo'} disabled={!state.canRedo} onClick={handleRedo} />
      <ToolbarItem icon={'trash'} hint={'Delete'} disabled={!state.canDelete} onClick={handleDelete} />
      <ToolbarItem icon={'move'} hint={'Fit to screen'} onClick={handleFitToSelect} />
      <ToolbarItem icon={'play'} hint={'Check'} disabled={finished} onClick={onValidateStage} />
      <LockToolbarItem stage={stage} onFinishStage={onFinishStage} onRollbackStage={onRollbackStage} />
      <LanSettingsToolbarItem stage={stage} onReloadStage={onReloadStage} />
      <ToolbarModalProvider icon={'question'} hint={'Help'}>
        <LanHelp />
      </ToolbarModalProvider>
      <ExportToolbarItem stage={stage} />
    </Toolbar>
  );
};
