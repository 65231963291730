import {
  HasNotExtendableParentModel,
  NotExtendableModel,
  NotSelectableModel,
} from '../../../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../../../utils/factory';
import { LnTemplateDto } from '../../../../../../api/nggrace-back';
import { LnCompositionTreeDataObjectProps } from './LnCompositionTreeDataObject';
import { LogicalNodeTreeModel, stringValueFormatter } from './LnComposition';

export const LnCompositionTree = (
  lnTemplate: LnTemplateDto,
  lnCompositionTreeDataObjectFactory: Factory<LnCompositionTreeDataObjectProps, LogicalNodeTreeModel>
): LogicalNodeTreeModel => {
  return {
    ...LnCompositionTreeLnTemplateModel(lnTemplate, lnCompositionTreeDataObjectFactory),
    ...HasNotExtendableParentModel(),
    ...NotExtendableModel(),
    ...NotSelectableModel(),
  };
};

const LnCompositionTreeLnTemplateModel = (
  lnTemplate: LnTemplateDto,
  lnCompositionTreeDataObjectFactory: Factory<LnCompositionTreeDataObjectProps, LogicalNodeTreeModel>
) => ({
  getName: () => `LN - ${stringValueFormatter(lnTemplate.type)}`,
  getKey: () => lnTemplate.type,
  onChildrenChanged: () => () => {},
  getChildren: () => {
    return lnTemplate.dataObjects.map((dataObject) => lnCompositionTreeDataObjectFactory({ dataObject }));
  },

  getClazz: () => lnTemplate.clazz,
  getCommonDataClass: () => '',
  getDescription: () => stringValueFormatter(lnTemplate.desc),
  getType: () => stringValueFormatter(lnTemplate.type),
  getBType: () => '',
  getFc: () => '',
});
