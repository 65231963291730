import React from 'react';
import { Styled as S } from './Libraries.styled';
import { TabPosition, TabType } from './Libraries';
import { Corner } from './Corner';

interface TabButtonWrapperProps {
  toggleState: TabType;
  tabType: TabType;
  position: TabPosition;
  name: string;
  setToggleState: (tab: TabType) => void;
}

export const TabButtonWrapper: React.FC<TabButtonWrapperProps> = ({
  toggleState,
  tabType,
  position,
  name,
  setToggleState,
}) => {
  let buttonMarginLeft = 0;
  if (toggleState === 'function-lib') {
    if (position === 'middle') {
      buttonMarginLeft = -32;
    } else if (position === 'right') {
      buttonMarginLeft = -16;
    }
  } else if (toggleState === 'hardware-lib') {
    if (position === 'middle') {
      buttonMarginLeft = -16;
    }
  } else {
    if (position === 'right') {
      buttonMarginLeft = -32;
    }
  }

  const active = toggleState === tabType;
  return (
    <S.TabButtonWrapper>
      {position !== 'left' && active && <Corner position={'left'} />}
      {position !== 'left' && active && <S.SpriteWhite position={'left'} />}
      <S.TabButton
        active={active}
        position={position}
        onClick={() => setToggleState(tabType)}
        marginLeft={buttonMarginLeft}
      >
        {name}
      </S.TabButton>
      {position !== 'right' && active && <S.SpriteWhite position={'right'} />}
      {position !== 'right' && active && <Corner position={'right'} />}
      {toggleState !== 'vendor-lib' && position === 'middle' && <S.SpriteGrey />}
      {toggleState !== 'hardware-lib' && position === 'right' && <S.SpriteGrey />}
    </S.TabButtonWrapper>
  );
};
