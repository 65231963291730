import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { NodeDirectory } from './directory/NodeDirectory';
import { NgGraceEngine } from './NgGraceEngine';
import { ProjectStyleSelection, StageDto } from '../../api/nggrace-back';
import { NgGraceModel } from './NgGraceModel';

export const EngineContext = React.createContext(new NgGraceEngine({} as NodeDirectory));

type EngineProviderProps<T extends NgGraceEngine> = PropsWithChildren<{
  stage: StageDto;
  engineFactory: (styleSelection: ProjectStyleSelection) => T;
  onInitStage(model: NgGraceModel): Promise<any>;
  onUpdateStage(json: string): void;
}>;

export const EngineProvider = <T extends NgGraceEngine>({
  stage,
  engineFactory,
  onInitStage,
  onUpdateStage,
  children,
}: EngineProviderProps<T>) => {
  const [ready, setReady] = useState(false);

  const engine = useMemo(() => engineFactory(stage.project.styleSelection), [
    engineFactory,
    stage.project.styleSelection,
  ]);

  useEffect(() => {
    const json = stage.json;
    if (!json) {
      engine.setModel(engine.buildNewModel());
      setReady(true);
      return;
    }

    engine
      .restoreState(JSON.parse(stage.json!), false)
      .then((model) => model.setLocked(stage.baseInfo.finished))
      .then(() => setReady(true));
  }, [engine, stage.baseInfo.finished, stage.json]);

  useEffect(() => {
    if (ready && stage.baseInfo.raw) {
      const model = engine.getModel();
      onInitStage(model)
        .then(() => engine.repaintCanvas(true))
        .then(() => engine.zoomToFitNodes());
    }
  }, [onInitStage, engine, ready, stage.baseInfo.raw]);

  useEffect(() => {
    if (ready) {
      return engine.onChange(() => {
        const model = engine.getModel().serialize();
        onUpdateStage(JSON.stringify(model));
      });
    }
  }, [ready, engine, onUpdateStage]);

  if (engine && ready) {
    return <EngineContext.Provider value={engine}>{children}</EngineContext.Provider>;
  }

  return <></>;
};
