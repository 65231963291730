import styled from 'styled-components';

const AcceptableInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
  margin: 0;

  span {
    margin: 0;
  }

  span:nth-child(2) {
    text-decoration: underline;
  }

  span:hover:not(:first-child) {
    cursor: pointer;
    color: turquoise;
  }
`;

export const Styled = {
  AcceptableInput,
};
