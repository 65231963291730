import React from 'react';
import ReactModal from 'react-modal';
import { Styled as S } from './MessageModal.styled';
import { Icon } from './Icon';
import { Btn } from './Btn.styled';
import { theme } from '../../theme';

type MessageModalProps = {
  header: string;
  isOpen: boolean;
  warn?: boolean;
  error?: boolean;

  onRequestClose(): void;
};

export const MessageModal: React.FC<MessageModalProps> = ({
  header,
  isOpen,
  warn,
  error,
  onRequestClose,
  children,
}) => {
  const icon = error ? 'error' : warn ? 'warn' : 'info';

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={theme.modal}>
      <S.MessageModal warn={warn} error={error}>
        <S.Left>
          <Icon name={icon} />
        </S.Left>
        <S.Right>
          <S.Header>{header}</S.Header>
          <S.Message>{children}</S.Message>
          <S.Footer>
            <Btn onClick={onRequestClose}>Ok</Btn>
          </S.Footer>
        </S.Right>
      </S.MessageModal>
    </ReactModal>
  );
};
