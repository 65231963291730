import {
  LayerModelGenerics,
  LayerModel,
  FactoryBank,
  DeserializeEvent,
  Toolkit,
  BaseModelListener,
  BaseEvent,
} from '@projectstorm/react-canvas-core';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory';
import { LanModel } from '../../LanModel';
import { LanEngine } from '../../LanEngine';
import { BuildingModel } from '../BuildingModel';

export const BuildingLayerModelType = 'building-layer';

export interface LayerListener extends BaseModelListener {
  modelAdded: (event: BaseEvent) => any;
  modelRemoved: (event: BaseEvent) => any;
}

export interface BuildingLayerModelGenerics extends LayerModelGenerics {
  PARENT: LanModel;
  ENGINE: LanEngine;
  CHILDREN: BuildingModel;
  LISTENER: LayerListener;
}

export class BuildingLayerModel extends LayerModel<BuildingLayerModelGenerics> {
  constructor(options: BuildingLayerModelGenerics['OPTIONS'] = {}) {
    super({ ...options, type: BuildingLayerModelType, transformed: true });
    this.models = {};
    this.repaintEnabled = true;
  }

  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }

  getChildModelFactoryBank(engine: BuildingLayerModelGenerics['ENGINE']) {
    return (engine.getBuildingFactories() as unknown) as FactoryBank<AbstractModelFactory>; // typing shit again
  }

  addModel(model: BuildingLayerModelGenerics['CHILDREN']) {
    super.addModel(model);
    model.registerListener({
      entityRemoved: () => {
        this.removeModel(model);
        this.fireEvent({ model }, 'modelRemoved');
      },
    });

    this.fireEvent({ model }, 'modelAdded');
  }
}
