import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toolkit } from '@projectstorm/react-canvas-core';

import { CardType } from '../../../../../hardware/card/HardwareCard';
import { ReportDto } from '../../../../../../../../api/nggrace-back';
import { MmsReportsStyled as S } from './MmsReports.styled';
import { ListWithoutMargin } from '../../../../../ListWithoutMargin.styled';
import { Icon } from '../../../../../../../widgets/Icon';
import { CheckboxField } from '../../../../../../../widgets/CheckboxField';
import { InputField } from '../../../../../../../widgets/InputField';
import { InputSelect } from '../../../../../../../widgets/InputSelect';
import { List } from '../../../../../../../widgets/List.styled';
import { AcceptableInput } from '../widget/AcceptableInput';
import { CardTypeContext } from '../../../LogicalDeviceList';
import { LogicalDeviceStateContext } from '../../../LogicalDeviceCard';

interface MmsReportsProps {}

interface IReportDto extends ReportDto {
  id: string;
  editable: boolean;
  remove: boolean;
}

export const MmsReports: React.FC<MmsReportsProps> = () => {
  const cardType = useContext(CardTypeContext);
  const [isEditableState, setIsEditableState] = useState<boolean>(false);
  const [isRemovableState, setIsRemovableState] = useState<boolean>(false);
  const { thirdStageState, handleThirdStageStateChange } = useContext(LogicalDeviceStateContext);
  const [reportsDto, setReportsDto] = useState<IReportDto[]>(
    thirdStageState?.dataPackets?.reports
      ? thirdStageState.dataPackets?.reports.map<IReportDto>((reportDto) => ({
          ...reportDto,
          id: Toolkit.UID(),
          editable: false,
          remove: false,
        }))
      : []
  );
  const prevReportsDto = useRef<IReportDto[]>([]);

  useEffect(() => {
    prevReportsDto.current = reportsDto;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDto.length]);

  const createReportsList = () => {
    setReportsDto((prevState) => {
      const newReportsDto = [
        ...prevState,
        {
          id: Toolkit.UID(),
          name: `MMS name ${prevState.length}`,
          optField: { seqNum: true, timeStamp: true, entryId: true, configRef: true, bufOvfl: true },
          reportsControl: { buffered: true, bufTime: 0, intgPd: 1000, indexed: true },
          trigOps: { dchg: true, dupd: true, gi: true, period: true },
          rptEnabled: 5,
          editable: false,
          remove: false,
        },
      ];
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          reports: newReportsDto,
        },
      });

      return newReportsDto;
    });
  };

  const handleOnChange = (reportDtoChanged: IReportDto) => {
    setReportsDto(
      reportsDto.map((reportDto) => {
        if (reportDto.id === reportDtoChanged.id) {
          setIsEditableState(reportDtoChanged.editable);
          setIsRemovableState(reportDtoChanged.remove);
          return reportDtoChanged;
        } else return reportDto;
      })
    );
  };

  const handleOnDelete = (id: string) => {
    setReportsDto((prevReportsDtoListState) => {
      const newReportsDto = prevReportsDtoListState.filter((reportDto) => reportDto.id !== id);
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          reports: newReportsDto,
        },
      });

      return newReportsDto;
    });
    setIsRemovableState(false);
  };

  const handleEditConfirm = () => {
    prevReportsDto.current = reportsDto;
    setReportsDto((prevReportsDtoListState) => {
      const newReportsDto = prevReportsDtoListState.map((reportDto) =>
        reportDto.editable
          ? {
              ...reportDto,
              editable: false,
            }
          : reportDto
      );
      handleThirdStageStateChange({
        dataPackets: {
          ...thirdStageState?.dataPackets,
          reports: newReportsDto,
        },
      });

      return newReportsDto;
    });
    setIsEditableState(false);
  };

  const handleEditCancel = () => {
    setReportsDto(
      prevReportsDto.current.map((reportDto) =>
        reportDto.editable
          ? {
              ...reportDto,
              editable: false,
            }
          : reportDto
      )
    );
    setIsEditableState(false);
  };

  return (
    <S.Container>
      <S.Content>
        <header>
          <h3>MMS reports list</h3>
          <S.BtnIcon onClick={createReportsList} disabled={cardType === 'view'}>
            <Icon name={'plus'} />
            <span>Add</span>
          </S.BtnIcon>
        </header>
        <S.Columns>
          <S.Column tableName={'DatasetListGrid'}>
            <header>
              <h3>Required parameters</h3>
            </header>
            <S.DatasetListGrid id="scrollableTarget">
              <S.HeaderRow>
                <ListWithoutMargin.HeaderCell>MMS report Name</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>DATASET</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell center>Edit</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell center>Delete</ListWithoutMargin.HeaderCell>
              </S.HeaderRow>
              {reportsDto.map((reportDto) => (
                <DatasetListRow
                  key={reportDto.id}
                  cardType={cardType}
                  reportDto={reportDto}
                  dataSet={thirdStageState?.dataPackets?.dataSets
                    ?.filter((dataSetDto) => dataSetDto.type === 'REPORTS')
                    .map((dataSetDto) => ({
                      id: dataSetDto.name!,
                      name: dataSetDto.name!,
                    }))}
                  isEditableState={isEditableState}
                  isRemovableState={isRemovableState}
                  handleEditConfirm={handleEditConfirm}
                  handleEditCancel={handleEditCancel}
                  handleOnChange={handleOnChange}
                  remove={handleOnDelete}
                />
              ))}
            </S.DatasetListGrid>
          </S.Column>
          <S.Column tableName={'reportsControl'}>
            <header>
              <h3>ReportsControl</h3>
            </header>
            <S.ReportsControl id="scrollableTarget">
              <S.HeaderRow>
                <ListWithoutMargin.HeaderCell>buffered</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>bufTime</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>intgPd</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>indexed</ListWithoutMargin.HeaderCell>
              </S.HeaderRow>
              {reportsDto.map((reportDto) => (
                <ReportsControlRow key={reportDto.id} reportDto={reportDto} handleOnChange={handleOnChange} />
              ))}
            </S.ReportsControl>
          </S.Column>
          <S.Column tableName={'rptEnabled'}>
            <header>
              <h3>RptEnabled</h3>
            </header>
            <S.RptEnabled id="scrollableTarget">
              <S.HeaderRow>
                <ListWithoutMargin.HeaderCell center>max</ListWithoutMargin.HeaderCell>
              </S.HeaderRow>
              {reportsDto.map((reportDto) => (
                <RptEnabledRow key={reportDto.id} reportDto={reportDto} handleOnChange={handleOnChange} />
              ))}
            </S.RptEnabled>
          </S.Column>
          <S.Column tableName={'trigOps'}>
            <header>
              <h3>TrigOps</h3>
            </header>
            <S.TrigOps id="scrollableTarget">
              <S.HeaderRow>
                <ListWithoutMargin.HeaderCell>dchg</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>dupd</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>gi</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>period</ListWithoutMargin.HeaderCell>
              </S.HeaderRow>
              {reportsDto.map((reportDto) => (
                <TrigOptsRow key={reportDto.id} reportDto={reportDto} handleOnChange={handleOnChange} />
              ))}
            </S.TrigOps>
          </S.Column>
          <S.Column tableName={'optField'}>
            <header>
              <h3>OptField</h3>
            </header>
            <S.OptField id="scrollableTarget">
              <S.HeaderRow>
                <ListWithoutMargin.HeaderCell>seqNum</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>timeStamp</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>entryID</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>configRef</ListWithoutMargin.HeaderCell>
                <ListWithoutMargin.HeaderCell>bufOvfl</ListWithoutMargin.HeaderCell>
              </S.HeaderRow>
              {reportsDto.map((reportDto) => (
                <OptFieldRow key={reportDto.id} reportDto={reportDto} handleOnChange={handleOnChange} />
              ))}
            </S.OptField>
          </S.Column>
        </S.Columns>
      </S.Content>
    </S.Container>
  );
};

interface DatasetListRowProps {
  cardType: CardType;
  reportDto: IReportDto;
  isEditableState: boolean;
  isRemovableState: boolean;
  dataSet?: Array<{ id: string; name: string }>;
  handleOnChange: (reportsDto: IReportDto) => void;
  handleEditCancel: () => void;
  handleEditConfirm: () => void;
  remove: (id: string) => void;
}

const DatasetListRow: React.FC<DatasetListRowProps> = ({
  cardType,
  reportDto,
  handleOnChange,
  dataSet,
  isEditableState,
  isRemovableState,
  handleEditCancel,
  handleEditConfirm,
  remove: handleOnDelete,
}) => {
  const handleNameChange = (name: string) => {
    handleOnChange({ ...reportDto, name });
  };

  const handleDatasetNameChange = (dataSetName: string) => {
    handleOnChange({ ...reportDto, dataSetName });
  };

  const handleEditableChange = (editable: boolean) => {
    handleOnChange({ ...reportDto, editable });
  };

  const handleRemoveChange = (remove: boolean) => {
    handleOnChange({ ...reportDto, remove });
  };

  const handleDelConfirm = () => {
    handleOnDelete(reportDto.id);
  };

  const handleDelCancel = () => {
    handleOnChange({ ...reportDto, remove: false });
  };

  return (
    <S.ClickedRow editable={reportDto.editable} remove={reportDto.remove}>
      <S.Cell>
        <InputField type={'text'} onChange={handleNameChange} value={reportDto.name} disabled={!reportDto.editable} />
      </S.Cell>
      <S.Cell>
        {dataSet && (
          <InputSelect
            onChange={handleDatasetNameChange}
            label={'Type'}
            hideBorder={true}
            hideLabel={true}
            selected={reportDto.dataSetName}
            options={dataSet}
            disabled={!reportDto.editable}
          />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!reportDto.editable ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleEditableChange(true)}
          >
            <Icon name={'edit'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleEditConfirm} handleCancel={handleEditCancel} />
        )}
      </S.Cell>
      <S.Cell center onClick={(e) => e.stopPropagation()}>
        {!reportDto.remove ? (
          <List.Button
            disabled={cardType === 'view' || isEditableState || isRemovableState}
            onClick={() => handleRemoveChange(true)}
          >
            <Icon name={'trash'} />
          </List.Button>
        ) : (
          <AcceptableInput handleConfirm={handleDelConfirm} handleCancel={handleDelCancel} />
        )}
      </S.Cell>
    </S.ClickedRow>
  );
};

interface ReportsControlRowProps {
  reportDto: IReportDto;
  handleOnChange: (reportsDto: IReportDto) => void;
}

interface RptEnabledRowProps {
  reportDto: IReportDto;
  handleOnChange: (reportsDto: IReportDto) => void;
}

const RptEnabledRow: React.FC<RptEnabledRowProps> = ({ reportDto, handleOnChange }) => {
  const handleRptEnabledChange = (rptEnabled: number) => {
    handleOnChange({ ...reportDto, rptEnabled });
  };

  return (
    <S.ClickedOptionRow editable={reportDto.editable} remove={reportDto.remove}>
      <S.Cell center>
        <InputField
          type={'text'}
          onChange={handleRptEnabledChange}
          value={reportDto.rptEnabled}
          disabled={!reportDto.editable}
        />
      </S.Cell>
    </S.ClickedOptionRow>
  );
};

const ReportsControlRow: React.FC<ReportsControlRowProps> = ({ reportDto, handleOnChange }) => {
  const handleBufferedChange = (buffered: boolean) => {
    handleOnChange({ ...reportDto, reportsControl: { ...reportDto.reportsControl, buffered } });
  };

  const handleBufTimeChange = (bufTime: number) => {
    handleOnChange({ ...reportDto, reportsControl: { ...reportDto.reportsControl, bufTime } });
  };

  const handleIntgPdChange = (intgPd: number) => {
    handleOnChange({ ...reportDto, reportsControl: { ...reportDto.reportsControl, intgPd } });
  };

  const handleIndexedChange = (indexed: boolean) => {
    handleOnChange({ ...reportDto, reportsControl: { ...reportDto.reportsControl, indexed } });
  };

  return (
    <S.ClickedOptionRow editable={reportDto.editable} remove={reportDto.remove}>
      <S.Cell center>
        <CheckboxField
          value={reportDto.reportsControl.buffered ?? true}
          onChange={handleBufferedChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <InputField
          type={'text'}
          onChange={handleBufTimeChange}
          value={reportDto.reportsControl.bufTime}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <InputField
          type={'text'}
          onChange={handleIntgPdChange}
          value={reportDto.reportsControl.intgPd}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.reportsControl.indexed ?? true}
          onChange={handleIndexedChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
    </S.ClickedOptionRow>
  );
};

interface TrigOptsRowProps {
  reportDto: IReportDto;
  handleOnChange: (reportsDto: IReportDto) => void;
}

const TrigOptsRow: React.FC<TrigOptsRowProps> = ({ reportDto, handleOnChange }) => {
  const handleDchgChange = (dchg: boolean) => {
    handleOnChange({ ...reportDto, trigOps: { ...reportDto.trigOps, dchg } });
  };

  const handleDupdChange = (dupd: boolean) => {
    handleOnChange({ ...reportDto, trigOps: { ...reportDto.trigOps, dupd } });
  };

  const handleGiChange = (gi: boolean) => {
    handleOnChange({ ...reportDto, trigOps: { ...reportDto.trigOps, gi } });
  };

  const handlePeriodChange = (period: boolean) => {
    handleOnChange({ ...reportDto, trigOps: { ...reportDto.trigOps, period } });
  };

  return (
    <S.ClickedOptionRow editable={reportDto.editable} remove={reportDto.remove}>
      <S.Cell center>
        <CheckboxField
          value={reportDto.trigOps.dchg ?? true}
          onChange={handleDchgChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.trigOps.dupd ?? true}
          onChange={handleDupdChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField value={reportDto.trigOps.gi ?? true} onChange={handleGiChange} disabled={!reportDto.editable} />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.trigOps.period ?? true}
          onChange={handlePeriodChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
    </S.ClickedOptionRow>
  );
};

interface OptFieldRowProps {
  reportDto: IReportDto;
  handleOnChange: (reportsDto: IReportDto) => void;
}

const OptFieldRow: React.FC<OptFieldRowProps> = ({ reportDto, handleOnChange }) => {
  const handleSeqNumChange = (seqNum: boolean) => {
    handleOnChange({ ...reportDto, optField: { ...reportDto.optField, seqNum } });
  };

  const handleTimeStampChange = (timeStamp: boolean) => {
    handleOnChange({ ...reportDto, optField: { ...reportDto.optField, timeStamp } });
  };

  const handleEntryIdChange = (entryId: boolean) => {
    handleOnChange({ ...reportDto, optField: { ...reportDto.optField, entryId } });
  };

  const handleConfigRefChange = (configRef: boolean) => {
    handleOnChange({ ...reportDto, optField: { ...reportDto.optField, configRef } });
  };

  const handleBufOvflChange = (bufOvfl: boolean) => {
    handleOnChange({ ...reportDto, optField: { ...reportDto.optField, bufOvfl } });
  };

  return (
    <S.ClickedOptionRow editable={reportDto.editable} remove={reportDto.remove}>
      <S.Cell center>
        <CheckboxField
          value={reportDto.optField.seqNum ?? true}
          onChange={handleSeqNumChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.optField.timeStamp ?? true}
          onChange={handleTimeStampChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.optField.entryId ?? true}
          onChange={handleEntryIdChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.optField.configRef ?? true}
          onChange={handleConfigRefChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
      <S.Cell center>
        <CheckboxField
          value={reportDto.optField.bufOvfl ?? true}
          onChange={handleBufOvflChange}
          disabled={!reportDto.editable}
        />
      </S.Cell>
    </S.ClickedOptionRow>
  );
};
