import React, { useCallback, useMemo } from 'react';
import { InputGroup } from '../../../widgets/InputGroup.styled';
import { InputField } from '../../../widgets/InputField';
import { ExportFormDto } from '../../../../api/nggrace-back';

type UpdateFormAction = (form: ExportFormDto) => ExportFormDto;

type CreatedGroupProps = {
  form: ExportFormDto;

  onChange(formUpdater: UpdateFormAction): void;
};

export const CreatedGroup: React.FC<CreatedGroupProps> = ({ form, onChange }) => {
  const createdAt = useMemo(() => new Date(form.createdAt).toISOString().slice(0, 10), [form.createdAt]);

  const handleCreatedAtChange = useCallback(
    (createdAt?: string) => {
      createdAt && onChange((dto) => ({ ...dto, createdAt: new Date(createdAt).getTime() }));
    },
    [onChange]
  );

  const maxLength = 255;

  return (
    <InputGroup>
      <InputField type="date" label="Date" value={createdAt} onChange={handleCreatedAtChange} maxLength={maxLength} />
    </InputGroup>
  );
};
