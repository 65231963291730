import { SmartLinkPointModel } from '../../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';

export interface LanLinkGraph {
  getId(): string;

  detach(): void;

  needDetach(): boolean;

  getPoints(): SmartLinkPointModel[];

  getColor(): string;

  getConnectorLength(): number;
}

export const getDistance = (a: Point, b: Point) => {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};
