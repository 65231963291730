import React from 'react';

import { Styled as S } from './LnTypeTree.styled';
import { MenuItem } from '../Datasets';
import { TreeNode, TreeNodeChildFactory } from './TreeNode';

interface LnTypeTreeProps {
  model: MenuItem;
  childFactory?: TreeNodeChildFactory;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;

  onChange(isAnyActiveNode: boolean): void;
}

const dataAttributeSubDataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, selected, onChange }) => (
  <TreeNode
    key={model.id}
    model={model}
    indentLevel={indentLevel + 1}
    last={last}
    initialOpen={model.expand}
    canHighlight={model.selectable}
    selected={selected}
    onChange={onChange}
  />
);

const dataObjectFactory: TreeNodeChildFactory = ({ model, indentLevel, last, selected, onChange }) => (
  <TreeNode
    key={model.id}
    model={model}
    indentLevel={indentLevel + 1}
    childFactory={dataAttributeSubDataObjectFactory}
    last={last}
    initialOpen={model.expand}
    canHighlight={model.selectable}
    selected={selected}
    onChange={onChange}
  />
);

const lnTemplateFactory: TreeNodeChildFactory = ({ model, indentLevel, last, onChange }) => (
  <TreeNode
    key={model.id}
    model={model}
    indentLevel={indentLevel + 1}
    childFactory={dataObjectFactory}
    last={last}
    initialOpen={model.expand}
    canHighlight={model.selectable}
    onChange={onChange}
  />
);

export const LnTypeTree: React.FC<LnTypeTreeProps> = ({
  model,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
  onChange,
}) => {
  return (
    <S.TreeRow>
      <TreeNode
        model={model}
        childFactory={lnTemplateFactory}
        canHighlight={canHighlight}
        hasParent={hasParent}
        indentLevel={indentLevel}
        last={last}
        initialOpen={initialOpen}
        onChange={onChange}
      />
    </S.TreeRow>
  );
};
