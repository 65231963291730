import { Graph } from '../Graph';
import { Link } from '../../Link';
import { Node, NodeType } from '../../node/Node';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { TranslatedNode } from '../../node/TranslatedNode';
import { BasePoint } from '../../../geometry/Point';
import { RotatedNode } from '../../node/RotatedNode';
import { DefaultSmartRotationHour, RotationHour } from '../../../geometry/RotationHour';
import { NormalizedNode } from '../../node/NormalizedNode';

export class BusWithLocalsAndTrsAndNeighbourhood implements Graph {
  private busTrAndNeighbourhood: Graph;
  private bus: Node;
  private busLocals: Graph;

  constructor(bus: Node, busTrAndNeighbourhood: Graph, busLocals: Graph) {
    this.bus = bus;
    this.busTrAndNeighbourhood = busTrAndNeighbourhood;
    this.busLocals = busLocals;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const busLocalsRect = this.busLocals.getRect();
    const xBusLocalsOffset = -busLocalsRect.getOrigin().x;

    const nodes = [
      ...this.getTrsAndNeighbourhoodNodes(),
      ...this.busLocals
        .getNodes()
        .map(
          (local) =>
            new TranslatedNode(
              new RotatedNode(
                local,
                new DefaultSmartRotationHour(RotationHour.SIX),
                new BasePoint(busLocalsRect.getOrigin().x, this.bus.getRect().getOrigin().y)
              ),
              new BasePoint(xBusLocalsOffset, -60)
            )
        ),
    ];

    return [...nodes, this.bus];
  }

  getTrsAndNeighbourhoodNodes() {
    const xTrAndNeighbourhoodOffset = -this.busTrAndNeighbourhood.getRect().getOrigin().x;
    const yNeighbourhoodOffset = 60;

    const trsAndNeighbours = this.busTrAndNeighbourhood.getNodes();
    const neighboursHeight = Polygon.boundingBoxFromPolygons(
      trsAndNeighbours.filter((node) => node.getType() === NodeType.OTHER).map((node) => node.getRect())
    ).getHeight();
    const trs = trsAndNeighbours.filter((node) => node.getType() === NodeType.TR);
    const neighbours = trsAndNeighbours.filter((node) => node.getType() === NodeType.OTHER);

    return [
      ...neighbours.map(
        (node) => new TranslatedNode(node, new BasePoint(xTrAndNeighbourhoodOffset, yNeighbourhoodOffset))
      ),
      ...trs.map((tr) => {
        return new TranslatedNode(
          new NormalizedNode(tr),
          new BasePoint(
            tr.getRect().getOrigin().x + xTrAndNeighbourhoodOffset,
            neighboursHeight + yNeighbourhoodOffset + yNeighbourhoodOffset - 15
          )
        );
      }),
    ];
  }

  getRect(): Rectangle {
    const wholeRect = Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
    const positiveRect = Polygon.boundingBoxFromPolygons(
      [...this.getTrsAndNeighbourhoodNodes(), this.bus].map((node) => node.getRect())
    );
    const right = wholeRect.getTopRight().x;
    const left = wholeRect.getBottomLeft().x;
    const top = positiveRect.getTopRight().y;
    const bottom = positiveRect.getBottomLeft().y;

    return new Rectangle(left, top, right - left, bottom - top);
  }
}
