import { Point } from '@projectstorm/geometry';
import { RotationHour } from '../../../geometry/RotationHour';
import { LanNodeModel } from '../LanNodeModel';
import {
  NetworkDeviceDirectoryEntry,
  PropertiesDirectoryEntry,
  PropertiesDirectoryName,
} from '../../../directory/PropertiesDirectory';
import { NetworkPortModel } from './NetworkPortModel';
import { AddedPoint, BasePoint } from '../../../geometry/Point';
import { LanNodeImageMargin, LanNodeLabelSize } from './NetworkNodeWidget';
import { BrickTextMetrics } from '../../../../../utils/TextMetrics';
import { LanPortModel } from '../port/LanPortModel';

export const DefaultNetworkSize = new Point(200, 30);
export const NetworkNodeType = 'network';

interface LanNodeImage {
  src: string;
  size: BasePoint;
}

export class NetworkNodeModel extends LanNodeModel {
  private readonly networkDeviceTypeDirectoryEntry: PropertiesDirectoryEntry;
  private readonly image?: LanNodeImage;

  constructor(
    networkDeviceTypeDirectoryEntry: PropertiesDirectoryEntry,
    networkDeviceDirectoryEntry: NetworkDeviceDirectoryEntry
  ) {
    super(
      {
        type: NetworkNodeType,
        payload: {
          hour: RotationHour.ZERO,
        },
        defaultSize: DefaultNetworkSize,
        resizers: [],
      },
      networkDeviceDirectoryEntry
    );
    this.networkDeviceTypeDirectoryEntry = networkDeviceTypeDirectoryEntry;
    if (networkDeviceDirectoryEntry.svg) {
      this.image = {
        src: networkDeviceDirectoryEntry.svg.src,
        size: {
          x: networkDeviceDirectoryEntry.svg.width,
          y: networkDeviceDirectoryEntry.svg.height,
        },
      } as LanNodeImage;
    }
  }

  getName(): string {
    return this.getDirectoryEntry().name.en;
  }

  createPort(label: string): LanPortModel {
    return new NetworkPortModel({ directoryEntry: this.getDirectoryEntry(), label });
  }

  getImage() {
    return this.image;
  }

  getSize(): BasePoint {
    const lanSize = super.getSize();
    const image = this.getImage();
    const labelLength = BrickTextMetrics.getWidth(this.getName());
    const labelSizeX = labelLength + LanNodeImageMargin * 2;
    if (!image) {
      return new BasePoint(Math.max(lanSize.x, labelSizeX), lanSize.y);
    }

    const imageMargin = LanNodeImageMargin;
    const imageSize = new AddedPoint(image.size, new BasePoint(imageMargin * 2, LanNodeLabelSize + imageMargin * 2));
    return new BasePoint(Math.max(lanSize.x, imageSize.x, labelSizeX), Math.max(lanSize.y, imageSize.y));
  }

  getDirectory(): PropertiesDirectoryName {
    return 'NetworkDeviceDirectory';
  }
}
