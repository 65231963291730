import styled from 'styled-components';
import { Icon } from './Icon.styled';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #1e224680;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid #103c8b;
  border-radius: 36px;
  background: #103c8b80;
  margin: -72px 0 18px 0;

  ${Icon} {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }
`;

export const Styled = {
  Overlay,
  Loading,
};
