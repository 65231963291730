import { SmartLinkModel } from './SmartLinkModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { PortEndedPath } from './path/default/PortEndedPath';
import { AngledPath } from './path/default/AngledPath';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { RestrictedDirectionPoint } from '../../geometry/Point';

export class ConnectedSmartLinkModel extends SmartLinkModel {
  constructor(source: ConnectablePortModel, target: ConnectablePortModel) {
    super();
    this.setSourcePort(source);
    this.setTargetPort(target);

    const path = new UpdatingDependenciesConnectingPath(
      new DefaultConnectingPath(new PortEndedPath(new PortEndedPath(new AngledPath(), source), target), this)
    );

    this.setPoints(
      path.connect(
        new RestrictedDirectionPoint(this.getSourcePort().getCenter()),
        new RestrictedDirectionPoint(target.getCenter())
      )
    );
  }
}
