import { TreeNodeWidget } from './TreeNodeWidget';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Factory } from '../../../../../../../../../utils/factory';
import { MenuItem } from '../Datasets';

export type TreeNodeChildFactory = Factory<
  { model: MenuItem; indentLevel: number; last: boolean; selected?: boolean; onChange(isAnyActiveNode: boolean): void },
  ReactNode
>;

interface TreeNodeProps {
  model: MenuItem;
  childFactory?: TreeNodeChildFactory;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;
  selected?: boolean;

  onChange(isAnyActiveNode: boolean): void;
}

export const TreeNode: React.FC<TreeNodeProps> = ({
  model,
  childFactory,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
  selected: active,
  onChange,
}: TreeNodeProps) => {
  const [children, setChildren] = useState(model.children);
  const [open, setOpen] = useState(initialOpen || false);
  const toggleOpen = useCallback(() => {
    model.expand = !open;
    setOpen((o) => !o);
  }, []);
  useEffect(() => {
    setChildren(model.children);
  }, [model]);

  const [selected, setSelected] = useState<boolean>(active ? active : false);
  useEffect(() => {
    // @ts-ignore
    setSelected(model.active);
  }, [active]);

  const handleSelected = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (canHighlight) {
      model.active = !model.active;
      model.children?.forEach((item) => {
        item.active = model.active;
      });
      setSelected(model.active);
    }
    onChange(model.active!);
  };

  const childrenComponents = useMemo(
    () =>
      childFactory &&
      children?.map((child, index) =>
        childFactory({
          model: child,
          indentLevel,
          last: children.length - 1 === index,
          selected: child.active,
          onChange: onChange,
        })
      ),
    [selected, children, childFactory, indentLevel]
  );

  return (
    <TreeNodeWidget
      highlight={selected}
      title={model.title}
      key={model.id}
      canHighlight={canHighlight}
      hasParent={hasParent}
      indentLevel={indentLevel}
      last={last}
      open={open}
      toggleOpen={toggleOpen}
      handleSelected={handleSelected}
    >
      {childrenComponents?.length ? childrenComponents : null}
    </TreeNodeWidget>
  );
};
