import styled from 'styled-components';

const CapexCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Graph = styled.svg`
  width: 386px;
  height: 386px;
`;

const GraphText = styled.text<{ color?: string }>`
  font: 950 16px 'Anton', sans-serif;
  fill: ${(props) => (props.color ? props.color : props.theme.colors.white)};
`;

const GraphCircle = styled.div<{ radius: number; top: number; color: string }>`
  display: flex;
  position: absolute;
  //TODO: fix size, 32px - size of title
  top: ${(props) => props.top - 32}px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 80%;
  background-color: ${(props) => props.color};
  width: ${(props) => props.radius * 2}px;
  height: ${(props) => props.radius * 2}px;
  box-shadow: 0 0 40px 3px ${(props) => props.theme.colors.grey};
  z-index: -1;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LegendIcon = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const LegendLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 24px 0;
  //overflow: scroll;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 32px;
  width: 100%;
`;

export const Styled = {
  CapexCard,
  Graph,
  GraphText,
  GraphCircle,
  Legend,
  LegendItem,
  LegendIcon,
  LegendLabel,
  Content,
  Footer,
};
