import * as React from 'react';
import { LanPortModel } from '../../../lan/node/port/LanPortModel';
import { Point } from '@projectstorm/geometry';
import { Size, sizeFontMapping } from '../../../lan/label/LanLabel';

interface ExportLanPortLabelProps {
  port: LanPortModel;
  position: Point;
  size: Size;
}

export const ExportLanPortLabel: React.FC<ExportLanPortLabelProps> = ({ port, position, size }) => {
  const index = Object.values(port.getParent().getPorts()).lastIndexOf(port) + 1;
  const labelSize = sizeFontMapping[size];

  return (
    <text
      x={position.x}
      y={position.y}
      fontFamily="GOSTRUS"
      fontSize={`${labelSize}px`}
      dominantBaseline={'hanging'}
      letterSpacing={'-0.5px'}
    >
      {index}
    </text>
  );
};
