import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { InputFieldRecord, PortRecord } from '../directory/NodeDirectory';
import { DirectoryNodeInputField, DirectoryNodeModelPayload } from '../directory/DirectoryNodeModel';
import { Styled as S } from './Properties.styled';
import { InputField } from '../../widgets/InputField';
import { CheckboxField } from '../../widgets/CheckboxField';

type PropertiesInputFieldProps = {
  field: InputFieldRecord;
  payload: DirectoryNodeModelPayload;
  port?: PortRecord;
  disabled?: boolean;

  onChange(payload: DirectoryNodeModelPayload): void;
};

export const PropertiesInputField: React.FC<PropertiesInputFieldProps> = ({
  field,
  payload,
  port,
  disabled,
  onChange,
}) => {
  const value = useMemo(() => {
    if (port) {
      if (payload.portFields[port.name]?.[field.name]) {
        return (payload.portFields[port.name][field.name] as DirectoryNodeInputField).value;
      }
    } else {
      if (payload.fields[field.name]) {
        return (payload.fields[field.name] as DirectoryNodeInputField).value;
      }
    }
  }, [field.name, payload.fields, payload.portFields, port]);

  const stringValue = useMemo(() => {
    if (value !== undefined) {
      return value;
    }
    return '';
  }, [value]);

  const booleanValue = useMemo(() => {
    if (value !== undefined) {
      return value === 'true';
    }
    return false;
  }, [value]);

  const handleChangeField = useCallback(
    (updatedField: DirectoryNodeInputField) => {
      if (port) {
        const updatedPayload: DirectoryNodeModelPayload = {
          ...payload,
          portFields: {
            ...payload.portFields,
            [port.name]: {
              ...payload.portFields[port.name],
              [field.name]: updatedField,
            },
          },
        };
        onChange(updatedPayload);
      } else {
        const updatedPayload: DirectoryNodeModelPayload = {
          ...payload,
          fields: {
            ...payload.fields,
            [field.name]: updatedField,
          },
        };
        onChange(updatedPayload);
      }
    },
    [field.name, onChange, payload, port]
  );

  const handleChange = useCallback(
    (value?: string) => {
      if (value !== undefined) {
        const updatedField: DirectoryNodeInputField = {
          value: value,
        };
        handleChangeField(updatedField);
      }
    },
    [handleChangeField]
  );

  const handleChangeBoolean = useCallback(
    (value: boolean) => {
      const updatedField: DirectoryNodeInputField = {
        value: '' + value,
      };
      handleChangeField(updatedField);
    },
    [handleChangeField]
  );

  return (
    <S.ContentItem>
      <S.ContentItemLabel>{field.displayName.en}</S.ContentItemLabel>
      {field.inputType === 'string' && <InputField onChange={handleChange} value={stringValue} disabled={disabled} />}
      {field.inputType === 'number' && (
        <InputField type="number" min={0} onChange={handleChange} value={stringValue} disabled={disabled} step={0.1} />
      )}
      {field.inputType === 'boolean' && (
        <CheckboxField onChange={handleChangeBoolean} value={booleanValue} disabled={disabled} />
      )}
    </S.ContentItem>
  );
};
