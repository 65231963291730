import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExportFormDto, ExportFormType, ProjectExportDto, StageType } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { Page } from '../../Page';
import { ProjectSidebar } from '../project/ProjectSidebar';
import { ExportToolbar } from './ExportToolbar';
import { Styled as S } from './ExportPage.styled';
import { ExportCard } from './ExportCard';
import { ExportFormPanel } from './ExportFormPanel';
import { exportSvg } from '../../editor/export/ExportSvg';
import { useDirectory } from '../../hooks/useDirectory';
import { Overlay } from '../../widgets/Overlay';
import { SldEngine } from '../../editor/sld/SldEngine';
import { SsdEngine } from '../../editor/ssd/SsdEngine';
import { ScdEngine } from '../../editor/scd/ScdEngine';
import { MessageModal } from '../../widgets/MessageModal';
import { ExportLang as $s } from '../export/Export.lang';
import { LanEngine } from '../../editor/lan/LanEngine';
import {PropertiesDirectory, VoltageLevelDirectoryEntry} from "../../editor/directory/PropertiesDirectory";

const svgExportStages: StageType[] = ['SLD', 'SSD', 'SCD', 'LAN'];

export const ExportPage: React.FC = () => {
  const [project, setProject] = useState<ProjectExportDto>();
  const [exportForm, setExportForm] = useState<ExportFormDto>();
  const [activeType, setActiveType] = useState<ExportFormType>();
  const [overlayVisible, setOverlayVisible] = useState(false);
  const { projectId } = useParams<{ projectId?: string }>();
  const {
    getNodeDirectory,
    voltageLevelDirectory,
    logicNodeDirectory,
    logicDeviceDirectory,
    controllerDirectory,
    switchDirectory,
    networkDeviceTypeDirectory,
    networkDeviceDirectory,
  } = useDirectory();
  const [errorMessage, setErrorMessage] = useState<string>();

  const hideMessage = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  useEffect(() => {
    if (projectId) {
      Api.getProjectExport(Number(projectId)).then(({ data: project }) => {
        setProject(project);
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      setExportForm(project.exportForms.find((exportForm) => exportForm.type === activeType));
    }
  }, [activeType, project]);

  const handleExport = useCallback(async () => {
    if (project) {
      setOverlayVisible(true);

      const directory = getNodeDirectory(project?.styleSelection);

      const createEngine = (stageType: StageType) => {
        switch (stageType) {
          case 'SLD':
            return new SldEngine(directory, voltageLevelDirectory);
          case 'SSD':
            return new SsdEngine(directory, voltageLevelDirectory, logicDeviceDirectory, logicNodeDirectory);
          case 'SCD':
            return new ScdEngine(directory, voltageLevelDirectory, logicDeviceDirectory, logicNodeDirectory, controllerDirectory);
          case 'LAN':
            return new LanEngine(
              switchDirectory,
              networkDeviceTypeDirectory,
              networkDeviceDirectory,
              controllerDirectory,
              () => {}
            );
        }
      };

      try {
        const files = new Map<string, string>();
        const svgStages = project.stages.filter((s) => svgExportStages.includes(s.type));

        for (const stage of svgStages) {
          const exportForm = project.exportForms.find((ef) => ef.type === stage.type && ef.formats.includes('SVG'));
          if (!exportForm) {
            continue;
          }

          const { data: diagram } = await Api.getStageDiagram(stage.id);
          const engine = createEngine(stage.type)!;
          const model = engine.buildNewModel();
          await model.deserializeModel(JSON.parse(diagram.json || ''), engine);
          const svg = await exportSvg({ project, directory, voltageLevelDirectory, networkDeviceDirectory, model, dto: exportForm });
          files.set(`${project.name}_${stage.type}.svg`, svg);
        }

        await Api.exportProjectWithFormData(project.id, project.name, files, {
          omitInterceptorErrorModal: true,
        }).catch(() => setErrorMessage('Unable to prepare report'));
      } finally {
        setOverlayVisible(false);
      }
    }
  }, [
    controllerDirectory,
    getNodeDirectory,
    logicDeviceDirectory,
    logicNodeDirectory,
    networkDeviceDirectory,
    networkDeviceTypeDirectory,
    project,
    switchDirectory,
  ]);

  const handleSave = useCallback(async (exportFormId: number, exportForm: ExportFormDto) => {
    await Api.updateExportForm(exportFormId, exportForm);
    setProject((project) => ({
      ...project!,
      exportForms: project!.exportForms.map((ef) => (ef.id !== exportFormId ? ef : { ...ef, ...exportForm })),
    }));
  }, []);

  const formExists = (type: ExportFormType) => project?.exportForms.some((ef) => ef.type === type);

  const formCompleted = (type: ExportFormType) => {
    const exportForm = project?.exportForms.find((ef) => ef.type === type);
    return exportForm ? exportForm.formats.length > 0 : false;
  };

  const anyFormCompleted = () => {
    return !!project?.exportForms.some((ef) => ef.formats.length > 0);
  };

  return (
    <Page hasSidebar>
      {project && (
        <>
          <ProjectSidebar project={project} current="EXPORT" />
          <S.ExportPage>
            <ExportToolbar project={project} disabled={!anyFormCompleted()} onExport={handleExport} />
            <S.Scroll>
              <S.Content>
                <ExportFormPanel
                  key={exportForm?.id || 'empty'}
                  initialExportForm={exportForm}
                  project={project}
                  style={project.styleSelection}
                  onSave={handleSave}
                />
                <S.Cards>
                  <ExportCard
                    title={$s.exportPage.lineDiagram.title[project.styleSelection]}
                    subtitle={$s.exportPage.lineDiagram.subtitle[project.styleSelection]}
                    type="SLD"
                    activeType={activeType}
                    onClick={setActiveType}
                    disabled={!formExists('SLD')}
                    completed={formCompleted('SLD')}
                  />
                  <ExportCard
                    title={$s.exportPage.specificationDescription.title[project.styleSelection]}
                    subtitle={$s.exportPage.specificationDescription.subtitle[project.styleSelection]}
                    type="SSD"
                    activeType={activeType}
                    onClick={setActiveType}
                    disabled={!formExists('SSD')}
                    completed={formCompleted('SSD')}
                  />
                  <ExportCard
                    title={$s.exportPage.configurationDescription.title[project.styleSelection]}
                    subtitle={$s.exportPage.configurationDescription.subtitle[project.styleSelection]}
                    type="SCD"
                    activeType={activeType}
                    onClick={setActiveType}
                    disabled={!formExists('SCD')}
                    completed={formCompleted('SCD')}
                  />
                  <ExportCard
                    title={$s.exportPage.lanStructure.title[project.styleSelection]}
                    subtitle={$s.exportPage.lanStructure.subtitle[project.styleSelection]}
                    type="LAN"
                    activeType={activeType}
                    onClick={setActiveType}
                    disabled={!formExists('LAN')}
                    completed={formCompleted('LAN')}
                  />
                  <ExportCard
                    title={$s.exportPage.specification.title[project.styleSelection]}
                    type="GENERAL"
                    disabled={!formExists('GENERAL')}
                    activeType={activeType}
                    onClick={setActiveType}
                    completed={formCompleted('GENERAL')}
                  />
                  <ExportCard
                    title={$s.exportPage.cableList.title[project.styleSelection]}
                    type="CABLE"
                    disabled={!formExists('CABLE')}
                    activeType={activeType}
                    onClick={setActiveType}
                    completed={formCompleted('CABLE')}
                  />
                  <ExportCard
                    title={$s.exportPage.total.title[project.styleSelection]}
                    type="TCO"
                    disabled={!formExists('TCO')}
                    activeType={activeType}
                    onClick={setActiveType}
                    completed={formCompleted('TCO')}
                  />
                </S.Cards>
              </S.Content>
            </S.Scroll>
          </S.ExportPage>
          {overlayVisible && <Overlay />}
          <MessageModal error header="We are sorry" isOpen={!!errorMessage} onRequestClose={hideMessage}>
            {errorMessage}
          </MessageModal>
        </>
      )}
    </Page>
  );
};
