import styled from 'styled-components';

const OpexForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 24px 0;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.dark};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

export const Styled = {
  OpexForm,
  Name,
  Description,
};
