import * as React from 'react';
import { Btn } from '../../widgets/Btn.styled';
import { Toolbar } from '../../toolbar/Toolbar';

type ExportToolbarProps = {
  project: { name: string };
  disabled?: boolean;

  onExport(): void;
};

export const ExportToolbar: React.FC<ExportToolbarProps> = ({ project, disabled, onExport }) => {
  const nextBtn = (
    <Btn onClick={onExport} disabled={disabled}>
      Export
    </Btn>
  );

  return <Toolbar title={project.name} btn={nextBtn} />;
};
