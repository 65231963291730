import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import {
  HardwareInterfaceDto,
  HardwareDto,
  IcdReadDto,
  VendorViewDto,
  SvgDto,
} from '../../../../../../api/nggrace-back';
import { Styled as S } from './CardContent.styled';
import { InputField } from './InputField';
import { Api } from '../../../../../../api/Api';
import { InputSelect } from './InputSelect';
import { PhysicalInterface } from './PhysicalInterface';
import { useDirectory } from '../../../../../hooks/useDirectory';
import { DescriptionField } from './DescriptionField';
import { IcdFileField } from './IcdFileField';
import { SvgFileField } from './svg/SvgFileField';
import { CardType } from '../HardwareCard';
import { OptionType } from './svg/SvgInputSelect';
import { IOAnalogueDigital } from './IOAnalogueDigital';

interface CardContentProps {
  hardwareId?: number;
  hardwareHasIcd: boolean;
  cardType: CardType;
  hardware: HardwareDto;
  icdFileName?: string;
  icdFileContent?: IcdReadDto;
  svg?: SvgDto;
  setSvg: (svgDto: SvgDto) => void;
  icdLoading: boolean;
  handleOnHardwareChange: (hardware: HardwareDto) => void;
  handleScrollChange: (scrollTop: number, scrollHeight: number) => void;
  handleIcdReadAndValidate: (icd: File, onFulfilled: () => void) => void;
}

export const CardContent: React.FC<CardContentProps> = ({
  hardwareId,
  hardwareHasIcd,
  cardType,
  hardware,
  handleScrollChange,
  icdFileContent,
  svg,
  setSvg,
  icdLoading,
  handleOnHardwareChange,
  handleIcdReadAndValidate,
  icdFileName: initIcdFileName,
}) => {
  const { currencyDirectory, hardwareInterfaceDirectory } = useDirectory();

  const [icdFileName, setIcdFileName] = useState<string | undefined>(initIcdFileName);
  const [svgOptions, setSvgOptions] = useState<OptionType[]>([]);
  const [vendors, setVendors] = useState<VendorViewDto[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const hardwareInterfaceDirectoryItems = hardwareInterfaceDirectory.getAll().map((o) => {
    return {
      id: o.id,
      name: o.name.en,
    };
  });

  useLayoutEffect(() => {
    Api.getAllSvg().then((response) => {
      setSvgOptions(
        response.data.map((svg) => {
          return {
            id: svg.id,
            name: svg.name,
            basic: svg.basic,
            content: svg.content,
          } as OptionType;
        })
      );
    });
    Api.getAllVendors().then((response) => {
      setVendors(response.data);
    });
  }, []);

  const handleIcdFileChange = (icdFile: File) => {
    setIcdFileName(icdFile.name);
    handleIcdReadAndValidate(icdFile, () => handleOnHardwareChange({ ...hardware, icdFile }));
  };

  const onSvgChange = (svg: OptionType) => {
    if (svg)
      handleOnHardwareChange({
        ...hardware,
        restFields: {
          ...hardware.restFields,
          svgId: svg.id,
        },
      });
  };

  const onSvgFileUpload = (file: File) => {
    Api.saveSvgFile(file).then((response) => {
      const svgDto = {
        id: response.data.id,
        name: file.name,
        basic: false,
        content: response.data.content,
      };
      setSvg(svgDto);
      setSvgOptions((options) => [svgDto as OptionType, ...options]);
      handleOnHardwareChange({
        ...hardware,
        restFields: {
          ...hardware.restFields,
          svgId: response.data.id,
        },
      });
    });
  };

  const onDeleteSvg = (svgId: number) => {
    Api.deleteSvg(svgId).then(() => {
      setSvgOptions((options) => options.filter((o) => o.id !== svgId));
    });
  };

  const handleModelChange = (model: string) => {
    handleOnHardwareChange({ ...hardware, commonFields: { ...hardware.commonFields, model } });
  };

  const handleVendorChange = (vendorId: number) => {
    handleOnHardwareChange({ ...hardware, commonFields: { ...hardware.commonFields, vendorId } });
  };

  const handleNameForEqSpecChange = (nameForEquipmentSpec: string) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, nameForEquipmentSpec } });
  };

  const handleOrderCodeChange = (orderCode: string) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, orderCode } });
  };

  const handlePerformancePointsChange = (performancePoints: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, performancePoints } });
  };

  const handleDescriptionChange = (description: string) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, description } });
  };

  const handlePriceChange = (price: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, price } });
  };

  const handleLifetimeChange = (lifetime: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, lifetime } });
  };

  const handleFailureFlowParameterChange = (failureFlowParameter: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, failureFlowParameter } });
  };

  const handleRecoveryTimeChange = (recoveryTime: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, recoveryTime } });
  };

  const handleCurrencyChange = (currency: string) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, currency } });
  };

  const handleInterfacesChange = (interfaces: HardwareInterfaceDto[]) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, interfaces } });
  };

  const handleAnalogueIONumberChange = (analogueInputsOutputsNumber: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, analogueInputsOutputsNumber } });
  };

  const handleDiscreteIONumberChange = (discreteInputsOutputsNumber: number) => {
    handleOnHardwareChange({ ...hardware, restFields: { ...hardware.restFields, discreteInputsOutputsNumber } });
  };

  const onScroll = () => {
    const scrollTop = containerRef.current!.scrollTop;
    const scrollHeight = containerRef.current!.scrollHeight - containerRef.current!.clientHeight;
    handleScrollChange(scrollTop, scrollHeight);
  };

  const scrollToBottom = () => {
    containerRef.current!.scrollTo(0, containerRef.current!.scrollHeight - containerRef.current!.clientHeight);
  };

  const handleIcdContentDownload = useCallback(async () => {
    // setOverlayVisible(true);
    try {
      await Api.downloadIcdFile(hardwareId!, icdFileName!, {
        omitInterceptorErrorModal: true,
      });
      // .catch(() => setError('Unable to download an ICD file'));
    } finally {
      // setOverlayVisible(false);
    }
  }, [hardwareId, icdFileName]);

  const handleInterfaceAdd = () => {
    handleInterfacesChange([...hardware.restFields.interfaces, {} as HardwareInterfaceDto]);
  };

  return (
    <S.Container ref={containerRef} onScroll={onScroll}>
      <S.Column position={'left'}>
        <InputSelect
          onChange={handleVendorChange}
          cardType={cardType}
          label={'Vendor'}
          selected={hardware.commonFields.vendorId}
          items={vendors}
        />
        <InputField
          onChange={handleModelChange}
          cardType={cardType}
          label={'Model'}
          value={hardware.commonFields.model}
        />
        <InputField
          onChange={handleNameForEqSpecChange}
          cardType={cardType}
          label={'Name for an equipment specification'}
          value={hardware?.restFields?.nameForEquipmentSpec}
        />
        <InputField
          onChange={handleOrderCodeChange}
          cardType={cardType}
          label={'Order code'}
          value={hardware?.restFields?.orderCode}
        />
        <DescriptionField
          onChange={handleDescriptionChange}
          cardType={cardType}
          label={'Description'}
          value={hardware?.restFields?.description}
        />
        <p>Physical interface {cardType !== 'view' && <span onClick={handleInterfaceAdd}>+Add</span>}</p>
        {hardware.restFields?.interfaces?.map((hardwareInterface, index) => (
          <PhysicalInterface
            cardType={cardType}
            index={index}
            selected={hardwareInterface.directoryId}
            number={hardwareInterface.number}
            interfaces={hardware.restFields.interfaces}
            handleInterfacesChange={handleInterfacesChange}
            items={hardwareInterfaceDirectoryItems.filter(
              (hardwareInterfaceItem) =>
                !hardware.restFields.interfaces
                  .filter((o) => o.directoryId !== hardwareInterface.directoryId)
                  .map((i) => i.directoryId)
                  .includes(hardwareInterfaceItem.id)
            )}
          />
        ))}
        <p>Digital and analog inputs/outputs</p>
        <IOAnalogueDigital
          analogueInputsOutputsNumber={hardware.restFields.analogueInputsOutputsNumber}
          discreteInputsOutputsNumber={hardware.restFields.discreteInputsOutputsNumber}
          cardType={cardType}
          handleAnalogueIONumberChange={handleAnalogueIONumberChange}
          handleDiscreteIONumberChange={handleDiscreteIONumberChange}
        />
      </S.Column>
      <S.Column position={'right'}>
        <InputField
          integer
          onChange={handlePerformancePointsChange}
          inputType={'number'}
          cardType={cardType}
          label={'Performance points'}
          value={hardware?.restFields?.performancePoints}
        />
        <InputSelect
          onChange={handleCurrencyChange}
          cardType={cardType}
          label={'Currency'}
          selected={hardware?.restFields?.currency}
          items={currencyDirectory.getAll().map((entry) => {
            return {
              id: entry.id,
              name: entry.name.en,
            };
          })}
        />
        <InputField
          onChange={handlePriceChange}
          inputType={'number'}
          cardType={cardType}
          label={'Price'}
          value={hardware?.restFields?.price}
        />
        <InputField
          integer
          onChange={handleLifetimeChange}
          inputType={'number'}
          cardType={cardType}
          label={'Device lifetime, years'}
          value={hardware?.restFields?.lifetime}
        />
        <InputField
          onChange={handleFailureFlowParameterChange}
          inputType={'number'}
          cardType={cardType}
          label={'Failure flow, failures per year'}
          value={hardware?.restFields?.failureFlowParameter}
        />
        <InputField
          integer
          onChange={handleRecoveryTimeChange}
          inputType={'number'}
          cardType={cardType}
          label={'Recovery time, hours'}
          value={hardware?.restFields?.recoveryTime}
        />
        {hardwareHasIcd && (
          <IcdFileField
            icdLoading={icdLoading}
            cardType={cardType}
            fileName={icdFileName}
            icdFileContent={icdFileContent}
            handleIcdContentDownload={handleIcdContentDownload}
            handleIcdFileChange={handleIcdFileChange}
          />
        )}
        <SvgFileField
          cardType={cardType}
          svg={svg}
          setSvg={setSvg}
          options={svgOptions}
          onSvgChange={onSvgChange}
          onSvgFileUpload={onSvgFileUpload}
          scrollToBottom={scrollToBottom}
          onSvgDelete={onDeleteSvg}
        />
      </S.Column>
    </S.Container>
  );
};
