import { NgGraceModel } from '../NgGraceModel';
import { NodeDirectory } from '../directory/NodeDirectory';
import { ExportFormatType, ExportFormDto, StageDto } from '../../../api/nggrace-back';
import FileDownload from 'js-file-download';
import { Api } from '../../../api/Api';
import { exportSvg } from './ExportSvg';
import {
  NetworkDeviceDirectoryEntry,
  PropertiesDirectory,
  VoltageLevelDirectoryEntry
} from '../directory/PropertiesDirectory';

export const exportStage = async (
  directory: NodeDirectory,
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>,
  networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>,
  model: NgGraceModel,
  stage: StageDto,
  dto: ExportFormDto,
  format: ExportFormatType
) => {
  switch (format) {
    case 'SVG':
      const svg = await exportSvg({
        directory,
        voltageLevelDirectory,
        networkDeviceDirectory,
        model: model,
        project: stage.project,
        dto,
      });
      FileDownload(svg, `${stage.project.name}-${stage.baseInfo.type}.svg`, 'image/svg+xml');
      return;
    case 'SCL':
      return Api.exportScl(stage.baseInfo.id, { omitInterceptorErrorModal: true }).then((response) => {
        FileDownload(response.data.value, `${stage.project.name}-${stage.baseInfo.type}.ssd`, 'application/xml');
      });
    default:
      return Promise.reject({ response: { data: 'Unsupported export format type' } });
  }
};
