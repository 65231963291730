import { ConnectivityNodeFactory } from '../../../connectivity/ConnectivityNodeFactory';
import { ConnectivityNodeWithPlaceholdersModel } from '../../../connectivity/ConnectivityNodeWithPlaceholdersModel';
import { DirectoryLogicDeviceModel } from '../../logic-device/LogicDeviceModel';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';
import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import { StageType } from '../../../../../api/nggrace-back';

export class SsdConnectivityNodeFactory extends ConnectivityNodeFactory {
  private stage: StageType = 'SSD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new ConnectivityNodeWithPlaceholdersModel<DirectoryLogicDeviceModel>(
      (node) =>
        new DefaultNodeWithPlaceholders<DirectoryLogicDeviceModel>(node, node, this.stage, event.initialConfig.SSD)
    );
  }
}
